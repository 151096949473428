/* eslint-disable dot-notation */
import React, { useEffect, useState } from 'react';
import {
	Box, Button, Container, Flex, Icon, SimpleGrid, Skeleton, Text, Tooltip, useColorModeValue as mode, useDisclosure, keyframes,
} from '@chakra-ui/react';

import { Link, useParams, useLocation } from 'react-router-dom';
import { FaWallet } from '@react-icons/all-files/fa/FaWallet';
import { AiOutlineEnter } from '@react-icons/all-files/ai/AiOutlineEnter';
import { VscRunAll } from '@react-icons/all-files/vsc/VscRunAll';
import { IoChevronBack } from '@react-icons/all-files/io5/IoChevronBack';
import { AiOutlineCheck } from '@react-icons/all-files/ai/AiOutlineCheck';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { AiOutlineEdit } from '@react-icons/all-files/ai/AiOutlineEdit';
import _ from 'lodash';
import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle';
import TicketModal from '../common/TicketModal';
import { timeDifference } from '../../../helpers/formating';
import ChecksWrapper from '../../common/ChecksWrapper';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { formatValue } from '../../../helpers/util';
import ClonedStrategyBlock from '../common/ClonedStrategyBlock';
import { useClonedStrategyDetails } from '../hooks/useClonedStrategyDetails';
import { StrategyAssetDetailsGroup } from '../../common/AssetDetails/AssetDetailsGroup';
import StrategyDetailsHeader from '../../common/StrategyDetailsHeader';
import { ResponsiveTooltip } from '../../common/Tooltip';

const buttonStyles = () => ({
	bg: mode('white', 'gray.900'),
	border: mode('2px solid rgb(0, 0, 0)', '2px solid white'),
	size: 'lg',
	my: '1',
	mx: '2',
	w: { base: '260px', md: '209px' },
});

const glowing = keyframes`
  0% {
    box-shadow: 0 0 5px #2ba805;
  }
  50% {
    box-shadow: 0 0 20px #49e819;
  }
  100% {
    box-shadow: 0 0 5px #2ba805;
  }
`;

function ClonedStrategyView() {
	const { tag, hash } = useParams();
	const glowAnimation = `${glowing} 1.5s infinite linear`;

	// TODO: Split into another custom hook because it's too big now
	const {
		totalProfit,
		blocksInfo,
		isWithdrawingProfit,
		isWithdrawingAll,
		blockSettingsList,
		setBlockSettingsList,
		isUpdatingSettings,
		updateSettings,
		isEditingSetting,
		cancelEditing,
		setIsEditingSettings,
		canWithdrawAll,
		withdrawProfit,
		withdrawAll,
		getDetails,
		pendingRewards,
		runnableCheck,
		strategyInfo,
		totalDeposit,
	} = useClonedStrategyDetails(hash, tag);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const doesAddUp = blockSettingsList && blockSettingsList.map((i) => i.length === 4 || (i.length === 6 && _.sum(i) === 200) || (_.sum(i) === 100 && true)).every((el) => el);

	const checkError = () => blockSettingsList && blockSettingsList.map((i) => i.length === 4 || (i.length === 6 && _.sum(i) === 200) || _.sum(i) === 100);

	const [blockError, setBlockError] = useState([false, false, false]);

	useDocumentTitle(`Bloomify - ${strategyInfo ? strategyInfo.name : 'Loading...'}`);

	useEffect(() => {
		setBlockError(() => checkError());
	}, [blockSettingsList]);

	const closeModal = async () => {
		onClose();
		await getDetails();
	};

	const stats = [
		{
			label: 'Deposit',
			value: `$${formatValue(totalDeposit)}`,
		},
		{
			label: 'Profit',
			value: `$${formatValue(totalProfit)}`,
		},
		{
			label: 'Pending Rewards',
			value: `$${formatValue(pendingRewards)}`,
		},
		{
			label: 'Last Run',
			value: strategyInfo && strategyInfo.lastRunTime === 0
				? 'Never' : strategyInfo && timeDifference(new Date().getTime(), new Date(strategyInfo.lastRunTime * 1000).getTime()),
		},
	];

	return (
    <ChecksWrapper>
        <Box px={{ base: 2, lg: 5 }} pb={16}>
            {isOpen && strategyInfo && <TicketModal isOpen={isOpen} onClose={closeModal} strategyAddr={strategyInfo.strategyAddress} />}
            <Container maxW="container.xl">
                <Link to="/portfolio">
                    <Button leftIcon={<Icon as={IoChevronBack} />} colorScheme="grey" variant="link" my={4}>
                        Go back to my portfolio
                    </Button>
                </Link>

                <Skeleton isLoaded={!!strategyInfo && !!blocksInfo} mt={2}>
                    {!!strategyInfo && (
                    	<>
                        <StrategyDetailsHeader strategy={strategyInfo} stats={stats} hasTour />
                        <Flex flexDir={{ base: 'column', md: 'row' }} flexWrap="wrap" justifyContent="center" align="center" pt={2}>
                            <Button
                                data-tut="reactour__step-legos__edit"
                                {...buttonStyles()}
                                leftIcon={isEditingSetting ? <AiOutlineClose /> : <AiOutlineEdit />}
                                onClick={() => (isEditingSetting ? cancelEditing() : setIsEditingSettings.on())}
                                disabled={!strategyInfo || strategyInfo.deprecated}
                                isLoading={isUpdatingSettings}>
                                {isEditingSetting ? 'Cancel' : 'Edit Settings'}
                            </Button>
                            <ResponsiveTooltip hasArrow label={!doesAddUp ? "Block doesn't add up to 100%" : ''} shouldWrapChildren mt="3">
                                {isEditingSetting && (
                                <Button
                                    {...buttonStyles()}
                                    isLoading={isUpdatingSettings}
                                    leftIcon={<AiOutlineCheck />}
                                    isDisabled={!doesAddUp}
                                    onClick={updateSettings}>
                                    Save Changes
                                </Button>
                                )}
                            </ResponsiveTooltip>
                            <Button
                                {...buttonStyles()}
                                leftIcon={<AiOutlineEnter />}
                                onClick={withdrawAll}
                                isDisabled={totalDeposit === 0 || !canWithdrawAll}
                                isLoading={isWithdrawingAll}>
                                Withdraw All
                            </Button>
                            <Button
                                {...buttonStyles()}
                                leftIcon={<FaWallet />}
                                onClick={withdrawProfit}
                                isDisabled={totalProfit === 0}
                                isLoading={isWithdrawingProfit}>
                                Withdraw Profit
                            </Button>
                        </Flex>
                        <Flex direction="column" flexWrap="wrap" justifyContent="center" align="center" py={2}>
                            {/* <Tooltip hasArrow label={!runnableCheck.isRunnable ? runnableCheck.reason: ""} shouldWrapChildren mt="3"> */}
                            {/* <Button
                                data-tut="reactour__step-legos__run"
                                {...buttonStyles()}
                                leftIcon={<VscRunAll />}
                                boxShadow={mode('sm', 'sm-dark')}
                                animation={runnableCheck.isRunnable ? glowAnimation : undefined}
                                isDisabled={!runnableCheck.isRunnable}
                                onClick={onOpen}>
                                Run
                            </Button> */}
                            {strategyInfo &&
                  (!runnableCheck.isRunnable || (strategyInfo && runnableCheck.isRunnable && !strategyInfo.lastRunTime) || (runnableCheck.isRunnable && runnableCheck.reason)) ? (
                      <Flex align="center" direction="row" justify="center">
                          <Icon as={FaExclamationTriangle} mx="auto" py={1} w={5} h={5} color="orange" />
                          {!runnableCheck.isRunnable && (
                          <Text fontSize="sm" mx="auto" mt="0.05rem" textAlign="center" color="orange">
                              {runnableCheck.reason}
                          </Text>
                          )}
                          {runnableCheck.isRunnable && !strategyInfo.lastRunTime && (
                          <Text fontSize="sm" mx="auto" mt="0.05rem" textAlign="center" color="orange">
                              Strategy has never been run
                          </Text>
                          )}
                          {runnableCheck.isRunnable && runnableCheck.reason && !!strategyInfo.lastRunTime && (
                          <Text fontSize="sm" mx="auto" mt="0.1rem" textAlign="center" color="orange">
                              {runnableCheck.reason}
                          </Text>
                          )}
                      </Flex>
                  	) : null}
                            {/* </Tooltip> */}
                        </Flex>
                    	</>
                    )}
                </Skeleton>
            </Container>
            <Skeleton isLoaded={blocksInfo && strategyInfo} mt={3}>
                <Container maxW={blocksInfo && blocksInfo.length === 3 ? 'container.3xl' : 'container.2xl'}>
                    <SimpleGrid
                        data-tut="reactour__step-legos__card"
                        w="max-content"
                        maxW="100%"
                        mx="auto"
                        justifyContent="center"
                        spacing={{ base: 2, md: 5 }}
                        py={6}
                        overflowY="auto"
                        columns={{ lg: 1, md: 1, sm: 1 }}
                        templateColumns={{
                        	lg: 'auto',
                        	xl: blocksInfo && blocksInfo.length === 1 ? 'auto' : blocksInfo && blocksInfo.length === 2 ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
                        }}>
                        {strategyInfo &&
                blockSettingsList &&
                blocksInfo &&
                blocksInfo.map((block: any, index: number) => {
                	const setBlockList = (newList: Array<any>) => {
                		const listCopy = [...blockSettingsList];
                		listCopy[index] = newList;
                		setBlockSettingsList(listCopy);
                	};
                	return (
                    <ClonedStrategyBlock
                        error={!blockError[index]}
                        deprecated={strategyInfo.deprecated}
                        isEditSettings={isEditingSetting}
                        key={index}
                        blockIndex={index}
                        currentBlock={block}
                        blocks={blocksInfo}
                        blockPercentages={blockSettingsList[index]}
                        setPercentages={setBlockList}
                        onDepositSuccess={getDetails}
                    />
                	);
                })}
                    </SimpleGrid>
                </Container>
            </Skeleton>
            {strategyInfo && <StrategyAssetDetailsGroup blocks={strategyInfo.blocks} />}
        </Box>
    </ChecksWrapper>
	);
}
export default ClonedStrategyView;
