import { createContext, useContext } from 'react';

export type TokensBalances = {
    balances: any
    setBalances: (newTokenObject: any) => void
    getTokenBalance: (tokenDetails: any) => any;
}

export const TokensBalancesContext = createContext<TokensBalances|null>(null);
export const useTokenBalance = () => useContext<TokensBalances|null>(TokensBalancesContext);
