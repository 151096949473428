import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { getRemoteTokenPrices, getTokenPrice as externalGetTokenPrice, getTokenDetails as remoteGetTokenDetails } from '../service/tokens.service';
import useWeb3 from './useWeb3';

export const useTokens = () => {
	const [tokens, setTokens] = useState<any>({});

	const getTokenPrice = (tokenDetails) => externalGetTokenPrice(tokenDetails, tokens);

	const providedTokens = useMemo<any>(() => ({ tokens, setTokens, getTokenPrice }), [tokens, setTokens]);

	const get = async () => {
		const result = await getRemoteTokenPrices();
		setTokens(result);
	};

	useEffect(() => {
		get();
		setInterval(() => get(), 60000);
	}, []);

	return {
		tokens, providedTokens, getTokenPrice,
	};
};
