/* eslint-disable no-shadow */
import { ContractCallContext } from 'ethereum-multicall';
import Web3 from 'web3';
import { BigNumber } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import _ from 'lodash';
import { defaultChain } from '../../../bloomify/config';
import { doMulticall } from '../../../service/multicall.service';
import {
	onConfirmation, onError, onReceipt, onSending, onSent, onTransactionHash,
} from '../../../utils/web3Handlers';
import { getTicketPrices } from './ticket.service';
import { getBlockAPR } from './block.service';
import { getExternallyCalculatedStatistics } from '../../../service/remote.service';
import {getGasPriceOptions} from "../../../utils/gasUtils";

const _generateClonedStrategyCallsContext = (strategyAddress: string): ContractCallContext[] => [
	{
		reference: 'settings',
		contractAddress: strategyAddress,
		abi: defaultChain.deployments.StrategyRunner.abi,
		calls: [
			{
				reference: 'settings',
				methodName: 'getSettings',
				methodParameters: [],
			},
		],
	},
	{
		reference: 'blocks',
		contractAddress: strategyAddress,
		abi: defaultChain.deployments.StrategyRunner.abi,
		calls: [
			{
				reference: 'settings',
				methodName: 'getBlocks',
				methodParameters: [],
			},
		],
	},
	{
		reference: 'lastRunTime',
		contractAddress: strategyAddress,
		abi: defaultChain.deployments.StrategyRunner.abi,
		calls: [
			{
				reference: 'lastRunTime',
				methodName: 'lastRunTime',
				methodParameters: [],
			},
		],
	},
	{
		reference: 'profitBalance',
		contractAddress: strategyAddress,
		abi: defaultChain.deployments.StrategyRunner.abi,
		calls: [
			{
				reference: 'profitBalance',
				methodName: 'profitBalance',
				methodParameters: [],
			},
		],
	},
	{
		reference: 'profitToken',
		contractAddress: strategyAddress,
		abi: defaultChain.deployments.StrategyRunner.abi,
		calls: [
			{
				reference: 'profitToken',
				methodName: 'profitToken',
				methodParameters: [],
			},
		],
	},
];

const _generateClonedStrategyBlockAdressesAndLastRunTimeContext = (strategyAddress: string): ContractCallContext[] => [
	{
		reference: 'blocks',
		contractAddress: strategyAddress,
		abi: defaultChain.deployments.StrategyRunner.abi,
		calls: [
			{
				reference: 'settings',
				methodName: 'getBlocks',
				methodParameters: [],
			},
		],
	},
	{
		reference: 'profitBalance',
		contractAddress: strategyAddress,
		abi: defaultChain.deployments.StrategyRunner.abi,
		calls: [
			{
				reference: 'profitBalance',
				methodName: 'profitBalance',
				methodParameters: [],
			},
		],
	},
	{
		reference: 'lastRunTime',
		contractAddress: strategyAddress,
		abi: defaultChain.deployments.StrategyRunner.abi,
		calls: [
			{
				reference: 'lastRunTime',
				methodName: 'lastRunTime',
				methodParameters: [],
			},
		],
	},
];

export const getDefaultStrategies = async (tag?: any): Promise<Array<any>> => {
	const generalStats = await getExternallyCalculatedStatistics();
	const strategies = tag ? [defaultChain.strategies.find((s) => s.tag === tag || s.deprecatedTags.includes(tag))] : defaultChain.strategies;

	const defaultStrats = await Promise.all(strategies
		.filter((x) => x)
		.map(async (s) => {
			const blocks = await Promise.all(s.blocks.map(async (b) => {
				const aprDetails = await getBlockAPR(b.address);
				return { ...b, aprDetails, dailyRate: aprDetails ? aprDetails.totalApr / 365 : null };
			}));

			const tvl = generalStats ? generalStats.tvls[s.tag] : 0;

			return { ...s, blocks, tvl };
		}));

	return defaultStrats;
};

// export const getValueInStableForCustomAmounts = async (web3, tokenAddress, customHexAmount, tokenPrices) => {
// 	const customAmountTokensContex = getSingleTokenContextWithCustomAmounts(tokenAddress, customHexAmount);

// 	if (customAmountTokensContex.context) {
// 		const response = await doMulticall(web3, customAmountTokensContex.context);

// 		// after we have a case we need to see if we should add div(1e18)
// 		return BigNumber.from(response[customAmountTokensContex.reference][customAmountTokensContex.index].hex).div(1e9).div(1e9);
// 	}
// 	if (customAmountTokensContex.lp) {
// 		return parseUnits(tokenPrices.address[tokenAddress.toLowerCase()].toString()).mul(BigNumber.from(customHexAmount)).div(1e9).div(1e9);
// 	}

// 	return BigNumber.from(0);
// };

export const getClonedStrategyInfo = async (web3: Web3, strategyAddress: string, tag: any) => {
	const context = _generateClonedStrategyCallsContext(strategyAddress);
	const multicall = await doMulticall(web3, context);
	const defaultStrat = (await getDefaultStrategies(tag))[0];
	const blocks = multicall.blocks;

	return defaultStrat
		? {
			...defaultStrat,
			strategyAddress,
			profitToken: multicall.profitToken[0],
			profitBalance: multicall.profitBalance[0],
			lastRunTime: parseInt(multicall.lastRunTime[0].hex, 16),
			settings: multicall.settings,
			blocks: defaultStrat.blocks.map((b, index) => ({
				...b,
				address: blocks[index],
			})),
			blockAdresses: multicall.blocks,
			deprecated: defaultStrat.deprecatedTags.length && defaultStrat.deprecatedTags.includes(tag),
		}
		: null;
};

export const getGeneralInfoForAClonedStrategy = async (web3: Web3, strategyAddress: string, tag: any) => {
	const context = _generateClonedStrategyBlockAdressesAndLastRunTimeContext(strategyAddress);
	const multicall = await doMulticall(web3, context);
	const defaultStrat = (await getDefaultStrategies(tag))[0];

	return defaultStrat
		? {
			...defaultStrat,
			strategyAddress,
			lastRunTime: parseInt(multicall.lastRunTime[0].hex, 16),
			blocks: defaultStrat.blocks.map((b, index) => ({
				...b,
				address: multicall.blocks[index],
			})),
			profitBalance: multicall.profitBalance[0],
			blockAdresses: multicall.blocks,
			deprecated: defaultStrat.deprecatedTags.length && defaultStrat.deprecatedTags.includes(tag),
		}
		: null;
};

export const estimateRunStrategyGas = async (web3: Web3, strategyAddr: string, walletAddr: string, errorCallback?: () => void) => {
	let bnbGas;
	let staticGas;
	const tickets = await getTicketPrices(web3, walletAddr);
	const sentryTicket = new web3.eth.Contract(defaultChain.deployments.SentryTicket.abi, defaultChain.addressBook.general.SentryTicket, { from: walletAddr });
	const gasPrice = await web3.eth.getGasPrice();

	try {
		const estimateBnbGas = await sentryTicket.methods.buyAndRunViaEther(strategyAddr).estimateGas({
			value: tickets[1],
			from: walletAddr,
		});
		bnbGas = (parseInt(gasPrice) * estimateBnbGas) / 1e18;
	} catch (error) {}
	try {
		const estimateStaticGas = await sentryTicket.methods.buyAndRun(strategyAddr).estimateGas({ from: walletAddr });
		staticGas = (parseInt(gasPrice) * estimateStaticGas) / 1e18;
	} catch (error) {}

	return bnbGas || staticGas || 0;
};

export const runStrategy = async (web3: Web3, strategyAddr: string, walletAddr: string, errorCallback?: () => void) => {
	const strategyRunner = new web3.eth.Contract(defaultChain.deployments.StrategyRunner.abi, strategyAddr, { from: walletAddr });
	const gasPrices = await getGasPriceOptions(web3);
	return await strategyRunner.methods
		.run()
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', errorCallback || onError);
};

export const withdrawStrategyProfit = async (web3: Web3, strategyAddr: string, walletAddr: string, errorCallback?: () => void) => {
	const strategyRunner = new web3.eth.Contract(defaultChain.deployments.StrategyRunner.abi, strategyAddr, { from: walletAddr });
	const gasPrices = await getGasPriceOptions(web3);
	return await strategyRunner.methods
		.withdrawProfit()
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', errorCallback || onError);
};

export const withdrawStrategyFunds = async (web3: Web3, strategyAddr: string, walletAddr: string, errorCallback?: () => void) => {
	const strategyRunner = new web3.eth.Contract(defaultChain.deployments.StrategyRunner.abi, strategyAddr, { from: walletAddr });
	const gasPrices = await getGasPriceOptions(web3);
	return await strategyRunner.methods
		.withdrawAll()
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', errorCallback || onError);
};

export const updateStrategySettings = async (web3: Web3, strategyAddr: string, settings: any, walletAddr: string, errorCallback?: () => void) => {
	const strategyRunner = new web3.eth.Contract(defaultChain.deployments.StrategyRunner.abi, strategyAddr, { from: walletAddr });
	const gasPrices = await getGasPriceOptions(web3);
	return await strategyRunner.methods
		.setSettings(settings)
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', errorCallback || onError);
};
