/* eslint-disable no-else-return */
import { ContractCallContext } from 'ethereum-multicall';
import Web3 from 'web3';
import { BigNumber } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import _ from 'lodash';
import moment from 'moment';
import { defaultChain } from '../../../bloomify/config';
import { doMulticall } from '../../../service/multicall.service';
import {
	onConfirmation,
	onError,
	onReceipt,
	onSending,
	onSent,
	onTransactionHash,
} from '../../../utils/web3Handlers';
import {
	fetchTokenPrice,
} from '../../../service/tokens.service';
import { getExternallyCalculatedStatistics } from '../../../service/remote.service';
import {getGasPriceOptions} from "../../../utils/gasUtils";

const _generateFarmCallsContext = (
	farmObject: any,
	walletAddress?,
): ContractCallContext[] => {
	if (!farmObject.isGovernance) {
		const context = [
			{
				reference: 'startBlock',
				contractAddress: farmObject.address,
				abi: defaultChain.deployments[farmObject.deployment].abi,
				calls: [
					{
						reference: 'startBlock',
						methodName: defaultChain.time ? 'startTime' : 'startBlock',
						methodParameters: [],
					},
				],
			},
			{
				reference: 'bonusEndBlock',
				contractAddress: farmObject.address,
				abi: defaultChain.deployments[farmObject.deployment].abi,
				calls: [
					{
						reference: 'bonusEndBlock',
						methodName: defaultChain.time ? 'bonusEndTime' : 'bonusEndBlock',
						methodParameters: [],
					},
				],
			},
		];

		if (walletAddress) {
			context.push({
				reference: 'userInfo',
				contractAddress: farmObject.address,
				abi: defaultChain.deployments[farmObject.deployment].abi,
				calls: [
					{
						reference: 'userInfo',
						methodName: 'userInfo',
						methodParameters: [0, walletAddress],
					},
				],
			});

			context.push({
				reference: 'pendingReward',
				contractAddress: farmObject.address,
				abi: defaultChain.deployments[farmObject.deployment].abi,
				calls: [
					{
						reference: 'pendingReward',
						methodName: 'pendingReward',
						methodParameters: [walletAddress, 0],
					},
				],
			});
		}

		return context;
	} else {
		const context = [

		];

		if (walletAddress) {
			context.push({
				reference: 'userInfo',
				contractAddress: farmObject.address,
				abi: defaultChain.deployments[farmObject.deployment].abi,
				calls: [
					{
						reference: 'userInfo',
						methodName: 'balanceOf',
						methodParameters: [walletAddress],
					},
				],
			});

			context.push({
				reference: 'pendingReward',
				contractAddress: farmObject.address,
				abi: defaultChain.deployments[farmObject.deployment].abi,
				calls: [
					{
						reference: 'pendingReward',
						methodName: 'earned',
						methodParameters: [walletAddress],
					},
				],
			});
		}

		return context;
	}
};

export const getFarmAPR = async (farmAddress) => {
	let generalStats = JSON.parse(localStorage.getItem('calculatedStats'));

	if (!generalStats) {
		await getExternallyCalculatedStatistics();
		generalStats = JSON.parse(localStorage.getItem('calculatedStats'));
	}

	generalStats = generalStats ? generalStats.result : null;

	let calculatedFarmApr = generalStats ? generalStats.aprs[farmAddress] : null;

	if (!calculatedFarmApr && generalStats) calculatedFarmApr = _.find(generalStats.aprs, (_val, addr) => farmAddress.toLowerCase() === addr.toLowerCase());

	return calculatedFarmApr;
};

export const getFarmTVL = async (farmAddress) => {
	let generalStats = JSON.parse(localStorage.getItem('calculatedStats'));

	if (!generalStats) {
		await getExternallyCalculatedStatistics();
		generalStats = JSON.parse(localStorage.getItem('calculatedStats'));
	}

	generalStats = generalStats ? generalStats.result : null;

	let calculatedFarmTVL = generalStats ? generalStats.tvls[farmAddress] : null;

	if (!calculatedFarmTVL && generalStats) calculatedFarmTVL = _.find(generalStats.tvls, (_val, addr) => farmAddress.toLowerCase() === addr.toLowerCase());

	return calculatedFarmTVL;
};

export const fetchFarms = async (
	web3: Web3,
	farmAddress?: string,
	walletAddress?: string,
): Promise<Array<any>> => {
	const farms = farmAddress
		? [
			defaultChain.farms.find(
				(s) => s.address === farmAddress,
			),
		]
		: defaultChain.farms;

	const fetchedFarms = await Promise.all(
		farms
			.filter((x) => x)
			.map(async (f) => {
				const farmContext = _generateFarmCallsContext(f, walletAddress);
				const response = await doMulticall(web3, farmContext);
				const depositTokenPrice = await fetchTokenPrice(f.depositToken);
				const earnTokenPrice = await fetchTokenPrice(f.earn);
				const balance = response.userInfo && response.userInfo[0]
					? Number(formatEther(BigNumber.from(response.userInfo[0].hex))) : 0;
				const earnings = response.pendingReward && response.pendingReward[0]
					? Number(formatEther(BigNumber.from(response.pendingReward[0].hex))) : 0;
				const tvl = await getFarmTVL(f.address);
				const aprDetails = await getFarmAPR(f.address);

				const baseFarmDetails = {
					...f,
					tvl,
					aprDetails,
					dailyRate: aprDetails ? aprDetails.totalApr / 365 : 0,
					depositToken: {
						...f.depositToken,
						address: f.depositToken.address,
						price: depositTokenPrice,
					},
					depositDetails: {
						amount: balance,
						value: depositTokenPrice * balance,
						hex: response.userInfo && response.userInfo[0] ? BigNumber.from(response.userInfo[0].hex)._hex : BigNumber.from(0)._hex,
					},
					earningsDetails: {
						amount: earnings,
						value: earnTokenPrice * earnings,
						hex: response.pendingReward && response.pendingReward[0] ? BigNumber.from(response.pendingReward[0])._hex : BigNumber.from(0)._hex,
					},
				};

				if (!f.isGovernance) {
					const startBlock = BigNumber.from(response.startBlock[0].hex).toNumber();
					const endBlock = BigNumber.from(response.bonusEndBlock[0].hex).toNumber();
					const currentBlock = defaultChain.time ? (new Date().getTime() / 1000) : await web3.eth.getBlockNumber();

					return {
						...baseFarmDetails,
						startBlock: defaultChain.time ? moment.unix(startBlock).format('DD/MM/YY hh:mmA') : startBlock,
						endBlock: defaultChain.time ? moment.unix(endBlock).format('DD/MM/YY hh:mmA') : endBlock,
						currentBlock,
						pending: startBlock > currentBlock,
						finished: endBlock < currentBlock,
					};
				} else {
					return baseFarmDetails;
				}
			}),
	);

	localStorage.setItem('fetchedFarms', JSON.stringify(fetchedFarms));

	return fetchedFarms;
};

export const depositAmountInFarm = async (
	web3: Web3,
	amount: BigNumber,
	farmAddress: string,
	walletAddr: string,
	errorCallback?: any,
	onReceiptCallback?: any,
) => {
	const farms = JSON.parse(localStorage.getItem('fetchedFarms'));
	const farm = farms.find((v) => v.address.toLowerCase() === farmAddress.toLowerCase());

	const farmContract = new web3.eth.Contract(
		defaultChain.deployments[farm.deployment].abi,
		farmAddress,
		{ from: walletAddr },
	);

	const method = farm.isGovernance ? farmContract.methods
		.stake(amount) : farmContract.methods
		.deposit(amount, 0);
	const gasPrices = await getGasPriceOptions(web3);
	return await method
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceiptCallback || onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', errorCallback || onError);
};

export const depositAllInFarm = async (
	web3: Web3,
	amount: BigNumber,
	farmAddress: string,
	walletAddr: string,
	errorCallback?: any,
	onReceiptCallback?: any,
) => {
	const farms = JSON.parse(localStorage.getItem('fetchedFarms'));
	const farm = farms.find((v) => v.address.toLowerCase() === farmAddress.toLowerCase());

	const farmContract = new web3.eth.Contract(
		defaultChain.deployments[farm.deployment].abi,
		farmAddress,
		{ from: walletAddr },
	);

	const method = farm.isGovernance ? farmContract.methods
		.stake(amount) : farmContract.methods
		.deposit(amount, 0);

	const gasPrices = await getGasPriceOptions(web3);
	return await method
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceiptCallback || onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', errorCallback || onError);
};

export const withdrawAmountFromFarm = async (
	web3: Web3,
	amount: BigNumber,
	farmAddress: string,
	walletAddr: string,
	errorCallback?: any,
	onReceiptCallback?: any,
) => {
	const farms = JSON.parse(localStorage.getItem('fetchedFarms'));
	const farm = farms.find((v) => v.address.toLowerCase() === farmAddress.toLowerCase());

	const farmContract = new web3.eth.Contract(
		defaultChain.deployments[farm.deployment].abi,
		farmAddress,
		{ from: walletAddr },
	);

	const method = farm.isGovernance ? farmContract.methods
		.withdraw(amount) : farmContract.methods
		.withdraw(amount, 0);

	const gasPrices = await getGasPriceOptions(web3);
	return await method
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceiptCallback || onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', errorCallback || onError);
};

export const claimEarningsFromFarm = async (
	web3: Web3,
	farmAddress: string,
	walletAddr: string,
) => {
	const farms = JSON.parse(localStorage.getItem('fetchedFarms'));
	const farm = farms.find((v) => v.address.toLowerCase() === farmAddress.toLowerCase());

	const farmContract = new web3.eth.Contract(
		defaultChain.deployments[farm.deployment].abi,
		farmAddress,
		{ from: walletAddr },
	);

	const method = farm.isGovernance ? farmContract.methods
		.getReward() : farmContract.methods
		.withdraw(0, 0);

	const gasPrices = await getGasPriceOptions(web3);
	return await method
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', onError);
};

export const withdrawAllFromFarm = async (
	web3: Web3,
	farmAddress: string,
	walletAddr: string,
	errorCallback?: any,
	onReceiptCallback?: any,
) => {
	const farms = JSON.parse(localStorage.getItem('fetchedFarms'));
	const farm = farms.find((v) => v.address.toLowerCase() === farmAddress.toLowerCase());

	const farmContract = new web3.eth.Contract(
		defaultChain.deployments[farm.deployment].abi,
		farmAddress,
		{ from: walletAddr },
	);

	const method = farm.isGovernance ? farmContract.methods
		.withdraw(BigNumber.from(farm.depositDetails.hex)) : farmContract.methods
		.withdraw(BigNumber.from(farm.depositDetails.hex), 0);

	const gasPrices = await getGasPriceOptions(web3);
	return await method
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceiptCallback || onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', errorCallback || onError);
};
