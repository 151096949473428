import React, { useState } from 'react';
import {
	Box,
	BoxProps,
	Center,
	HStack,
	Link as ExternalLink,
	Text,
	Stack,
	StackProps,
	useColorModeValue,
	useDisclosure,
	Flex,
	Skeleton,
} from '@chakra-ui/react';
import { HiX } from '@react-icons/all-files/hi/HiX';
import { HiOutlineMenu } from '@react-icons/all-files/hi/HiOutlineMenu';
import { useLocation } from 'react-router-dom';
import { NavLink } from './NavLink';
import { NavList } from './NavList';
import { NavListItem } from './NavListItem';
import useOnScreen from '../../../hooks/useOnScreen';
import { defaultChain } from '../../../bloomify/config';
import { useTokenPrices } from '../../../contexts/TokenPricesContext';
import TokenImage from '../TokenImage';
import { getTokenDetails } from '../../../service/tokens.service';
import { formatValue } from '../../../helpers/util';
import { useGetGeneralPlatformStats } from '../../../hooks/useGetGeneralPlatformStats';

const appLinks = [
	{
		label: 'Accumulators', href: null, to: 'accumulators', menu: null,
	},
	{
		label: 'Profit-Takers', href: null, to: 'profit-takers', menu: null,
	},
	{
		label: 'Vaults', href: null, to: 'vaults', menu: null,
	},
	{
		label: 'Farms', href: null, to: 'farms', menu: null,
	},
	defaultChain.shortName === 'bsc' ? ({
		label: 'Advanced',
		subTo: ['/portfolio'],
		to: null,
		href: null,
		menu: [
			// { label: 'Create Strategy', to: 'strategies' },
			{ label: 'My Strategies', to: 'portfolio' },
		],
	}) : null,
].filter((x) => x);

const homeLinks = [
	// {
	// 	label: 'How to', href: '/#howto', to: '/', menu: null,
	// },
	// {
	// 	label: 'Main Features', href: '/#features', to: '/', menu: null,
	// },
	// {
	// 	label: 'Stake', href: '/#stake', to: '/', menu: null,
	// },
	// {
	// 	label: 'Roadmap', href: '/#roadmap', to: '/', menu: null,
	// },
	// {
	// 	label: 'Join us', href: '/#join', to: '/', menu: null,
	// },
];

const ids = ['howto', 'features', 'stake', 'roadmap', 'join'];

function MobileNavContent(props: BoxProps) {
	const { isOpen, onToggle } = useDisclosure();
	const { getTokenPrice } = useTokenPrices();

	const router = useLocation();
	const isHomepage = router.pathname === '/';
	const links = isHomepage ? homeLinks : appLinks;

	const getGeneralStats = useGetGeneralPlatformStats();

	const activeId = useOnScreen(ids, 69);

	return (
    <Box {...props}>
        <Center
            as="button"
            p="2"
            fontSize="2xl"
            color={useColorModeValue('gray.800', 'whiteAlpha.900')}
            onClick={onToggle}
            >
            {isOpen ? <HiX /> : <HiOutlineMenu />}
        </Center>
        <NavList
            pos="absolute"
            zIndex={222}
            insetX="0"
            bg={useColorModeValue('white', 'gray.800')}
            top="64px"
            animate={isOpen ? 'enter' : 'exit'}
            >
            <Stack spacing="0">
                {/* <Skeleton isLoaded={!!getTokenPrice('STATIC')}>
                    <Flex align="center" justify="center" h="100%" direction="column" my="5">
                        <Flex align="center" justify="center" h="100%" mb="3">
                            <ExternalLink href={getTokenDetails('STATIC').buyLink} target="_blank">
                                <Flex justify="center" align="center" mr="3">{getTokenPrice('STATIC') && <TokenImage xsm token="STATIC" />}
                                    <Text fontSize="sm" ml={2} fontWeight="bold" textColor="muted">
                                        { getTokenPrice('STATIC') ? getTokenPrice('STATIC').toFixed(3) : 'Loading...' }
                                    </Text>
                                </Flex>
                            </ExternalLink>
                            <ExternalLink href={getTokenDetails('CHARGE').buyLink} target="_blank">
                                <Flex justify="center" align="center" mb={0}>{getTokenPrice('CHARGE') && <TokenImage xsm token="CHARGE" />}
                                    <Text fontSize="sm" ml={2} fontWeight="bold" textColor="muted">
                                        { getTokenPrice('CHARGE') ? getTokenPrice('CHARGE').toFixed(3) : 'Loading...' }
                                    </Text>
                                </Flex>
                            </ExternalLink>
                        </Flex>
                        <Flex justify="center" h="50%">
                            <Text fontSize="sm" fontWeight="bold" textColor="muted" mr={2}>
                                {defaultChain.configName.toUpperCase()} TVL:
                            </Text>

                            <Text fontSize="sm" fontWeight="regular" textColor="muted">
                                { `$${formatValue(getGeneralStats.chainTvl)}` }
                            </Text>
                        </Flex>
                    </Flex>
                </Skeleton> */}
                {links.map((link, index) => (
                    <NavListItem key={index} mb="-2px" textAlign="center">
                        <NavLink.Mobile activeId={activeId} onClick={onToggle} to={link.to} {...link}>{link.label}</NavLink.Mobile>
                    </NavListItem>
                ))}
            </Stack>
        </NavList>
    </Box>
	);
}

function DesktopNavContent(props: StackProps) {
	const router = useLocation();
	const isHomepage = router.pathname === '/';
	const links = isHomepage ? homeLinks : appLinks;

	const activeId = useOnScreen(ids, 69);

	return (
    <HStack spacing="8" align="stretch" {...props} h="69px">
        {links.map((link, index) => (
            <NavLink.Desktop activeId={activeId} key={index} to={link.to} {...link}>
                {link.label}
            </NavLink.Desktop>
        ))}
    </HStack>
	);
}

export const NavContent = {
	Mobile: MobileNavContent,
	Desktop: DesktopNavContent,
};
