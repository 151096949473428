/* eslint-disable max-len */
/* eslint-disable no-shadow */
import { useEffect, useState } from 'react';

export const useBoardrooms = (epoch: number, nextEpochPoint: number, lastClaimedEpoch: number, lastDepositEpoch: number) => {
	const [withdrawTimer, setWithdrawTimer] = useState<string>();
	const [claimTimer, setClaimTimer] = useState<string>();

	useEffect(() => {
		if (epoch !== null) {
			const withdrawDiff = epoch - lastDepositEpoch;
			const claimDiff = epoch - lastClaimedEpoch;
			// console.log(claimDiff, withdrawDiff, epoch, lastClaimedEpoch, lastDepositEpoch, nextEpochPoint)

			// Check the deposit epoch and the deposit difference, if still locked subtarct epochs from the difference
			if (lastDepositEpoch !== null && lastDepositEpoch !== 0 && withdrawDiff < 6) {
				countdown((5 - withdrawDiff) * 28800 + nextEpochPoint, setWithdrawTimer);
			}
			// Check if 3 epochs have passed since last deposit time.
			if (lastDepositEpoch + 3 >= epoch) {
				countdown(2 * 28800 + nextEpochPoint, setClaimTimer);
			}
		}
	}, []);

	// Epoch Timer countdown
	const countdown = (timer: number, setTimer: (timer: string) => void) => {
		setInterval(() => {
			if (timer !== undefined) {
				const now = new Date().getTime();
				const distance = timer * 1000 - now;
				let days = distance / (24 * 60 * 60 * 1000);
				let hours = (days % 1) * 24;
				let minutes = (hours % 1) * 60;
				let seconds = (minutes % 1) * 60;
				[days, hours, minutes, seconds] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(seconds)];
				setTimer(`${days > 0 ? days : ''} ${days > 0 ? 'day(s)' : ''} ${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`);
			}
		}, 1000);
	};

	return { withdrawTimer, claimTimer };
};
