/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Button,
	Flex,
	Text,
	Box,
	NumberInput,
	NumberInputField,
	FormControl,
	useColorModeValue as mode,
	Select, Icon,
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import _ from 'lodash';
import { BigNumber } from 'ethers';
import { parseUnits, formatEther } from 'ethers/lib/utils';
import { IoWarning } from '@react-icons/all-files/io5/IoWarning';
import useWeb3 from '../../../../hooks/useWeb3';
import TokenImage from '../../../common/TokenImage';
import { erc20Allowance, erc20Approve } from '../../../../service/erc2.service';
import { defaultChain } from '../../../../bloomify/config';
import { formatValue } from '../../../../helpers/util';
import { useTokenPrices } from '../../../../contexts/TokenPricesContext';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	blockIndex: number;
	token: any;
	strategyAddress: string;
	description?: string;
	depositedAmount: BigNumber;
	depositedValue: number;
	withdrawZap: boolean;
  };

function WithdrawModal({
	  isOpen,
	  blockIndex,
	  onClose,
	  token,
	  strategyAddress,
	  withdrawZap = false,
	  depositedValue = 0,
	  depositedAmount = BigNumber.from(0),
	  description = `Deposit ${token === 'native' ? 'BNB' : token.symbol.toUpperCase()} or Zap one of the other available tokens`,
}: Props) {
	const { account } = useWeb3React();
	const { getTokenPrice, tokens } = useTokenPrices();

	const depositedAmountInteger = Number(formatEther(depositedAmount));

	const web3 = useWeb3();

	const [withdrawToken, setWithdrawToken] = useState<any>(token);
	// Gets the allowance of a given token for a given wallet
	const getAllowance = async (tokenDetails) => {
		const allowance = await erc20Allowance(web3, tokenDetails.address, token.address, account);

		setTokenAllowance(allowance);
	};

	const [tokenAllowance, setTokenAllowance] = useState<any>(0);
	const [maxEnabled, setMaxEnabled] = useState<any>(false);

	const withdrawTokens = [defaultChain.addressBook.tokens.BNB, defaultChain.addressBook.tokens.STATIC,
		defaultChain.addressBook.tokens.CHARGE, defaultChain.addressBook.tokens.BUSD];

	if (!withdrawTokens.some((t) => t.address.toLowerCase() === token.address.toLowerCase())) {
		withdrawTokens.push(token);
	}

	useEffect(() => {
		if (account) getAllowance(token);
	}, [account]);

	const [withdrawAmount, setWithdraw] = useState<BigNumber>(BigNumber.from(0));
	const [input, setInput] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [inputPrice, setInputPrice] = useState<number>(getTokenPrice(withdrawToken) ? input * getTokenPrice(withdrawToken) : 0);

	// Checks if there is balances and a valid withdraw > 0
	const isError = withdrawAmount && withdrawAmount.gt(depositedAmount);

	// uses util function to map an address to a token name / LP name
	const tokenAddr = withdrawToken.address;

	// Changes the withdraw token
	const onChangeWithdrawToken = async (token: any) => {
		setWithdrawToken(token);
		setMaxEnabled(false);
		await getAllowance(token);
		setWithdraw(parseUnits('0', token.decimals));
		setInput(0);
	};

	// Deposits given token in the block
	const withdrawFunds = async () => {
		console.log('widrawing');
		// setIsLoading(true);
		// await depositInBlock(
		// 	web3,
		// 	strategyAddress,
		// 	tokenAddr,
		// 	blockIndex,
		// 	deposit.toString(),
		// 	account,
		// 	() => setIsLoading(false),
		// 	() => onClose(),
		// );
	};

	// Approval to spend the given ERC20 token
	const onApprove = async () => {
		setIsLoading(true);
		const response = await erc20Approve(web3, tokenAddr, strategyAddress, account, () => setIsLoading(true));

		if (response.status) {
			await getAllowance(withdrawToken);
			setIsLoading(false);
		}
	};

	// const withdrawFunds = async () => {
	// 	setIsLoading(true);
	// 	if (maxEnabled) {
	// 		await onWithdrawAll(
	// 			() => setIsLoading(false),
	// 			() => onClose(),
	// 		);

	// 		setWithdraw(parseUnits('0'));
	// 	} else {
	// 		await onWithdraw(
	// 			withdrawAmount,
	// 			() => setIsLoading(false),
	// 			() => onClose(),
	// 		);

	// 		setWithdraw(parseUnits('0'));
	// 	}
	// };

	useEffect(() => {
		setInputPrice(getTokenPrice(withdrawToken) ? input * getTokenPrice(withdrawToken) : 0);
	}, [input]);

	const roundWitdrawValue = (percent: number) => {
		if (depositedAmount) {
			switch (percent) {
				case 25: {
					setMaxEnabled(false);
					return depositedAmount ? BigNumber.from(depositedAmount).div(4) : BigNumber.from(0);
				}
				case 50: {
					setMaxEnabled(false);
					return depositedAmount ? BigNumber.from(depositedAmount).div(2) : BigNumber.from(0);
				}
				case 75: {
					setMaxEnabled(false);
					return depositedAmount ? BigNumber.from(depositedAmount).div(4).mul(3) : BigNumber.from(0);
				}
				case 100: {
					setMaxEnabled(true);
					return depositedAmount ? BigNumber.from(depositedAmount) : BigNumber.from(0);
				}
				default: {
					setMaxEnabled(false);
					return BigNumber.from(0);
				}
			}
		}
	};
	const roundWithdrawArr = [25, 50, 75, 100];

	return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
        <ModalOverlay />
        <ModalContent maxW="620px" px={{ base: 4, md: 5 }}>
            <ModalHeader mx="auto" px={{ base: 1, md: 5 }}>
                <Flex direction="column" justify="center" align="center">
                    <Text fontWeight="bold" fontSize="25px">Withdraw {withdrawToken.symbol.toUpperCase()}</Text>
                    <Text fontSize="sm" pl="4" minW="275px" textAlign="center" fontWeight="light" textColor="#8A88B7">
                        {description}
                    </Text>
                </Flex>
            </ModalHeader>
            <ModalCloseButton transition="transform 0.15s ease-in-out" _hover={{ transform: 'scale3d(1.02, 1.02, 1)' }} />
            <ModalBody p={{ base: 1, md: 5 }}>
                <Flex flexDir="column" justify="center" align="center" py={3}>
                    <FormControl isInvalid={isError} mr={2}>
                        <Flex alignItems="center" justifyContent="space-between" w="full">
                            <Text color="#8A88B7" mb={2}>{withdrawZap ? 'Withdraw as' : 'Withdraw'}</Text>
                            <Box>
                                <Flex justify="center" align="center">
                                    <TokenImage sm token={withdrawToken === 'native' ? defaultChain.nativeToken : withdrawToken} />
                                    { !withdrawZap
    	? <Text ml={2}>{withdrawToken.toUpperCase()}</Text>
                                    	:
    <Select
        bg={mode('#FAFAFA', 'blackAlpha.300')}
        variant="filled"
        ml={2}
        maxW="200px"
        onChange={(e) => onChangeWithdrawToken(e.target.value === defaultChain.nativeToken ? 'native' : e.target.value)}>
        <option value="none" selected disabled hidden>
            Select an Option
        </option>
        {withdrawTokens.map((tokenDetails: any, key: number) => (
            <option
                key={key}
                selected={tokenDetails.address.toLowerCase() === withdrawToken.address.toLowerCase() && true}
                value={JSON.stringify(tokenDetails)}>
                {tokenDetails.symbol.toUpperCase()}
            </option>
        ))}
    </Select>}
                                </Flex>
                            </Box>
                        </Flex>
                        <Text fontSize="sm" color="#8A88B7" mt="2px">
                            Balance ~{formatValue(depositedAmountInteger, 3)} ({`$${formatValue(depositedValue, 2)}`})
                        </Text>
                        <FormControl isInvalid={isError} mr={2} rounded="lg" bg={mode('#FAFAFA', 'blackAlpha.300')} py={3} px={4} mt={4}>
                            <NumberInput
                                variant="unstyled"
                                defaultValue={0}
                                value={input}
                                onChange={(num: any) => {
                	setInput(num);
                	setMaxEnabled(false);
                	setWithdraw(parseUnits(num || '0', withdrawToken.decimals));
                                }}
                                w="100%">
                                <NumberInputField w="100%" fontSize="25px" bg="transparent" />
                            </NumberInput>
                            <Text fontSize={14} color="#8A88B7">~{`$${formatValue(inputPrice, 2)}`}</Text>
                        </FormControl>
                        {isError ?
                            <Flex align="center" pt="2px" pb={1}>
                                <Box maxW={10}>
                                    <Icon as={IoWarning} color="pink.500" />
                                </Box>
                                <Text ml={2} color="pink.500" fontSize="sm">
                                    Insufficient or no tokens
                                </Text>
                            </Flex> : <Box h="30px" />}
                    </FormControl>
                    {withdrawToken.address.toLowerCase() !== defaultChain.nativeTokenAddress.toLowerCase() ? (
                        <Flex pt={2} flexDir="row" flexWrap="wrap" justifyContent="center">
                            {roundWithdrawArr.map((el, i) =>
                                <Button
                                    my={{ base: 1, md: 0 }}
                                    mx={2}
                                    variant="filled"
                                    bg={mode('#FAFAFA', 'blackAlpha.300')}
                                    w={{ base: '120px', md: '100px' }}
                                    onClick={() => {
    	setInput(Number(formatEther(roundWitdrawValue(el))));
    	setWithdraw(roundWitdrawValue(el));
                                    }}>
                                    {el}%
                                </Button>)}
                        </Flex>
                    ) : null }
                </Flex>
                <Button
                    my="auto"
                    marginTop="1rem"
                    isDisabled={isError || withdrawAmount.eq(0)}
                    isLoading={isLoading}
                    w="100%"
                    bg={mode('white', 'gray.800')}
                    border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}
                    onClick={withdrawFunds}>
                    Confirm
                </Button>
            </ModalBody>
        </ModalContent>
    </Modal>
	);
}

export default WithdrawModal;
