/* eslint-disable wrap-iife */
import { useEffect, useState } from 'react';
import { useWalletModal } from '@pancakeswap-libs/uikit';
import { Cookies } from 'react-cookie';
import { useWallet } from 'use-wallet';
import { useWeb3React } from '@web3-react/core';
import { useDidUpdate } from './useDidUpdate';
import { connectorsByName } from '../utils/web3React';
import useAuth from './useAuth';
import { eventTrack } from '../utils/eventTracking';

const cookies = new Cookies();
const cookiesOptions = { path: '/', maxAge: 2592000 };

export const useWalletProvider = () => {
	const { account: wallet } = useWeb3React();
	// const { isMember, setIsMember } = useXStaticMembership()
	const { login, logout } = useAuth();
	const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, wallet);

	const logoutWallet = () => {
		cookies.remove('walletAddress');
	};

	useDidUpdate(() => {
		if (wallet) {
			const get = async () => {
				if (window.history.state.usr) {
					const usr = { };
					window.history.replaceState({ ...window.history.state, usr }, '');
				}
			};
			get();
		}

		if (wallet) eventTrack('Wallet in App', 'Conect/Change Wallet', wallet);
	}, [wallet]);

	return {
		logoutWallet, onPresentConnectModal, wallet, onPresentAccountModal,
	};
};
