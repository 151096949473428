import React from 'react';
import {
	Box,
	Button,
	Flex, Icon,
	Img,
	Popover,
	PopoverContent,
	PopoverTrigger,
	useColorModeValue as mode,
	VStack,
} from '@chakra-ui/react';
import { getSavedChain, setChain } from './switcher.cookie';
import { chains, operatingChains } from '../../../bloomify/config';

function ChainSwitcher() {
	return (
    <Box rounded="lg" position="fixed" bottom="15px" right={['16px', '24px']}>
        <Popover isLazy>
            <PopoverTrigger>
                {!getSavedChain()
                    	? <Button
                            size="lg"
                            colorScheme={chains.BSC.mainnet.colorScheme}
                            variant="solid"
                            leftIcon={<Img src={chains.BSC.mainnet.icon} w="35px" h="35px" />}>BSC</Button>
                    	: <Button
                            size="lg"
                            colorScheme={chains[getSavedChain()].mainnet.colorScheme}
                            variant="solid"
                            leftIcon={<Img src={chains[getSavedChain()].mainnet.icon} w="35px" h="35px" />}>{getSavedChain()}</Button>}
            </PopoverTrigger>
            <PopoverContent w="max" bg={mode('#FAFAFA', 'gray.800')} rounded="lg" px={2} py={4}>
                <VStack spacing={3} mx={2}>
                    {operatingChains.map((i, index) => <Button
                        size="lg"
                        key={index}
                        colorScheme="yellow"
                        variant="outline"
                        disabled={getSavedChain() === i}
                        leftIcon={<Img src={chains[i].mainnet.icon} w="35px" h="35px" />}
                        onClick={() => setChain(i)}>
                        {i}
                    </Button>)}
                </VStack>
            </PopoverContent>
        </Popover>
    </Box>
	);
}

export default ChainSwitcher;
