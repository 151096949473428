/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import {
	BoxProps, Flex, useBreakpointValue, Text, Select, FormControl, InputGroup, InputLeftElement, Input,
} from '@chakra-ui/react';
import _ from 'lodash';
import { SearchIcon } from '@chakra-ui/icons';
import { useWeb3React } from '@web3-react/core';

type Props = BoxProps & {
  vaults: any[];
  filteredVaults: (strats: any[]) => void;
  clearFilters?: boolean;
};

function VaultsFilters({ vaults = [], filteredVaults, clearFilters }: Props) {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const useVaults = vaults.filter((v) => v.shouldDisplay);

	const { account } = useWeb3React();

	const allExistingDepositTokens = [
		{ value: 'all', label: 'All' },
		..._(useVaults)
			.map((s) => s.depositToken.symbol)
			.filter((x) => x)
			.orderBy()
			.uniq()
			.map((item) => ({ value: item, label: item.toUpperCase() }))
			.value(),
	];

	const accumulatingTokens = _(useVaults)
		.map((s) => s.accumulatedToken && s.accumulatedToken.symbol)
		.filter((x) => x)
		.orderBy()
		.uniq()
		.map((item) => ({ value: item, label: item.toUpperCase() }))
		.value();

	const allExistingAccumulatingTokens = accumulatingTokens.length ? [
		 { value: 'all', label: 'All' },
		...accumulatingTokens,
	] : [];

	const typeFilter = [
		{ value: 'all', label: 'All' },
		{ value: 'mine', label: `My ${useVaults[0] ? `${useVaults[0].type}s` : 'Vaults'}` },
	];

	const [selectedTypeFilter, setSelectedTypeFilter] = useState<any>('all');

	const [selectedDepositTokensInvolved, setselectedDepositTokensInvolved] = useState<any>('all');
	const [selectedAccumulatedTokensInvolved, setselectedAccumulatedTokensInvolved] = useState<any>('all');

	const [searchWord, setSearchWord] = useState<any>('');

	useEffect(() => {
		apply();
	}, [
		selectedTypeFilter,
		searchWord,
		selectedDepositTokensInvolved,
		selectedAccumulatedTokensInvolved,
	]);

	useEffect(() => {
		if (clearFilters) {
			setSearchWord('');
			setSelectedTypeFilter('all');
			setselectedDepositTokensInvolved('all');
			setselectedAccumulatedTokensInvolved('all');
		}
	}, [clearFilters]);

	const apply = () => {
		let filteredVaultsList = _.cloneDeep(useVaults);

		if (
			(!account || selectedTypeFilter === 'all') &&
	  selectedDepositTokensInvolved === 'all' &&
	  selectedAccumulatedTokensInvolved === 'all' &&
      !searchWord
		) {
			filteredVaults(filteredVaultsList);
			return;
		}

		filteredVaultsList = _.filter(
			filteredVaultsList,
			(vault) =>
				(selectedTypeFilter === 'all' || (account && vault.depositDetails.amount > 0)) &&
        (!searchWord || vault.name.toLowerCase().includes(searchWord.toLowerCase())) &&
		(selectedDepositTokensInvolved === 'all' || vault.depositToken.symbol === selectedDepositTokensInvolved)
		&& (selectedAccumulatedTokensInvolved === 'all' || vault.accumulatedToken.symbol === selectedAccumulatedTokensInvolved),
		);

		filteredVaults(filteredVaultsList);
		return;
	};

	return (
    <Flex py={1} position="relative" top={-3} left={-2} direction={isMobile ? 'column' : 'row'}>
      <FormControl mr={3} pt={6}>
        <InputGroup>
          <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
          <Input
              value={searchWord}
              fontSize="15px"
              size="lg"
              placeholder="Search by name"
              onChange={(e: any) => {
            	setSearchWord(e.target.value);
              }}
          />
        </InputGroup>
      </FormControl>
      {!!account && <FormControl py={isMobile ? 3 : 0} mr={3}>
        <Text fontSize="sm" pb="2px" opacity="0.8">{useVaults[0] ? `${useVaults[0].type}s` : 'Vaults'}</Text>
        <Select
            maxW="257px"
            fontSize="15px"
            size="lg"
            onChange={(e) => {
          	setSelectedTypeFilter(e.target.value);
            }}>
          {typeFilter.map((option: any, key: number) => (
            <option key={key} selected={option.value === selectedTypeFilter} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </FormControl>}
      <FormControl py={isMobile ? 3 : 0} mr={3}>
        <Text fontSize="sm" pb="2px" opacity="0.8">Deposit Token</Text>
        <Select
            maxW="257px"
            fontSize="15px"
            size="lg"
            onChange={(e) => {
          	setselectedDepositTokensInvolved(e.target.value);
            }}>
          {allExistingDepositTokens.map((option: any, key: number) => (
            <option key={key} selected={option.value === selectedDepositTokensInvolved} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </FormControl>
	  {!!allExistingAccumulatingTokens.length && <FormControl py={isMobile ? 3 : 0}>
        <Text fontSize="sm" pb="2px" opacity="0.8">{useVaults[0].type} Token</Text>
        <Select
            maxW="257px"
            fontSize="15px"
            size="lg"
            onChange={(e) => {
            	setselectedAccumulatedTokensInvolved(e.target.value);
            }}>
          {allExistingAccumulatingTokens.map((option: any, key: number) => (
            <option key={key} selected={option.value === selectedAccumulatedTokensInvolved} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </FormControl>}
    </Flex>
	);
}

export default VaultsFilters;
