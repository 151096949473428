/* eslint-disable no-shadow */
import { useWeb3React } from '@web3-react/core';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import { getGenericStrategy } from '../../../service/strategy_data.service';
import { createMoneyLegoStrategy } from '../services/strategy_factory.service';
import useWeb3 from '../../../hooks/useWeb3';
import { defaultChain } from '../../../bloomify/config';
import { strategyGenerateContractBlockSettings } from '../../../helpers/util';

export const useStrategyCreator = (tag: string, strategy?: any) => {
	const { account } = useWeb3React();
	const web3 = useWeb3();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [strategyFromConfigDetails, setStrategy] = useState(null);

	useEffect(() => {
		setStrategy(strategy || []);
	}, [strategy]);

	const createStrategy = async (blockSettings: any) => {
		const strategy = getGenericStrategy(tag);

		let percentages = [];
		percentages = strategyGenerateContractBlockSettings(strategyFromConfigDetails, blockSettings);

		console.log(blockSettings, percentages, strategy);

		strategy.setPercentages(percentages);

		setIsLoading(true);
		await createMoneyLegoStrategy(
			web3,
			account,
			strategy.data,
			(address) => {
				if(!address || address === "") {
					navigate('portfolio');
				} else {
					navigate(`/portfolio/${tag}/${address}`);
				}
			},
			() => setIsLoading(false),
		);
	};

	return {
		blocks: strategyFromConfigDetails ? strategyFromConfigDetails.blocks : [],
		createStrategy,
		isLoading,
	};
};
