import {
	Multicall,
	ContractCallContext,
} from 'ethereum-multicall';
import Web3 from 'web3';
import _ from 'lodash';
import { defaultChain } from '../bloomify/config';

// Multicall Testnet 0xf12B6268d44711EF7B58425bd53B093a00343507
export const doMulticall = async (web3: Web3, contractCallContext: ContractCallContext[]): Promise<any> => {
	const multicall = new Multicall({ web3Instance: web3, multicallCustomContractAddress: defaultChain.multicallAddress });
	let contractCalls = null;
	const results = {};

	if (contractCallContext.length > 90) {
		contractCalls = _.chunk(contractCallContext, 90);
	}

	if (!contractCalls) {
		const response = await multicall.call(contractCallContext);

		_.forEach(response.results, (result, reference) => {
			results[reference] = result.callsReturnContext[0].returnValues;
		});
	} else {
		await Promise.all(_.map(contractCalls, async (callChunk) => {
			const chunkResponse = await multicall.call(callChunk);

			_.forEach(chunkResponse.results, (result, reference) => {
				results[reference] = result.callsReturnContext[0].returnValues;
			});

			return callChunk;
		}));
	}

	return results;
};

// const cachedResponses = JSON.parse(localStorage.getItem('cachedResponses')) || {};

// Multicall Testnet 0xf12B6268d44711EF7B58425bd53B093a00343507

// CACHING not really used now, needs improvements (on threading also)
// export const doMulticall = async (web3: Web3, contractCallContext: ContractCallContext[], useCache = false, expiry = 30000): Promise<any> => {
// 	const multicall = new Multicall({ web3Instance: web3, multicallCustomContractAddress: '0x41263cBA59EB80dC200F3E2544eda4ed6A90E76C' });
// 	const results = {};

// 	if (!useCache) { // do normal multicall; no cache involved
// 		const response = await multicall.call(contractCallContext);

// 		_.forEach(response.results, (result, reference) => {
// 			results[reference] = result.callsReturnContext[0].returnValues;
// 		});
// 	} else { // do normal multicall only when needed; cache result; return results when there is a cached and not expired response
// 		const cachingPartition = _.partition(contractCallContext, (context) => { // split in expired/not expired responses
// 			const reference = context.reference;
// 			const contractAddress = context.contractAddress.toLowerCase();
// 			const cachedResponsePerAddress = cachedResponses[contractAddress];

// 			 if (cachedResponsePerAddress
// 				 && cachedResponsePerAddress[reference]
// 				 && cachedResponsePerAddress[reference].result) {
// 				 return cachedResponsePerAddress[reference].expiry > Date.now();
// 			 }
// 				 return false;
// 		});

// 		if (cachingPartition[1].length) { // for expired responsed, do again the multicall
// 			const response = await multicall.call(cachingPartition[1]);

// 			_.forEach(response.results, (result, reference) => {
// 				results[reference] = result.callsReturnContext[0].returnValues;
// 				const contractAddress = result.originalContractCallContext.contractAddress.toLowerCase();

// 				cachedResponses[contractAddress] = { ...cachedResponses[contractAddress] };
// 				cachedResponses[contractAddress][reference] = { result: result.callsReturnContext[0].returnValues, expiry: Date.now() + expiry };
// 			});

// 			localStorage.setItem('cachedResponses', JSON.stringify(cachedResponses));
// 		}

// 		if (cachingPartition[0].length) { // for unexpired responses - return the cached response
// 			_.forEach(cachingPartition[0], (context) => {
// 				const reference = context.reference;
// 				const contractAddress = context.contractAddress.toLowerCase();

// 				results[reference] = cachedResponses[contractAddress][reference].result;
// 			});
// 		}
// 	}

// 	return results;
// };
