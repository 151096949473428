/* eslint-disable max-len */
import { defaultBlocks } from './blocks.config';
import {featureAllowed} from "../../env";


const TAKE_PROFIT = 1;
const AUTOCOMPOUND = 2;
const REINVEST = 3;

const DEFAULT_PERCENTAGE_TAKE_PROFIT = 2000;
const DEFAULT_PERCENTAGE_AUTOCOMPOUND = 4000;
const DEFAULT_PERCENTAGE_REINVEST = 4000;

export const defaultStrategies = [
].filter(
	(i) => featureAllowed(i.env)
);

export const deprecatedTags = defaultStrategies
	.map((s) => s.deprecatedTags)
	.flat();
export const currentExistingTags = defaultStrategies.map((s) => s.tag);
