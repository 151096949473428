import React, { useState } from 'react';
import {
	Box,
	Flex,
	Link,
	Text,
	Button,
	Image,
	Heading, useColorModeValue as mode,
	keyframes,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { NavHashLink } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import dark from '../../assets/logo/bloomify-dark.png';
// @ts-ignore
import light from '../../assets/logo/bloomify-light.png';
// @ts-ignore
import charge from '../../assets/logo/charge-logo.png';

import { HowTo } from './HowTo';
import { MainFeatures } from './MainFeatures';
import { MoneyLegosTimeline } from './MoneyLegosTimeline';
import { eventTrack } from '../../utils/eventTracking';
import SocialsExpanded from '../common/Footer/SocialsExpanded';
import Footer from '../common/Footer';

// const circleDark = require("../../assets/circle-dark.svg").default;
// const circleLight = require("../../assets/circle-light.svg").default;

// @ts-ignore
import bubbles from '../../assets/bg.png';

// @ts-ignore
import bgLight from '../../assets/bg-light.png';
// @ts-ignore
import bgDark from '../../assets/bg-dark.png';
import { Stake } from './Stake';
import { StrategyTabs } from './StrategyTabs';
import { Team } from './Team';
import { useGetGeneralPlatformStats } from '../../hooks/useGetGeneralPlatformStats';
import { formatValue } from '../../helpers/util';

function Landing() {
	const navigate = useNavigate();
	const animationKeyframes = keyframes`
    0% { opacity: 1; top: 18px; }
    100% { opacity: 0; top: 60px; transform: scale(0.8); }
  `;

	const animation = `${animationKeyframes} 1s ease-in-out infinite`;
	const getGeneralStats = useGetGeneralPlatformStats();

	return (
		// eslint-disable-next-line react/jsx-indent
		<>
    <Flex flexDir="column" maxW="1500px" mx="auto" pb={20} overflow="hidden">
        <Box
            display={{ base: 'none', md: 'block' }}
            position="absolute"
            top="72px"
            transitionDuration="200ms"
            minH="calc(100vh - 69px)"
            w="100vw"
            opacity={0.92}
            bgImg={mode("url('/circle-light.svg')", "url('/circle-dark.svg')")}
            bgSize={{ md: 'cover', lg: '120%', '2xl': 'auto' }}
            bgRepeat="no-repeat"
            left="0"
            bgPosition="center"
        />
        <Box
            display={{ base: 'none', md: 'block' }}
            position="absolute"
            transitionDuration="200ms"
            top="72px"
            minH="calc(100vh - 69px)"
            w="100vw"
            opacity={0.8}
            // bgImg={mode(bgDark, bgLight)}
            bgImg={bubbles}
            bgSize={{ md: 'cover', lg: '120%', '2xl': 'cover' }}
            bgRepeat="no-repeat"
            left="0"
            bgPosition="center"
            className="hero-image"
        />

        <>
            <Box
                h={{ base: '100%', md: '100vh' }}
                zIndex={1}
                position="relative"
                px={{ base: '20px', xl: '107px' }}
        >
                <Flex mt={-4} flexDir={{ base: 'column', md: 'row' }} justify="center" h="full">
                    <Flex
                        maxW="560px"
                        py={{ base: 10, md: 4 }}
                        px={{ base: 1, md: 6 }}
                        pb={{ base: 8 }}
                        justify="center"
                        textAlign="center"
                        flexDirection="column"
                        color={mode('gray.800', 'whiteAlpha.800')}
                        mt={{ base: 0, md: -2, lg: -4 }}
                        minH={{ base: 'calc(100vh - 69px)', md: 'calc(100vh - 120px)' }}
                        h={{ base: 'max-content', md: 'full' }}>
                        <Image mx="auto" src={mode(dark, light)} w={{ base: '120px', md: '180px' }} h="auto" />
                        {/* <Text mx="auto" fontSize="15px" fontWeight={300} display="inline-flex">a <Link
                            fontWeight={600}
                            display="inline-flex"
                            href="https://www.chargeventures.co/"
                            target="_blank"
                            color={mode('gray.800', 'whiteAlpha.900')}
                            mr={2}
                            mb={{ base: 5, md: 8, xl: 12, '2xl': 16 }}
                            alignItems="center"><Image src={charge} w={5} h={5} mx={2} />Charge Ventures</Link> product</Text> */}
                        <Heading
                            color={mode('#05050F', '#ffffff')}
                            fontSize={{ base: '5xl', lg: '6xl' }}
                            lineHeight="1.125"
                            pb={1}
                            mt={{
                        	base: 4, md: 6, xl: 9, '2xl': 12,
                            }}
                            variant="mainTitle">
                            WITHDRAWAL ONLY
                        </Heading>
                        <Text
                            lineHeight="170%"
                            align="center"
                            fontSize={{ base: 'sm', md: 'lg' }}
                            fontWeight={300}
                            mt={{ base: 1, md: 2, lg: 3 }}
                            mb={{ base: 3, md: 6 }}
                            maxW="420px"
                            mx="auto">
                            Please note that as of December 04, 2023 Bloomify is withdrawal only
                        </Text>
                        {/* <Heading
                            color={mode('#05050F', '#ffffff')}
                            fontSize={{ base: '3xl', lg: '4xl' }}
                            lineHeight="1.125"
                            pb={1}
                            variant="mainTitle">
                            Total TVL: { `$${formatValue(getGeneralStats.protocolTvl)}` }
                        </Heading> */}
                        <Flex
                            flexDir={{ base: 'column', md: 'row' }}
                            mx={{ base: 'auto', md: 'unset' }}
                            mt={{
                        	base: 4, md: 6, lg: 8, xl: 10,
                            }}>
                            <Button
                                onClick={() => {
                                	navigate('/accumulators');
                                	eventTrack('Landing Page', 'Launch dApp Button', 'Button');
                                }}
                                color={mode('gray.700', 'white')}
                                border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}
                                width="150px"
                                mx="auto"
                                h="50px">
                                Start Bloomify
                            </Button>
                        </Flex>
                        {/* <Flex flexDir={{ base: 'column', md: 'row' }} mx={{ base: 'auto', md: 'unset' }} w="100%" left="0" position="absolute" bottom={{ base: 5, md: '8.125vh' }} justify="center">
                            <NavHashLink to="#howto" smooth mx="auto">
                                <Button
                                    w="36px"
                                    h="72px"
                                    mx="auto"
                                    bg="transparent"
                                    position="relative"
                                    color={mode('gray.700', 'white')}>
                                    <Box
                                        as={motion.div}
                                        animation={animation}
                                        position="absolute"
                                        left="0"
                                        right="0"
                                        top="0"
                                        borderRadius="50%"
                                        w={2}
                                        h={2}
                                        mx="auto"
                                        bg={mode('gray.700', 'white')} />
                                </Button>
                            </NavHashLink>
                        </Flex> */}
                    </Flex>
                </Flex>
            </Box>
            {/* <div id="howto">
                <StrategyTabs />
            </div> */}

            {/* <div id="stake">
                <Stake />
            </div> */}

            {/* <Box as="section" id="join">
                <Box
                    mx="auto"
                    py={16}
                    px={{ base: '20px', xl: '107px' }}
          >
                    <Box textAlign="center" mx="auto" mb="10" maxW="600px">
                        <Heading variant="h6">
                            Our channels
                        </Heading>
                        <Text align="center" fontSize={{ base: 'sm', md: 'md' }} fontWeight={300} mb={14}>
                            Let's connect
                        </Text>
                    </Box>
                    <SocialsExpanded />
                </Box>
            </Box> */}
        </>
    </Flex>
    <Footer hasSocials={false} />
		</>
	);
}

export default Landing;
