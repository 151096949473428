import { addressBook as remoteAddressBook } from 'blockchain-addressbook';

export const addressBook = {
  ...remoteAddressBook.bsc,
  tokens: {
    ...remoteAddressBook.bsc.tokens,
    CHARGE: {
      ...remoteAddressBook.bsc.tokens.CHARGE,
      website: 'https://bloomify.co',
      name: 'Charge',
      description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // '$Charge is the utility token for Bloomify and can be staked to earn % of performance fees.',
      logoURI: require('../../../assets/tokens/charge-bsc.svg').default
      // buyLink: 'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x1C6bc8e962427dEb4106aE06A7fA2d715687395c'
    },
    STATIC: {
      ...remoteAddressBook.bsc.tokens.STATIC,
      website: 'https://bloomify.co',
      name: 'Static',
      logoURI: require('../../../assets/tokens/static-bsc.svg').default,
      description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // '$Static is the reward token for staking $Charge on Bloomify and can also be used to pay the run fee for personal strategies.',
      // buyLink: 'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x7dEb9906BD1d77B410a56E5C23c36340Bd60C983'
    },
    BUSD: {
      ...remoteAddressBook.bsc.tokens.BUSD,
      logoURI: require('../../../assets/tokens/busd.svg').default
    },
    BNB: {
      ...remoteAddressBook.bsc.tokens.WBNB,
      logoURI: require('../../../assets/tokens/bnb.svg').default
    },
    WBNB: {
      ...remoteAddressBook.bsc.tokens.WBNB,
      logoURI: require('../../../assets/tokens/bnb.svg').default
    },
    CAKE: {
      ...remoteAddressBook.bsc.tokens.CAKE,
      logoURI: require('../../../assets/tokens/cake.svg').default
    },
    USDT: {
      ...remoteAddressBook.bsc.tokens.USDT,
      logoURI: require('../../../assets/tokens/tether.svg').default
    },
    TETHER: {
      ...remoteAddressBook.bsc.tokens.USDT,
      logoURI: require('../../../assets/tokens/tether.svg').default
    },
    USDC: {
      ...remoteAddressBook.bsc.tokens.USDC,
      logoURI: require('../../../assets/tokens/USDC.svg').default
    },
    BTC: {
      ...remoteAddressBook.bsc.tokens.BTCB,
      logoURI: require('../../../assets/tokens/btcb.svg').default
    },
    BTCB: {
      ...remoteAddressBook.bsc.tokens.BTCB,
      logoURI: require('../../../assets/tokens/btcb.svg').default
    },
    ETH: {
      ...remoteAddressBook.bsc.tokens.ETH,
      logoURI: require('../../../assets/tokens/eth.svg').default
    },
    WETH: {
      ...remoteAddressBook.bsc.tokens.ETH,
      logoURI: require('../../../assets/tokens/eth.svg').default
    },
    BIFI: {
      ...remoteAddressBook.bsc.tokens.BIFI,
      logoURI: require('../../../assets/tokens/bifi.png')
    },
    PULSE: {
      address: '0xbceBAeAF1160Edc84D81A8f2D075858eE3960e9E',
      decimals: 18,
      name: 'Pulse',
      symbol: 'PULSE',
      logoURI: require('../../../assets/tokens/pulse.png'),
      description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // '$Pulse is a token no longer in circulation and may be redeemed for $Static by staking in the $Pulse pool.',
    },
    WOM: {
      ...remoteAddressBook.bsc.tokens.WOM,
      logoURI: require('../../../assets/tokens/wom.png')
    },

    PEEL: {
      ...remoteAddressBook.bsc.tokens.PEEL,
      logoURI: require('../../../assets/tokens/peel.png')
    },

    TRIVIA: {
      ...remoteAddressBook.bsc.tokens.TRIVIA,
      logoURI: require('../../../assets/tokens/trivia.png')
    },
    ADA: {
      ...remoteAddressBook.bsc.tokens.ADA,
      logoURI: require('../../../assets/tokens/ada.png')
    }
  },
  lps: {
    'pancake-static-busd': {
      address: '0x69758726b04e527238B261ab00236AFE9F34929D',
      name: 'Static-BUSD',
      symbol: 'static-busd',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0x7dEb9906BD1d77B410a56E5C23c36340Bd60C983/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
      logoURI: require('../../../assets/tokens/static-busd.svg').default
    },
    'pancake-charge-busd': {
      address: '0xB73b4eeb4c4912C1d1869219A22660eB478B57eA',
      name: 'Charge-BUSD',
      symbol: 'charge-busd',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0x1C6bc8e962427dEb4106aE06A7fA2d715687395c/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
      logoURI: require('../../../assets/tokens/charge-busd.svg').default
    },
    'pancake-cake-bnb': {
      address: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
      name: 'Cake-BNB',
      symbol: 'cake-bnb',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      decimals: 18,
      logoURI: require('../../../assets/tokens/cake-bnb.svg').default
    },
    'pancake-busd-bnb': {
      address: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
      name: 'BUSD-BNB',
      symbol: 'busd-bnb',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
      logoURI: require('../../../assets/tokens/BUSD-BNB.svg').default
    },
    'pancake-usdt-bnb': {
      address: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
      name: 'USDT-BNB',
      symbol: 'usdt-bnb',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/BNB/0x55d398326f99059fF775485246999027B3197955',
      decimals: 18,
      logoURI: require('../../../assets/tokens/USDT-BNB.svg').default
    },
    'pancake-btcb-bnb': {
      address: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
      name: 'BTCB-BNB',
      symbol: 'btcb-bnb',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/BNB/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      decimals: 18,
      logoURI: require('../../../assets/tokens/btcb-bnb.svg').default
    },
    'pancake-eth-bnb': {
      address: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
      name: 'ETH-BNB',
      symbol: 'eth-bnb',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/BNB/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      decimals: 18,
      logoURI: require('../../../assets/tokens/eth-bnb.svg').default
    },
    'pancake-cake-busd': {
      address: '0x804678fa97d91B974ec2af3c843270886528a9E6',
      name: 'Cake-BUSD',
      symbol: 'cake-busd',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
      logoURI: require('../../../assets/tokens/cake-busd.svg').default
    },
    'pancake-usdc-busd': {
      address: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1',
      name: 'USDC-BUSD',
      symbol: 'usdc-busd',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
      logoURI: require('../../../assets/tokens/usdc-busd.svg').default
    },
    'pancake-usdt-busd': {
      address: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
      name: 'USDT-BUSD',
      symbol: 'usdt-busd',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0x55d398326f99059fF775485246999027B3197955/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
      logoURI: require('../../../assets/tokens/usdt-busd.svg').default
    },
    'pancake-stable-usdt-busd': {
      address: '0x36842f8fb99d55477c0da638af5ceb6bbf86aa98',
      name: 'USDT-BUSD (Stable LP)',
      symbol: 'usdt-busd',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x55d398326f99059fF775485246999027B3197955',
      decimals: 18,
      logoURI: require('../../../assets/tokens/usdt-busd.svg').default
    },
    'pancake-stable-usdc-busd': {
      address: '0x1A77C359D0019cD8F4d36b7CDf5a88043D801072',
      name: 'USDC-BUSD (Stable LP)',
      symbol: 'usdc-busd',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
      logoURI: require('../../../assets/tokens/usdc-busd.svg').default
    },
    'pancake-stable-usdt-usdc': {
      address: '0xee1bcc9F1692E81A281b3a302a4b67890BA4be76',
      name: 'USDC-USDT (Stable LP)',
      symbol: 'usdc-usdt',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/0x55d398326f99059fF775485246999027B3197955',
      decimals: 18,
      logoURI: require('../../../assets/tokens/usdc-usdt.svg').default
    },
    'pancake-usdc-usdt': {
      address: '0xec6557348085aa57c72514d67070dc863c0a5a8c',
      name: 'USDC-USDT',
      symbol: 'usdc-usdt',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/0x55d398326f99059fF775485246999027B3197955',
      decimals: 18,
      logoURI: require('../../../assets/tokens/usdc-usdt.svg').default
    },
    'pancake-btcb-eth': {
      address: '0xD171B26E4484402de70e3Ea256bE5A2630d7e88D',
      name: 'BTCB-ETH',
      symbol: 'btcb-eth',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      decimals: 18,
      logoURI: require('../../../assets/tokens/btcb-eth.svg').default
    },
    'pancake-btt-busd': {
      address: '0xdcfbB12DED3FEa12D2A078Bc6324131cD14bF835',
      name: 'BTT-BUSD',
      symbol: 'btt-busd',
      platformId: 'pancake',
      decimals: 18,
      logoURI: require('../../../assets/tokens/BTT-BUSD.svg').default
    },
    'pancake-mbox-bnb': {
      address: '0x8FA59693458289914dB0097F5F366d771B7a7C3F',
      name: 'MBOX-BNB',
      symbol: 'mbox-bnb',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377/BNB',
      decimals: 18,
      logoURI: require('../../../assets/tokens/MBOX-BNB.svg').default
    },
    'pancake-trx-busd': {
      address: '0xb5D108578Be3750209d1b3A8f45FFee8C5a75146',
      name: 'TRX-BUSD',
      symbol: 'trx-busd',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
      logoURI: require('../../../assets/tokens/TRX-BUSD.svg').default
    },
    'pancake-eth-usdc': {
      address: '0xEa26B78255Df2bBC31C1eBf60010D78670185bD0',
      name: 'ETH-USDC',
      symbol: 'eth-usdc',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      decimals: 18,
      logoURI: require('../../../assets/tokens/ETH-USDC.svg').default
    },
    'pancake-btcb-busd': {
      address: '0xF45cd219aEF8618A92BAa7aD848364a158a24F33',
      name: 'BTCB-BUSD',
      symbol: 'btcb-busd',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
      logoURI: require('../../../assets/tokens/btcb-busd.svg').default
    },
    'pancake-wom-busd': {
      address: '0xe68d05418a8d7969d9ca6761ad46f449629d928c',
      name: 'WOM-BUSD',
      symbol: 'wom-busd',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0xAD6742A35fB341A9Cc6ad674738Dd8da98b94Fb1/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
      logoURI: require('../../../assets/tokens/wom-busd.svg').default
    },

    'pancake-peel-busd': {
      address: '0x25bfd3162360bbd8ff97b86169288b311c2a68d7',
      name: 'PEEL-BUSD',
      symbol: 'peel-busd',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0x734548a9e43d2D564600b1B2ed5bE9C2b911c6aB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
      logoURI: require('../../../assets/tokens/PEEL-BUSD.svg').default
    },

    'pancake-trivia-bnb': {
      address: '0xef642c40eebbc964881dd7bd1a0b50e90441e73a',
      name: 'TRIVIA-BNB',
      symbol: 'trivia-bnb',
      platformId: 'pancake',
      buyLink: 'https://pancakeswap.finance/add/0xb465f3cb6Aba6eE375E12918387DE1eaC2301B05/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
      logoURI: require('../../../assets/tokens/TRIVIA-BNB.svg').default
    }
  },
  blocks: {
    Charge_BUSD: '0xa84cfa716A46EB1d24839EF955aB2f294E5E06d4',
    Cake_BNB: '0xBe80Fad5cAb8E94E18F4bc969A6374ea316b3870',
    Cake_BUSD: '0x34bE3FDcd0ef76A8E6c87e5588d9BF7e8eC39959',
    BTCB_BNB: '0xF9f1807c2ec2534261149C02A8a25Bc6b6E2eb65',
    BTCB_ETH: '0xC652c739bB71816a25658765b63F65B8557c27a6',
    ETH_BNB: '0xF54e192c76E9a0D33D05f668a8a222FC7f98B2bD',
    USDT_BUSD: '0x51AB26D9E183689645A2C98c5F1652A0B67B6Ba2',
    USDC_BUSD: '0xe893A701b5f2d8c87d63ddD9f3F14baE824623f0',
    USDT_BUSD_StableLP: '0xd0d5AEb86960eDaea1931705033BA53074158235',
    USDC_BUSD_StableLP: '0x6D2426AeF1D2fa4b1F9628374Da457E1Dd473667',
    BTCB_BUSD: '0x83F79adBc274d8cc9b55b21869fddAEdd426F837',
    BTT_BUSD: '0x33143Ef213762c86a015FfADB21e9A8d23b2c0B0',
    MBOX_BNB: '0x8906879Fc383F83F419be136dE50F1CE35665A74',
    TRX_BUSD: '0x70Ee22b4eCbcb46E870Fc110FB058d7048139745',
    ETH_USDC: '0x7019674297D2cC7Dd4b21C51e7B7CDF9d64DF7Ba',
    banks: {
      BUSD_USDT: '0x83F79adBc274d8cc9b55b21869fddAEdd426F837',
      CHARGE_BUSD: '0x8926EC29c06c832875111B9cdcC70e49d07A832f'
    }
  },
  strategies: {
    StrategyFactory: '0x57aA27300A3EC20b12016F77367C24540757532F',
    StrategyRegistry: '0xE6B31b15a7AB9cF0a9a0CF2383CF7df9e18151C8'
  },
  vaults: {
    USDT_BUSD_PCS_STABLELP: '0x6c98E60887d79DB93c9fCbEDfAdCf2F3DbC51605',
    USDC_BUSD_PCS_STABLELP: '0xF58f3077fe1f3FAFCd252a552D5d569768a2df99',
    USDT_USDC_PCS_STABLELP: '0x8E30fd742676Ba9922d6eF2422ec721134608A3a',
    USDT_BUSD: '0x492C4D20572dc8D64bD2d33534Fad036f9b107fE',
    USDC_BUSD: '0xbf7747900dC02C0F402f178d00F2603Bc4135892',
    USDC_USDT: '0x0371473E5B661E2cB865283b91DD409E8eb16Bbb',
    CHARGE_BUSD: '0xCa0418dB390da414Bca35C5162DF29EA377B8348',
    STATIC_BUSD: '0xf905F6665Ba72d4E27F9C1715232657CdfC07C8d',
    ETH_BNB: '0xd887Cb7B5c449D3Bb0781988f9bBB1ceDfd25bff',
    BTCB_ETH: '0xF66074C872064a396f4AffbBC808457403b58d45',
    CAKE_BNB: '0xe31E30c0ff17d12f883401d0938eb4342182f6c4',
    CAKE_BUSD: '0xc2192dc1A838198379bC0d11f48404D65103Cab1',
    WOM_BUSD: '0x5c202382f39b07719f6a6bb2Fe86406A9fD3561f',
    accumulators: {
      USDT_BUSD_to_BTCB_ETH: '0x9E9369b2830EFE35Ae7d96C30cC401E8362F16cc',
      USDC_BUSD_to_BTCB_ETH: '0x98C884F1b5E9570b05A8329Bb829B47C24319fFB',
      // USDC_BUSD_to_Charge_BUSD: '0x35FB1B396AB9e059b9Da062B039Da8CF30Ae23ad',
      // USDT_BUSD_to_Charge_BUSD: '0x28e17D13C2963C645cA0166cec1E25295300eb52',
      USDC_BUSD_to_Cake_BUSD: '0x245790bC567D452D9Dd362E9f306a473df6440c3',
      USDT_BUSD_to_Cake_BUSD: '0x1ea412983C9fBdd82C95D858C4D74a7eDBCb22e3',
      USDC_BUSD_to_Cake_BNB: '0x4Eef9DE8c3ada57e9EBC5B17d9A493F0C0D6BD23',
      USDT_BUSD_to_Cake_BNB: '0x04D24c4aa99949F2df2FDB5Ce7E07de813F808c2',
      USDC_BUSD_to_ETH_BNB: '0xC01EE46B5Bee36039fa32478447a687b58C42457',
      USDT_BUSD_to_ETH_BNB: '0x3Cde9E8F675e55B7C13E79D2763356104cFd99ae',
      USDT_BUSD_to_WOM_BUSD: '0x0a62d789E036C2A9CFd03032662125cD0Dd6c769',
      USDT_BUSD_to_BNB: '0x907932E9c5847314c9C33A3824bA0Cc60913DEd4',
      USDT_BUSD_to_ETH: '0x48A574ec61C96eBD515f8016734d02a5d2CBc107',
      USDT_BUSD_to_BTC: '0x6Ae69820668f4cA2C224C08B93781Fe4235f7dE9',

      USDT_BUSD_StableLP_to_ETH_BNB: '0x385e5CA511307E63f6D11aCA98FAd13cbF38464b',
      USDT_BUSD_StableLP_to_WOM_BUSD: '0x1104F9cE50d23f757fc8656bF83769e227343e0a',
      USDT_BUSD_StableLP_to_Cake_BUSD: '0x0AC2a460465572D37Ad061D4403802999F7C0ba4',
      USDT_BUSD_StableLP_to_BNB_VENUS: '0x13C9D8e4FA5aB43Cf8fe27F32Dfc1BC079855320',

      USDT_USDC_StableLP_to_ETH_BNB: '0x50431eEBe49D0ea107BC764afbe2be0c53271170',
      USDT_USDC_StableLP_to_WOM_BUSD: '0xCf8104D2a76bf0b359C48Efa19EC49A8F0ab97B6',
      USDT_USDC_StableLP_to_CAKE_BUSD: '0x4cabB9c34760A2C7ce081884dbe768378d06e673',
      USDT_USDC_StableLP_to_BNB_VENUS: '0x9AF60Cd6f5352573CDee81A7C912902E2f91FBe2'
    },
    profitTakers: {
      Cake_BUSD_to_USDC_BUSD: '0x5FCE5E1b72344D664a023854783d62a76D4c70FB',
      Cake_BUSD_to_USDT_BUSD: '0xf9Eb7f2f706EEfF6fb90f675392939f0E19fD375',
      Cake_BNB_to_USDC_BUSD: '0x6ae9b84002B80496384E2c2F9dB9FEF76C7378b0',
      Cake_BNB_to_USDT_BUSD: '0x6615eFA09f5Bd02cA42dAdAB2b8921aB9EB9ADc3',
      // Charge_BUSD_to_USDC_BUSD: '0x53BF284f3fdD332c361602C7A6a068FE0B319b67',
      // Charge_BUSD_to_USDT_BUSD: '0x87F86a6c51CB12e90D02FAbA64014B1B1364529f',
      BTCB_BUSD_to_USDC_BUSD: '0xB41a3BF05450F3ac5a9744DdFDCa624Cd224a352',
      BTCB_BUSD_to_USDT_BUSD: '0x33Ae9A17415d422e646224b4dAe47cD43B0E4dA3',
      WOM_BUSD_to_USDC_BUSD: '0xCaf0B74567259f5609973163dD436E99b97Ee780',
      PEEL_BUSD_to_USDC_BUSD: '0xc80A344b464F292FA62b66F23dc6802f1c124dbB',
      TRIVIA_BNB_to_USDC_BUSD: '0xF252b1DCfDC782ABdc3F778566246f2296C36eb5',
      Cake_BUSD_to_ADA: '0x22223b2CC46d030273b6Cb1407fDE43c373B347f',
      Cake_BNB_to_ADA: '0x2F9AE7E17d35EFF05B1B8200b630d3F4dc6d9605',

      BTCB_BUSD_to_USDT_BUSD_StableLP: '0x67543117Dc86E4931462eAc26B6CB06B0794Cd57',
      Cake_BUSD_to_USDT_BUSD_StableLP: '0xa8D179e26e1b0b8F757F25361f1987E10599a2Ff',
      Cake_BNB_to_USDT_BUSD_StableLP: '0x905A863C36D67BBfBCb83e1E6e50Eec07465CbfD',
      BUSD_BNB_to_USDT_BUSD_StableLP: '0xf771Ccdd77E9f5A3ccBf729AC5CB7456F8F50CCC',

      BTCB_BUSD_to_USDT_USDC_StableLP: '0xc131B6736Dd017CCe6F02090ee81C4884f624651',
      Cake_BUSD_to_USDT_USDC_StableLP: '0xB2e5B6349e7Ee21E20E17759a5546741c0698810',
      Cake_BNB_to_USDT_USDC_StableLP: '0x153E28c6eBafEF8c012A33073721ea3191E71748',
      BUSD_BNB_to_USDT_USDC_StableLP: '0x6DAc5C7aFAb218715fC54156b05587e6E5317aEb',
      WOM_BUSD_to_USDT_USDC_StableLP: '0x3e8f4193A0182F1cD4FC5dbE6aA27B537E6494BD',
      PEEL_BUSD_to_USDT_USDC_StableLP: '0xc438bCf4d61Bd138C655423490472BfaD74e7071'
    }
  },
  farms: {
    CHARGE_BUSD: '0x03C437a123b791915A11EbB5D2F26865f8143b2f',
    STATIC_BUSD: '0x7ee65525A43A70c68Caf4aE116340466fE06c6dA'
  },
  pools: {
    Charge: '0x592491e62E0115406fD03080ED7Fb17926cFB571',
    Pulse: '0x75cA2ce9F9cCaCF470192a86115c8b4010E404Be'
  },
  general: {
    SentryTicket: '0xfd4BE6fF00631469854ee35Ed502cc35854E81Ac'
  },
  routers: {
    pancake: '0x10ED43C718714eb63d5aA57B78B54704E256024E'
  }
};
