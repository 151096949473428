import React from 'react';
import { BoxProps, Image } from '@chakra-ui/react';
import { getTokenIconUrl } from '../../../helpers/util';

type Props = BoxProps & {
	token?: string
	href?: string
    w?: string | number
    h?: string | number
	sm?: boolean
	xsm?: boolean
	pl?: string | number
	pr?: string | number
}
function TokenImage(props: Props) {
	let {
		token, w, h, pl, pr, sm = false, xsm = false, href = '',
	} = props;
	if (token) {
		if (!token.includes('-') && !w && h) {
			w = h;
		}
		if (token.includes('-') && !token.includes('chip')) {
			return <Image my="auto" mx="auto" pl={pl} pr={pr} src={getTokenIconUrl(token)} width={xsm ? '31.4px' : (sm ? '41.5px' : '58px')} height={xsm ? '20px' : (sm ? '25px' : '35px')} {...props} />;
		}

		// TODO - changes icons to match sizes
		if (token === 'cake') {
			return <Image my="auto" mx="auto" pl={pl} pr={pr} src={getTokenIconUrl(token)} width={xsm ? '12px' : (sm ? '20px' : (w ? '50px' : '30px'))} height={xsm ? '12px' : (sm ? '20px' : (h ? '50px' : '30px'))} {...props} />;
		}

		return (
    <Image my="auto" mx="auto" pl={pl} pr={pr} src={getTokenIconUrl(token)} width={w || (xsm ? '20px' : (sm ? '25px' : '35px'))} height={h || (xsm ? '20px' : (sm ? '25px' : '35px'))} {...props} />
		);
	} if (href) {
		return (
    <Image my="auto" mx="auto" pl={pl} pr={pr} src={href} width={w || (xsm ? '20px' : (sm ? '25px' : '35px'))} height={h || (xsm ? '20px' : (sm ? '25px' : '35px'))} {...props} />
		);
	}

	return null;
}

export default TokenImage;
