import { addressBook } from './addressbook.config';
import { ENV_PROD, ENV_TESTING, featureAllowed } from '../../env';

// description for Static-USDC farm - Deposit your Static-USDC LP to gain more Charge. Only a limited amount of Charge is left before
// emissions halt, forever! This may be your next step as you move over from the Boardroom or with your LP from the Membership Farm.

// description for Charge farm - $Charge and earn a percentage of performance fees from Bloomify. Of the 4.25% collected,
// 3% goes back to $Charge stakers! No LP? No worries, stake $Charge, earn $Static, from there you can take profit, restake, or wrap.

export const deployedFarms = [
  {
    address: addressBook.pools.CHARGE,
    deployment: 'GovernanceRewardPool',
    isGovernance: true,
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'Deposit your $Charge in the farm, and earn $Static. Your earned $Static will stay in the Earnings until you Claim or Withdraw. Withdrawing your tokens will also Claim earnings.',
    chain: 'FTM',
    env: ENV_PROD,
    otherInfo: ['Performance Fee Rewards'],
    name: 'Charge Reward Pool',
    platform: 'Bloomify',
    depositToken: {
      ...addressBook.tokens.CHARGE,
      isLP: false,
      canZap: false
    },
    earn: addressBook.tokens.STATIC,
    geolocation: true
  },
  {
    address: addressBook.farms.CHARGE_USDC,
    deployment: 'GenericFarmAllocation',
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'Deposit your Charge-USDC LP to gain more Charge. Only a limited amount of Charge is left before emissions halt, forever!',
    chain: 'FTM',
    env: ENV_PROD,
    name: 'Charge-USDC Farm',
    otherInfo: ['Deposit CHARGE-USDC', 'Earn CHARGE'],
    platform: 'Bloomify',
    depositToken: {
      ...addressBook.lps['spookyswap-charge-usdc'],
      isLP: true,
      canZap: false
    },
    earn: addressBook.tokens.CHARGE,
    geolocation: true
  },
  {
    address: addressBook.farms.STATIC_USDC,
    deployment: 'GenericFarmAllocation',
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'Deposit your Static-USDC LP to gain more Charge. Only a limited amount of Charge is left before emissions halt, forever!',
    chain: 'FTM',
    env: ENV_PROD,
    name: 'Static-USDC Farm',
    otherInfo: ['Deposit STATIC-USDC', 'Earn CHARGE'],
    platform: 'Bloomify',
    depositToken: {
      ...addressBook.lps['spookyswap-static-usdc'],
      isLP: true,
      canZap: false,
      geolocation: true
    },
    earn: addressBook.tokens.CHARGE,
    geolocation: true
  }
].filter((i) => featureAllowed(i.env));
