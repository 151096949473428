/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect } from 'react';
import {
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Skeleton,
	useColorModeValue as mode,
	Text,
	Stack,
	Link,
	Icon,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { VscRunAll } from '@react-icons/all-files/vsc/VscRunAll';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle';
import { RadioCard, RadioCardGroup } from '../../../common/RadioCardGroup';
import TokenImage from '../../../common/TokenImage';
import { useTicketSentry } from '../../hooks/useTicketSentry';
import { formatValue } from '../../../../helpers/util';
import { useTokenBalance } from '../../../../contexts/TokensBalancesContext';

type Props = {
  strategyAddr: string;
  isOpen: boolean;
  onClose: () => void;
};

function TicketModal({ strategyAddr, isOpen, onClose }: Props) {
	const {
		prices,
		ticketToken,
		setTicketToken,
		buyTicket,
		isLoading,
		staticApproved,
		onApprove,
	} = useTicketSentry(strategyAddr, onClose);

	const { getTokenBalance, balances } = useTokenBalance();
	const [disableRunButton, setDisableRunButton] = useState<boolean>(true);

	// disalbe run button if there is no balance for the selected token or if the tax is bigger than the amount user has in wallet
	useEffect(() => {
		if (balances && prices) {
			setDisableRunButton(
				!getTokenBalance(ticketToken).integer ||
          (ticketToken === 'static'
          	? getTokenBalance(ticketToken).integer < prices[0].amount
          	: getTokenBalance(ticketToken).integer < prices[1].amount),
			);
		}
	}, [balances, ticketToken, prices]);

	const buyStaticLink =
    'https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x7dEb9906BD1d77B410a56E5C23c36340Bd60C983'; // to do per chain in the near future
	// ftm chain link - https://spooky.fi/#/swap?inputCurrency=0x04068da6c83afcfa0e13ba15a6696662335d5b75&outputCurrency=0x27182C8b647fd83603bB442C0E450DE7445ccfB8

	return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
        <ModalOverlay />
        <ModalContent
            style={{ borderRadius: '3xl' }}
            w={{ base: '96vw', lg: 'unset' }}
      >
            <ModalHeader mx="auto">Strategy Running Fee</ModalHeader>
            <ModalCloseButton
                transition="transform 0.15s ease-in-out"
                _hover={{ transform: 'scale3d(1.02, 1.02, 1)' }}
        />
            <ModalBody textAlign="center">
                <Text fontWeight="300" fontSize={{ base: 'sm', md: 'md' }}>
                    There's a fee for every execution run. Please choose how you'd like
                    to pay
                </Text>
                <Text fontWeight="bold" py={1} fontSize={{ base: 'sm', md: 'md' }}>
                    Static fees are 10% cheaper than native token fees
                </Text>
                <Text
                    fontWeight="300"
                    fontSize={{ base: 'sm', md: 'md' }}
                    marginTop="1rem"
          >
                    You can buy Static from{' '}
                    <Link href={buyStaticLink} isExternal fontWeight="bold">
                        here <ExternalLinkIcon mx="2px" />
                    </Link>
                </Text>
                <Skeleton isLoaded={prices !== undefined}>
                    <Flex justifyContent="center" py={{ base: 2, md: 4, lg: 8 }}>
                        <RadioCardGroup
                            defaultValue={ticketToken}
                            spacing="3"
                            onChange={(e) => setTicketToken(e)}
              >
                            {prices &&
                  prices.map((i: any, key: number) => (
                      <RadioCard
                          value={key === 0 ? 'static' : 'native'}
                          mx={{ base: 0, md: 4 }}
                    >
                          <Stack spacing={0.5}>
                              <TokenImage
                                  token={key === 0 ? 'static' : 'bnb'}
                                  w="60px"
                                  h="60px"
                        />
                              <Text
                                  fontSize={{ base: 'sm', lg: 'md' }}
                                  w={{
                          	base: '90px',
                          	sm: '120px',
                          	md: '150px',
                          	lg: '220px',
                                  }}
                                  fontWeight="bold"
                        >
                                  {key === 0 ? 'Static' : 'BNB'}
                              </Text>
                              <Text
                                  fontSize={{ base: 'md', lg: 'lg' }}
                                  w={{
                          	base: '90px',
                          	sm: '120px',
                          	md: '150px',
                          	lg: '220px',
                                  }}
                                  overflow="hidden"
                                  whiteSpace="nowrap"
                                  textOverflow="ellipsis"
                        >
                                  {formatValue(i.amount)}
                              </Text>
                              <Text
                                  fontSize={{ base: 'sm', lg: 'md' }}
                                  w={{
                          	base: '90px',
                          	sm: '120px',
                          	md: '150px',
                          	lg: '220px',
                                  }}
                        >
                                  (${formatValue(i.price)})
                              </Text>
                          </Stack>
                      </RadioCard>
                  ))}
                        </RadioCardGroup>
                    </Flex>

                    {ticketToken === 'native' ||
            (ticketToken === 'static' && staticApproved) ? (
                		<>
                    <Button
                        {...buttonStyles()}
                        leftIcon={<VscRunAll />}
                        onClick={buyTicket}
                        isLoading={isLoading}
                        disabled={disableRunButton}
                >
                        Run
                    </Button>
                    {balances && disableRunButton ? (
                        <Text fontSize={{ base: 'sm', lg: 'sm' }} color="red" pb={2}>
                            <Flex justify="center" align="center">
                                <Icon
                                    as={FaExclamationTriangle}
                                    w={4}
                                    h={4}
                                    pr={1}
                                    color="red"
                      />
                                You do not have enough{' '}
                                {ticketToken === 'native' ? 'BNB' : 'Static'} (you have:{' '}
                                {formatValue(getTokenBalance(ticketToken).integer)})
                            </Flex>
                        </Text>
                    ) : balances ? (
                        <Text
                            fontSize={{ base: 'sm', lg: 'sm' }}
                            color="green"
                            pb={2}
                  >
                            <Flex justify="center" align="center">
                                You have {formatValue(getTokenBalance(ticketToken).integer)}{' '}
                                {ticketToken === 'native' ? 'BNB' : 'Static'}{' '}
                            </Flex>
                        </Text>
                    ) : null}
                    		</>
            	) : (
                <Button
                    {...buttonStyles()}
                    leftIcon={<FaCheck />}
                    onClick={() => onApprove('static')}
                    isLoading={isLoading}
              >
                    Approve Static
                </Button>
            	)}
                </Skeleton>
            </ModalBody>
        </ModalContent>
    </Modal>
	);
}

const buttonStyles = () => ({
	bg: mode('white', 'gray.900'),
	border: mode('2px solid rgb(0, 0, 0)', '2px solid white'),
	size: 'lg',
	margin: '2',
});

export default TicketModal;
