/* eslint-disable no-shadow */
import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Button,
	Flex,
	Text,
	useColorModeValue as mode,
	Link,
	Icon,
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import _ from 'lodash';
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';

type Props = {
  isOpen: boolean;
  onClose: () => void;
}

function GeolocationModal({
	isOpen,
	onClose,
}: Props) {
	return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
        <ModalOverlay />
        <ModalContent maxW="620px" px={{ base: 4, md: 5 }}>
            <ModalHeader mx="auto" px={{ base: 1, md: 5 }}>
                <Flex direction="column" justify="center" align="center">
                    <Text fontWeight="bold" fontSize="25px">
                        Restricted location
                    </Text>
                    <Link href="https://docs.bloomify.co/information/disclaimer" target="_blank">
                        <Button variant="link">
                            Click here for more details <Icon as={FaExternalLinkAlt} mx="auto" pl={2} w={5} h={5} color="muted" />
                        </Button>
                    </Link>
                </Flex>
            </ModalHeader>
            <ModalCloseButton transition="transform 0.15s ease-in-out" _hover={{ transform: 'scale3d(1.02, 1.02, 1)' }} />
            <ModalBody p={{ base: 1, md: 5 }}>
                <Flex flexDir="column" justify="center" align="center" py={3}>
                    <Text pl="4" textAlign="center">
                        Due to regulatory concerns, geo-restrictions have been implemented for this.
                        It appears that your IP address originates from a restricted jurisdiction.
                        If you are in a non-restricted location, and using a VPN, adjust it so that your IP address comes from the jurisdiction in which you reside.
                    </Text>
                </Flex>

                <Button
                    my="auto"
                    marginTop="1rem"
                    w="100%"
                    bg={mode('white', 'gray.800')}
                    border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}
                    onClick={onClose}>
                    I Understand
                </Button>
            </ModalBody>
        </ModalContent>
    </Modal>
	);
}

export default GeolocationModal;
