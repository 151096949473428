/* eslint-disable no-sparse-arrays */
/* eslint-disable max-len */
import { addressBook } from './addressbook.config';
import { deployedAccumulators } from './accumulators.data';
import { deployedProfitTakers } from './profit-takers.data';
import { ENV_PROD, ENV_TESTING, featureAllowed } from '../../env';

export const deployedVaults = [
  ...deployedAccumulators,
  ...deployedProfitTakers,
  {
    address: addressBook.vaults.USDT_BUSD_PCS_STABLELP,
    strategyAddress: '0xA56f608582B303f20d5D8B1842CBb6e8DD59DE4D',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDT-BUSD Vault',
    depositToken: {
      ...addressBook.lps['pancake-stable-usdt-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Vault',
    otherInfo: ['Deposit USDT-BUSD', 'Earn USDT-BUSD'],
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Hiding from the bear market? Want your profits to be earning? Deposit USDT-BUSD and earn back more of the same LP. Make your money make more money while being in a stablecoin pair. '
  },
  {
    address: addressBook.vaults.USDC_BUSD_PCS_STABLELP,
    strategyAddress: '0xA56f608582B303f20d5D8B1842CBb6e8DD59DE4D',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDC-BUSD Vault',
    depositToken: {
      ...addressBook.lps['pancake-stable-usdc-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Vault',
    otherInfo: ['Deposit USDC-BUSD', 'Earn USDC-BUSD'],
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Hiding from the bear market? Want your profits to be earning? Deposit USDC-BUSD and earn back more of the same LP. Make your money make more money while being in a stablecoin pair. '
  },

  {
    address: addressBook.vaults.USDT_USDC_PCS_STABLELP,
    strategyAddress: '0xA56f608582B303f20d5D8B1842CBb6e8DD59DE4D',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDC-USDT Vault',
    depositToken: {
      ...addressBook.lps['pancake-stable-usdt-usdc'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Vault',
    otherInfo: ['Deposit USDC-USDT', 'Earn USDC-USDT'],
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Hiding from the bear market? Want your profits to be earning? Deposit USDC-USDT and earn back more of the same LP. Make your money make more money while being in a stablecoin pair. '
  },
  {
    address: addressBook.vaults.CHARGE_BUSD,
    strategyAddress: '0xAA9E9F857a59011283E8ABf4AFf0caa6f11fCe17',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'CHARGE-BUSD Vault',
    otherInfo: ['Deposit CHARGE-BUSD', 'Earn CHARGE-BUSD'],
    depositToken: {
      ...addressBook.lps['pancake-charge-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Vault',
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Stake your Charge-BUSD and earn more Charge-BUSD, stack LPs repeatedly!',
  },
  {
    address: addressBook.vaults.STATIC_BUSD,
    strategyAddress: '0xc059305669f3ACd8fda6E93183b134886d2e9FB7',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'STATIC-BUSD Vault',
    otherInfo: ['Deposit STATIC-BUSD', 'Earn STATIC-BUSD'],
    depositToken: {
      ...addressBook.lps['pancake-static-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Vault',
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Like Static-BUSD LP? Stake your Static-BUSD and earn more Static-BUSD.',
  },
  {
    address: addressBook.vaults.ETH_BNB,
    strategyAddress: '0x9471489ED581b32334E92e082A9086f6a9B1a186',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'BSC',
    env: ENV_PROD,
    otherInfo: ['Deposit ETH-BNB', 'Earn ETH-BNB'],
    name: 'ETH-BNB Vault',
    depositToken: {
      ...addressBook.lps['pancake-eth-bnb'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Vault',
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Like ETH-BNB LP? Stake your ETH-BNB and earn more ETH-BNB.',
  },
  {
    address: addressBook.vaults.BTCB_ETH,
    strategyAddress: '0x43B4FEfB235574A1202C2cf9bc7B17A48Fa5b3c5',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'BSC',
    env: ENV_TESTING,
    name: 'BTCB-ETH Vault',
    otherInfo: ['Deposit BTCB-ETH', 'Earn BTCB-ETH'],
    depositToken: {
      ...addressBook.lps['pancake-btcb-eth'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Vault',
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Like ETH-BNB LP? Stake your ETH-BNB and earn more ETH-BNB.',
  },
  {
    address: addressBook.vaults.CAKE_BNB,
    strategyAddress: '0xdc151426b2385e0824c33D31b48c2FD2133f5B71',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'CAKE-BNB Vault',
    otherInfo: ['Deposit CAKE-BNB', 'Earn CAKE-BNB'],
    depositToken: {
      ...addressBook.lps['pancake-cake-bnb'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Vault',
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Like CAKE-BNB LP? Stake your CAKE-BNB and earn more CAKE-BNB.',
  },
  {
    address: addressBook.vaults.CAKE_BUSD,
    strategyAddress: '0xffC27967f33b37Ad90e71274A80C38221911941E',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'CAKE-BUSD Vault',
    otherInfo: ['Deposit CAKE-BUSD', 'Earn CAKE-BUSD'],
    depositToken: {
      ...addressBook.lps['pancake-cake-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Vault',
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Like CAKE-BUSD LP? Stake your CAKE-BUSD and earn more CAKE-BUSD.',
  },

  {
    address: addressBook.vaults.WOM_BUSD,
    strategyAddress: '0xd382BE42CB64ED58CB9c562f375ADEA12E348370',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'WOM-BUSD Vault',
    otherInfo: ['Deposit WOM-BUSD', 'Earn WOM-BUSD'],
    depositToken: {
      ...addressBook.lps['pancake-wom-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Vault',
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Like WOM-BUSD LP? Stake your WOM-BUSD and earn more WOM-BUSD.',
  },
  {
    deprecated: true,
    address: addressBook.vaults.USDT_BUSD,
    strategyAddress: '0xA56f608582B303f20d5D8B1842CBb6e8DD59DE4D',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDT-BUSD Vault',
    depositToken: {
      ...addressBook.lps['pancake-usdt-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Vault',
    otherInfo: ['Deposit USDT-BUSD', 'Earn USDT-BUSD'],
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Hiding from the bear market? Want your profits to be earning? Deposit USDT-BUSD and earn back more of the same LP. Make your money make more money while being in a stablecoin pair. '
  },
  {
    deprecated: true,
    address: addressBook.vaults.USDC_BUSD,
    strategyAddress: '0x50e6DB955014b3Ef2A717281b7B2D7294D1675e0',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDC-BUSD Vault',
    otherInfo: ['Deposit USDC-BUSD', 'Earn USDC-BUSD'],
    depositToken: {
      ...addressBook.lps['pancake-usdc-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Vault',
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Hiding from the bear market? Want your profits to be earning? Deposit USDC-BUSD and earn back more of the same LP. Make your money make more money while being in a stablecoin pair.'
  },
  {
    deprecated: true,
    address: addressBook.vaults.USDC_USDT,
    strategyAddress: '0x96c3B5e0A244d8cF523C2be5e8f99E4C062ff0f4',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'BSC',
    env: ENV_PROD,
    otherInfo: ['Deposit USDC-USDT', 'Earn USDC-USDT'],
    name: 'USDC-USDT Vault',
    depositToken: {
      ...addressBook.lps['pancake-usdc-usdt'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Vault',
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Hiding from the bear market? Want your profits to be earning? Deposit USDC-USDC and earn back more of the same LP. Make your money make more money while being in a stablecoin pair. '
  }
].filter((i) => featureAllowed(i.env));
