/* eslint-disable no-undef */
import {
	Box,
	HStack, Container, Text, Flex, StackDivider, Icon, Link, useColorModeValue as mode,
} from '@chakra-ui/react';
import * as React from 'react';
import { BsBoxArrowUpRight } from '@react-icons/all-files/bs/BsBoxArrowUpRight';

interface Props {
  children: JSX.Element[]
  title?: string
  description?: string
  minW?: any
  hasDocs?: boolean
}
export function StatWrapper(props: Props) {
	const {
		children, minW, title = 'Title', description = 'description',
	} = props;

	return (
    <Flex
        px={{
    	base: 2, sm: 6, md: 8, '2xl': 4,
        }}
        pt={4}
        pb={8}
        justify="space-between"
        flexDir={{ base: 'column', lg: 'row' }}
        w="100%"
        mx="auto"
        minW={minW}
        maxW="8xl">
        <Flex flexDir="column" maxW={{ base: '94%', lg: '48%', xl: '52%' }} textAlign={{ base: 'center', lg: 'left' }}>
            <Text
                fontWeight="bold"
                fontSize="3xl">
                {title}</Text>
            <Text color={mode('gray.900', 'whiteAlpha.800')} fontSize="15px" lineHeight="1.75" pr={{ base: 0, md: '2%', lg: '5%' }} pt={1}>
                {description}
                {/* <Link mt="2" display="flex" alignItems="center" href="https://docs.bloomify.co/" target="_blank" color="#8A88B7">
                    Learn more <Icon ml={1} as={BsBoxArrowUpRight} />
                </Link> */}
            </Text>
        </Flex>
        <HStack
            pt={{ base: 4, lg: 1 }}
            flexDir={{ base: 'column', sm: 'row' }}
            flexWrap="wrap"
            align="center"
            justify="center"
            spacing={{ base: 4, md: 6, lg: 12 }}
            divider={<StackDivider
                display={{ base: 'none', sm: 'flex' }}
                marginInlineEnd={{ base: 4, lg: 8 }}
                marginInlineStart={{ base: 4, lg: 8 }} />}
            bg="transparent"
            px={{ base: 2, md: 4 }}
            rounded="lg">
            {children}
        </HStack>
    </Flex>
	);
}
