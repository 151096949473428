/* eslint-disable no-sparse-arrays */
/* eslint-disable max-len */
import { addressBook } from './addressbook.config';
import { ENV_PROD, ENV_TESTING, featureAllowed } from '../../env';

export const description = (tokenA, tokenB) => `${tokenA.name} is deposited into the farm. Yield is sold to accumulate ${tokenB.name} and deposited in an auto-compounding Bank.
The Bank autocompounds into more ${tokenB.name} unless funds are claimed or withdrawn.
This strategy automatically harvests your yield from ${tokenA.name} and buys into ${tokenB.name} at regular intervals ensuring you are taking advantage of lowering your average entry price.`;

export const holdDescription = (tokenA, tokenB) => `${tokenA.name} is deposited into the farm. Yield is sold to accumulate ${tokenB.name}.
This strategy automatically harvests your yield from ${tokenA.name} and buys into ${tokenB.name} at regular intervals ensuring you are taking advantage of lowering your average entry price.`;

export const deployedAccumulators = [
  {
    address: addressBook.vaults.accumulators.USDC_STARGATE_to_USDC_FTM,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'USDC (Stargate LP) to USDC-FTM Accumulator',
    depositToken: {
      ...addressBook.tokens.sfUSDC,
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['spookyswap-usdc-ftm'],
      isLP: true,
      canZap: false
    },
    platforms: ['Stargate', 'Spookyswap'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDC LP', 'Accumulate FTM-USDC'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.tokens['sfUSDC'], addressBook.lps['spookyswap-usdc-ftm']),
  },

  {
    address: addressBook.vaults.accumulators.USDC_STARGATE_to_FTM,
    isUsingAccumulations: true,
    isHoldAccumulator: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'USDC (Stargate LP) to FTM Accumulator',
    depositToken: {
      ...addressBook.tokens.sfUSDC,
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.tokens.WFTM,
      isLP: false,
      canZap: false
    },
    platforms: ['Stargate'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDC LP', 'Accumulate FTM'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // holdDescription(addressBook.tokens.sfUSDC, addressBook.tokens.WFTM)
  },

  {
    address: addressBook.vaults.accumulators.USDC_MAI_to_USDC_FTM,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'USDC-MAI to USDC-FTM Accumulator',
    depositToken: {
      ...addressBook.lps['spookyswap-usdc-mai'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['spookyswap-usdc-ftm'],
      isLP: true,
      canZap: false
    },
    platforms: ['Spookyswap'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDC-MAI', 'Accumulate FTM-USDC'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['spookyswap-usdc-mai'], addressBook.lps['spookyswap-usdc-ftm']),
  },

  {
    address: addressBook.vaults.accumulators.USDC_MAI_to_BOO_FTM,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'USDC-MAI to BOO-FTM Accumulator',
    depositToken: {
      ...addressBook.lps['spookyswap-usdc-mai'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['spookyswap-boo-ftm'],
      isLP: true,
      canZap: false
    },
    platforms: ['Spookyswap'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDC-MAI', 'Accumulate BOO-FTM'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['spookyswap-usdc-mai'], addressBook.lps['spookyswap-boo-ftm']),
  },

  {
    address: addressBook.vaults.accumulators.USDC_MAI_to_ETH_FTM,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'USDC-MAI to ETH-FTM Accumulator',
    depositToken: {
      ...addressBook.lps['spookyswap-usdc-mai'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['spookyswap-eth-ftm'],
      isLP: true,
      canZap: false
    },
    platforms: ['Spookyswap'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDC-MAI', 'Accumulate ETH-FTM'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['spookyswap-usdc-mai'], addressBook.lps['spookyswap-eth-ftm']),
  },

  {
    address: addressBook.vaults.accumulators.USDC_MAI_to_FTM,
    isUsingAccumulations: true,
    isHoldAccumulator: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'USDC-MAI to FTM Accumulator',
    depositToken: {
      ...addressBook.lps['spookyswap-usdc-mai'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.tokens.WFTM,
      canZap: false
    },
    platforms: ['Spookyswap'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDC-MAI', 'Accumulate FTM'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // holdDescription(addressBook.lps['spookyswap-usdc-mai'], addressBook.tokens.WFTM)
  },

  {
    address: addressBook.vaults.accumulators.USDC_MAI_to_ETH,
    isUsingAccumulations: true,
    isHoldAccumulator: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'USDC-MAI to ETH Accumulator',
    depositToken: {
      ...addressBook.lps['spookyswap-usdc-mai'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.tokens.ETH,
      canZap: false
    },
    platforms: ['Spookyswap'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDC-MAI', 'Accumulate ETH'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // holdDescription(addressBook.lps['spookyswap-usdc-mai'], addressBook.tokens.ETH)
  }
].filter((i) => featureAllowed(i.env));
