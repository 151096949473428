/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
import React, { useEffect } from 'react';
import {
	Image, Text, Flex, Link, useColorModeValue as mode, Divider, Spacer, Box, Center, useDisclosure,
} from '@chakra-ui/react';
import { Link as LinkRouter } from 'react-router-dom';
import Socials from './Socials';
// @ts-ignore
import charge from '../../../assets/logo/charge-logo.png';
// @ts-ignore
import dark from '../../../assets/logo/bloomify-dark.png';
// @ts-ignore
import light from '../../../assets/logo/bloomify-light.png';

import InformationModal from '../Modal/Premade/InformationModal';

function Footer({ hasSocials = true }) {
	const { isOpen: isOpenInfoModal, onOpen: onOpenInfoModal, onClose: onCloseInfoModal } = useDisclosure();

	const closeWithdrawModal = async () => {
		onCloseInfoModal();
	};

	/* useEffect(() => {
		onOpenInfoModal();
	}, []); */

	return (
    <Flex flexDir="column" justify="center" py={5} bg="bg-surface">
        <Image mx="auto" src={mode(dark, light)} w={{ base: '180px', md: '240px' }} h="auto" my={1} />
        {/* <Text
            mx="auto"
            pb={8}
            color={mode('gray.500', 'whiteAlpha.700')}
            fontWeight={300}
            display="inline-flex">a <Link
                fontWeight={600}
                display="inline-flex"
                href="https://www.chargeventures.co/"
                target="_blank"
                color={mode('gray.800', 'whiteAlpha.900')}
                mr={2}
                alignItems="center"><Image src={charge} w={5} h={5} mx={2} />Charge Ventures</Link> product</Text> */}

        <Spacer />

        { isOpenInfoModal && window.location.pathname !== '/' ? (
            <InformationModal
                href="https://medium.com/pancakeswap/introducing-usdt-busd-usdc-busd-and-usdc-usdt-pairs-on-stableswap-enjoy-lower-slippage-and-fees-8960a86b2f12"
                title="Attention!"
                onClose={() => onCloseInfoModal()}
                isOpen={isOpenInfoModal}
                text="Pancakeswap initiated a change to USDT-BUSD, USDC-BUSD and USDC-USDT. As users unstake to migrate, TVL will be affected. For more please visit our channels linked at the bottom of the page."
            />
        ) : null}

        {/* <Flex py={10} px={5} flexWrap="wrap" justifyItems="center">
            <Box mx="auto">
                {hasSocials ?
                	<>
                    <Flex pb={2}>
                            <Text fontSize="md" color={mode('gray.500', 'whiteAlpha.700')} fontWeight={300} my="auto" mx="auto">Our channels</Text>
                        </Flex>
                    <Socials />
                	</>
                  	: null}
                <Divider />
                <Flex px={{ base: 1, md: 5 }} py={4} justify="center">
                    <Link href="/bloomify_audit.pdf" target="_blank"><Text color={mode('gray.500', 'whiteAlpha.700')} fontWeight={300}>Audit</Text></Link>
                    <Center height="30px" w="30px">
                        <Divider orientation="vertical" />
                    </Center>
                    <Link href="/bloomify_whitepaper.pdf" target="_blank"><Text color={mode('gray.500', 'whiteAlpha.700')} fontWeight={300}>Whitepaper</Text></Link>
                    <Center height="30px" w="30px">
                        <Divider orientation="vertical" />
                    </Center>
                    <LinkRouter to="/terms-and-conditions"><Text color={mode('gray.500', 'whiteAlpha.700')} fontWeight={300}>Disclaimer</Text></LinkRouter>
                </Flex>
            </Box>
        </Flex> */}
    </Flex>
	);
}

export default Footer;
