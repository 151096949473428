/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
	Flex, Text, Skeleton, Container, Button, Icon, useColorModeValue as mode,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle';
import _ from 'lodash';
import { useFetchUserClonedStrategies } from '../hooks/useFetchUserClonedStrategies';
import NotConnected from '../../common/NotConnected/index';
import MyPortfolioStats from './MyPortfolioStats';

import { formatUS } from '../../../helpers/formating';
import { formatValue } from '../../../helpers/util';
import StrategiesFilters from '../../common/StrategiesFilters/StrategiesFilters';
import GridView from '../../common/GridView';
import StrategyCard from '../../common/StrategyCard';
import PaginationModule from '../../common/Pagination/Pagination';

function UserClonedStrategies() {
	const navigate = useNavigate();
	const {
		account, clonedStrategies, stats,
	} = useFetchUserClonedStrategies();

	const [isGridView, setIsGridView] = useState<boolean>(false);
	const [enabledOwnStrats, setEnabledLegos] = useState(clonedStrategies);
	const [defaultOwnStrats, setDefaultOwnStrats] = useState(clonedStrategies);

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(0);
	const perPage = 15;

	const [clearFilters, setClearFilters] = useState<boolean>(false);

	const filteredStrategies = (filteredOnlyLegoDetails: any[]) => {
		if (!clonedStrategies) return;

		const rows = filteredOnlyLegoDetails ? filteredOnlyLegoDetails.slice(perPage * (currentPage - 1), perPage * currentPage) : null;
		const mapped = rows.map((s) => clonedStrategies.find((d) => d.clonedStratDetails.strategyAddress.toLowerCase() === s.strategyAddress.toLowerCase()));
		setClearFilters(false);

		setEnabledLegos(mapped);
	};

	useEffect(() => {
		const withoutHiddenDeprecated = _.filter(clonedStrategies, (s) => !s.clonedStratDetails.deprecated || s.currentStratTotalDeposit > 0);
		setDefaultOwnStrats(withoutHiddenDeprecated);

		const rows = withoutHiddenDeprecated ? withoutHiddenDeprecated.slice(perPage * (currentPage - 1), perPage * currentPage) : null;
		const totalPagesSet = withoutHiddenDeprecated ? withoutHiddenDeprecated.length / perPage : 0;
		setTotalPages((Math.round(totalPagesSet) + 0.5) < totalPagesSet ? Math.round(totalPagesSet) + 1 : Math.round(totalPagesSet));
		setClearFilters(true);

		setEnabledLegos(rows);
	}, [clonedStrategies, currentPage]);

	if (!account) {
		return <NotConnected />;
	}

	return (
    <Flex flexDir="column" h="100%" flexGrow={1}>
        {clonedStrategies && <MyPortfolioStats {...stats} strategies={defaultOwnStrats ? defaultOwnStrats.length : undefined} />}
        <Skeleton my={5} isLoaded={!clonedStrategies || !!clonedStrategies.length} height="100%">
            <Container maxW="1800px" px={{ base: 1, sm: 5 }}>
                {clonedStrategies && defaultOwnStrats && (
                <Flex align="center" justify="center">
                    <StrategiesFilters strategies={defaultOwnStrats.map((s) => s.clonedStratDetails)} filteredStrategies={filteredStrategies} clearFilters={clearFilters} myStrategies />
                </Flex>
                )}
                {clonedStrategies && defaultOwnStrats && defaultOwnStrats.some((s) => s.clonedStratDetails.deprecated) ? (
                    <Flex align="center" justify="center" py={3}>
                        <Flex w="50%">
                            <Icon as={FaExclamationTriangle} mx="auto" w={8} mr={1} h={8} color="red" />
                            <Text fontSize="md" textAlign="center" color="red">
                                {defaultOwnStrats.filter((s) => s.clonedStratDetails.deprecated).length > 1
                  	? 'Some of your custom strategies are based on archived (older version) strategies. Please withdraw funds, create new strategies or move funds to strategies based on current version.'
                  	: 'You have a custom strategy based on an archived strategy. Withdraw your funds, create another strategy or move your funds to an already created strategy.'}
                            </Text>
                        </Flex>
                    </Flex>
                ) : null}
                <GridView
                    isGridView={isGridView}
                    setIsGridView={setIsGridView}
                    length={clonedStrategies && enabledOwnStrats ? enabledOwnStrats.length : 0}>
                    {clonedStrategies && enabledOwnStrats && enabledOwnStrats.length
            	? enabledOwnStrats.map((data: any, key: number) => (
                <StrategyCard
                    strategy={data.clonedStratDetails}
                    isLegoCard
                    isGridView={isGridView}
                    key={key}
                    profit={`$${formatValue(data.clonedStratDetails.profitBalance.hex / 1e18)}`}
                    deposit={`$${formatValue(data.currentStratTotalDeposit)}`}
                />
            		))
            	: null}
                </GridView>
                <Flex align="center" justify="center" w="100%"> <PaginationModule totalPages={totalPages} setPage={(page) => setCurrentPage(page)} /> </Flex>
            </Container>
            {(!clonedStrategies || ((clonedStrategies && clonedStrategies.length === 0) || (enabledOwnStrats && enabledOwnStrats.length === 0))) && (
            <Flex flexDir="column" my="auto" py={14} mx="auto">
                <Text fontSize="3xl" py={5} textAlign="center">
                    No strategies found
                </Text>
                <Button
                    onClick={() => navigate('/strategies')}
                    width="300px"
                    mx="auto"
                    h="50px"
                    my="auto"
                    bg={mode('white', 'gray.700')}
                    border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}
                >
                    Browse Strategies
                </Button>
            </Flex>
            )}
        </Skeleton>
    </Flex>
	);
}

export default UserClonedStrategies;
