import {Circle, SquareProps, Text, Tooltip, useColorModeValue as mode} from '@chakra-ui/react'
import React from 'react';
import {ResponsiveTooltip} from "../Tooltip";


interface RadioCircleProps extends SquareProps {
    isCompleted: boolean
    isActive: boolean
    stepNumber: number
    stepTitle?: string
    setStep: any
}

export const StepCircle = (props: RadioCircleProps) => {
    const { isCompleted, stepTitle, isActive, stepNumber, setStep } = props
    const handler = () => setStep(stepNumber)
    return (
      <ResponsiveTooltip label={ stepTitle ? stepTitle : `Farm ${stepNumber}`} placement='top'>
        <Circle
            cursor='pointer'
            onClick={handler}
            size={{ base: 8, lg: 10 }}
            // bg={isCompleted ? mode('gray.900', 'whiteAlpha.800') : 'inherit'}
            bg={'transparent'}
            // borderWidth={isCompleted ? '0' : '2px'}
            borderWidth={'2px'}
            // borderColor={isActive ? mode('gray.900', 'whiteAlpha.800') : 'inherit'}
            borderColor={mode('gray.900', 'whiteAlpha.800')}
            {...props}
        >
            <Text cursor='pointer' fontSize={'sm'} fontWeight="semibold" color={mode('gray.900', 'whiteAlpha.800')}>{stepNumber}</Text>
        </Circle>
      </ResponsiveTooltip>
    )
}
