/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import {
	Button,
	Flex,
	Spacer,
	Image,
	Text,
	useMediaQuery,
	Box,
	Link as ExternalLink,
	useColorModeValue as mode,
	Skeleton,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { formatWalletAddr } from '../../../helpers/formating';
import '../../../assets/main.css';
import { NavContent } from '../Navbar/NavContent';
import { ColorModeSwitcher } from '../ColorModeSwitcher/ColorModeSwitcher';
import { useWalletProvider } from '../../../hooks/useWalletProvider';
// @ts-ignore
import light from '../../../assets/logo/bloomify-light.png';
// @ts-ignore
import dark from '../../../assets/logo/bloomify-dark.png';
import { useTokenPrices } from '../../../contexts/TokenPricesContext';
import { getTokenDetails } from '../../../service/tokens.service';
import TokenImage from '../TokenImage';
import { useGetGeneralPlatformStats } from '../../../hooks/useGetGeneralPlatformStats';
import { formatValue } from '../../../helpers/util';
import { getSavedChain } from '../ChainSwitcher/switcher.cookie';
import { defaultChain } from '../../../bloomify/config';

function TopNavBar() {
	const { pathname } = useLocation();

	const [isMobile] = useMediaQuery('(max-width: 1280px)');
	const { getTokenPrice } = useTokenPrices();
	const getGeneralStats = useGetGeneralPlatformStats();

	const {
		onPresentConnectModal, logoutWallet, onPresentAccountModal, wallet,
	} = useWalletProvider();

	const shadow = mode('md', 'md-dark');

	return (
    <Box zIndex={222} w="100%" px={8} bg="bg-surface" shadow={shadow} position="fixed" backdropFilter="saturate(180%) blur(5px)">
        <Flex maxW="8xl" mx="auto">
            {!isMobile && <Link to="/"><Image src={mode(dark, light)} w="auto" h="38px" ml={4} mr={8} mb={3} mt={4} /></Link>}
            <NavContent.Desktop display={{ base: 'none', xl: 'flex' }} flex="1" align="stretch" justify="center" />
            <NavContent.Mobile display={{ base: 'flex', xl: 'none' }} />
            {isMobile && <Spacer />}
            {/* {!isMobile && <Skeleton isLoaded={!!getTokenPrice('STATIC') && !!getGeneralStats.chainTvl}>
                <Flex align="center" justify="center" h="100%" direction="column">
                    <Flex align="end" justify="center" h="50%" mb={1}>
                        <ExternalLink href={getTokenDetails('STATIC').buyLink} target="_blank">
                            <Flex justify="center" align="center" mr="3">{getTokenPrice('STATIC') && <TokenImage xsm token="STATIC" />}
                                <Text fontSize="sm" ml={2} fontWeight="bold" textColor="muted">
                                    { getTokenPrice('STATIC') ? getTokenPrice('STATIC').toFixed(3) : 'Loading...' }
                                </Text>
                            </Flex>
                        </ExternalLink>
                        <ExternalLink href={getTokenDetails('CHARGE').buyLink} target="_blank">
                            <Flex justify="center" align="center" mb={0}>{getTokenPrice('CHARGE') && <TokenImage xsm token="CHARGE" />}
                                <Text fontSize="sm" ml={2} fontWeight="bold" textColor="muted">
                                    { getTokenPrice('CHARGE') ? getTokenPrice('CHARGE').toFixed(3) : 'Loading...' }
                                </Text>
                            </Flex>
                        </ExternalLink>
                    </Flex>
                    <Flex align="start" justify="center" h="50%">
                        <Text fontSize="sm" fontWeight="bold" textColor="muted" mr={2}>
                            {defaultChain.configName.toUpperCase()} TVL:
                        </Text>

                        <Text fontSize="sm" fontWeight="regular" textColor="muted">
                            { `$${formatValue(getGeneralStats.chainTvl)}` }
                        </Text>
                    </Flex>
                </Flex>
            </Skeleton>} */}
            <ColorModeSwitcher mx={5} my={3} />
            { !(pathname === '/') ?
                <Button
                    bg={mode('white', 'gray.700')}
                    my={3}
                    w="150px"
                    border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}
                    onClick={wallet ? onPresentAccountModal : onPresentConnectModal}>
                    {wallet ? formatWalletAddr(wallet) : 'Connect Wallet'}
                </Button> :
                <Link to="/accumulators">
                    <Button
                        width="150px"
                        my={2}
                        mx="auto"
                        h="50px"
                        color={mode('gray.700', 'white')}
                        border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}>
                        Start Bloomify
                    </Button>
                </Link>}
        </Flex>
    </Box>
	);
}

export default TopNavBar;
