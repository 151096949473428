import {
	Box, Text, Flex, keyframes, useColorModeValue as mode, Badge, Spacer, HStack, Icon, Button, Tooltip, UnorderedList, ListItem,
} from '@chakra-ui/react';
import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { AiOutlineQuestionCircle } from '@react-icons/all-files/ai/AiOutlineQuestionCircle';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle';
import { AiOutlineBank } from '@react-icons/all-files/ai/AiOutlineBank';
import TokenImage from '../TokenImage';
import { formatValue } from '../../../helpers/util';
import { StratBlockAPRDetails } from '../APRDetails';
import { ResponsiveTooltip } from '../Tooltip';

type Props = {
  strategy?: any;
  isGridView?: boolean;
  isMobile?: boolean;
  profit?: string;
  deposit?: string;
  isLegoCard?: boolean;
};

export const animationKeyframes = keyframes`
      0% { box-shadow: inset 0 0 500px 0px rgb(237 100 166 / 10%), 0 0 20px 2px rgb(237 100 166 / 58%); }
      50% { box-shadow: inset 0 0 500px 0px rgb(237 100 166 / 2%), 0 0 20px 2px rgb(237 100 166 / 12%); }
      100% { box-shadow: inset 0 0 500px 0px rgb(237 100 166 / 10%), 0 0 20px 2px rgb(237 100 166 / 58%); }
    `;

export const animation = `${animationKeyframes} 3s ease-in-out infinite`;

function StrategyCard({
	strategy, isGridView, isMobile, profit, deposit, isLegoCard, ...boxProps
}: Props) {
	const navigate = useNavigate();
	const iconForType = strategy.type.toLowerCase().replace(/ /g, '-');
	const url = isLegoCard ? `${strategy.tag}/${strategy.strategyAddress}` : `${strategy.tag}`;

	return (
    <Flex
        pb={6}
        px={{ base: 0, lg: 2, xl: isGridView ? 3 : 1 }}
        w={{ base: '100%', lg: '50%', xl: isGridView ? '33.33%' : '100%' }}
        justify="center">
        <Flex
            as={motion.div}
            w="full"
            transition="transform 0.15s ease-in-out"
            _hover={{ transform: 'scale3d(1.02, 1.02, 1)' }}
            // animation={strategy.otherInfo ? animation : null}
            maxW="8xl"
            h="max-content"
            rounded="3xl"
            bg="bg-surface"
            p={{ base: 2, sm: 4 }}
            onClick={() => navigate(`${url}`, { state: { strategy } })}
            boxShadow={mode('sm', 'sm-dark')}
            cursor="pointer"
            flexDir={{ base: 'column', xl: isGridView ? 'column' : 'row' }}
            justify="space-between">
            <Flex justify="start">
                <Flex>
                    <TokenImage token={iconForType} w="60px" h="60px" />
                    <Box my="auto" px={1}>
                        <Flex>
                            <Text my="auto" fontSize={{ base: 'lg', sm: 'xl' }} pl={2} pr={1} pb={0} fontWeight="bold">
                                <Flex ml={1} py={1} justifyContent="center">
                                    {strategy.includesBank && <Icon as={AiOutlineBank} w="26px" h="26px" mr={1.5} color="green.400" /> }
                                    {strategy.name}
                                </Flex>
                            </Text>
                            <ResponsiveTooltip label={strategy.description} hasArrow placement="top" p={4} borderRadius="md">
                                <Button py={0} px={1} leftIcon={<Icon as={AiOutlineQuestionCircle} w="16px" h="16px" />} minW="20px" colorScheme="grey" variant="link" />
                            </ResponsiveTooltip>
                        </Flex>
                        <Flex maxW="20vw" flexWrap="wrap">
                            <Badge my="2px" colorScheme="purple" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                                {strategy.type}
                            </Badge>
                            <ResponsiveTooltip label={strategy.platforms.join(', ')} hasArrow placement="bottom" p={4} borderRadius="md">
                                <Badge my="2px" colorScheme="yellow" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                                    <Flex align="center">
                                        {' '}
                                        {strategy.platforms.length > 1 ? `${strategy.platforms.length} Platform(s)` : strategy.platforms[0]}
                                        {strategy.platforms.length > 1 && <Icon as={FaInfoCircle} mx="auto" pl={1} w={4} h={4} /> }
                                    </Flex>
                                </Badge>
                            </ResponsiveTooltip>
                            {(!!strategy.earn || !!strategy.deprecated) && <Badge my="2px" colorScheme="orange" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                                {strategy.deprecated ? 'ARCHIVED' : `Earn ${strategy.earn}`}
                            </Badge>}
                            {strategy.otherInfo ? strategy.otherInfo.map((i) =>
                                <Badge my="2px" colorScheme="green" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                                    {i}
                                </Badge>) : null}
                        </Flex>
                        {isLegoCard && (!strategy.lastRunTime || !deposit || strategy.deprecated) ? (
                            <Flex direction="row" align="center" justify={isMobile ? 'center' : null}>
                                <Icon as={FaExclamationTriangle} py={2} w={7} h={7} color="orange" />
                                <Text fontSize="sm" mt="0.1rem" textAlign="center" color="orange">
                                    {strategy.deprecated ? 'Archived - Withdraw your funds' : 'Attention is required'}
                                </Text>
                            </Flex>
                        ) : null}
                    </Box>
                </Flex>
            </Flex>
            <Flex justify="center" flexWrap="wrap" minW={{ lg: '340px' }} my={{ base: 2, xl: isGridView ? 2 : 0 }}>
                {strategy.blocks.map((block, key) => (
                    <Flex key={key} m={1} flexDir="column" alignSelf="stretch" bg={mode('#FAFAFA', 'blackAlpha.300')} rounded="lg" px={{ base: 3, md: 5 }} py={4}>
                        <TokenImage token={block.token.symbol.toLowerCase()} />
                        <Text fontSize="sm" mx="auto" lineHeight="20px" textAlign="center" w="86px">
                            {block.name}
                        </Text>
                        <StratBlockAPRDetails aprDetails={block.aprDetails} />
                    </Flex>
                ))}
            </Flex>
            <Spacer />
            <HStack
                justify="center"
                my={{ base: 4, md: 3 }}
                mx={isGridView ? null : { base: 4, md: 3 }}
                flexDir={{ base: 'column', sm: 'row' }}
                spacing={{ base: 0, sm: 8 }}
                bg={mode('#FAFAFA', 'blackAlpha.300')}
                px={6}
                py={4}
                rounded="lg">
                <Box my="auto" minW="60px">
                    <Flex justify="center" align="center" direction="column">
                        <Text fontSize="sm" fontWeight="light" textColor="gray.400">
                            TVL
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                            ${formatValue(strategy.tvl, 2)}
                        </Text>
                    </Flex>
                </Box>
                {deposit && profit && (
                	<>
                    {' '}
                    <Box my="auto" minW="60px">
                        <Flex justify="center" align="center" direction="column">
                            <Text fontSize="sm" fontWeight="light" textColor="gray.400">
                                Deposit
                            </Text>
                            <Text fontSize="lg" fontWeight="bold">
                                {deposit}
                            </Text>
                        </Flex>
                    </Box>
                    <Box my="auto" minW="60px">
                        <Flex justify="center" align="center" direction="column">
                            <Text fontSize="sm" fontWeight="light" textColor="gray.400">
                                Profit
                            </Text>
                            <Text fontSize="lg" fontWeight="bold">
                                {profit}
                            </Text>
                        </Flex>
                    </Box>{' '}
                	</>
                )}
            </HStack>
            {!isLegoCard &&
          (isGridView || isMobile ? (
              <Flex flexDir="column" bg="purple.500" borderRadius={20}>
                  <Icon as={FaPlus} mx="auto" py={2} w={10} h={10} color="white" />
              </Flex>
          ) : (
              <Flex flexDir="column" ml={2} justify="center">
                  <Icon as={FaPlus} mx="auto" py={2} borderRadius={20} bg="purple.500" w={10} h={10} color="white" />
              </Flex>
          ))}
        </Flex>
    </Flex>
	);
}

export default StrategyCard;
