import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { theme } from '@chakra-ui/pro-theme';
import { Web3ReactProvider } from '@web3-react/core';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { getLibrary } from './utils/web3React';
import { chargeTheme } from './assets/theme';
import App from './App';

/* if (process.env.REACT_APP_ENVIRONMENT) {
	Sentry.init({
		dsn: 'https://28d1d4e59fa047acb4a4e5566e5bf72e@o1268783.ingest.sentry.io/6456673',
		integrations: [new BrowserTracing()],
		environment: process.env.REACT_APP_ENVIRONMENT || ENV_TESTING,

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: process.env.REACT_APP_ENVIRONMENT == ENV_PROD ? 0.4 : 1.0,
	});
} */

const appElement = document.getElementById('root');
ReactDOM.render(
    <ChakraProvider theme={chargeTheme}>
        <BrowserRouter>
            <Web3ReactProvider getLibrary={getLibrary}>
                <App />
            </Web3ReactProvider>
        </BrowserRouter>
    </ChakraProvider>,
    appElement,
);
