/* eslint-disable max-len */
import { addressBook } from './addressbook.config';
import { deployedAccumulators } from './accumulators.data';
import { deployedProfitTakers } from './profit-takers.data';
import { ENV_PROD, ENV_TESTING, featureAllowed } from '../../env';

export const deployedVaults = [
  ...deployedAccumulators,
  ...deployedProfitTakers,

  {
    address: addressBook.vaults.USDC_MAI,
    strategyAddress: '0x12bC82A34D7fFBeb968c365745BEB46969d9FF15',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'USDC-MAI Vault',
    otherInfo: ['Deposit USDC-MAI', 'Earn USDC-MAI'],
    depositToken: {
      ...addressBook.lps['spookyswap-usdc-mai'],
      isLP: true,
      canZap: false
    },
    platforms: ['Spookyswap'],
    type: 'Vault',
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Like USDC-MAI LP? Stake your USDC-MAI and earn more USDC-MAI.',
  },

  {
    address: addressBook.vaults.USDC_FTM,
    strategyAddress: '0x5DaC4344dD46E2A0f1Cd460d019A9A4562d66EA8',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'USDC-FTM Vault',
    otherInfo: ['Deposit USDC-FTM', 'Earn USDC-FTM'],
    depositToken: {
      ...addressBook.lps['spookyswap-usdc-ftm'],
      isLP: true,
      canZap: false
    },
    platforms: ['Spookyswap'],
    type: 'Vault',
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Like USDC-FTM LP? Stake your USDC-FTM and earn more USDC-FTM.',
  },

  {
    address: addressBook.vaults.BOO_FTM,
    strategyAddress: '0x93e6531239852FA6C34Df8CAfeaf4c725C7D3f60',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'BOO-FTM Vault',
    otherInfo: ['Deposit BOO-FTM', 'Earn BOO-FTM'],
    depositToken: {
      ...addressBook.lps['spookyswap-boo-ftm'],
      isLP: true,
      canZap: false
    },
    platforms: ['Spookyswap'],
    type: 'Vault',
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Like BOO-FTM LP? Stake your BOO-FTM and earn more BOO-FTM.',
  },

  {
    address: addressBook.vaults.ETH_FTM,
    strategyAddress: '0x95cCFBE9fD8Ea21f93cDF8A60B555C74349caA59',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'ETH-FTM Vault',
    otherInfo: ['Deposit ETH-FTM', 'Earn ETH-FTM'],
    depositToken: {
      ...addressBook.lps['spookyswap-eth-ftm'],
      isLP: true,
      canZap: false
    },
    platforms: ['Spookyswap'],
    type: 'Vault',
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Like ETH-FTM LP? Stake your ETH-FTM and earn more ETH-FTM.',
  },

  {
    address: addressBook.vaults.BTC_FTM,
    strategyAddress: '0x4f8415cF357621723bc98AcD4682956D5E321F6D',
    deployments: {
      vault: 'GenericVault',
      strategy: 'GenericVaultStrategy'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'BTC-FTM Vault',
    otherInfo: ['Deposit BTC-FTM', 'Earn BTC-FTM'],
    depositToken: {
      ...addressBook.lps['spookyswap-btc-ftm'],
      isLP: true,
      canZap: false
    },
    platforms: ['Spookyswap'],
    type: 'Vault',
    fees: {
      withdrawal: 0.1,
      deposit: 0,
      performance: 4.25
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // 'Like BTC-FTM LP? Stake your BTC-FTM and earn more BTC-FTM.',
  }
].filter((i) => featureAllowed(i.env));
