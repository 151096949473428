import {
	Box,
	Text,
	Flex,
	useColorModeValue as mode,
	Badge,
	Spacer,
	HStack,
	Icon,
	Button,
	Link,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { AiOutlineQuestionCircle } from '@react-icons/all-files/ai/AiOutlineQuestionCircle';
import { useWeb3React } from '@web3-react/core';
import { FaCrown } from '@react-icons/all-files/fa/FaCrown';
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';
import TokenImage from '../../../common/TokenImage';
import { formatValue } from '../../../../helpers/util';
import { ValueTokenStat } from '../../../common/ValueTokenStat';
import { GeneralCardAPRDetails } from '../../../common/APRDetails';
import { ResponsiveTooltip } from '../../../common/Tooltip';

type Props = {
  farm?: any;
  isGridView?: boolean;
  isMobile?: boolean;
};

function FarmCard({
	farm,
	isGridView,
	isMobile,
	...boxProps
}: Props) {
	const navigate = useNavigate();
	const { account } = useWeb3React();
	const url = `/farms/${farm.address}`;

	return (
    <Flex
        pb={6}
        px={{ base: 0, lg: 2, xl: isGridView ? 3 : 1 }}
        w={{ base: '100%', lg: '50%', xl: isGridView ? '33.33%' : '100%' }}
        justify="center"
        alignSelf="stretch"
    >
        <Flex
            w="full"
            transition="transform 0.15s ease-in-out"
            _hover={{ transform: 'scale3d(1.02, 1.02, 1)' }}
            maxW="8xl"
            h={{ base: 'max-content', lg: '100%', xl: isGridView ? '100%' : 'max-content' }}
            rounded="3xl"
            bg="bg-surface"
            p={{ base: 2, sm: 4 }}
            onClick={isMobile ? () => {} : () => navigate(`${url}`, { state: { farm } })}
            boxShadow={mode('sm', 'sm-dark')}
            cursor="pointer"
            flexDir={{ base: 'column', xl: isGridView ? 'column' : 'row' }}
            justify="space-between"
        >
            <Flex justify="start">
                <Flex>
                    <TokenImage token={farm.depositToken.symbol} w="60px" h="60px" />
                    <Box my="auto" px={1}>
                        <Flex>
                            <Flex ml={1} py={1} justifyContent="center">
                                {farm.isGovernance && <Icon as={FaCrown} w="26px" h="26px" color="orange.400" /> }
                                <Text
                                    my="auto"
                                    fontSize={{ base: 'lg', sm: 'xl' }}
                                    pl={2}
                                    pr={1}
                                    pb={0}
                                    fontWeight="bold"
                            >
                                    {farm.name}
                                </Text>
                            </Flex>
                            <ResponsiveTooltip
                                label={farm.description}
                                hasArrow
                                placement="top"
                                p={4}
                                borderRadius="md"
                            >
                                <Button
                                    py={0}
                                    px={1}
                                    leftIcon={(
                                        <Icon
                                            as={AiOutlineQuestionCircle}
                                            w="16px"
                                            h="16px"
                                        />)}
                                    minW="20px"
                                    colorScheme="grey"
                                    variant="link"
                                />
                            </ResponsiveTooltip>
                        </Flex>
                        <Flex maxW="320px" flexWrap="wrap">
                            {farm.endBlock < farm.currentBlock && <Badge
                                colorScheme="red"
                                my="2px"
                                mx={1}
                                fontSize="12px"
                                variant={mode('solid', 'subtle')}>FINISHED</Badge>}
                            {farm.startBlock > farm.currentBlock && <Badge
                                colorScheme="yellow"
                                my="2px"
                                mx={1}
                                fontSize="12px"
                                variant={mode('solid', 'subtle')}>PENDING</Badge>}
                            <Badge colorScheme="purple" my="2px" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                                <Flex align="center"> {farm.platform}
                                </Flex>
                            </Badge>
                            {farm.geolocation &&
                            <Badge colorScheme="yellow" my="2px" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                                <Link href="https://docs.bloomify.co/information/disclaimer" target="_blank">
                                    <Flex align="center"> Geofenced <Icon as={FaExternalLinkAlt} mx="auto" pl={1} w={3} h={3} />
                                    </Flex>
                                </Link>
                            </Badge> }
                            {farm.otherInfo ? farm.otherInfo.map((i) =>
                                <Badge my="2px" colorScheme="green" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                                    {i}
                                </Badge>) : null}
                            {!!farm.deprecated && <Badge my="2px" colorScheme="red" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                                ARCHIVED
                            </Badge>}
                        </Flex>
                    </Box>
                </Flex>
            </Flex>
            <Spacer />
            {/* {farm && <HStack
                justify="center"
                align="center"
                minW="250px"
                my={{ base: 4, md: 3 }}
                mx={isGridView ? null : { base: 4, md: 3 }}
                flexDir={{ base: 'column', sm: 'row' }}
                spacing={{ base: 0, sm: 8 }}
                bg={mode('#FAFAFA', 'blackAlpha.300')}
                px={6}
                py={4}
                opacity={farm.hideTVL || farm.hideAPR ? 0.725 : 1}
                rounded="lg"
            >
                <Box my="auto" minW="60px">
                    <Flex justify="center" align="center" direction="column">
                        <Text fontSize="sm" fontWeight="light" textColor="gray.400">
                            TVL
                        </Text>
                        <Text textAlign="center" fontSize="lg" fontWeight="bold">
                            {farm.hideTVL ? '-' : `$${formatValue(farm.tvl || 0, 2)}`}
                        </Text>
                    </Flex>
                </Box>
                {!farm.hideAPR
                	? <GeneralCardAPRDetails aprDetails={farm.aprDetails} />
                	: <Box my="auto" minW="60px">
                    <Flex justify="center" align="center" direction="column">
                        <Text fontSize="sm" fontWeight="light" textColor="gray.400">
                            APY
                        </Text>
                        <Text fontSize="md" fontWeight="bold">
                            -
                        </Text>
                    </Flex>
                </Box>}
            </HStack>} */}

            {!!(account && farm) && <HStack
                justify="center"
                minW={{ base: 'unset', lg: isGridView ? 'unset' : '340px' }}
                my={{ base: 4, md: 3 }}
                mx={isGridView ? null : { base: 4, md: 3 }}
                flexDir={{ base: 'column', sm: 'row' }}
                spacing={{ base: 0, sm: 8 }}
                bg={mode('#FAFAFA', 'blackAlpha.300')}
                px={6}
                py={4}
                rounded="lg"
            >
                <ValueTokenStat text="Deposited" amount={farm.depositDetails.amount} value={farm.depositDetails.value} token={farm.depositToken.symbol} />
                <ValueTokenStat text="Earned" amount={farm.earningsDetails.amount} value={farm.earningsDetails.value} token={farm.earn.symbol} />
                {/* {deposit && profit && (
                	<>
                    {' '}
                    <Box my="auto" minW="60px">
                        <Flex justify="center" align="center" direction="column">
                            <Text fontSize="sm" fontWeight="light" textColor="gray.400">
                                Deposit
                            </Text>
                            <Text fontSize="lg" fontWeight="bold">
                                {deposit}
                            </Text>
                        </Flex>
                    </Box>
                    <Box my="auto" minW="60px">
                        <Flex justify="center" align="center" direction="column">
                            <Text fontSize="sm" fontWeight="light" textColor="gray.400">
                                Profit
                            </Text>
                            <Text fontSize="lg" fontWeight="bold">
                                {profit}
                            </Text>
                        </Flex>
                    </Box>
                    {' '}
                	</>
                )} */}
            </HStack> }
            {(isGridView || isMobile ? (
                <Flex
                    onClick={() => navigate(`${url}`, { state: { farm } })}
                    flexDir="column"
                    bg="purple.500"
                    borderRadius={20}>
                    <Icon
                        as={FaPlus}
                        mx="auto"
                        py={2}
                        w={10}
                        h={10}
                        color="white"
                  />
                </Flex>
            ) : (
                <Flex flexDir="column" ml={2} justify="center">
                    <Icon
                        as={FaPlus}
                        mx="auto"
                        py={2}
                        borderRadius={20}
                        bg="purple.500"
                        w={10}
                        h={10}
                        color="white" />
                </Flex>
            ))}
        </Flex>
    </Flex>
	);
}

export default FarmCard;
