import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import _ from 'lodash';
import { commify } from 'ethers/lib/utils';
import { defaultChain } from '../bloomify/config';
import { getTokenDetails } from '../service/tokens.service';

dayjs.extend(utc);
dayjs.extend(tz);
export const timeZone = dayjs.tz.guess();

export const getTokenIconUrl = (token: any) => {
	if (!_.isString(token)) {
		return token.logoURI || 'no-image';
	}

	switch (token.toLowerCase()) {
		case 'logo-dark':
			return require('../assets/logo-dark.svg').default;
		case 'logo-light':
			return require('../assets/logo-light.svg').default;
		case 'blue-chip':
			return require('../assets/tokens/blue-chip.svg').default;
		case 'native':
			return require('../assets/tokens/bnb.svg').default;
		default: {
			const tokenDetails = getTokenDetails(token);

			return tokenDetails ? (tokenDetails.logoURI || 'no-image') : 'no-image';
		}
	}
};

export const strategyGenerateContractBlockSettings = (strategy: {blocks: any[]}, blockSettings: any) => {
	const percentages = [];
	let doubleRewardIdx = -1;

	for (let index = 0; index < strategy.blocks.length; index++) {
		doubleRewardIdx = _.findIndex(strategy.blocks, (b) => b.isDoubleRewardToken);

		if (doubleRewardIdx > -1) { // if blocks with double reward (like static-busd boardroom)
			const doubleRewardSettings = _.fill(Array(2), blockSettings[doubleRewardIdx].map((i) => i * 100)).flat(); // settings for each existing reward token
			percentages.push(doubleRewardIdx === index
				? doubleRewardSettings : blockSettings[index].map((i) => i * 100)); // only push for the double reward block, otherwise put single block settings
		} else { // if block with no double reward
			percentages.push(blockSettings[index].map((i) => i * 100)); // just place settings in it
		}
	}

	return percentages;
};

export const formatValue = (val, minimumDecimals = 3) => {
	if (!val) return 0;

	const num = parseFloat(_.replace(val, /,/g, ''));
	if (!num) return 0;
	if (num > 100000) return summarizeNum(val);

	const decimals = getDecimals(val, minimumDecimals);
	const valueWithDecimalsNeeded = num.toFixed(decimals);

	return commify(valueWithDecimalsNeeded);
};

export const getDecimals = (val, minimumDecimals = 3) => {
	if (!val) return 0;

	const num = parseFloat(_.replace(val, /,/g, ''));
	if (!num) return 0;

	const stringValue = num.toString();
	let decimalsNeeded = 1;

	if (num < 1 && stringValue.includes('.')) {
		const valueForDecimals = Number(
			stringValue.replace(stringValue.substr(0, stringValue.indexOf('.')), '0'),
		);
		const decimals = -Math.floor(Math.log10(valueForDecimals) + 1) + 2; // count decimals needed
		decimalsNeeded = !decimals || decimals === Infinity ? null : decimals;
	}

	decimalsNeeded = decimalsNeeded > 10 ? 10 : decimalsNeeded;

	return decimalsNeeded < minimumDecimals ? minimumDecimals : decimalsNeeded;
};

export const summarizeNum = (val) => {
	let num = parseFloat(_.replace(val, /,/g, ''));

	if (!num) return 0;
	const negative = num < 0;

	let suffix = '';

	num = Math.abs(num);

	if (num > 1000) {
		suffix = 'K';
		num /= 1000;
	}
	if (num > 1000) {
		suffix = 'M';
		num /= 1000;
	}
	if (num > 1000) {
		suffix = 'B';
		num /= 1000;
	}

	if (num > 1000) {
		suffix = 'T';
		num /= 1000;
	}

	if (negative) {
		return `-${_.round(num, 2)}${suffix}`.trim();
	}

	return `${_.round(num, 2)}${suffix}`.trim();
};
