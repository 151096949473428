import _ from 'lodash';
import { defaultChain } from '../bloomify/config';

// Percentages
const ONE_HUNDRED_PERCENT = 10000;

// Predefined strat tags
export const getGenericStrategy = (_strategyTag) => {
	const strategy = defaultChain.strategies.find((s) => s.tag == _strategyTag);

	if (strategy) return createDefaultStrategy(strategy);

	return null;
};

const createDefaultStrategy = (_rawStrategy) => {
	const blocks = [];
	const adaptors = [];
	const settings = [];

	_rawStrategy.blocks.forEach((b) => {
		blocks.push(b.address);
		adaptors.push([[b.adapter, [b.token.address]]]);

		const variables = b.actionSettings.map((c) => [b.rewardTokens[0].address, c.id, c.value, c.reinvestInTheBlockIndex || 0]);

		if (b.isDoubleRewardToken) {
			const secondVariables = b.actionSettings.map((c) => [b.rewardTokens[1].address, c.id, c.value, c.reinvestInTheBlockIndex || 0]);
			settings.push(variables.concat(secondVariables));
		} else {
			settings.push(variables);
		}
	});

	return new PredefinedStrategy(_rawStrategy.tag, blocks, adaptors, settings);
};

class PredefinedStrategy {
    data: any = {}

    constructor(_tag, _blocks, _adaptors, _settings) {
    	this.data = {};
    	this.data.tag = _tag;
    	this.data.blocks = _blocks;
    	this.data.settings = _settings;
    	this.data.adaptors = _adaptors;
    }

    /*
     * Change the percentage settings.
     *
     * Params: 2D Array
     *   - Index 0: Block Number
     *   - Index 1: Percentages per action
     * Returns false if there was a problem
     */
    setPercentages = (_percentages) => {
    	if (this.validPercentages(_percentages)) {
    		for (let i = 0; i < this.data.settings.length; i++) {
    			for (let j = 0; j < this.data.settings[i].length; j++) {
    				this.data.settings[i][j][2] = _percentages[i][j];
    			}
    		}
    		return true;
    	}
    	return false;
    }

    validPercentages = (_percentages) => {
    	if (_percentages == null
            || _percentages.length != this.data.settings.length) {
    		return false;
    	}
    	// Check number of actions match the number in the strategy
    	for (let i = 0; i < _percentages.length; i++) {
    		if (_percentages[i].length != this.data.settings[i].length) {
    			return false;
    		}
    	}
    	// Check % add up to 100%
    	for (let i = 0; i < _percentages.length; i++) {
    		const percentTotals = {};
    		for (let j = 0; j < _percentages[i].length; j++) {
    			if (!percentTotals.hasOwnProperty(this.data.settings[i][j][0])) {
    				percentTotals[this.data.settings[i][j][0]] = 0;
    			}
    			percentTotals[this.data.settings[i][j][0]] += _percentages[i][j];
    		}

    		_.forEach(Object.keys(percentTotals), (key) => {
    			if (percentTotals[key] != ONE_HUNDRED_PERCENT) {
    				return false;
    			}
    		});
    		// for (const key of Object.keys(percentTotals)) {

    		// }
    	}
    	return true;
    }
}
