import { addressBook as remoteAddressBook } from 'blockchain-addressbook';

export const addressBook = {
	...remoteAddressBook.fantom,
	tokens: {
		...remoteAddressBook.fantom.tokens,
		CHARGE: {
			name: 'Charge',
			symbol: 'CHARGE',
			address: '0xe74621A75C6ADa86148b62Eef0894E05444EAE69',
			decimals: 18,
			chainId: 250,
			website: 'https://bloomify.co',
			buyLink: 'https://spooky.fi/#/swap?inputCurrency=0x04068da6c83afcfa0e13ba15a6696662335d5b75&outputCurrency=0xe74621A75C6ADa86148b62Eef0894E05444EAE69',
			logoURI: require('../../../assets/tokens/charge-ftm.svg').default,
			description:
				'$Charge is the utility token for Bloomify and can be staked to earn % of performance fees.',
		},
		STATIC: {
			address: '0x27182C8b647fd83603bB442C0E450DE7445ccfB8',
			name: 'Static',
			symbol: 'STATIC',
			decimals: 18,
			chainId: 250,
			website: 'https://bloomify.co',
			buyLink: 'https://spooky.fi/#/swap?inputCurrency=0x04068da6c83afcfa0e13ba15a6696662335d5b75&outputCurrency=0x27182C8b647fd83603bB442C0E450DE7445ccfB8',
			logoURI: require('../../../assets/tokens/static-ftm.svg').default,
			description:
			  '$Static is the reward token for staking $Charge on Bloomify and can also be used to pay the run fee for personal strategies.',
		},
		USDC: {
			...remoteAddressBook.fantom.tokens.USDC,
			logoURI: require('../../../assets/tokens/USDC.svg').default,
		},
		FTM: {
			...remoteAddressBook.fantom.tokens.FTM,
			logoURI: require('../../../assets/tokens/fantom.svg').default,
		},
		WFTM: {
			...remoteAddressBook.fantom.tokens.FTM,
			logoURI: require('../../../assets/tokens/fantom.svg').default,
		},
		ETH: {
			...remoteAddressBook.fantom.tokens.ETH,
			logoURI: require('../../../assets/tokens/eth.svg').default,
		},
		'sfUSDC': {
			...remoteAddressBook.fantom.tokens.sfUSDC,
			symbol: 'S*USDC',
			logoURI: require('../../../assets/tokens/USDC.svg').default,
			buyLink: 'https://stargate.finance/pool/USDC-FTM/add',
			website: 'https://stargate.finance/',
			description: `${remoteAddressBook.fantom.tokens.sfUSDC.name} is minted by depositing USDC in Stargate Finance Liquidity Pools. ${remoteAddressBook.fantom.tokens.sfUSDC['description']}`,
		},
		'S*USDC': {
			...remoteAddressBook.fantom.tokens.sfUSDC,
			symbol: 'S*USDC',
			logoURI: require('../../../assets/tokens/USDC.svg').default,
			website: 'https://stargate.finance/',
			description: `${remoteAddressBook.fantom.tokens.sfUSDC.name} is minted by depositing USDC in Stargate Finance Liquidity Pools. ${remoteAddressBook.fantom.tokens.sfUSDC['description']}`,
			buyLink: 'https://stargate.finance/pool/USDC-FTM/add',
		},
	},
	lps: {
		'spookyswap-static-usdc': {
			address: '0x34011465A924C554220E768dcB27d59124Ce3Fa6',
			name: 'Static-USDC',
			symbol: 'static-usdc',
			platformId: 'spookyswap',
			logoURI: require('../../../assets/tokens/static-usdc.svg').default,
			buyLink: 'https://spooky.fi/#/add/0x04068da6c83afcfa0e13ba15a6696662335d5b75/0x27182C8b647fd83603bB442C0E450DE7445ccfB8',
		},
		'spookyswap-charge-usdc': {
			address: '0x5d2e8a24028F5749eC58b053B38D5aaFa57c2691',
			name: 'Charge-USDC',
			symbol: 'charge-usdc',
			platformId: 'spookyswap',
			buyLink: 'https://spooky.fi/#/add/0x04068da6c83afcfa0e13ba15a6696662335d5b75/0xe74621A75C6ADa86148b62Eef0894E05444EAE69',
			logoURI: require('../../../assets/tokens/charge-usdc.svg').default,
		},
		'spookyswap-usdc-ftm': {
			address: '0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c',
			name: 'USDC-FTM',
			symbol: 'usdc-ftm',
			platformId: 'spookyswap',
			buyLink: 'https://spooky.fi/#/add/0x04068da6c83afcfa0e13ba15a6696662335d5b75/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
			logoURI: require('../../../assets/tokens/USDC-FTM.svg').default,
		},
		'spookyswap-usdc-mai': {
			address: '0x4dE9f0ED95de2461B6dB1660f908348c42893b1A',
			name: 'USDC-MAI',
			symbol: 'usdc-mai',
			buyLink: 'https://spooky.fi/#/add/0x04068da6c83afcfa0e13ba15a6696662335d5b75/0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
			platformId: 'spookyswap',
			logoURI: require('../../../assets/tokens/USDC-MAI.svg').default,
		},
		'spookyswap-boo-ftm': {
			address: '0xec7178f4c41f346b2721907f5cf7628e388a7a58',
			name: 'BOO-FTM',
			symbol: 'boo-ftm',
			buyLink: 'https://spooky.fi/#/add/0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
			platformId: 'spookyswap',
			logoURI: require('../../../assets/tokens/BOO-FTM.svg').default,
		},
		'spookyswap-wftm-sftmx': {
			address: '0x4dE9f0ED95de2461B6dB1660f908348c42893b1A',
			name: 'SFTMX-FTM',
			symbol: 'sftmx-ftm',
			buyLink: 'https://spooky.fi/#/add/0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
			platformId: 'spookyswap',
			logoURI: require('../../../assets/tokens/BOO-FTM.svg').default,
		},

		'spookyswap-eth-ftm': {
			address: '0xf0702249f4d3a25cd3ded7859a165693685ab577',
			name: 'ETH-FTM',
			symbol: 'eth-ftm',
			buyLink: 'https://spooky.fi/#/add/0x74b23882a30290451A17c44f4F05243b6b58C76d/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
			platformId: 'spookyswap',
			logoURI: require('../../../assets/tokens/ETH-FTM.svg').default,
		},

		'spookyswap-btc-ftm': {
			address: '0xfdb9ab8b9513ad9e419cf19530fee49d412c3ee3',
			name: 'BTC-FTM',
			symbol: 'btc-ftm',
			buyLink: 'https://spooky.fi/#/add/0x321162Cd933E2Be498Cd2267a90534A804051b11/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
			platformId: 'spookyswap',
			logoURI: require('../../../assets/tokens/BTC-FTM.svg').default,
		},
	},
	farms: {
		CHARGE_USDC: '0xc9403bfA82C26F012EC2314dA4dEDf9a7521ecF3',
		STATIC_USDC: '0xf0385D4Ecf9A94fD5f09F1dE3AcE59B01298E749',
	},
	pools: {
		CHARGE: '0xd76D4C5FAcd6DA7F6B4B60734dE29570924F22D6',
	},
	vaults: {
		USDC_FTM: '0x264Cf3b76791cA16A878c170533F86786d4e71d7',
		USDC_MAI: '0x9334a028eccD3B9439b66bAd7bf2B66E547669b1',
		BOO_FTM: '0x7de26D5Da9a6B4c37f569cd9016067db5c8B6D4c',
		ETH_FTM: '0x4fb917C9d74Be57335a1bc3c5e39BfB3eb1ed4Cf',
		BTC_FTM: '0x26C055dFb11fDDE9d535255ED7Fe20D395e5CA1f',
		accumulators: {
			USDC_STARGATE_to_USDC_FTM: '0xd4F40667D1Ee89400f95Bb9e170672E24059c284',
			USDC_STARGATE_to_FTM: '0x3e8949eAA79D23A63383cCf6A4A53337C5679AD0',
			USDC_MAI_to_USDC_FTM: '0x55C84adEFa914E72fD615612F510e0894F5b90eC',
			USDC_MAI_to_BOO_FTM: '0xF2AeBeB7b2FF9BB56dA3F9675Cf91A3f63A26823',
			USDC_MAI_to_ETH_FTM: '0x7224a77a2D943163E41B2E0dA129517154B49305',
			USDC_MAI_to_FTM: '0xa3A5aA89Eaee0275186d9F8fC4303311d170294B',
			USDC_MAI_to_ETH: '0xecB28094BA72141cB55f7301d7D1A160FA927Fd5',
		},
		profitTakers: {
			USDC_FTM_to_USDC_MAI: '0xBab6B1682744E73267A573544148aD28613F11d5',
			BOO_FTM_to_USDC_MAI: '0x493548895B47Ff9f3c99AF47dFb37e928E4DbD13',
			ETH_FTM_to_USDC_MAI: '0xA0D83280a394D31c0aA451d52e1584303cD18423'
		}
	},
	routers: {
		spookyswap: '0xf491e7b69e4244ad4002bc14e878a34207e38c29',
	},
};
