/* eslint-disable no-sparse-arrays */
/* eslint-disable max-len */
import { addressBook } from './addressbook.config';
import { ENV_PROD, ENV_TESTING, featureAllowed } from '../../env';
import { description } from '../bsc/profit-takers.data';

export const deployedProfitTakers = [
  {
    address: addressBook.vaults.profitTakers.USDC_FTM_to_USDC_MAI,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'USDC-FTM to USDC-MAI Profit Taker',
    depositToken: {
      ...addressBook.lps['spookyswap-usdc-ftm'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['spookyswap-usdc-mai'],
      isLP: true,
      canZap: false
    },
    platforms: ['Spookyswap'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit USDC-FTM', 'Profits taken in USDC-MAI'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['spookyswap-usdc-ftm'], addressBook.lps['spookyswap-usdc-mai']),
  },

  {
    address: addressBook.vaults.profitTakers.BOO_FTM_to_USDC_MAI,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'BOO-FTM to USDC-MAI Profit Taker',
    depositToken: {
      ...addressBook.lps['spookyswap-boo-ftm'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['spookyswap-usdc-mai'],
      isLP: true,
      canZap: false
    },
    platforms: ['Spookyswap'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit BOO-FTM', 'Profits taken in USDC-MAI'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['spookyswap-boo-ftm'], addressBook.lps['spookyswap-usdc-mai']),
  },

  {
    address: addressBook.vaults.profitTakers.ETH_FTM_to_USDC_MAI,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'FTM',
    env: ENV_PROD,
    name: 'ETH-FTM to USDC-MAI Profit Taker',
    depositToken: {
      ...addressBook.lps['spookyswap-eth-ftm'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['spookyswap-usdc-mai'],
      isLP: true,
      canZap: false
    },
    platforms: ['Spookyswap'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit ETH-FTM', 'Profits taken in USDC-MAI'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['spookyswap-eth-ftm'], addressBook.lps['spookyswap-usdc-mai']),
  }
].filter((i) => featureAllowed(i.env));
