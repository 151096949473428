import axios from 'axios';
import { defaultChain } from '../bloomify/config';

export const getExternallyCalculatedStatistics = async () => {
	const stats = JSON.parse(localStorage.getItem('calculatedStats'));

	if (stats && stats.result && stats.result.tvls && stats.expiry > Date.now()) return stats.result;

	const response = await axios.get(`https://firebasestorage.googleapis.com/v0/b/bloomify/o/config%2F${defaultChain.shortName.toLowerCase()}.json?alt=media`);

	const save = {
		result: response.data,
		expiry: Date.now() + (60 * 1000 * 8),
	};

	localStorage.setItem('calculatedStats', JSON.stringify(save));

	return response.data;
};
