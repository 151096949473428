/* eslint-disable no-mixed-operators */
/* eslint-disable no-shadow */
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import useWeb3 from '../../../hooks/useWeb3';
import {
	getClonedStrategyGeneralBlocksInfo,
	getClonedStrategyAddresses,
	getClonedStrategies,
} from '../services/clonedStrategies.service';
import { useDidUpdate } from '../../../hooks/useDidUpdate';
import { useTokenPrices } from '../../../contexts/TokenPricesContext';
import { formatValue } from '../../../helpers/util';

export const useFetchUserClonedStrategies = () => {
	const web3 = useWeb3();
	const { account } = useWeb3React();
	const { getTokenPrice, tokens } = useTokenPrices();
	const [clonedStrategies, setClonedStrategies] = useState([]);
	const [stats, setStats] = useState<any>({});

	useDidUpdate(() => {
		if (account) get();
	}, [account]);

	useEffect(() => {
		if (account) get();
	}, [account]);

	useEffect(() => {
		if (account) get();
	}, []);

	const get = async () => {
		if (tokens && tokens.name && getTokenPrice('STATIC')) {
			const cloned = await getClonedStrategyAddresses(web3, account);
			const clonedStrategies = await getClonedStrategies(web3, cloned.strats, cloned.stratTags);

			const blocksPerStratDetails = clonedStrategies ? await getClonedStrategyGeneralBlocksInfo(web3, clonedStrategies.map((i) => i.blockAdresses)) : null;

			let totalDeposit = 0;
			let totalProfit = 0;

			const strats = clonedStrategies && clonedStrategies.map((clonedStrat, index) => {
				const currentStratTotalDeposit = blocksPerStratDetails[index]
					.map((fetchedBlox, blockIndex) => (fetchedBlox.blockBalance / 1e18) * getTokenPrice(clonedStrat.blocks[blockIndex].token.address))
					.reduce((a: any, b: any) => a + b);

				totalDeposit += currentStratTotalDeposit;
				totalProfit += clonedStrat.profitBalance.hex / 1e18;

				return {
					clonedStratDetails: clonedStrat,
					blocksDetails: blocksPerStratDetails[index],
					currentStratTotalDeposit,
				};
			});

			setClonedStrategies(strats.length ? strats : null);

			setStats({
				strategies: clonedStrategies.length,
				deposit: clonedStrategies.length > 0 ? formatValue(totalDeposit) : 0,
				profit: clonedStrategies.length > 0 ? formatValue(totalProfit) : 0,
			});
		} else {
			setStats({
				strategies: 0,
				deposit: 0,
				profit: 0,
			});

			setClonedStrategies(null);
		}
	};

	return {
		stats,
		account,
		clonedStrategies,
	};
};
