import Web3 from 'web3';
import { defaultChain } from '../../../bloomify/config';
import {
	onConfirmation, onError, onReceipt, onSending, onSent, onTransactionHash,
} from '../../../utils/web3Handlers';
import {getGasPriceOptions} from "../../../utils/gasUtils";

export const getTicketPrices = async (
	web3: Web3,
	walletAddr: string,
	errorCallback?: () => void,
) => {
	const sentryTicket = new web3.eth.Contract(
		defaultChain.deployments.SentryTicket.abi,
		defaultChain.addressBook.general.SentryTicket,
		{ from: walletAddr },
	);
	return await sentryTicket.methods.ticketPrices().call();
};

export const buyStrategyTicket = async (
	web3: Web3,
	walletAddress: string,
	strategyAddr: string,
	ticketType: string,
	nativeGasPrice: string,
	errorCallback?: any,
) => {
	const sentryTicket = new web3.eth.Contract(
		defaultChain.deployments.SentryTicket.abi,
		defaultChain.addressBook.general.SentryTicket,
		{ from: walletAddress },
	);

	// console.log(strategyAddr, ticketType, nativeGasPrice)

	const gasPrices = await getGasPriceOptions(web3);
	if (ticketType === 'static') {
		return await sentryTicket.methods.buyAndRun(strategyAddr).send({...gasPrices})
			.on('sending', onSending)
			.on('sent', onSent)
			.on('transactionHash', onTransactionHash)
			.on('receipt', onReceipt)
			.on('confirmation', onConfirmation)
			.on('error', errorCallback || onError);
	}
	return await sentryTicket.methods.buyAndRunViaEther(strategyAddr).send({ value: nativeGasPrice })
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', errorCallback || onError);
};

export const getStrategyTickets = async (web3: Web3, walletAddress: string, strategyAddr: string) => {
	const sentryTicket = new web3.eth.Contract(
		defaultChain.deployments.SentryTicket.abi,
		defaultChain.addressBook.general.SentryTicket,
		{ from: walletAddress },
	);

	return await sentryTicket.methods.tickets(strategyAddr).call();
};
