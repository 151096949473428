/* eslint-disable react/jsx-indent */
import {
	BoxProps,
	Button,
	Divider,
	Text,
	Stack,
	useBreakpointValue,
	Tooltip,
	useColorModeValue as mode,
	Circle,
} from '@chakra-ui/react';
import * as React from 'react';
import { StepCircle } from './StepCircle';

interface StepProps extends BoxProps {
    title?: string
    description: any
    isCompleted: boolean
    isActive: boolean
    isLastStep: boolean
    isFirstStep: boolean
    stepNumber: number
    stepTitle?: any
    handler: any
    noOfSteps?: number
}

export function Step(props: StepProps) {
	const {
		isActive, isCompleted, isLastStep, isFirstStep, title, noOfSteps, description, stepNumber, stepTitle, handler, ...stackProps
	} =
        props;

	const isMobile = useBreakpointValue({ base: true, lg: false });

	const orientation = useBreakpointValue<'horizontal' | 'vertical'>({
		base: 'vertical',
		lg: 'horizontal',
	});

	return (
		<>
        {!isMobile ? <Tooltip label={stepTitle || `Farm ${stepNumber}`} h="100%" placement="top">
        <Button
            ml={!isCompleted && !isActive ? 2 : 0}
            mr={isCompleted && !isActive ? 2 : 0}
            position="relative"
            cursor="pointer"
            left={isActive ? `calc(50% - ${42 * stepNumber - 21}px)` : (isCompleted ? '0%' : `calc(100% - ${42 * noOfSteps}px)`)}
            transition="left 400ms cubic-bezier(0.25, 0.46, 0.45, 0.84)"
            onClick={() => handler(stepNumber)}
            w="10"
            h="10"
            borderRadius="50px"
            bg="transparent"
            borderWidth="2px"
            borderColor={!isActive ? mode('gray.700', 'whiteAlpha.600') : mode('gray.900', 'whiteAlpha.800')}
              >
                <Text
                    cursor="pointer"
                    fontSize="sm"
                    fontWeight="semibold"
                    color={isCompleted ? mode('gray.900', 'whiteAlpha.800') : (isActive ? mode('gray.900', 'whiteAlpha.800') : 'gray.300')}>{stepNumber}</Text>
            </Button>
    </Tooltip> :
    <Stack
        spacing="0"
        h="inherit"
        align="center"
        direction={{ base: 'column', lg: 'row' }}
        w={isActive && !isMobile ? `calc(100% + ${(stepNumber - 1) * 32}px)` : '34px'}
        pl={isFirstStep && isActive && !isMobile ? `calc(50% - ${(stepNumber + 1) * 32 / 2}px)` : '0'}
        pr={isLastStep && isActive && !isMobile ? `calc(50% - ${(stepNumber - 1) * 32 / 2}px)` : '0'}
        >
            {!isFirstStep && <Divider
                maxH={isMobile ? '120px' : 'unset'}
                display={isMobile ? 'none' : 'initial'}
                orientation={orientation}
                borderWidth="1px"
                borderColor={isFirstStep ? 'transparent' : isCompleted || isActive ? mode('gray.900', 'whiteAlpha.900') : 'inherit'}
                w={!isMobile ? (!isActive ? '32px' : `calc(100% + ${(stepNumber - 1) * 32 - 48}px)`) : 'unset'}
                transition={isMobile ? 'unset' : 'border 400ms cubic-bezier(0.25, 0.46, 0.45, 0.84)'}
            />}
            <StepCircle
                stepNumber={stepNumber}
                isActive={isActive}
                isCompleted={isCompleted}
                setStep={handler}
                stepTitle={stepTitle} />
            {!isLastStep && <Divider
                minH={isMobile ? '25%' : 'unset'}
                w={!isMobile ? (!isActive ? '32px' : `calc(100% - ${stepNumber * 32 - 48}px)`) : 'unset'}
                orientation={orientation}
                borderWidth="1px"
                borderColor={isLastStep ? 'transparent' : (isCompleted && !isActive ? mode('gray.900', 'whiteAlpha.900') : (isLastStep ? 'transparent' : 'inherit'))}
                transition={isMobile ? 'unset' : 'border 400ms cubic-bezier(0.25, 0.46, 0.45, 0.84)'}
            />}
        </Stack>}
		</>
	);
}
//
// <Stack spacing="0" h="inherit" align="center" direction={{ base: 'column', lg: 'row' }}
//   // w={isActive && !isMobile ? `calc(100% + ${(stepNumber - 1) * 32}px)` : '34px'}
//   // pl={isFirstStep && isActive && !isMobile ? `calc(50% - ${(stepNumber+1) * 32/2}px)` : '0'}
//   // pr={isLastStep && isActive && !isMobile ? `calc(50% - ${(stepNumber-1) * 32/2}px)` : '0'}
// >
//     {/*{!isFirstStep && <Divider*/}
//     {/*    maxH={isMobile ? '120px' : 'unset'}*/}
//     {/*    display={isMobile ? 'none' : 'initial'}*/}
//     {/*    orientation={orientation}*/}
//     {/*    borderWidth="1px"*/}
//     {/*    borderColor={isFirstStep ? 'transparent' : isCompleted || isActive ? mode('gray.900', 'whiteAlpha.900') : "inherit"}*/}
//     {/*    w={!isMobile ? (!isActive ? '32px' : `calc(100% + ${(stepNumber-1) * 32 - 48}px)`) : 'unset'}*/}
//     {/*    transition={isMobile? 'unset' : "border 400ms cubic-bezier(0.25, 0.46, 0.45, 0.84)"}*/}
//     {/*/>}*/}
//     <StepCircle
//       transform={!isCompleted ? `translateX(${50 * (stepNumber)}%)`: 'unset'}
//       stepNumber={stepNumber} isActive={isActive} isCompleted={isCompleted} setStep={handler} stepTitle={stepTitle}/>
//     {/*{!isLastStep && <Divider*/}
//     {/*    minH={isMobile ? '25%' : 'unset'}*/}
//     {/*    w={!isMobile ? (!isActive ? '32px' : `calc(100% - ${stepNumber * 32 - 48}px)`) : 'unset'}*/}
//     {/*    orientation={orientation}*/}
//     {/*    borderWidth="1px"*/}
//     {/*    borderColor={isLastStep ? "transparent" : ( isCompleted && !isActive ? mode('gray.900', 'whiteAlpha.900') : (isLastStep ? 'transparent' : "inherit"))}*/}
//     {/*    transition={isMobile? 'unset' : "border 400ms cubic-bezier(0.25, 0.46, 0.45, 0.84)"}*/}
//     {/*/>}*/}
// </Stack>
