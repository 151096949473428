import {
	Icon,
	Flex,
	Tooltip,
	UnorderedList,
	ListItem,
	Text,
} from '@chakra-ui/react';
import React from 'react';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { formatValue } from '../../../helpers/util';
import Stat from '../../generic-strategies/common/Stat';
import { ResponsiveTooltip } from '../Tooltip';

export function StratBlockAPRDetails({ aprDetails = null }) {
	return (<Flex pt={2}>
    {aprDetails && (
    <ResponsiveTooltip
        label={<Flex direction="column">
            <UnorderedList>
                <ListItem><Flex><Text fontWeight="bold" mr={1}>Yield APR:</Text> {aprDetails.baseApr}%</Flex></ListItem>
                {!!aprDetails.tradingApr && <ListItem><Flex><Text fontWeight="bold" mr={1}>Trading APR:</Text> {aprDetails.tradingApr}%</Flex></ListItem> }
                <ListItem><Flex><Text fontWeight="bold" mr={1}>APY:</Text> {aprDetails.totalApy}%</Flex></ListItem>
            </UnorderedList>
        </Flex>}
        hasArrow
        placement="bottom"
        p={4}
        borderRadius="md">
        <Flex justify="center" align="center">
            <Icon as={FaInfoCircle} mx="auto" pr={1} w={4} h={4} />
            <Text
                fontSize="sm"
                mx="auto"
                lineHeight="20px"
                textAlign="center"
                fontWeight="bold"
                pr={2}
        >
                APY:
            </Text>
        </Flex>
    </ResponsiveTooltip>
    )}
    <Text
        fontSize="sm"
        mx="auto"
        lineHeight="20px"
        textAlign="center"
        >
        {aprDetails
            	? `${formatValue(aprDetails.totalApy, 2)}%`
            	: 'No APY available yet'}
    </Text>
</Flex>);
}

export function APRDetailsForDataBlock({ aprDetails = null }) {
	return (<Flex pt={2}>
    {aprDetails && (
    <ResponsiveTooltip
        label={<Flex direction="column">
            <UnorderedList>
                <ListItem><Flex><Text fontWeight="bold" mr={1}>Yield APR:</Text> {aprDetails.baseApr}%</Flex></ListItem>
                {!!aprDetails.tradingApr && <ListItem><Flex><Text fontWeight="bold" mr={1}>Trading APR:</Text> {aprDetails.tradingApr}%</Flex></ListItem>}
                <ListItem><Flex><Text fontWeight="bold" mr={1}>APY:</Text> {aprDetails.totalApy}%</Flex></ListItem>
            </UnorderedList>
        </Flex>}
        hasArrow
        placement="bottom"
        p={4}
        borderRadius="md">
        <Flex>
            <Icon as={FaInfoCircle} mx="auto" pr={1} w={4} h={4} mt={0.5} />
            <Stat label="APY" value={`${formatValue(aprDetails.totalApy, 2)}%`} />

        </Flex>
    </ResponsiveTooltip>
    )}
</Flex>);
}

export function GeneralCardAPRDetails({ aprDetails = null, itemType = '' }) {
	return (<Flex justify="center">
    <ResponsiveTooltip
        label={aprDetails && <Flex direction="column">
            <UnorderedList>
                <ListItem><Flex><Text fontWeight="bold" mr={1}>Yield APR:</Text> {aprDetails.baseApr}%</Flex></ListItem>
                {!!aprDetails.tradingApr && <ListItem><Flex><Text fontWeight="bold" mr={1}>Trading APR:</Text> {aprDetails.tradingApr}%</Flex></ListItem>}
                <ListItem><Flex><Text fontWeight="bold" mr={1}>APY:</Text> {aprDetails.totalApy}%</Flex></ListItem>
                {!!aprDetails.blocks && <>
                    <ListItem><Flex><Text fontWeight="bold" mr={1}>Deposit Block APY:</Text> {aprDetails.blocks[0].totalApy}%</Flex></ListItem>
                    <ListItem><Flex><Text fontWeight="bold" mr={1}>{itemType} Block APY:</Text> {aprDetails.blocks[1].totalApy}%</Flex></ListItem>
                </>}
            </UnorderedList>
        </Flex>}
        hasArrow
        placement="bottom"
        p={4}
        borderRadius="md">
        <Flex justify="center" align="center">
            <Flex justify="center" align="center" direction="column">
                <Text fontSize="sm" fontWeight="light" textColor="gray.400">
                    <Flex justify="center" align="center">
                        <Icon as={FaInfoCircle} mx="auto" pr={1} w={4} h={4} />
                        APY
                    </Flex>
                </Text>
                <Text textAlign="center" fontSize={aprDetails ? 'lg' : 'sm'} fontWeight="bold">
                    {aprDetails
                    	? `${formatValue(aprDetails.totalApy, 2)}%`
                    	: '-'}
                </Text>
            </Flex>
        </Flex>
    </ResponsiveTooltip>
</Flex>);
}
