/* eslint-disable dot-notation */
/* eslint-disable quotes */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-indent */
import {
	Container,
	Flex,
	StackDivider,
	Text,
	Stack,
	Box,
	useColorModeValue as mode,
	Button,
	Icon,
	SimpleGrid,
	useDisclosure,
	Badge,
	Tooltip,
	Skeleton,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { IoChevronBack } from '@react-icons/all-files/io5/IoChevronBack';
import TokenImage from '../../../common/TokenImage';
import { Step } from '../../../common/ProgressSteps/Step';
import { useStep } from '../../../common/ProgressSteps/useStep';
import StrategyBlocksContainer from './StrategyBlockContainer';
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle';
import { StrategyAssetDetailsGroup } from '../../../common/AssetDetails/AssetDetailsGroup';
import StrategyDetailsHeader from '../../../common/StrategyDetailsHeader';
import { useDefaultStrategies } from '../../hooks/useDefaultStrategies';
import { formatValue } from '../../../../helpers/util';

function StrategyDetails() {
	const { tag } = useParams();
	const location = useLocation();
	const strategy = useDefaultStrategies(tag, location.state ? location.state["strategy"] : null);
	const { blocks, name } = strategy;
	const [currentStep, { setStep, goToNextStep, goToPrevStep }] = useStep({ maxStep: blocks ? blocks.length : 1, initialStep: 1 });

	useDocumentTitle(`Bloomify - ${name}`);

	const { isOpen, onToggle } = useDisclosure();

	const goToNextStepCustom = () => {
		goToNextStep();
		onToggle();
	};

	const goToPrevStepCustom = () => {
		goToPrevStep();
		onToggle();
	};

	const generalStats = [
		// {
		// 	label: 'TVL',
		// 	value: strategy ? `$${formatValue(strategy.tvl)}` : null,
		// },
	];

	return (
    <Skeleton my={5} isLoaded={!!strategy} height="100%">
        { !!strategy.length || (strategy && blocks) && <Flex flexDir="column">
            <Container maxW="8xl">
                {/* <Link to="/strategies">
                    <Button leftIcon={<Icon as={IoChevronBack} />} colorScheme="grey" variant="link" my={4}>
                        Go back to strategies
                    </Button>
                </Link> */}
                <StrategyDetailsHeader strategy={strategy} stats={generalStats} />
            </Container>
            <SimpleGrid
                mt={2}
                columns={{ base: 2, lg: 1 }}
                templateColumns={blocks.length > 1
                      	? {
              		base: '40px 1fr', xs: '50px 1fr', sm: '60px 1fr', md: '70px 1fr', lg: 'repeat(1, minmax(0, 1fr))',
              	}
                      	: { base: 'repeat(1, minmax(0, 1fr))' }}>
                {blocks.length > 1 ?
                    <Container maxW="7xl" py={4} pb={{ base: 4, lg: 1 }} pr={{ base: 1, lg: 8 }}>
                        <Stack spacing="0" position="relative" direction={{ base: 'column', lg: 'row' }} h="100%" w="100%">
                            {blocks.map((block, key) => {
                    	const farm = (
                        <Flex p={0} m={0}>
                            <TokenImage token={block.token.symbol.toLowerCase()} />
                            <Text px={2} my="auto" lineHeight="1.25">{block.name}</Text>
                        </Flex>
                    	);

                    	return <Step
                        key={key}
                        handler={setStep}
                        noOfSteps={blocks.length}
                        stepNumber={key + 1}
                        stepTitle={block.name}
                        description={farm}
                        isCompleted={currentStep > key + 1}
                        isActive={currentStep === key + 1}
                        isLastStep={blocks.length === key + 1}
                        isFirstStep={key === 0} />;
                            })}
                        </Stack>
                    </Container> : null }
                <StrategyBlocksContainer
                    strategy={strategy}
                    step={currentStep}
                    nextStep={goToNextStepCustom}
                    prevStep={goToPrevStepCustom}
                    setStep={setStep}
              />
            </SimpleGrid>
            <StrategyAssetDetailsGroup blocks={strategy.blocks} />
                                                       </Flex> }
    </Skeleton>
	);
}

export default StrategyDetails;
