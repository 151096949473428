/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable quotes */
/* eslint-disable dot-notation */
import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Container,
	Flex,
	Icon,
	Skeleton,
	useColorModeValue as mode,
	useDisclosure,
	keyframes,
	Link as InternalLink,
	useBreakpointValue,
	Text,
	Spacer,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { Link, useParams, useLocation } from 'react-router-dom';
import { FaWallet } from '@react-icons/all-files/fa/FaWallet';
import { IoChevronBack } from '@react-icons/all-files/io5/IoChevronBack';
import _ from 'lodash';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';
import { useWeb3React } from '@web3-react/core';
import { FaMoneyBill } from '@react-icons/all-files/fa/FaMoneyBill';
import { FaRedo } from '@react-icons/all-files/fa/FaRedo';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import TokenImage from '../../../common/TokenImage';
import { useSingleVaultDetails } from '../../hooks/useSingleVaultDetails';
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle';
import { formatValue } from '../../../../helpers/util';
import { VaultAssetDetailsGroup } from '../../../common/AssetDetails/AssetDetailsGroup';
import VaultDetailsHeader from '../VaultDetailsHeader';
import DepositModal from '../../../common/Modal/Premade/DepositModal';
import WithdrawModal from '../../../common/Modal/Premade/WithdrawModal';
import { StatCard } from '../../../common/StatComponent';
import Web3Button from '../../../common/Web3Button';
import { timeDifference } from '../../../../helpers/formating';
import { ResponsiveTooltip } from '../../../common/Tooltip';
import useRevokeAccessGeolocation from '../../../../hooks/useRevokeAccessGeolocation';
import GeolocationModal from '../../../common/Modal/Premade/GeolocationModal';

const callToAction = require('../../../../assets/img/call-to-action.svg').default;

const buttonStyles = () => ({
	bg: mode('white', 'gray.900'),
	border: mode('2px solid rgb(0, 0, 0)', '2px solid white'),
	size: 'lg',
	my: '1',
	w: { base: '260px', sm: '280px', md: '340px' },
});

const opacityKeyframes = keyframes`
  0% { opacity: 0.5; }
  70% { opacity: 1 }
  100% { opacity: 0.5 }
`;

const animation = (delay = 0) => `${opacityKeyframes} 7s ease-in-out ${delay}s infinite`;

const initStat = {
	label: '',
	value: null,
	feeInfo: null,
	info: '',
};

function VaultDetails() {
	const [fees, setFees] = useState([initStat]);
	const { account } = useWeb3React();

	const { address } = useParams();
	const location = useLocation();

	const {
		vaultInfo, isWithdrawing, isDepositing, isClaimingAccumulated, withdrawAmount, withdrawAll, depositAmount, depositAll, getDetails, claimAccumulated,
	} =
    useSingleVaultDetails(address, location.state ? location.state['vault'] : null);

	const [vaultDetails, setVaultDetails] = useState<any>(vaultInfo);

	useDocumentTitle(`Bloomify - ${vaultDetails ? vaultDetails.name : 'Loading...'}`);

	const isMobile = useBreakpointValue({ base: true, lg: false });

	const { isOpen: isOpenDepositModal, onOpen: onOpenDepositModal, onClose: onCloseDepositModal } = useDisclosure();
	const { isOpen: isOpenWithdrawModal, onOpen: onOpenWithdrawModal, onClose: onCloseWithdrawModal } = useDisclosure();
	const { isOpen: isOpenGeofenceModal, onOpen: onOpenGeofenceModal, onClose: onCloseGeofenceModal } = useDisclosure();

	const { revokeAccess, isLoading } = useRevokeAccessGeolocation();

	const [geofenced, setGeofenced] = useState<boolean>(false);
	const [geofencedShownAlready, setGeofencedShown] = useState<boolean>(false);

	const closeDepositModal = async () => {
		onCloseDepositModal();
		await getDetails();
	};

	const closeWithdrawModal = async () => {
		onCloseWithdrawModal();
		await getDetails();
	};

	const lastHarvest =
    vaultDetails && vaultDetails.lastHarvest === 0 ? 'Never' : vaultDetails && timeDifference(new Date().getTime(), new Date(vaultDetails.lastHarvest * 1000).getTime());

	const generalStats = [
		// {
		// 	label: 'TVL',
		// 	value: vaultDetails ? `$${formatValue(vaultDetails.tvl)}` : null,
		// },
		// {
		// 	label: 'APY',
		// 	value: vaultDetails && vaultDetails.aprDetails ? `${formatValue(vaultDetails.aprDetails.totalApy || 0)}%` : null,
		// 	aprInfo: vaultDetails && vaultDetails.aprDetails ? vaultDetails.aprDetails : null,
		// 	itemType: vaultInfo ? vaultInfo.type : null,
		// },
		vaultDetails && vaultDetails.lastHarvest
			? {
				label: 'Last Harvest',
				value: lastHarvest,
				info: `Last time yield bought more ${vaultDetails.accumulatedToken.name}`,
			}
			: null,
	].filter((x) => x);

	useEffect(() => {
		setVaultDetails(vaultInfo);
		setGeofenced(vaultInfo ? !isLoading && vaultInfo.geolocation && revokeAccess : false);
		if (geofenced && !isOpenGeofenceModal && !geofencedShownAlready) onOpenGeofenceModal();

		if (
			vaultInfo &&
      (vaultInfo.fees.deposit || vaultInfo.fees.deposit === 0) &&
      (vaultInfo.fees.withdrawal || vaultInfo.fees.withdrawal === 0) &&
      (vaultInfo.fees.performance || vaultInfo.fees.performance === 0)
		) {
			setFees(
				[
					{
						label: 'Deposit Fee',
						value: vaultInfo ? `${formatValue(vaultInfo.fees.deposit)}%` : null,
						feeInfo: null,
						info: null,
					},
					{
						label: 'Performance Fee',
						value: vaultInfo ? `${formatValue(vaultInfo.fees.performance)}%` : null,
						feeInfo: {
							holders: 4,
							treasury: 0,
							devs: 0,
							harvest: 0.25,
						},
						info: null,
					},
					{
						label: 'Withdrawal Fee',
						value: vaultInfo ? `${formatValue(vaultInfo.fees.withdrawal)}%` : null,
						feeInfo: null,
						info: vaultInfo.fees.withdrawal ? 'This is to prevent front running and sandwich attacks' : null,
					},
					vaultInfo.fees.claim
						? {
							label: 'Claim Fee',
							value: vaultInfo ? `${formatValue(vaultInfo.fees.claim)}%` : null,
							feeInfo: null,
							info: 'This is to prevent front running and sandwich attacks',
						}
						: null,
				].filter((x) => x),
			);
		}
	}, [vaultInfo, geofenced, isLoading]);

	return (
    <Skeleton my={5} isLoaded={!!vaultDetails} height="100%">
      <Box px={{ base: 2, lg: 5 }} pb={16}>
        <Container maxW="container.xl">
          <Link to={`/${vaultInfo ? vaultInfo.type.toLowerCase() : 'vault'}s`}>
            <Button leftIcon={<Icon as={IoChevronBack} />} colorScheme="grey" variant="link" my={4}>
              Go back
            </Button>
          </Link>

          {isOpenDepositModal && vaultDetails && (
            <DepositModal
                isOpen={isOpenDepositModal}
                onClose={closeDepositModal}
                base={vaultDetails}
                canDepositAll={!vaultDetails.isUsingAccumulations}
                onDeposit={(amount, onError, onReceipt, token) => depositAmount(amount, onError, onReceipt, token)}
                onDepositAll={(_amount, onError, onReceipt) => depositAll(onError, onReceipt)}
            />
          )}

          {isOpenWithdrawModal && vaultDetails && (
            <WithdrawModal
                isOpen={isOpenWithdrawModal}
                onClose={closeWithdrawModal}
                base={vaultDetails}
                onWithdraw={(amount, onError, onReceipt) => withdrawAmount(amount, onError, onReceipt)}
                onWithdrawAll={(onError, onReceipt) => withdrawAll(onError, onReceipt)}
            />
          )}

          {geofenced && isOpenGeofenceModal && (
                    <GeolocationModal
                        isOpen={geofenced}
                        onClose={() => { setGeofencedShown(true); return onCloseGeofenceModal(); }}
            />
          )}

          {!!vaultDetails && <VaultDetailsHeader vault={vaultDetails} stats={generalStats} />}

          {!!vaultDetails && vaultDetails.accumulatedToken && !vaultDetails.isHoldAccumulator && (
            <Flex
                bg="bg-surface"
                bgImg={callToAction}
                mx="auto"
                align="center"
                fontWeight={400}
                fontSize="16px"
                my={4}
                p={8}
                w="100%"
                justifyContent="center"
                flexDir="column"
                boxShadow={mode('sm', 'sm-dark')}
                rounded="3xl"
                maxH={{ base: '120px', md: '50px' }}
                position="relative">
              The Bank autocompounds into more {vaultDetails.accumulatedToken.name} unless funds are claimed or withdrawn.
            </Flex>
          )}
          <Flex py={5} flexDir={{ base: 'column', md: 'row' }}>
            {!!vaultDetails && (
            	<>
                <Flex
                    bg="bg-surface"
                    mx="auto"
                    w={!isMobile ? (vaultDetails.accumulatedToken ? '40%' : '600px') : '100%'}
                    p={5}
                    flexDir="column"
                    rounded="3xl"
                    position="relative"
                    border="2px solid var(--chakra-colors-bg-surface)">
                  {/* DEPOSIT AMOUNT */}
                  <Flex py={5} px={{ base: 2, md: 5 }} flexDir={{ base: 'column', xs: 'row' }} justifyContent="space-between" w="100%">
                    <Box px={{ base: 1, md: 3 }}>
                      <Text lineHeight="1.725" color="purple.500" fontWeight="medium" fontSize="xl">
                        {account ? 'Deposited amount' : 'Connect wallet & deposit'}
                      </Text>
                      <Flex align="center">
                        <TokenImage token={vaultDetails.depositToken.symbol} sm />
                        <Text ml={2} fontSize="sm" lineHeight="1.4" w="100%">{`${vaultDetails.depositToken.name}`}</Text>
                        {/* BUY */}
                        {!vaultDetails.depositToken.canZap && vaultDetails.depositToken.buyLink && (
                          <InternalLink href={vaultDetails.depositToken.buyLink} target="_blank" mx={2} mt={-1}>
                            <Button variant="link" color="#8A88B7">
                              {'( '}
                              {vaultDetails.depositToken.isLP ? 'Get LP' : 'Buy Token'}
                              <Icon as={FaExternalLinkAlt} mx="auto" pl={2} w={5} h={5} mr={1} />)
                            </Button>
                          </InternalLink>
                        )}
                        {/*  END BUY */}
                      </Flex>
                    </Box>
                    {!!account && (
                      <Flex flexDir="column" alignItems="center" px={{ base: 1, md: 3 }}>
                        <Text w="full" textAlign={{ base: 'left', sm: 'right' }} pl={{ base: 3, xs: 0 }} color="purple.500" fontSize={{ base: '2xl', md: '3xl' }} fontWeight="bold">
                          {vaultDetails ? `${formatValue(vaultDetails.depositDetails.amount)}` : 0}
                        </Text>
                        <Text w="full" textAlign={{ base: 'left', sm: 'right' }} fontSize="sm" color="#8A88B7">
                          ~{vaultDetails ? `$${formatValue(vaultDetails.depositDetails.value)}` : 0}
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                  {/* END DEPOSIT AMOUNT */}
                  <Flex w="full" flexWrap="wrap" justifyContent="center">
                    <Box mx={{ base: 0, sm: 3 }} w="100%">
                      {/* DEPOSIT FEES */}
                      <Flex
                          bg={mode('#FAFAFA', 'blackAlpha.300')}
                          rounded="lg"
                          flexDir={{ base: 'column', sm: 'row' }}
                          px="2"
                          justify="space-evenly"
                          pt={{ base: '2', md: '4' }}
                          mt={3}
                          mb={4}
                          w="100%"
                          textAlign={{ base: 'left', md: 'center' }}>
                        {fees.map((fee, i) => i < 2 && <StatCard {...fee} size="sm" key={i} />)}
                      </Flex>
                      {/* END DEPOSIT FEES */}
                      <Flex flexDir={{ base: 'column', sm: 'row' }}>
                        {/* <Web3Button
                            {...buttonStyles()}
                            leftIcon={<FaPlus />}
                            onClick={onOpenDepositModal}
                            isLoading={isDepositing}
                            text="Deposit"
                            disabled={geofenced || vaultDetails.deprecated}
                            w={vaultDetails.accumulatedToken ? { base: '100%', sm: '50%' } : '100%'}
                            mr={vaultDetails.accumulatedToken ? '2' : '0'}
                        /> */}
                        {!!vaultDetails.accumulatedToken && (
                          <Web3Button
                              {...buttonStyles()}
                              leftIcon={<FaWallet />}
                              onClick={onOpenWithdrawModal}
                              disabled={!vaultDetails.depositDetails.amount}
                              isLoading={isWithdrawing}
                              w={{ base: '100%', sm: '50%' }}
                              ml={{ base: 0, sm: 2 }}
                              text="Withdraw"
                          />
                        )}
                      </Flex>
                    </Box>
                    {!vaultDetails.accumulatedToken && (
                      <Box mx={{ base: 0, sm: 3 }} w="full">
                        {/* FEES */}
                        <Flex
                            bg={mode('#FAFAFA', 'blackAlpha.300')}
                            rounded="lg"
                            flexDir={{ base: 'column', sm: 'row' }}
                            px="2"
                            justify="space-evenly"
                            pt={{ base: '2', md: '4' }}
                            my={3}
                            w="100%"
                            textAlign={{ base: 'left', md: 'center' }}>
                          {fees.map((fee, i) => i >= 2 && <StatCard {...fee} size="sm" key={i} />)}
                        </Flex>
                        <Web3Button
                            {...buttonStyles()}
                            w="100%"
                            leftIcon={<FaWallet />}
                            onClick={onOpenWithdrawModal}
                            disabled={!vaultDetails.depositDetails.amount}
                            isLoading={isWithdrawing}
                            text="Withdraw"
                        />
                        {/* END FEES */}
                      </Box>
                    )}
                  </Flex>
                </Flex>

                {vaultDetails.accumulatedToken && (
                  <Flex
                      bg="bg-surface"
                      mx="auto"
                      w={!isMobile ? (vaultDetails.accumulatedToken ? '40%' : '800px') : '100%'}
                      ml={{ base: 0, md: 5 }}
                      mt={{ base: 6, md: 0 }}
                      p={5}
                      rounded="3xl"
                      position="relative"
                      border="2px solid var(--chakra-colors-bg-surface)">
                    <Flex flexDir="column" w="100%">
                      <Flex py={5} px={{ base: 2, md: 5 }} flexDir={{ base: 'column', xs: 'row' }} justifyContent="space-between">
                        <Box px={{ base: 1, md: 3 }}>
                          <Text lineHeight="1.725" color="green.300" fontWeight="medium" fontSize="xl">
                            <ResponsiveTooltip
                                label={!vaultDetails.isHoldAccumulator ? "The claimable/yielded tokens autocompound in a bank, continuing to generate more yield until they are withdrawn or claimed." : ""}
                                hasArrow
                                placement="bottom"
                                p={4}
                                borderRadius="md">
                              <Flex align="center">
                                {account && !vaultDetails.isHoldAccumulator && <Icon as={FaRedo} mx="auto" pr={2} w={5} h={5} />}
                                {account ? (vaultDetails.type.toLowerCase().includes('profit') ? 'Amount of profit taken' : 'Accumulated amount') : 'Connect wallet & deposit'}
                                {account && !vaultDetails.isHoldAccumulator && <Icon as={FaInfoCircle} mx="auto" pl={2} w={5} h={5} />}
                              </Flex>
                            </ResponsiveTooltip>
                          </Text>
                          <Flex align="center">
                            <TokenImage token={vaultDetails.accumulatedToken.symbol} sm />
                            <Text ml={2} fontSize="sm" lineHeight="1.4" w="100%">{`${vaultDetails.accumulatedToken.name}`}</Text>
                          </Flex>
                        </Box>
                        <Spacer />
                        {!!account && (
                          <Flex flexDir="column" alignItems="center" px={{ base: 1, md: 3 }}>
                            <Text
                                w="full"
                                textAlign={{ base: 'left', sm: 'right' }}
                                pl={{ base: 3, xs: 0 }}
                                color="green.400"
                                fontSize={{ base: '2xl', md: '3xl' }}
                                fontWeight="bold">
                              {vaultDetails ? `${formatValue(vaultDetails.accumulatedDetails.amount)}` : 0}
                            </Text>
                            <Text w="full" textAlign={{ base: 'left', sm: 'right' }} fontSize="sm" color="#8A88B7">
                              ~{vaultDetails ? `$${formatValue(vaultDetails.accumulatedDetails.value)}` : 0}
                            </Text>
                          </Flex>
                        )}
                      </Flex>

                      <Flex w="full" flexWrap="wrap" justifyContent="center">
                        <Box w="full" mx={{ base: 0, sm: 3 }}>
                          {/* FEES */}
                          <Flex
                              bg={mode('#FAFAFA', 'blackAlpha.300')}
                              rounded="lg"
                              flexDir={{ base: 'column', sm: 'row' }}
                              mx="auto"
                              justify="space-evenly"
                              pt={{ base: '2', md: '4' }}
                              w="100%"
                              my={3}
                              textAlign={{ base: 'left', md: 'center' }}>
                            {fees.map((fee, i) => i >= 2 && <StatCard {...fee} size="sm" key={i} />)}
                          </Flex>
                          {/* <Web3Button
                              {...buttonStyles()}
                              leftIcon={<FaWallet />}
                              onClick={onOpenWithdrawModal}
                              disabled={!vaultDetails.depositDetails.amount}
                              isLoading={isWithdrawing}
                              text="Withdraw"
                          /> */}
                          <Flex justify="center" my={4}>
                            <Web3Button
                                {...buttonStyles()}
                                leftIcon={<FaMoneyBill />}
                                onClick={claimAccumulated}
                                mx="auto"
                                w="full"
                                isLoading={isClaimingAccumulated}
                                disabled={!vaultDetails.accumulatedDetails.amount || geofenced}
                                info="The claimable/yielded tokens autocompound in a bank, continuing to generate more yield until they are withdrawn or claimed."
                                text={vaultDetails.type.toLowerCase().includes('profit') ? 'Claim Profit' : 'Claim Accumulations'}
                            />
                          </Flex>
                          {/* END FEES */}
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
            	</>
            )}
            {/* explanation */}
            {vaultDetails && vaultDetails.accumulatedToken && !isMobile && (
              <Flex
                  mx="auto"
                  w={!isMobile ? (vaultDetails.accumulatedToken ? '20%' : '800px') : '100%'}
                  ml={5}
                  px={5}
                  pt={5}
                  pb={3}
                  justifyContent="center"
                  rounded="3xl"
                  position="relative"
                  border="2px solid var(--chakra-colors-bg-surface)">
                <Box minW="200px" px={6}>
                  <Flex w="100%" flexDir="column" justify="center" align="center">
                    <Box w="100%" bg="bg-surface" rounded="lg" px="3" mb={2} py={{ base: '2', md: '4' }}>
                      <Flex align="center" justify="center" direction="column" w="100%">
                        <TokenImage token={vaultDetails.depositToken.symbol} mb={1} sm />
                        <Text fontSize="sm" lineHeight="1.4">{`${vaultDetails.depositToken.name}`}</Text>
                      </Flex>
                    </Box>

                    <Box as={motion.div} opacity={0.5} textAlign="center" animation={animation(1)}>
                      <Text fontSize="13px" lineHeight="1.4">
                        Harvest yield
                      </Text>
                      <Icon as={IoChevronBack} transform="rotate(-90deg)" mb={2} mt={-1} />
                    </Box>

                    <Box as={motion.div} opacity={0.5} textAlign="center" animation={animation(2)}>
                      <Text fontSize="13px" lineHeight="1.4">
                        buy
                      </Text>
                      <Icon as={IoChevronBack} transform="rotate(-90deg)" mt={-1} />
                    </Box>

                    <Box px={3} w="100%" mt={2} bg="bg-surface" rounded="lg" py={{ base: '2', md: '4' }}>
                      <Flex align="center" justify="center" direction="column" w="100%">
                        <TokenImage token={vaultDetails.accumulatedToken.symbol} mb={1} sm />
                        <Text fontSize="13px" lineHeight="1.4">{`${vaultDetails.accumulatedToken.name}`}</Text>
                      </Flex>
                    </Box>

					  {!vaultDetails.isHoldAccumulator && <Flex justify="space-between" pt={2}>
                      <Box as={motion.div} opacity={0.5} textAlign="center" animation={animation(3)}>
                        <Text fontSize="13px" lineHeight="1.4">
                          harvest yield
                        </Text>
                        <Icon as={IoChevronBack} transform="rotate(-90deg)" mt={-1} />
                      </Box>

                      <Box as={motion.div} opacity={0.5} textAlign="center" animation={animation(4)} ml={3}>
                        <Text fontSize="sm" lineHeight="1.4">
                          buy
                        </Text>
                        <Icon as={IoChevronBack} transform="rotate(90deg)" mt={-1} />
                      </Box>
                    </Flex>}
                  </Flex>
                </Box>
              </Flex>
            )}
            {/*	 end explanation */}
          </Flex>

          {/* {!!account && vaultDetails && vaultDetails.isUsingAccumulations && (
            <Flex justify="center" mt={4} mb={8}>
              <Web3Button
                  {...buttonStyles()}
                  leftIcon={<FaMoneyBill />}
                  onClick={claimAccumulated}
                  mx="auto"
                  isLoading={isClaimingAccumulated}
                  disabled={vaultDetails.accumulatedDetails.amount}
                  info="The claimable/yielded tokens autocompound in a bank, continuing to generate more yield until they are withdrawn or claimed."
                  text={vaultDetails.type.toLowerCase().includes('profit') ? 'Claim Profit' : 'Claim Accumulations'}
              />
            </Flex>
          )} */}
        </Container>
        {!!vaultDetails && <VaultAssetDetailsGroup depositToken={vaultDetails.depositToken} accumulatedToken={vaultDetails.accumulatedToken} />}
      </Box>
    </Skeleton>
	);
}
export default VaultDetails;
