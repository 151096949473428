import React from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';
import { StatCard, StatWrapper } from '../../common/StatComponent';

type Props = {
    strategies: number
    deposit: number|string
    profit: number|string
}

function MyPortfolioStats({ strategies, deposit, profit }: Props) {
	return (
    <StatWrapper
      title={'My strategies'}
      description={'Here you can see your custom created strategies. Please keep in mind that run fees are paid for in BNB or a 10% discount for $Static.'}
    >
        <Skeleton w={{ base: '100%', sm: 'max-content' }} isLoaded={deposit !== undefined}>
            <StatCard label="Total Deposits" value={`$${deposit || 0}`} />
        </Skeleton>
        <Skeleton w={{ base: '100%', sm: 'max-content' }} isLoaded={profit !== undefined}>
            <StatCard label="Total Profit" value={`$${profit}`} />
        </Skeleton>
        <Skeleton w={{ base: '100%', sm: 'max-content' }} isLoaded={strategies !== undefined}>
            <StatCard label="Created Strategies" value={strategies} />
        </Skeleton>
    </StatWrapper>
	);
}

export default MyPortfolioStats;
