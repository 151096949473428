import {
	Flex,
	Container,
	useBreakpointValue, Text, Skeleton,
} from '@chakra-ui/react';
import * as React from 'react';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import VaultCard from './VaultCard';
import GridView from '../../common/GridView';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { useAllVaults } from '../hooks/useAllVaults';
import VaultsPlatformStats from './VaultsPlatformStats';
import VaultsFilters from '../common/VaultsFilters';
import { useGetGeneralPlatformStats } from '../../../hooks/useGetGeneralPlatformStats';
import PaginationModule from '../../common/Pagination/Pagination';

type Props = {
		info: {
			title: string,
			description: string
		},
    type: string;
    description?: string;
};

function Vaults({
	info = {
		title: '',
		description: '',
	},
	type = 'vaults',
}: Props) {
	const [isGridView, setIsGridView] = useState<boolean>(false);
	const [clearFilters, setClearFilters] = useState<boolean>(false);
	const existingVaults = useAllVaults(type);
	const [enabledVaults, setEnabledVaults] = useState<any[]>(existingVaults);

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(0);
	const perPage = 15;

	const isMobile = useBreakpointValue({ base: true, xl: false });

	const filteredVaults = (vaults: any[]) => {
		const rows = vaults ? vaults.filter((v) => v.shouldDisplay).slice(perPage * (currentPage - 1), perPage * currentPage) : null;
		setEnabledVaults(rows);
		setClearFilters(false);
	};

	useEffect(() => {
		const rows = existingVaults ? existingVaults.filter((v) => v.shouldDisplay)
			.slice(perPage * (currentPage - 1), perPage * currentPage) : null;
		const totalPagesSet = existingVaults ? existingVaults.length / perPage : 0;
		setTotalPages((Math.round(totalPagesSet) + 0.5) < totalPagesSet ? Math.round(totalPagesSet) + 1 : Math.round(totalPagesSet));
		setEnabledVaults(rows);
		setClearFilters(true);
	}, [existingVaults, currentPage]);

	const barType = `${type[0].toUpperCase() + type.slice(1, type.length)}s`;

	useDocumentTitle(`Bloomify - ${barType}`);
	const getGeneralStats = useGetGeneralPlatformStats();

	return (
    <Flex flexDir="column" h="100%" overflow="hidden">
        <Skeleton my={5} isLoaded={!existingVaults || (!!existingVaults.length && !!getGeneralStats.vaults && !!getGeneralStats.vaults.tvlPerVault)} height="100%">
            {!!getGeneralStats.vaults && !!getGeneralStats.vaults.tvlPerVault && <Flex h="100%" w="100%" flexDir="column" overflowY="auto" pt={10}>
                {/* {existingVaults && existingVaults[0] && <VaultsPlatformStats
                    vaults={existingVaults}
                    tvl={getGeneralStats[`${existingVaults[0].type.toLowerCase()}s`].totalTvl}
                    lastUpdated={getGeneralStats.updatedAt}
                    title={info.title}
                    description={info.description} /> } */}
                <Container maxW="1800px" px={{ base: 1, sm: 5 }}>
                    <Flex align="center" justify="center">
                        {existingVaults && <VaultsFilters vaults={existingVaults} filteredVaults={filteredVaults} clearFilters={clearFilters} />}
                    </Flex>
                    <GridView isGridView={isGridView} setIsGridView={setIsGridView} length={existingVaults ? existingVaults.length : 0} vaults>
                        {enabledVaults && enabledVaults.map((vault, key) =>
                            <VaultCard
                                isGridView={isGridView}
                                isMobile={isMobile}
                                key={key}
                                vault={vault} />)}
                        {(!existingVaults || (enabledVaults && !enabledVaults.length)) && <Text fontSize="3xl" py={5} textAlign="center" width="100%">Nothing found yet</Text>}
                    </GridView>
                    <Flex align="center" justify="center" w="100%"> <PaginationModule totalPages={totalPages} setPage={(page) => setCurrentPage(page)} /> </Flex>
                </Container>
            </Flex> }
        </Skeleton>
    </Flex>
	);
}

export default Vaults;
