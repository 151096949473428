import React from 'react';
import {
	Badge, Box, Flex, Stack, SimpleGrid, StackDivider, Text, useColorModeValue as mode, Tooltip, Icon, Link,
} from '@chakra-ui/react';
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';
import { StatCard } from '../../../common/StatComponent/StatCard';
import TokenImage from '../../../common/TokenImage';

type Props = {
  farm: any
  stats?: any
}
function FarmDetailsHeader({ farm, stats }: Props) {
	return (
    <Flex justify="space-between" py={2} px={0} rounded="xl" flexDir={{ base: 'column', xl: 'row' }}>
        <Box py={2} my="auto">
            <Flex>
                <TokenImage token={farm.depositToken.symbol} mx={0} />
                <Text fontSize="3xl" fontWeight="bold" my="auto" px={2}>{farm.name}</Text>
            </Flex>
            <Flex mt="2px" mb="0.5rem" flexWrap="wrap">
                <Badge colorScheme="purple" my="2px" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                    <Flex align="center"> {farm.platform}
                    </Flex>
                </Badge>
                {farm.geolocation &&
                <Badge colorScheme="yellow" my="2px" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                    <Link href="https://docs.bloomify.co/information/disclaimer" target="_blank">
                        <Flex align="center"> Geofenced <Icon as={FaExternalLinkAlt} mx="auto" pl={1} w={3} h={3} />
                        </Flex>
                    </Link>
                </Badge> }
                {farm.otherInfo ? farm.otherInfo.map((i) =>
                    <Badge my="2px" colorScheme="green" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                        {i}
                    </Badge>) : null}
                {!!farm.deprecated && <Badge my="2px" colorScheme="red" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                    ARCHIVED
                </Badge>}
            </Flex>
            <Text fontSize="14px" fontWeight="normal" px={2}>{farm.description}</Text>
        </Box>
        <Box as="section" pl={{ base: 0, lg: 4 }} my="auto">
            <Box mx="auto">
                <SimpleGrid
                    p={2}
                    spacingY={{ base: 0, sm: 2, lg: 0 }}
                    spacingX={2}
                    justifyItems="center"
                    flexWrap="wrap"
                    columns={{
                    	base: 1, sm: 2, md: 4, lg: 2,
                    }}
                    row={{ base: stats.length, sm: 2, lg: 'auto' }}
                    minW={{ base: '100%', lg: '42vw', xl: '500px' }}>
                    {stats && stats.length ? stats.map((i, key) =>
                        <StatCard {...i} key={key} />) : null}
                </SimpleGrid>
            </Box>
        </Box>
    </Flex>
	);
}

export default FarmDetailsHeader;
