/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable quotes */
/* eslint-disable dot-notation */
import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Container,
	Flex,
	Icon,
	Skeleton,
	useColorModeValue as mode,
	useDisclosure,
	Link as InternalLink,
	useBreakpointValue,
	Text,
	Spacer,
	Badge,
} from '@chakra-ui/react';

import { Link, useParams, useLocation } from 'react-router-dom';
import { FaWallet } from '@react-icons/all-files/fa/FaWallet';
import { IoChevronBack } from '@react-icons/all-files/io5/IoChevronBack';
import _ from 'lodash';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';
import { FaMoneyBill } from '@react-icons/all-files/fa/FaMoneyBill';
import { useWeb3React } from '@web3-react/core';
import TokenImage from '../../../common/TokenImage';
import { useSingleFarmDetails } from '../../hooks/useSingleFarmDetails';
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle';
import { formatValue } from '../../../../helpers/util';
import { FarmAssetDetailsGroup } from '../../../common/AssetDetails/AssetDetailsGroup';
import FarmDetailsHeader from '../FarmDetailsHeader';
import DepositModal from '../../../common/Modal/Premade/DepositModal';
import WithdrawModal from '../../../common/Modal/Premade/WithdrawModal';
import Web3Button from '../../../common/Web3Button';
import { defaultChain } from '../../../../bloomify/config';
import useRevokeAccessGeolocation from '../../../../hooks/useRevokeAccessGeolocation';
import GeolocationModal from '../../../common/Modal/Premade/GeolocationModal';

const buttonStyles = () => ({
	bg: mode('white', 'gray.900'),
	border: mode('2px solid rgb(0, 0, 0)', '2px solid white'),
	size: 'lg',
	my: '1',
	w: { base: '260px', sm: '280px', md: '340px' },
});

function FarmDetails() {
	const { account } = useWeb3React();
	const { address } = useParams();
	const location = useLocation();
	const { revokeAccess, isLoading } = useRevokeAccessGeolocation();

	const {
		farmInfo, isWithdrawing, isDepositing, isClaiming, withdrawAmount, withdrawAll, depositAmount, depositAll, getDetails, claimEarnings,
	} = useSingleFarmDetails(
		address,
	);

	const [farmDetails, setFarmDetails] = useState<any>(farmInfo);
	const [geofenced, setGeofenced] = useState<boolean>(false);
	const [geofencedShownAlready, setGeofencedShown] = useState<boolean>(false);

	useDocumentTitle(`Bloomify - ${farmDetails ? farmDetails.name : 'Loading...'}`);

	const isMobile = useBreakpointValue({ base: true, lg: false });

	const { isOpen: isOpenDepositModal, onOpen: onOpenDepositModal, onClose: onCloseDepositModal } = useDisclosure();
	const { isOpen: isOpenWithdrawModal, onOpen: onOpenWithdrawModal, onClose: onCloseWithdrawModal } = useDisclosure();
	const { isOpen: isOpenGeofenceModal, onOpen: onOpenGeofenceModal, onClose: onCloseGeofenceModal } = useDisclosure();

	const closeDepositModal = async () => {
		onCloseDepositModal();
		await getDetails();
	};

	const closeWithdrawModal = async () => {
		onCloseWithdrawModal();
		await getDetails();
	};

	const generalStats = [
		// farmDetails && !farmDetails.hideTVL ? {
		// 	label: 'TVL',
		// 	value: farmDetails ? `$${formatValue(farmDetails.tvl)}` : null,
		// } : null,
		// farmDetails && !farmDetails.hideAPR ? {
		// 	label: 'APY',
		// 	value: farmDetails.aprDetails ? `${formatValue(farmDetails.aprDetails.totalApy || 0)}%` : null,
		// 	aprInfo: farmDetails.aprDetails ? farmDetails.aprDetails : null,
		// } : null,
		farmDetails && farmDetails.startBlock ? {
			label: defaultChain.time ? 'Start Time' : 'Start Block',
			value: farmDetails ? farmDetails.startBlock : null,
			href: farmDetails && !defaultChain.time ? `${defaultChain.blockExplorerUrl}/${farmDetails.startBlock}` : null,
			time: defaultChain.time,
		} : null,
		farmDetails && farmDetails.endBlock ? {
			label: defaultChain.time ? 'End Time' : 'End Block',
			value: farmDetails ? farmDetails.endBlock : null,
			href: farmDetails && !defaultChain.time ? `${defaultChain.blockExplorerUrl}/${farmDetails.endBlock}` : null,
			time: defaultChain.time,
		} : null,
	].filter((x) => x);

	useEffect(() => {
		setFarmDetails(farmInfo);
		setGeofenced(farmInfo ? !isLoading && farmInfo.geolocation && revokeAccess : false);
		if (geofenced && !isOpenGeofenceModal && !geofencedShownAlready) onOpenGeofenceModal();
	}, [farmInfo, geofenced, isLoading]);

	return (
    <Skeleton my={5} isLoaded={!!farmDetails} height="100%">
        <Box px={{ base: 2, lg: 5 }} pb={16}>
            <Container maxW="container.xl">
                <Link to="/farms">
                    <Button leftIcon={<Icon as={IoChevronBack} />} colorScheme="grey" variant="link" my={4}>
                        Go back to farms
                    </Button>
                </Link>

                {isOpenDepositModal && farmDetails && (
                <DepositModal
                    isOpen={isOpenDepositModal}
                    onClose={closeDepositModal}
                    base={farmDetails}
                    onDeposit={(amount, onError, onReceipt) => depositAmount(amount, onError, onReceipt)}
                    onDepositAll={(amount, onError, onReceipt) => depositAll(amount, onError, onReceipt)}
            />
                )}

                {isOpenWithdrawModal && farmDetails && (
                <WithdrawModal
                    isOpen={isOpenWithdrawModal}
                    onClose={closeWithdrawModal}
                    base={farmDetails}
                    onWithdraw={(amount, onError, onReceipt) => withdrawAmount(amount, onError, onReceipt)}
                    onWithdrawAll={(onError, onReceipt) => withdrawAll(onError, onReceipt)}
            />
                )}

                {geofenced && isOpenGeofenceModal && (
                    <GeolocationModal
                        isOpen={geofenced}
                        onClose={() => { setGeofencedShown(true); return onCloseGeofenceModal(); }}
            />
                )}
                {!!farmDetails && <FarmDetailsHeader farm={farmDetails} stats={generalStats} />}
            </Container>
            <Container maxW="container.2xl" py={5}>
                {!!farmDetails && (
                <Flex
                    bg="bg-surface"
                    mx="auto"
                    w={!isMobile ? '820px' : '100%'}
                    p={5}
                    justifyContent="center"
                    flexDir="column"
                    rounded="3xl"
                    position="relative"
                    border="2px solid var(--chakra-colors-bg-surface)">
                    {farmDetails.finished && <Badge
                        colorScheme="red"
                        my="2px"
                        mx={1}
                        fontSize="12px"
                        variant={mode('solid', 'subtle')}
                        textAlign="center">FINISHED</Badge>}
                        {farmDetails.pending && <Badge
                            colorScheme="yellow"
                            my="2px"
                            mx={1}
                            fontSize="12px"
                            variant={mode('solid', 'subtle')}
                            textAlign="center">PENDING</Badge>}
                    {/* DEPOSIT AMOUNT */}
                    <Flex py={5} px={{ base: 2, md: 5 }} flexDir={{ base: 'column', xs: 'row' }}>

                        <Flex>
                            <Box px={{ base: 1, md: 3 }}>
                                <Text lineHeight="1.725" color="purple.500" fontWeight="medium" fontSize="xl">
                                    {account ? 'Deposited amount' : 'Connect wallet & deposit'}
                                </Text>
                                <Flex align="center">
                                    <TokenImage token={farmDetails.depositToken.symbol} sm />
                                    <Text ml={2} fontSize="sm" w="100%">{`${farmDetails.depositToken.name}`}</Text>
                                    {/* BUY */}
                                    {!farmDetails.depositToken.canZap && farmDetails.depositToken.buyLink && (
                                    <InternalLink href={farmDetails.depositToken.buyLink} target="_blank" mx={2} mt={-1}>
                                        <Button variant="link" color="#8A88B7">
                                            {'( '}
                                            {farmDetails.depositToken.isLP ? 'Get LP' : 'Buy Token'}
                                            <Icon as={FaExternalLinkAlt} mx="auto" pl={2} w={5} h={5} mr={1} />)
                                        </Button>
                                    </InternalLink>
                                    )}
                                    {/*  END BUY */}
                                </Flex>
                            </Box>
                        </Flex>
                        <Spacer />
                        {!!account && (
                        <Flex flexDir="column" alignItems="center" px={{ base: 1, md: 3 }}>
                            <Text
                                w="full"
                                textAlign={{ base: 'left', sm: 'right' }}
                                pl={{ base: 3, xs: 0 }}
                                color="purple.500"
                                fontSize={{ base: '2xl', md: '3xl' }}
                                fontWeight="bold">
                                {farmDetails ? `${formatValue(farmDetails.depositDetails.amount)}` : 0}
                            </Text>
                            {farmDetails.depositToken.symbol !== 'PULSE' && <Text w="full" textAlign={{ base: 'left', sm: 'right' }} fontSize="sm" color="#8A88B7">
                                ~{farmDetails ? `$${formatValue(farmDetails.depositDetails.value)}` : 0}
                            </Text> }
                        </Flex>
                        )}
                    </Flex>
                    {!!account && (
                    <Flex py={5} px={{ base: 2, md: 5 }} flexDir={{ base: 'column', xs: 'row' }}>
                        <Flex>
                            <Box px={{ base: 1, md: 3 }}>
                                <Text lineHeight="1.725" color="green.500" fontWeight="medium" fontSize="xl">
                                    Earnings
                                </Text>
                                <Flex align="center">
                                    <TokenImage token={farmDetails.earn.symbol.toLowerCase()} sm />
                                    <Text ml={2} fontSize="sm" w="100%">{`${farmDetails.earn.name}`}</Text>
                                </Flex>
                            </Box>
                        </Flex>
                        <Spacer />
                        <Flex flexDir="column" alignItems="center" px={{ base: 1, md: 3 }}>
                            <Text
                                w="full"
                                textAlign={{ base: 'left', sm: 'right' }}
                                pl={{ base: 3, xs: 0 }}
                                color="green.500"
                                fontSize={{ base: '2xl', md: '3xl' }}
                                fontWeight="bold">
                                {farmDetails ? `${formatValue(farmDetails.earningsDetails.amount)}` : 0}
                            </Text>
                            <Text w="full" textAlign={{ base: 'left', sm: 'right' }} fontSize="sm" color="#8A88B7">
                                ~{farmDetails ? `$${formatValue(farmDetails.earningsDetails.value)}` : 0}
                            </Text>
                        </Flex>{' '}
                    </Flex>
                    )}

                    {/* END DEPOSIT AMOUNT */}
                    <Flex w="full" flexWrap="wrap" justifyContent="center">
                        {/* <Box mx={{ base: 0, sm: 3 }}>
                            <Web3Button
                                {...buttonStyles()}
                                leftIcon={<FaPlus />}
                                onClick={onOpenDepositModal}
                                isLoading={isDepositing}
                                text="Deposit"
                                disabled={farmDetails.finished || geofenced || farmDetails.deprecated} />
                        </Box> */}

                        <Box mx={{ base: 0, sm: 3 }}>
                            <Web3Button
                                {...buttonStyles()}
                                leftIcon={<FaWallet />}
                                onClick={onOpenWithdrawModal}
                                disabled={!farmDetails.depositDetails.amount}
                                isLoading={isWithdrawing}
                                text="Withdraw" />
                        </Box>

                        {!!account && (
                        <Box mx={{ base: 0, sm: 3 }} mt={5}>
                            <Web3Button
                                {...buttonStyles()}
                                leftIcon={<FaMoneyBill />}
                                onClick={claimEarnings}
                                isLoading={isClaiming}
                                disabled={!farmDetails.earningsDetails.amount || geofenced}
                                text="Claim"
                    />
                        </Box>
                        )}
                    </Flex>
                </Flex>
                )}
            </Container>
            {!!farmDetails && <FarmAssetDetailsGroup depositToken={farmDetails.depositToken} />}
        </Box>
    </Skeleton>
	);
}
export default FarmDetails;
