/* eslint-disable dot-notation */
import { ContractCallContext } from 'ethereum-multicall';
import Web3 from 'web3';
import { BigNumber } from 'ethers';
import _ from 'lodash';
import { doMulticall } from './multicall.service';
import { defaultChain, allAvailableTokens } from '../bloomify/config';
import { getTokenDetails } from './tokens.service';

const supportedProviders = _.map(defaultChain.addressBook.platforms, (_p, pName) => pName.toLowerCase());

const _generateContext = (tokenList: any, walletAddress: string): ContractCallContext[] => {
	const context = [];

	_.forEach(tokenList, (token) => {
		context.push({
			reference: `${token.symbol.toLowerCase()}-${token.address.toLowerCase()}`,
			contractAddress: token.address,
			abi: defaultChain.deployments.MockToken.abi,
			calls: [
				{
					reference: `${token.symbol.toLowerCase()}-${token.address.toLowerCase()}`,
					methodName: 'balanceOf',
					methodParameters: [walletAddress],
				},
			],
		});
	});

	return context;
};

export const getTokenBalances = async (web3: Web3, address: string) => {
//	const tokenBalances = JSON.parse(localStorage.getItem('tokenBalances')) || {};

	//	if (!tokenBalances.expiry || (tokenBalances.expiry && tokenBalances.expiry < Date.now())) {
	const native = await web3.eth.getBalance(address).then((h:any) => h);
	const nativeBalance = {
		integer: native / 1e18,
		hex: BigNumber.from(native),
	};

	const call = _generateContext(allAvailableTokens, address);
	const multicall = await doMulticall(web3, call);

	const formatTokens = () => {
		const result = { name: { tokens: {}, lps: {} }, address: { } };
		result.address[defaultChain.nativeTokenAddress.toLowerCase()] = nativeBalance;
		result.name.tokens['native'] = nativeBalance;

		_.forEach(allAvailableTokens, (token) => {
			const callBalance = multicall[`${token.symbol.toLowerCase()}-${token.address.toLowerCase()}`][0];
			const balance = {
				 	integer: callBalance.hex / Math.pow(10, token.decimals ?? 18),
				 	hex: BigNumber.from(callBalance.hex),
				 };

			result.address[token.address.toLowerCase()] = balance;

			if (token.platformId) {
				result.name.lps[`${token.platformId.toLowerCase()}-${token.symbol.toLowerCase()}`] = balance;
			} else {
				result.name.tokens[token.symbol] = balance;
			}
		});

	 return result;
	};

	const response = formatTokens();
	// const cachedTokenBalances = { response, expiry: Date.now() + 10000 };
	// localStorage.setItem('tokenBalances', JSON.stringify(cachedTokenBalances));

	return response;
	//	}

//	return tokenBalances.response;
};

export const getTokenBalance = (tokenDetails, tokens) => {
	if (!tokenDetails) {
		return {
			integer: 0,
			hex: BigNumber.from(0),
		};
	}

	const getTokenBalanceByPlatform = (nameOrAddress, platformId) => {
		const finalName = `${platformId.toLowerCase()}-${nameOrAddress.toLowerCase()}`;

		let tokenBalance = getTokenBalanceByAddress(nameOrAddress);

		if (!tokenBalance) tokenBalance = getTokenBalanceByName(finalName, true);

		return tokenBalance;
	};

	const getTokenBalanceByName = (tokenName, isLp?) => {
		let token = null;

		if (isLp) token = tokens.name.lps[tokenName.toLowerCase()];

		else {
			const lpPlatform = supportedProviders.find((p) => tokenName.includes(`${p}-`));
			token = lpPlatform ? tokens.name.lps[tokenName.toLowerCase()] : (tokens.name.tokens[tokenName.toUpperCase()] || tokens.name.tokens[tokenName.toLowerCase()]);
		}

		return token || null;
	};

	const getTokenBalanceByAddress = (tokenAddress) => {
		let token = null;

		token = tokens.address[tokenAddress.toLowerCase()];

		return token || null;
	};

	const getTokenBalanceByDetails = (details) => {
		if (!details) return null;

		if (details.address && details.address.toLowerCase() === defaultChain.nativeTokenAddress.toLowerCase()) return getTokenBalanceByName('native');

		if (details.platformId && details.address) return getTokenBalanceByPlatform(details.address, details.platformId);

		if (details.platformId && (details.symbol || details.name) && !details.address) return getTokenBalanceByPlatform((details.symbol || details.name), details.platformId);

		if (details.address) return getTokenBalanceByAddress(details.address);

		if (!details.address && (details.symbol || details.name)) return getTokenBalanceByName((details.symbol || details.name));
	};

	if (_.isString(tokenDetails)) {
		const stringToken = tokenDetails === 'native' ? defaultChain.nativeTokenAddress : tokenDetails;
		const addressBookToken = getTokenDetails(stringToken);

		return getTokenBalanceByDetails(addressBookToken);
	}

	return getTokenBalanceByDetails(tokenDetails);
};
