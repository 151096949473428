

import _ from 'lodash';
import { addressBook } from './address_book.config';
import { getSavedChain } from '../modules/common/ChainSwitcher/switcher.cookie';
import { config as bscConfig } from './config/bsc/bsc-config';
import { config as ftmConfig } from './config/ftm/ftm-config';

export const CHAIN_ID_BSC = { mainnet: 56, testnet: 97 };
export const CHAIN_ID_FTM = { mainnet: 250, testnet: 4002 };


export const chains: { [chain: string]: { [env: string]: any } } = {
	BSC: {
		mainnet: {
			chainId: CHAIN_ID_BSC.mainnet,
			shortName: 'bsc',
			configName: 'bsc',
			nativeToken: 'bnb',
			nativeTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', // WBNB
			multicallAddress: '0x41263cBA59EB80dC200F3E2544eda4ed6A90E76C',
			name: 'Binance Smart Chain',
			colorScheme: 'yellow',
			icon: require('../assets/tokens/bnb.svg').default,
			scanUrl: 'https://bscscan.com',
			defaultProvider: 'https://bsc-dataseed1.defibit.io/',
			deployments: require('./deployments/bsc/mainnet.json'),
			addressBook: addressBook.BSC,
			blockExplorerUrl: 'https://bscscan.com/block',
			farms: bscConfig.farms,
			vaults: bscConfig.vaults,
			blocks: bscConfig.blocks,
			strategies: bscConfig.strategies,
			deprecatedStrategiesTags: bscConfig.deprecatedTags,
		},
	},
	FTM: {
		mainnet: {
			chainId: CHAIN_ID_FTM.mainnet,
			shortName: 'fantom',
			configName: 'ftm',
			nativeTokenAddress: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83', // WFTM
			multicallAddress: '0xF548895E2f09Dcf949519bF24e9d70012EDA4840',
			name: 'Fantom',
			nativeToken: 'ftm',
			colorScheme: 'blue',
			icon: require('../assets/tokens/fantom.svg').default,
			scanUrl: 'https://ftmscan.com',
			defaultProvider: 'https://rpc.ftm.tools/',
			deployments: require('./deployments/ftm/mainnet.json'),
			addressBook: addressBook.FTM,
			blockExplorerUrl: 'https://ftmscan.com/block',
			farms: ftmConfig.farms,
			vaults: ftmConfig.vaults,
			blocks: ftmConfig.blocks,
			strategies: ftmConfig.strategies,
			time: true,
			deprecatedStrategiesTags: ftmConfig.deprecatedTags,
		},
	},
};

export const operatingChains = ['BSC', 'FTM'];

// TODO: default to BSC testnet if no ENV is found
export const defaultChain = chains[getSavedChain() || 'BSC'][process.env.REACT_APP_CHAIN_ENVIRONMENT || 'mainnet'];

export const currentEnvironmentChains = {
	BSC: { ...chains.BSC[process.env.REACT_APP_CHAIN_ENVIRONMENT || 'mainnet'] },
	FTM: { ...chains.FTM[process.env.REACT_APP_CHAIN_ENVIRONMENT || 'mainnet'] },
};

export const getAssets = (asset) => {
	const [t1, t2] = asset.toUpperCase().split('-');
	return [defaultChain.addressBook.tokens[t1.toUpperCase()], t2 ? defaultChain.addressBook.tokens[t2.toUpperCase()] : null].filter((x) => x);
};

export const allAvailableTokens = [..._.map(defaultChain.addressBook.tokens, (t) => t), ..._.map(defaultChain.addressBook.lps, (lpT) => lpT)].flat();


