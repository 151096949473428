import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import useWeb3 from '../../../hooks/useWeb3';
import { buyStrategyTicket, getStrategyTickets, getTicketPrices } from '../services/ticket.service';
import { useTokenPrices } from '../../../contexts/TokenPricesContext';
import { fromWei } from '../../../helpers/web3-helpers';
import { erc20Allowance, erc20Approve } from '../../../service/erc2.service';
import { defaultChain } from '../../../bloomify/config';
import { formatValue } from '../../../helpers/util';

export const useTicketSentry = (strategyAddr: string, onClose: () => void) => {
	const web3 = useWeb3();
	const { account } = useWeb3React();
	const { getTokenPrice } = useTokenPrices();

	const [prices, setPrices] = useState<any>();
	const [ticketToken, setTicketToken] = useState<string>('static');
	const [staticApproved, setStaticApproved] = useState<boolean>(false);
	const [bnbApproved, setBnbApproved] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const getCurrentTicketPrices = async () => {
		const result = await getTicketPrices(web3, account);

		setPrices([
			{ wei: result[0], amount: fromWei(result[0]), price: (fromWei(result[0]) * getTokenPrice('STATIC')).toFixed(3) },
			{ wei: result[1], amount: fromWei(result[1]), price: (fromWei(result[1]) * getTokenPrice(defaultChain.nativeTokenAddress)).toFixed(3) },
		]);
	};

	const getAllowance = async () => {
		const allowance = await erc20Allowance(web3, defaultChain.addressBook.tokens.STATIC.address, defaultChain.addressBook.general.SentryTicket, account);
		setStaticApproved(allowance > 0);
		const allowance2 = await erc20Allowance(web3, defaultChain.nativeTokenAddress, defaultChain.addressBook.general.SentryTicket, account);
		setBnbApproved(allowance2 > 0);
	};

	const onApprove = async (ticket: string) => {
		setIsLoading(true);
		const response = await erc20Approve(
			web3,
			ticket === 'static' ? defaultChain.addressBook.tokens.STATIC.address : defaultChain.nativeTokenAddress,
			defaultChain.addressBook.general.SentryTicket,

			account,

			() => console.log('error'),
		);
		if (response.status) {
			await getAllowance();
			setIsLoading(false);
		}
	};

	const buyTicketAndRun = async () => {
		setIsLoading(true);
		const response = await buyStrategyTicket(
			web3,
			account,
			strategyAddr,
			ticketToken,
			prices[1].wei,
			() => setIsLoading(false),
		);
		if (response.status) {
			onClose();
		}
	};

	useEffect(() => {
		Promise.all([
			getCurrentTicketPrices(),
			getAllowance(),
		]);
	}, []);

	return {
		prices,
		staticApproved,
		bnbApproved,
		ticketToken,
		setTicketToken,
		buyTicket: buyTicketAndRun,
		onApprove,
		isLoading,
	};
};
