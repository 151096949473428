/* eslint-disable no-sparse-arrays */
/* eslint-disable max-len */
import { addressBook } from './addressbook.config';
import { ENV_PROD, ENV_TESTING, featureAllowed } from '../../env';

export const description = (tokenA, tokenB) => `${tokenA.name} is deposited into the farm.
Yield is sold to take profits in ${tokenB.name} and deposited in an auto-compounding Bank.
The Bank autocompounds into more ${tokenB.name} unless funds are claimed or withdrawn.`;

export let deployedProfitTakers: (
  | {
      otherInfo: string[];
      chain: string;
      fees: { performance: number; deposit: number; claim: number; withdrawal: number };
      address: string;
      description: string;
      env: string;
      type: string;
      platforms: string[];
      deployments: { vault: string };
      depositToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
      isUsingAccumulations: boolean;
      name: string;
      accumulatedToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
    }
  | {
      otherInfo: string[];
      chain: string;
      fees: { performance: number; deposit: number; claim: number; withdrawal: number };
      address: string;
      description: string;
      env: string;
      type: string;
      platforms: string[];
      deployments: { vault: string };
      depositToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
      isUsingAccumulations: boolean;
      name: string;
      accumulatedToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
    }
  | {
      otherInfo: string[];
      chain: string;
      fees: { performance: number; deposit: number; claim: number; withdrawal: number };
      address: string;
      description: string;
      env: string;
      type: string;
      platforms: string[];
      deployments: { vault: string };
      depositToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
      isUsingAccumulations: boolean;
      name: string;
      accumulatedToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
    }
  | {
      otherInfo: string[];
      chain: string;
      fees: { performance: number; deposit: number; claim: number; withdrawal: number };
      address: string;
      description: string;
      env: string;
      type: string;
      platforms: string[];
      deployments: { vault: string };
      depositToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
      isUsingAccumulations: boolean;
      name: string;
      accumulatedToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
    }
  | {
      otherInfo: string[];
      chain: string;
      fees: { performance: number; deposit: number; claim: number; withdrawal: number };
      address: string;
      description: string;
      env: string;
      type: string;
      platforms: string[];
      deployments: { vault: string };
      depositToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
      isUsingAccumulations: boolean;
      name: string;
      accumulatedToken: any;
      isHoldAccumulator: boolean;
    }
  | {
      otherInfo: string[];
      chain: string;
      fees: { performance: number; deposit: number; claim: number; withdrawal: number };
      address: string;
      description: string;
      env: string;
      type: string;
      platforms: string[];
      deployments: { vault: string };
      depositToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
      isUsingAccumulations: boolean;
      name: string;
      accumulatedToken: any;
      isHoldAccumulator: boolean;
    }
  | {
      otherInfo: string[];
      chain: string;
      fees: { performance: number; deposit: number; claim: number; withdrawal: number };
      address: string;
      deprecated: boolean;
      description: string;
      env: string;
      type: string;
      platforms: string[];
      deployments: { vault: string };
      depositToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
      isUsingAccumulations: boolean;
      name: string;
      accumulatedToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
    }
  | {
      otherInfo: string[];
      chain: string;
      fees: { performance: number; deposit: number; claim: number; withdrawal: number };
      address: string;
      deprecated: boolean;
      description: string;
      env: string;
      type: string;
      platforms: string[];
      deployments: { vault: string };
      depositToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
      isUsingAccumulations: boolean;
      name: string;
      accumulatedToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
    }
  | {
      otherInfo: string[];
      chain: string;
      fees: { performance: number; deposit: number; claim: number; withdrawal: number };
      address: string;
      deprecated: boolean;
      description: string;
      env: string;
      type: string;
      platforms: string[];
      deployments: { vault: string };
      depositToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
      isUsingAccumulations: boolean;
      name: string;
      accumulatedToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
    }
  | {
      otherInfo: string[];
      chain: string;
      fees: { performance: number; deposit: number; claim: number; withdrawal: number };
      address: string;
      deprecated: boolean;
      description: string;
      env: string;
      type: string;
      platforms: string[];
      deployments: { vault: string };
      depositToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
      isUsingAccumulations: boolean;
      name: string;
      accumulatedToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
    }
  | {
      otherInfo: string[];
      chain: string;
      fees: { performance: number; deposit: number; claim: number; withdrawal: number };
      address: string;
      deprecated: boolean;
      description: string;
      env: string;
      type: string;
      platforms: string[];
      deployments: { vault: string };
      depositToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
      isUsingAccumulations: boolean;
      name: string;
      accumulatedToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
    }
  | {
      otherInfo: string[];
      chain: string;
      fees: { performance: number; deposit: number; claim: number; withdrawal: number };
      address: string;
      deprecated: boolean;
      description: string;
      env: string;
      type: string;
      platforms: string[];
      deployments: { vault: string };
      depositToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
      isUsingAccumulations: boolean;
      name: string;
      accumulatedToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
    }
  | {
      otherInfo: string[];
      chain: string;
      fees: { performance: number; deposit: number; claim: number; withdrawal: number };
      address: string;
      deprecated: boolean;
      description: string;
      env: string;
      type: string;
      platforms: string[];
      deployments: { vault: string };
      depositToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
      isUsingAccumulations: boolean;
      name: string;
      accumulatedToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
    }
  | {
      otherInfo: string[];
      chain: string;
      fees: { performance: number; deposit: number; claim: number; withdrawal: number };
      address: string;
      deprecated: boolean;
      description: string;
      env: string;
      type: string;
      platforms: string[];
      deployments: { vault: string };
      depositToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
      isUsingAccumulations: boolean;
      name: string;
      accumulatedToken: { symbol: string; address: string; canZap: boolean; buyLink: string; decimals: number; name: string; platformId: string; logoURI: any; isLP: boolean };
    }
)[];
deployedProfitTakers = [
  {
    address: addressBook.vaults.profitTakers.BTCB_BUSD_to_USDT_BUSD_StableLP,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'BTCB-BUSD to USDT-BUSD Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-btcb-busd'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-stable-usdt-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit BTCB-BUSD', 'Profits taken in USDT-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-btcb-busd'], addressBook.lps['pancake-stable-usdt-busd']),
  },

  {
    address: addressBook.vaults.profitTakers.Cake_BUSD_to_USDT_BUSD_StableLP,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'Cake-BUSD to USDT-BUSD Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-cake-busd'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-stable-usdt-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit Cake-BUSD', 'Profits taken in USDT-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-cake-busd'], addressBook.lps['pancake-stable-usdt-busd']),
  },

  {
    address: addressBook.vaults.profitTakers.Cake_BNB_to_USDT_BUSD_StableLP,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'Cake-BNB to USDT-BUSD Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-cake-bnb'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-stable-usdt-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit Cake-BNB', 'Profits taken in USDT-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-cake-bnb'], addressBook.lps['pancake-stable-usdt-busd']),
  },

  {
    address: addressBook.vaults.profitTakers.BUSD_BNB_to_USDT_BUSD_StableLP,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'BUSD-BNB to USDT-BUSD Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-busd-bnb'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-stable-usdt-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit BUSD-BNB', 'Profits taken in USDT-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-busd-bnb'], addressBook.lps['pancake-stable-usdt-busd']),
  },

  {
    address: addressBook.vaults.profitTakers.BTCB_BUSD_to_USDT_USDC_StableLP,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'BTCB-BUSD to USDC-USDT Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-btcb-busd'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-stable-usdt-usdc'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit BTCB-BUSD', 'Profits taken in USDC-USDT'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-btcb-busd'], addressBook.lps['pancake-stable-usdt-usdc']),
  },

  {
    address: addressBook.vaults.profitTakers.Cake_BUSD_to_USDT_USDC_StableLP,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'Cake-BUSD to USDC-USDT Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-cake-busd'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-stable-usdt-usdc'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit Cake-BUSD', 'Profits taken in USDC-USDT'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-cake-busd'], addressBook.lps['pancake-stable-usdt-usdc']),
  },

  {
    address: addressBook.vaults.profitTakers.Cake_BNB_to_USDT_USDC_StableLP,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'Cake-BNB to USDC-USDT Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-cake-bnb'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-stable-usdt-usdc'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit Cake-BNB', 'Profits taken in USDC-USDT'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-cake-bnb'], addressBook.lps['pancake-stable-usdt-usdc']),
  },

  {
    address: addressBook.vaults.profitTakers.BUSD_BNB_to_USDT_USDC_StableLP,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'BUSD-BNB to USDC-USDT Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-busd-bnb'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-stable-usdt-usdc'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit BUSD-BNB', 'Profits taken in USDC-USDT'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-busd-bnb'], addressBook.lps['pancake-stable-usdt-usdc']),
  },

  {
    address: addressBook.vaults.profitTakers.WOM_BUSD_to_USDT_USDC_StableLP,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'WOM-BUSD to USDC-USDT Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-wom-busd'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-stable-usdt-usdc'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit WOM-BUSD', 'Profits taken in USDC-USDT'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-wom-busd'], addressBook.lps['pancake-stable-usdt-usdc']),
  },

  {
    address: addressBook.vaults.profitTakers.PEEL_BUSD_to_USDT_USDC_StableLP,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'PEEL-BUSD to USDC-USDT Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-peel-busd'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-stable-usdt-usdc'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit PEEL-BUSD', 'Profits taken in USDC-USDT'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-peel-busd'], addressBook.lps['pancake-stable-usdt-usdc']),
  },

  {
    address: addressBook.vaults.profitTakers.Cake_BUSD_to_ADA,
    isUsingAccumulations: true,
    isHoldAccumulator: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'Cake-BUSD to ADA Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-cake-busd'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.tokens.ADA,
      isLP: false,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit Cake-BUSD', 'Profits taken in ADA'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-cake-busd'], addressBook.tokens['ADA']),
  },

  {
    address: addressBook.vaults.profitTakers.Cake_BNB_to_ADA,
    isUsingAccumulations: true,
    isHoldAccumulator: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'Cake-BNB to ADA Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-cake-bnb'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.tokens.ADA,
      isLP: false,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit Cake-BNB', 'Profits taken in ADA'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-cake-bnb'], addressBook.tokens['ADA']),
  },
  {
    deprecated: true,
    address: addressBook.vaults.profitTakers.Cake_BUSD_to_USDC_BUSD,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'Cake-BUSD to USDC-BUSD Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-cake-busd'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-usdc-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit Cake-BUSD', 'Profits taken in USDC-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-cake-busd'], addressBook.lps['pancake-usdc-busd']),
  },
  {
    deprecated: true,
    address: addressBook.vaults.profitTakers.Cake_BUSD_to_USDT_BUSD,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'Cake-BUSD to USDT-BUSD Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-cake-busd'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-usdt-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit Cake-BUSD', 'Profits taken in USDT-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-cake-busd'], addressBook.lps['pancake-usdt-busd']),
  },
  {
    deprecated: true,
    address: addressBook.vaults.profitTakers.Cake_BNB_to_USDC_BUSD,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'Cake-BNB to USDC-BUSD Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-cake-bnb'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-usdc-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit Cake-BNB', 'Profits taken in USDC-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-cake-bnb'], addressBook.lps['pancake-usdc-busd']),
  },
  {
    deprecated: true,
    address: addressBook.vaults.profitTakers.Cake_BNB_to_USDT_BUSD,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'Cake-BNB to USDT-BUSD Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-cake-bnb'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-usdt-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit Cake-BNB', 'Profits taken in USDT-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-cake-bnb'], addressBook.lps['pancake-usdt-busd']),
  },
  {
    deprecated: true,
    address: addressBook.vaults.profitTakers.BTCB_BUSD_to_USDC_BUSD,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'BTCB-BUSD to USDC-BUSD Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-btcb-busd'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-usdc-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit BTCB-BUSD', 'Profits taken in USDC-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-btcb-busd'], addressBook.lps['pancake-usdc-busd']),
  },
  // {
  // 	address: addressBook.vaults.profitTakers.BTCB_BUSD_to_USDT_BUSD,
  // 	isUsingAccumulations: true,
  // 	deployments: {
  // 		vault: 'GenericAccumulatorVault',
  // 	},
  // 	chain: 'BSC',
  // 	env: ENV_TESTING,
  // 	name: 'BTCB-BUSD to USDT-BUSD Profit Taker',
  // 	depositToken: {
  // 		...addressBook.lps['pancake-btcb-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	accumulatedToken: {
  // 		...addressBook.lps['pancake-usdt-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	platforms: ['Pancake'],
  // 	type: 'Profit-Taker',
  // 	otherInfo: ['Deposit BTCB-BUSD', 'Profits taken in USDT-BUSD'],
  // 	fees: {
  // 		withdrawal: 0,
  // 		deposit: 0,
  // 		performance: 4.25,
  // 		claim: 0.1,
  // 	},
  // 	description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' , // description(addressBook.lps['pancake-btcb-busd'], addressBook.lps['pancake-usdt-busd']),
  // }
  // {
  // 	address: addressBook.vaults.profitTakers.Charge_BUSD_to_USDC_BUSD,
  // 	isUsingAccumulations: true,
  // 	deployments: {
  // 		vault: 'GenericAccumulatorVault',
  // 	},
  // 	chain: 'BSC',
  // 	env: ENV_PROD,
  // 	name: 'Charge-BUSD to USDC-BUSD Profit Taker',
  // 	depositToken: {
  // 		...addressBook.lps['pancake-charge-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	accumulatedToken: {
  // 		...addressBook.lps['pancake-usdc-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	platforms: ['Pancake'],
  // 	type: 'Profit-Taker',
  // 	otherInfo: ['Deposit Charge-BUSD', 'Profits taken in USDC-BUSD'],
  // 	fees: {
  // 		withdrawal: 0,
  // 		deposit: 0,
  // 		performance: 4.25,
  // 		claim: 0.1,
  // 	},
  // 	description:
  // 		'Charge-BUSD is deposited into the farm. Yield is sold to take profits in USDC-BUSD and deposited in an auto-compounding Bank. The Bank autocompounds into more USDC-BUSD unless funds are claimed or withdrawn.',
  // },
  // {
  // 	address: addressBook.vaults.profitTakers.Charge_BUSD_to_USDT_BUSD,
  // 	isUsingAccumulations: true,
  // 	deployments: {
  // 		vault: 'GenericAccumulatorVault',
  // 	},
  // 	chain: 'BSC',
  // 	env: ENV_PROD,
  // 	name: 'Charge-BUSD to USDT-BUSD Profit Taker',
  // 	depositToken: {
  // 		...addressBook.lps['pancake-charge-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	accumulatedToken: {
  // 		...addressBook.lps['pancake-usdt-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	platforms: ['Pancake'],
  // 	type: 'Profit-Taker',
  // 	otherInfo: ['Deposit Charge-BUSD', 'Profits taken in USDT-BUSD'],
  // 	fees: {
  // 		withdrawal: 0,
  // 		deposit: 0,
  // 		performance: 4.25,
  // 		claim: 0.1,
  // 	},
  // 	description:
  // 		'Charge-BUSD is deposited into the farm. Yield is sold to take profits in USDT-BUSD and deposited in an auto-compounding Bank. The Bank autocompounds into more USDT-BUSD unless funds are claimed or withdrawn.',
  // },

  {
    deprecated: true,
    address: addressBook.vaults.profitTakers.WOM_BUSD_to_USDC_BUSD,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'WOM-BUSD to USDC-BUSD Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-wom-busd'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-usdc-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit WOM-BUSD', 'Profits taken in USDC-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-wom-busd'], addressBook.lps['pancake-usdc-busd']),
  },

  {
    deprecated: true,
    address: addressBook.vaults.profitTakers.PEEL_BUSD_to_USDC_BUSD,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'PEEL-BUSD to USDC-BUSD Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-peel-busd'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-usdc-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit PEEL-BUSD', 'Profits taken in USDC-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-peel-busd'], addressBook.lps['pancake-usdc-busd']),
  },

  {
    deprecated: true,
    address: addressBook.vaults.profitTakers.TRIVIA_BNB_to_USDC_BUSD,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'TRIVIA-BNB to USDC-BUSD Profit Taker',
    depositToken: {
      ...addressBook.lps['pancake-trivia-bnb'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-usdc-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Profit-Taker',
    otherInfo: ['Deposit TRIVIA-BNB', 'Profits taken in USDC-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-trivia-bnb'], addressBook.lps['pancake-usdc-busd']),
  }
].filter((i) => featureAllowed(i.env));
