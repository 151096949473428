/* eslint-disable no-shadow */
import { useEffect, useState } from 'react';
import {
	getDefaultStrategies,
} from '../../cloned-strategies/services/strategy.service';

export const useDefaultStrategies = (tag?: any, existingStrategy?: any) => {
	const [strategies, setStrategies] = useState([]);
	const [strategy, setStrategy] = useState(null);

	useEffect(() => {
		get(tag);
	}, []);

	const get = async (tag = null) => {
		const strategies = existingStrategy ? [existingStrategy] : await getDefaultStrategies(tag);
		return tag ? setStrategy(strategies[0]) : setStrategies(strategies.length ? strategies : null);
	};

	return strategy || strategies;
};
