import React, { useState } from 'react';
import { Container, Skeleton } from '@chakra-ui/react';
import PageTour from '../../../common/PageTour';
import GenericStrategyView from '../../common/GenericStrategyView';
import { useStrategyCreator } from '../../hooks/useStrategyCreator';

type Props = {
  strategy: any;
  step: number;
  nextStep: () => any;
  prevStep: () => any;
  setStep: (e) => any;
};

function StrategyBlocksContainer({
	strategy,
	nextStep,
	prevStep,
	setStep,
	step,
}: Props) {
	const [isTourOpen, setIsTourOpen] = useState(false);
	const {
		blocks, createStrategy, isLoading,
	} = useStrategyCreator(
		strategy.tag,
		strategy,
	);

	const openTour = () => setIsTourOpen(true);

	return (
    <Container>
        <Skeleton
            isLoaded={!!blocks && !!blocks.length}
            mt={2}
            height="100%"
            width="100%"
      >
            <PageTour
                blocks={blocks}
                currentStep={step}
                nextStep={nextStep}
                isTourOpen={isTourOpen}
                setIsTourOpen={setIsTourOpen}
        />
            <GenericStrategyView
                setStep={setStep}
                step={step}
                nextStep={nextStep}
                prevStep={prevStep}
                createStrategy={createStrategy}
                blocks={blocks}
                isLoading={isLoading}
                openTour={openTour}
        />
        </Skeleton>
    </Container>
	);
}

export default StrategyBlocksContainer;
