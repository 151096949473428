import axios from 'axios';
import _ from 'lodash';
import { defaultChain, allAvailableTokens } from '../bloomify/config';

const supportedProviders = _.map(defaultChain.addressBook.platforms, (_p, pName) => pName.toLowerCase());

export const _computeLpPrice = (
	totalSupply,
	t0price,
	t0supply,
	t1price,
	t1supply,
): number => {
	const token0In1Lp = (t0supply * 1e18) / totalSupply / 1e18;
	const token1In1Lp = (t1supply * 1e18) / totalSupply / 1e18;
	return token0In1Lp * t0price + token1In1Lp * t1price;
};

export const getRemoteTokenPrices = async () => {
	let tokenPrices = JSON.parse(localStorage.getItem('remoteTokenPrices')) || {};

	 if (!tokenPrices.expiry || (tokenPrices.expiry && tokenPrices.expiry < Date.now())) {
		tokenPrices = await axios.get('https://firebasestorage.googleapis.com/v0/b/bloomify/o/config%2Fprices.json?alt=media');

		const formatTokens = (data) => {
			const result = { name: { tokens: {}, lps: {} }, address: { } };

			_.forEach(data.tokens, (token, name) => {
				result.address[token.address.toLowerCase()] = token;
				result.address[token.address.toLowerCase()].name = name;

				result.name.tokens[name] = token;
			});

			_.forEach(data.lps, (token, name) => {
				result.address[token.address.toLowerCase()] = token;
				result.address[token.address.toLowerCase()].name = name;

				result.name.lps[name] = token;
			});

		 return result;
		};

		const response = formatTokens(tokenPrices.data[defaultChain.shortName]);

		const cachedTokenPrices = { response, expiry: Date.now() + 60000 };
		localStorage.setItem('remoteTokenPrices', JSON.stringify(cachedTokenPrices));

		return response;
	 }

	 return tokenPrices.response;
};

export const fetchTokenPrice = async (tokenDetails) => {
	const tokenPrices = JSON.parse(localStorage.getItem('remoteTokenPrices')) || {};

	// if (!tokenPrices.expiry || (tokenPrices.expiry && tokenPrices.expiry < Date.now())) {
	// 	tokenPrices = await getRemoteTokenPrices();
	// } else {
	// 	tokenPrices = tokenPrices.response;
	// }

	return getTokenPrice(tokenDetails, tokenPrices.response);
};

export const getTokenPrice = (tokenDetails, tokens) => {
	if (!tokenDetails) return 0;
	if (!tokens) return 0;

	const getTokenPriceByPlatform = (nameOrAddress, platformId) => {
		const finalName = `${platformId.toLowerCase()}-${nameOrAddress.toLowerCase()}`;

		let tokenPrice = getTokenPriceByAddress(nameOrAddress);

		if (!tokenPrice) tokenPrice = getTokenPriceByName(finalName, true);

		return tokenPrice;
	};

	const getTokenPriceByName = (tokenName, isLp?) => {
		let token = null;

		if (isLp) token = tokens.name.lps[tokenName.toLowerCase()];

		else {
			const lpPlatform = supportedProviders.find((p) => tokenName.includes(`${p}-`));
			token = lpPlatform ? tokens.name.lps[tokenName.toLowerCase()] : (tokens.name.tokens[tokenName.toUpperCase()] || tokens.name.tokens[tokenName.toLowerCase()]);
		}

		return token ? token.price : null;
	};

	const getTokenPriceByAddress = (tokenAddress) => {
		let token = null;

		token = tokens.address ? tokens.address[tokenAddress.toLowerCase()] : null;

		return token ? token.price : null;
	};

	const getTokenPriceByDetails = (details) => {
		if (!details) return null;

		if (details.symbol === 'PULSE') return 0;

		if (details.address && details.address.toLowerCase() === defaultChain.nativeTokenAddress.toLowerCase()) return getTokenPriceByName(defaultChain.nativeToken.toUpperCase());

		if (details.platformId && details.address) return getTokenPriceByPlatform(details.address, details.platformId);

		if (details.platformId && (details.symbol || details.name) && !details.address) return getTokenPriceByPlatform((details.symbol || details.name), details.platformId);

		if (details.address) return getTokenPriceByAddress(details.address);

		if (!details.address && (details.symbol || details.name)) return getTokenPriceByName((details.symbol || details.name));
	};

	if (_.isString(tokenDetails)) {
		if (tokenDetails.toLowerCase().includes('pulse')) return 0;

		const stringToken = tokenDetails === 'native' ? defaultChain.nativeTokenAddress : tokenDetails;
		const addressBookToken = getTokenDetails(stringToken);

		return getTokenPriceByDetails(addressBookToken);
	}

	return getTokenPriceByDetails(tokenDetails);
};

export const getTokenDetails = (nameOrAddressOrDetails, platformId?) => {
	let stringToken = '';

	if (_.isString(nameOrAddressOrDetails)) {
		stringToken = nameOrAddressOrDetails.toLowerCase() === 'native'
		|| nameOrAddressOrDetails === defaultChain.nativeToken.toLowerCase() ? defaultChain.nativeTokenAddress : nameOrAddressOrDetails;
	} else {
		stringToken = nameOrAddressOrDetails.address.toLowerCase() || nameOrAddressOrDetails.symbol || nameOrAddressOrDetails.name;
		platformId = nameOrAddressOrDetails.platformId || null;
	}

	const lpPlatform = platformId || supportedProviders.find((p) => stringToken.includes(`${p}-`));

	const addressBookToken = allAvailableTokens.find((t) => (t.address.toLowerCase() === stringToken.toLowerCase()
		|| t.symbol.toLowerCase() === stringToken.toLowerCase()
		|| `${lpPlatform}-${t.symbol.toLowerCase()}` === stringToken.toLowerCase()
		|| `${lpPlatform}-${t.symbol.toLowerCase()}` === `${lpPlatform}-${stringToken.toLowerCase()}`
		|| t.name.toLowerCase() === stringToken.toLowerCase()) && (lpPlatform ? t.platformId.toLowerCase() === lpPlatform.toLowerCase() : true));

	return addressBookToken;
};

export const getTokenPlatformDetails = (nameOrAddressOrDetails, platformId?) => {
	const token = getTokenDetails(nameOrAddressOrDetails, platformId);

	return token.platformId ? _.find(defaultChain.addressBook.platforms, (_p, pName) => pName.toLowerCase() === token.platformId) : null;
};
