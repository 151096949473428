/* eslint-disable no-shadow */
/* eslint-disable no-mixed-operators */
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { useBoolean } from '@chakra-ui/react';
import _ from 'lodash';
import useWeb3 from '../../../hooks/useWeb3';
import { getClonedStrategyBlocks, getClonedStrategyAllBlocksInfo } from '../services/clonedStrategies.service';
import {
	estimateRunStrategyGas,
	getClonedStrategyInfo,
	updateStrategySettings,
	withdrawStrategyFunds,
	withdrawStrategyProfit,
} from '../services/strategy.service';
import { useTokenPrices } from '../../../contexts/TokenPricesContext';
import { getGenericStrategy } from '../../../service/strategy_data.service';
import { defaultChain } from '../../../bloomify/config';
import { useDidUpdate } from '../../../hooks/useDidUpdate';
import { strategyGenerateContractBlockSettings } from '../../../helpers/util';

export const useClonedStrategyDetails = (legoHash: string, tag: string, fetchedStrategy?: any) => {
	const strategy = getGenericStrategy(tag);

	const web3 = useWeb3();
	const { account } = useWeb3React();
	const { getTokenPrice, tokens } = useTokenPrices();

	// Containers and lists
	const [strategyInfo, setStrategyInfo] = useState<any>(fetchedStrategy);
	const [blocksInfo, setBlocksInfo] = useState<Array<any>>();
	const [blockSettingsList, setBlockSettingsList] = useState<Array<any>>();
	const [settingsCopy, setSettingsCopy] = useState<any>();

	// Info related
	const [totalDeposit, setTotalDeposit] = useState<number>();
	const [totalProfit, setTotalProfit] = useState<number>();
	const [pendingRewards, setPendingRewards] = useState<number>();
	const [isBoardroom, setIsBoardroom] = useState(false);
	const [canWithdrawAll, setCanWithdrawAll] = useState<boolean>(false);
	const [runnableCheck, setRunnableCheck] = useState<any>({ isRunnable: true });

	// Action related
	const [isWithdrawingProfit, setIsWithdrawingProfit] = useBoolean(false);
	const [isWithdrawingAll, setIsWithdrawingAll] = useBoolean(false);
	const [isEditingSetting, setIsEditingSettings] = useBoolean(false);
	const [isUpdatingSettings, setIsUpdatingSetting] = useBoolean(false);

	const cancelEditing = () => {
		setBlockSettingsList(settingsCopy);
		setIsEditingSettings.off();
	};

	// Checks to see if a strategy is runnable by checking:
	// if the BR is claimable, strategy is profitable and if there is a deposit
	const checkStrategyRunnable = (isBrHarvestable: boolean, isProfitable: boolean, isDeposit: boolean, isDeprecated: boolean) => {
		if (isDeprecated) {
			setRunnableCheck({
				isRunnable: false,
				reason: 'The underlying strategy has been archived. Withdraw your funds and recreate this strategy or move your funds to another strategy.',
			});
		} else if (!isDeposit) {
			setRunnableCheck({ isRunnable: false, reason: 'There are no funds deposited, deposit to continue' });
		} else if (!isBrHarvestable) {
			setRunnableCheck({ isRunnable: false, reason: 'Boardroom is locked, wait until unlock period, see countdown timer' });
		} else if (!isProfitable) {
			setRunnableCheck({ isRunnable: true, reason: 'Run gas exceeds estimated rewards, wait until rewards > gas' });
		} else {
			setRunnableCheck({ isRunnable: true });
		}
	};

	// Withdraws profit only
	const withdrawProfit = async () => {
		setIsWithdrawingProfit.on();
		const response = await withdrawStrategyProfit(web3, strategyInfo.strategyAddress, account, setIsWithdrawingProfit.off);
		if (response.status) {
			await getDetails(true);
		}
		setIsWithdrawingProfit.off();
	};

	// Withdraws all
	const withdrawAll = async () => {
		setIsWithdrawingAll.on();
		const response = await withdrawStrategyFunds(web3, strategyInfo.strategyAddress, account, setIsWithdrawingAll.off);
		if (response.status) {
			await getDetails(true);
		}
		setIsWithdrawingAll.off();
	};

	// Gets the details of the strategy instance and the individual blocks
	const getDetails = async (invalidateCache = false) => {
		if (account && tokens.name && !!getTokenPrice('STATIC')) {
			const blocks = await getClonedStrategyBlocks(web3, legoHash, account); // Get blocks for lego hash
			const blocksInfo = await getClonedStrategyAllBlocksInfo(web3, blocks); // Get the blocks details for blocks array

			const strategyInfo = !invalidateCache ? (fetchedStrategy || await getClonedStrategyInfo(web3, legoHash, tag))
				: await getClonedStrategyInfo(web3, legoHash, tag); // Get the strategy instance details

			// Only BR block has a isHarvestable boolean
			const isBrHarvestable = blocksInfo.map((i) => i.harvestable || i.harvestable === null).includes(true);

			//  Total pending rewards from blocks array. Computed dollar price with the current price of the token
			const pendingRewards = blocksInfo.map((block) => (block.blockPendingRewards ? (block.blockPendingRewards.length > 1
				? block.blockPendingRewards.reduce((a, b) => (a.hex / 1e18 * getTokenPrice('CHARGE')) + (b.hex / 1e18 * getTokenPrice('STATIC')))
				: block.blockPendingRewards[0].hex / 1e18 * getTokenPrice(block.outToken.toLowerCase())) : 0)).reduce((a, b) => a + b) || 0;

			// Boolean that checks if all deposits can be withdrawn not pending any BRs
			const canWithdrawAll = blocksInfo.map((i) => i.withdrawable || i.withdrawable === null).includes(true);
			setCanWithdrawAll(canWithdrawAll);

			// Check if it contains a boardroom block
			blocksInfo.map((i) => setIsBoardroom(i.lastEpochClaimed !== false));

			// Getting the percentages on each of the actions and turning them into a single array of form [20,20,60]
			const strategySetting = strategyInfo.settings.map(
				(i, key) => strategyInfo.settings[key].map((i) => i[2].hex / 1e2),
			);

			setSettingsCopy(strategySetting);
			setBlockSettingsList(strategySetting);

			// Stats calculated here
			setPendingRewards(pendingRewards);

			// Calculates total deposit tokens and multiplies them with token price to get dollar amount

			const totalDeposit = blocksInfo.map((i) => (
				i.blockBalance / 1e18 * getTokenPrice(i.depositToken))).reduce((a:any, b:any) => a + b);

			setTotalDeposit(totalDeposit || 0);

			const estimatedRunGas = await getRunEstimatedGas(legoHash);
			checkStrategyRunnable(isBrHarvestable, pendingRewards > estimatedRunGas, totalDeposit > 0, strategyInfo.deprecated);

			// Calculates total profit tokens and multiples them with token price to get dollar amount
			setTotalProfit((parseInt(strategyInfo.profitBalance.hex, 16) / 1e18 * getTokenPrice(strategyInfo.profitToken)));
			setStrategyInfo(strategyInfo);

			// Array of block details object combined with the strategy details
			const result = blocksInfo.map((i:any, key:number) => ({ ...strategyInfo.blocks[key], ...i, blockHash: blocks[key] }));

			setBlocksInfo(result);
		}
	};

	const updateSettings = async () => {
		setIsUpdatingSetting.on();

		let percentages = [];
		percentages = strategyGenerateContractBlockSettings(strategyInfo, blockSettingsList);
		strategy.setPercentages(percentages);

		const response = await updateStrategySettings(web3, legoHash, strategy.data.settings, account, setIsUpdatingSetting.off);

		if (response.status) {
			await getDetails(true);
			setIsEditingSettings.off();
			setIsUpdatingSetting.off();
		}
	};

	const getRunEstimatedGas = async (strategyAddr: string) => {
		const h = await estimateRunStrategyGas(web3, strategyAddr, account);
		return h * getTokenPrice(defaultChain.nativeTokenAddress);
	};

	useDidUpdate(() => {
		getDetails(true);
	}, [account]);

	useEffect(() => {
		getDetails();
	}, [account]);

	useEffect(() => {
		getDetails();
	}, [tokens]);

	return {
		isWithdrawingProfit,
		isWithdrawingAll,
		isUpdatingSettings,
		isEditingSetting,
		setIsEditingSettings,
		blockSettingsList,
		setBlockSettingsList,
		settingsCopy,
		setSettingsCopy,
		isBoardroom,
		canWithdrawAll,
		runnableCheck,
		strategyInfo,
		withdrawProfit,
		withdrawAll,
		getDetails,
		updateSettings,
		cancelEditing,
		blocksInfo,
		totalDeposit,
		totalProfit,
		pendingRewards,
	};
};
