/* eslint-disable dot-notation */
/* eslint-disable no-else-return */
import { ContractCallContext } from 'ethereum-multicall';
import Web3 from 'web3';
import { BigNumber } from 'ethers';
import { formatEther, parseUnits } from 'ethers/lib/utils';
import _ from 'lodash';
import { defaultChain } from '../../../bloomify/config';
import { doMulticall } from '../../../service/multicall.service';
import {
	onConfirmation,
	onError,
	onReceipt,
	onSending,
	onSent,
	onTransactionHash,
} from '../../../utils/web3Handlers';
import { fetchTokenPrice } from '../../../service/tokens.service';
import { getExternallyCalculatedStatistics } from '../../../service/remote.service';
import {getGasPriceOptions} from "../../../utils/gasUtils";
import {ga} from "react-ga";

const _generateVaultCallsContext = (
	vaultObject: any,
	walletAddress?,
): ContractCallContext[] => {
	if (!vaultObject.isUsingAccumulations) {
		const context = [
			{
				reference: 'getPricePerFullShare',
				contractAddress: vaultObject.address,
				abi: defaultChain.deployments[vaultObject.deployments.vault].abi,
				calls: [
					{
						reference: 'getPricePerFullShare',
						methodName: 'getPricePerFullShare',
						methodParameters: [],
					},
				],
			},
		];

		if (walletAddress) {
			context.push({
				reference: 'balanceOf',
				contractAddress: vaultObject.address,
				abi: defaultChain.deployments[vaultObject.deployments.vault].abi,
				calls: [
					{
						reference: 'balanceOf',
						methodName: 'balanceOf',
						methodParameters: [walletAddress],
					},
				],
			});
		}

		return context;
	}

	const context = [
		{
			reference: 'lastHarvest',
			contractAddress: vaultObject.address,
			abi: defaultChain.deployments[vaultObject.deployments.vault].abi,
			calls: [
				{
					reference: 'lastHarvest',
					methodName: 'lastRunTime',
					methodParameters: [],
				},
			],
		},
	];

	if (walletAddress) {
		context.push({
			reference: 'getUserBalances',
			contractAddress: vaultObject.address,
			abi: defaultChain.deployments[vaultObject.deployments.vault].abi,
			calls: [
				{
					reference: 'getUserBalances',
					methodName: 'getUserBalances',
					methodParameters: [walletAddress],
				},
			],
		});
	}

	return context;
};

// const _generateTVLVaultContext = (vaultObject) => [
// 	{
// 		reference: 'depositToken',
// 		contractAddress: vaultObject.address,
// 		abi: defaultChain.deployments[vaultObject.deployments.vault].abi,
// 		calls: [
// 			{
// 				reference: 'depositToken',
// 				methodName: 'want',
// 				methodParameters: [],
// 			},
// 		],
// 	},
// 	{
// 		reference: 'TVLBalance',
// 		contractAddress: vaultObject.strategyAddress,
// 		abi: defaultChain.deployments[vaultObject.deployments.strategy].abi,
// 		calls: [
// 			{
// 				reference: 'TVLBalance',
// 				methodName: 'balanceOf',
// 				methodParameters: [],
// 			},
// 		],
// 	}];

export const getVaultAPR = async (vaultAddress) => {
	let generalStats = JSON.parse(localStorage.getItem('calculatedStats'));

	if (!generalStats) {
		await getExternallyCalculatedStatistics();
		generalStats = JSON.parse(localStorage.getItem('calculatedStats'));
	}

	generalStats = generalStats ? generalStats.result : null;

	let calculatedVaultApr = generalStats ? generalStats.aprs[vaultAddress] : null;

	if (!calculatedVaultApr && generalStats) calculatedVaultApr = _.find(generalStats.aprs, (_val, addr) => vaultAddress.toLowerCase() === addr.toLowerCase());

	return calculatedVaultApr;
};

export const getVaultTVL = async (vaultAddress) => {
	let generalStats = JSON.parse(localStorage.getItem('calculatedStats'));

	if (!generalStats) {
		await getExternallyCalculatedStatistics();
		generalStats = JSON.parse(localStorage.getItem('calculatedStats'));
	}

	generalStats = generalStats ? generalStats.result : null;

	let calculatedVaultTVL = generalStats ? generalStats.tvls[vaultAddress] : null;

	if (!calculatedVaultTVL && generalStats) calculatedVaultTVL = _.find(generalStats.tvls, (_val, addr) => vaultAddress.toLowerCase() === addr.toLowerCase());

	return calculatedVaultTVL;
};

export const fetchVaults = async (
	web3: Web3,
	vaultAddress?: string,
	walletAddress?: string,
	type?: string,
): Promise<Array<any>> => {
	let vaults = vaultAddress
		? [
			defaultChain.vaults.find(
				(s) => s.address.toLowerCase() === vaultAddress.toLowerCase(),
			),
		]
		: defaultChain.vaults;

	if (!vaultAddress) {
		switch (type) {
			case 'vault':
				vaults = vaults.filter((v) => v.type.toLowerCase().includes('vault'));
				break;

			case 'accumulator':
				vaults = vaults.filter((v) => v.type.toLowerCase().includes('accumulator'));
				break;

			case 'profit':
				vaults = vaults.filter((v) => v.type.toLowerCase().includes('profit'));
				break;

			default:
				vaults = vaults.filter((v) => v.type.toLowerCase().includes('vault'));
				break;
		}
	}

	const fetchedVaults = await Promise.all(
		vaults
			.filter((x) => x)
			.map(async (v) => {
				const depositTokenPrice = await fetchTokenPrice(v.depositToken);
				const accumulatorTokenPrice = v.isUsingAccumulations ? await fetchTokenPrice(v.accumulatedToken) : 0;
				const vaultContext = _generateVaultCallsContext(v, walletAddress);
				const response = await doMulticall(web3, vaultContext);
				const tvl = await getVaultTVL(v.address);
				const aprDetails = await getVaultAPR(v.address);

				const vaultObject = {
					...v,
					depositToken: {
						...v.depositToken,
						price: depositTokenPrice,

					},
					tvl,
					aprDetails,
					dailyRate: aprDetails ? aprDetails.totalApr / 365 : null,
				};

				if (v.isUsingAccumulations) {
					const depositBalanceAmount = response.getUserBalances ? response.getUserBalances[0].hex / Math.pow(10, v.depositToken.decimals || 18) : 0;
					const accumulatedBalanceAmount = response.getUserBalances ? response.getUserBalances[1].hex / Math.pow(10, v.accumulatedToken.decimals || 18) : 0;

					return {
						...vaultObject,
						depositDetails: {
							amount: depositBalanceAmount,
							value: depositTokenPrice * depositBalanceAmount,
							hex: response.getUserBalances ? response.getUserBalances[0].hex : '0x00',
						},
						accumulatedDetails: {
							amount: accumulatedBalanceAmount,
							value: accumulatorTokenPrice * accumulatedBalanceAmount,
							hex: response.getUserBalances ? response.getUserBalances[1].hex : '0x00',
						},
						lastHarvest: parseInt(response.lastHarvest[0].hex, 16),
						shouldDisplay: !vaultObject.deprecated || depositBalanceAmount || accumulatedBalanceAmount
					};
				} else {
					const pricePerFullShare = Number(formatEther(response.getPricePerFullShare[0].hex));
					const balance = response.balanceOf && response.balanceOf[0]
						? Number(formatEther(BigNumber.from(response.balanceOf[0].hex)
							.mul(response.getPricePerFullShare[0].hex).div(1e9).div(1e9))) : 0;

					return {
						...vaultObject,
						pricePerFullShare,
						depositDetails: {
							amount: balance,
							value: depositTokenPrice * balance,
							hex: response.balanceOf && response.balanceOf[0] ? BigNumber.from(response.balanceOf[0].hex)
								.mul(response.getPricePerFullShare[0].hex).div(1e9).div(1e9)._hex : BigNumber.from(0)._hex,
						},
						shouldDisplay: !vaultObject.deprecated || balance
					};
				}
			})
	);

	const ids = _.map(fetchedVaults, (v) => v['address']);
	if (!ids) return fetchedVaults;

	const remainings = _.filter(JSON.parse(localStorage.getItem('fetchedVaults')), (v) => !ids.includes(v.address));
	const cached = _.uniqBy([...fetchedVaults, ...remainings], 'address');

	localStorage.setItem('fetchedVaults', JSON.stringify(cached));

	return fetchedVaults;
};

// export const getValueInStableForCustomAmounts = async (
// 	web3,
// 	tokenAddress,
// 	customHexAmount,
// 	tokenPrices,
// ) => {
// 	const customAmountTokensContex = getSingleTokenContextWithCustomAmounts(
// 		tokenAddress,
// 		customHexAmount,
// 	);

// 	if (customAmountTokensContex.context) {
// 		const response = await doMulticall(web3, customAmountTokensContex.context, true);

// 		return BigNumber.from(
// 			response[customAmountTokensContex.reference][
// 				customAmountTokensContex.index
// 			].hex,
// 		)
// 			.div(1e9)
// 			.div(1e9); // after we have a case we need to see if we should add div(1e18)
// 	} if (customAmountTokensContex.lp) {
// 		return parseUnits(
// 			tokenPrices.address[tokenAddress.toLowerCase()].toString(),
// 		)
// 			.mul(BigNumber.from(customHexAmount))
// 			.div(1e9)
// 			.div(1e9);
// 	}

// 	return BigNumber.from(0);
// };

export const depositAmountInVault = async (
	web3: Web3,
	amount: BigNumber,
	vaultAddress: string,
	walletAddr: string,
	errorCallback?: any,
	onReceiptCallback?: any,
	token?: any
) => {
	const vault = defaultChain.vaults.find((v) => v.address.toLowerCase() === vaultAddress.toLowerCase());

	const vaultContract = new web3.eth.Contract(
		defaultChain.deployments[vault.deployments.vault].abi,
		vaultAddress,
		{ from: walletAddr },
	);
	const method = !vault.isUsingAccumulations ? vaultContract.methods
		.deposit(amount) : vaultContract.methods.deposit(token?.address || vault.depositToken.address, amount, 0, 0);
	const gasPrices = await getGasPriceOptions(web3);
	return await method
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceiptCallback || onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', errorCallback || onError);
};

export const depositAllInVault = async (
	web3: Web3,
	vaultAddress: string,
	walletAddr: string,
	errorCallback?: any,
	onReceiptCallback?: any,
) => {
	const vault = defaultChain.vaults.find((v) => v.address.toLowerCase() === vaultAddress.toLowerCase());

	const vaultContract = new web3.eth.Contract(
		defaultChain.deployments[vault.deployments.vault].abi,
		vaultAddress,
		{ from: walletAddr },
	);

	const gasPrices = await getGasPriceOptions(web3);
	return await vaultContract.methods
		.depositAll()
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceiptCallback || onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', errorCallback || onError);
};

export const withdrawDepositedAmountFromVault = async (
	web3: Web3,
	amount: BigNumber,
	vaultAddress: string,
	walletAddr: string,
	errorCallback?: any,
	onReceiptCallback?: any,
) => {
	const vaults = JSON.parse(localStorage.getItem('fetchedVaults'));
	const vault = vaults.find((v) => v.address.toLowerCase() === vaultAddress.toLowerCase());

	const vaultContract = new web3.eth.Contract(
		defaultChain.deployments[vault.deployments.vault].abi,
		vaultAddress,
		{ from: walletAddr },
	);

	const finalAmount = !vault.isUsingAccumulations ? amount.mul(1e9).mul(1e9).div(parseUnits(vault.pricePerFullShare.toString())) : amount;
	const method = !vault.isUsingAccumulations ? vaultContract.methods.withdraw(finalAmount) : vaultContract.methods.withdraw(0, finalAmount);

	const gasPrices = await getGasPriceOptions(web3);
	return await method
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceiptCallback || onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', errorCallback || onError);
};

export const claimAllAccumulatedTokensFromVault = async (
	web3: Web3,
	vaultAddress: string,
	walletAddr: string,
) => {
	const vaults = JSON.parse(localStorage.getItem('fetchedVaults'));
	const vault = vaults.find((v) => v.address.toLowerCase() === vaultAddress.toLowerCase());

	const vaultContract = new web3.eth.Contract(
		defaultChain.deployments[vault.deployments.vault].abi,
		vaultAddress,
		{ from: walletAddr },
	);

	const gasPrices = await getGasPriceOptions(web3);
	return await vaultContract.methods.withdraw(1, 0)
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', onError);
};

export const withdrawAllFromVault = async (
	web3: Web3,
	vaultAddress: string,
	walletAddr: string,
	errorCallback?: any,
	onReceiptCallback?: any,
) => {
	const vault = defaultChain.vaults.find((v) => v.address.toLowerCase() === vaultAddress.toLowerCase());

	const vaultContract = new web3.eth.Contract(
		defaultChain.deployments[vault.deployments.vault].abi,
		vaultAddress,
		{ from: walletAddr },
	);

	const fetchedVaults = JSON.parse(localStorage.getItem('fetchedVaults'));
	const fetchedVault = fetchedVaults.find((v) => v.address.toLowerCase() === vaultAddress.toLowerCase());
	const method = vault.isUsingAccumulations ? vaultContract.methods
		.withdraw(0, BigNumber.from(fetchedVault.depositDetails.hex)) : vaultContract.methods.withdrawAll();

	const gasPrices = await getGasPriceOptions(web3);
	return await method
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceiptCallback || onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', errorCallback || onError);
};
