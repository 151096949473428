export const TAKE_PROFIT_ID = 1;
export const AUTOCOMPOUND_ID = 2;
export const REINVEST_ID = 3;

export const ACTION_COLOR_MAP = { // based on action id above
	1: 'green',
	2: 'pink',
	3: 'cyan',
};

export const DEFAULT_PERCENTAGE_TAKE_PROFIT = 2000;
export const DEFAULT_PERCENTAGE_AUTOCOMPOUND = 4000;
export const DEFAULT_PERCENTAGE_REINVEST = 4000;
