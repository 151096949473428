/* eslint-disable no-sparse-arrays */
/* eslint-disable max-len */
import { addressBook } from './addressbook.config';
import { ENV_PROD, ENV_TESTING, featureAllowed } from '../../env';

export const description = (tokenA, tokenB) => `${tokenA.name} is deposited into the farm. Yield is sold to accumulate ${tokenB.name} and deposited in an auto-compounding Bank.
The Bank autocompounds into more ${tokenB.name} unless funds are claimed or withdrawn.
This strategy automatically harvests your yield from ${tokenA.name} and buys into ${tokenB.name} at regular intervals ensuring you are taking advantage of lowering your average entry price.`;

export const holdDescription = (tokenA, tokenB) => `${tokenA.name} is deposited into the farm. Yield is sold to accumulate ${tokenB.name}.
This strategy automatically harvests your yield from ${tokenA.name} and buys into ${tokenB.name} at regular intervals ensuring you are taking advantage of lowering your average entry price.`;

export const deployedAccumulators = [
  {
    address: addressBook.vaults.accumulators.USDT_BUSD_StableLP_to_ETH_BNB,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDT-BUSD to ETH-BNB Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-stable-usdt-busd'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-eth-bnb'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDT-BUSD', 'Accumulate ETH-BNB'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-stable-usdt-busd'], addressBook.lps['pancake-eth-bnb']),
  },

  {
    address: addressBook.vaults.accumulators.USDT_BUSD_StableLP_to_WOM_BUSD,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDT-BUSD to WOM-BUSD Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-stable-usdt-busd'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-wom-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDT-BUSD', 'Accumulate WOM-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-stable-usdt-busd'], addressBook.lps['pancake-wom-busd']),
  },

  {
    address: addressBook.vaults.accumulators.USDT_BUSD_StableLP_to_Cake_BUSD,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDT-BUSD to Cake-BUSD Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-stable-usdt-busd'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-cake-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDT-BUSD', 'Accumulate Cake-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-stable-usdt-busd'], addressBook.lps['pancake-cake-busd']),
  },
  {
    address: addressBook.vaults.accumulators.USDT_BUSD_StableLP_to_BNB_VENUS,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDT-BUSD to BNB Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-stable-usdt-busd'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.tokens.WBNB,
      isLP: false,
      canZap: false
    },
    platforms: ['Pancake', 'Beefy', 'Venus'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDT-BUSD', 'Accumulate BNB'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-stable-usdt-busd'], addressBook.tokens.WBNB),
  },

  {
    address: addressBook.vaults.accumulators.USDT_USDC_StableLP_to_ETH_BNB,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDC-USDT to ETH-BNB Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-stable-usdt-usdc'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-eth-bnb'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDC-USDT', 'Accumulate ETH-BNB'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-stable-usdt-usdc'], addressBook.lps['pancake-eth-bnb']),
  },

  {
    address: addressBook.vaults.accumulators.USDT_USDC_StableLP_to_WOM_BUSD,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDC-USDT to WOM-BUSD Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-stable-usdt-usdc'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-wom-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDC-USDT', 'Accumulate WOM-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-stable-usdt-usdc'], addressBook.lps['pancake-wom-busd']),
  },

  {
    address: addressBook.vaults.accumulators.USDT_USDC_StableLP_to_CAKE_BUSD,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDC-USDT to CAKE-BUSD Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-stable-usdt-usdc'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-cake-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDC-USDT', 'Accumulate Cake-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-stable-usdt-usdc'], addressBook.lps['pancake-cake-busd']),
  },

  {
    address: addressBook.vaults.accumulators.USDT_USDC_StableLP_to_BNB_VENUS,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDC-USDT to BNB Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-stable-usdt-usdc'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.tokens.WBNB,
      isLP: false,
      canZap: false
    },
    platforms: ['Pancake', 'Beefy', 'Venus'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDC-USDT', 'Accumulate BNB'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-stable-usdt-usdc'], addressBook.tokens.WBNB),
  },

  {
    deprecated: true,
    address: addressBook.vaults.accumulators.USDT_BUSD_to_ETH_BNB,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDT-BUSD to ETH-BNB Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-usdt-busd'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-eth-bnb'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDT-BUSD', 'Accumulate ETH-BNB'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-usdt-busd'], addressBook.lps['pancake-eth-bnb']),
  },

  // {
  // 	address: addressBook.vaults.accumulators.USDC_BUSD_to_ETH_BNB,
  // 	isUsingAccumulations: true,
  // 	deployments: {
  // 		vault: 'GenericAccumulatorVault',
  // 	},
  // 	chain: 'BSC',
  // 	env: ENV_PROD,
  // 	name: 'USDC-BUSD to ETH-BNB Accumulator',
  // 	depositToken: {
  // 		...addressBook.lps['pancake-usdc-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	accumulatedToken: {
  // 		...addressBook.lps['pancake-eth-bnb'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	platforms: ['Pancake'],
  // 	type: 'Accumulator',
  // 	otherInfo: ['Deposit USDC-BUSD', 'Accumulate ETH-BNB'],
  // 	fees: {
  // 		withdrawal: 0,
  // 		deposit: 0,
  // 		performance: 4.25,
  // 		claim: 0.1,
  // 	},
  // 	description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' , // description(addressBook.lps['pancake-usdc-busd'], addressBook.lps['pancake-eth-bnb']),
  // },

  {
    deprecated: true,
    address: addressBook.vaults.accumulators.USDT_BUSD_to_Cake_BNB,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDT-BUSD to Cake-BNB Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-usdt-busd'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-cake-bnb'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDT-BUSD', 'Accumulate Cake-BNB'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-usdt-busd'], addressBook.lps['pancake-cake-bnb']),
  },
  // {
  // 	address: addressBook.vaults.accumulators.USDC_BUSD_to_Cake_BNB,
  // 	isUsingAccumulations: true,
  // 	deployments: {
  // 		vault: 'GenericAccumulatorVault',
  // 	},
  // 	chain: 'BSC',
  // 	env: ENV_TESTING,
  // 	name: 'USDC-BUSD to Cake-BNB Accumulator',
  // 	depositToken: {
  // 		...addressBook.lps['pancake-usdc-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	accumulatedToken: {
  // 		...addressBook.lps['pancake-cake-bnb'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	platforms: ['Pancake'],
  // 	type: 'Accumulator',
  // 	otherInfo: ['Deposit USDC-BUSD', 'Accumulate Cake-BNB'],
  // 	fees: {
  // 		withdrawal: 0,
  // 		deposit: 0,
  // 		performance: 4.25,
  // 		claim: 0.1,
  // 	},
  // 	description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' , // description(addressBook.lps['pancake-usdc-busd'], addressBook.lps['pancake-cake-bnb']),
  // },
  {
    deprecated: true,
    address: addressBook.vaults.accumulators.USDT_BUSD_to_Cake_BUSD,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDT-BUSD to Cake-BUSD Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-usdt-busd'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-cake-busd'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDT-BUSD', 'Accumulate Cake-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-usdt-busd'], addressBook.lps['pancake-cake-busd']),
  },
  // {
  // 	address: addressBook.vaults.accumulators.USDC_BUSD_to_Cake_BUSD,
  // 	isUsingAccumulations: true,
  // 	deployments: {
  // 		vault: 'GenericAccumulatorVault',
  // 	},
  // 	chain: 'BSC',
  // 	env: ENV_TESTING,
  // 	name: 'USDC-BUSD to Cake-BUSD Accumulator',
  // 	depositToken: {
  // 		...addressBook.lps['pancake-usdc-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	accumulatedToken: {
  // 		...addressBook.lps['pancake-cake-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	platforms: ['Pancake'],
  // 	type: 'Accumulator',
  // 	otherInfo: ['Deposit USDC-BUSD', 'Accumulate Cake-BUSD'],
  // 	fees: {
  // 		withdrawal: 0,
  // 		deposit: 0,
  // 		performance: 4.25,
  // 		claim: 0.1,
  // 	},
  // 	description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' , // description(addressBook.lps['pancake-usdc-busd'], addressBook.lps['pancake-cake-busd']),
  // },
  // {
  // 	address: addressBook.vaults.accumulators.USDT_BUSD_to_Charge_BUSD,
  // 	isUsingAccumulations: true,
  // 	deployments: {
  // 		vault: 'GenericAccumulatorVault',
  // 	},
  // 	chain: 'BSC',
  // 	env: ENV_PROD,
  // 	name: 'USDT-BUSD to Charge-BUSD Accumulator',
  // 	depositToken: {
  // 		...addressBook.lps['pancake-usdt-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	accumulatedToken: {
  // 		...addressBook.lps['pancake-charge-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	platforms: ['Pancake'],
  // 	type: 'Accumulator',
  // 	otherInfo: ['Deposit USDT-BUSD', 'Accumulate Charge-BUSD'],
  // 	fees: {
  // 		withdrawal: 0,
  // 		deposit: 0,
  // 		performance: 4.25,
  // 		claim: 0.1,
  // 	},
  // 	description:
  // 		'USDT-BUSD is deposited into the farm. Yield is sold to accumulate Charge-BUSD and deposited in an auto-compounding Bank. The Bank autocompounds into more BTCB-ETH unless funds are claimed or withdrawn. This strategy automatically harvests your yield from USDT-BUSD and buys into BTCB-ETH at regular intervals ensuring you are taking advantage of lowering your average entry price.',
  // },
  // {
  // 	address: addressBook.vaults.accumulators.USDC_BUSD_to_Charge_BUSD,
  // 	isUsingAccumulations: true,
  // 	deployments: {
  // 		vault: 'GenericAccumulatorVault',
  // 	},
  // 	chain: 'BSC',
  // 	env: ENV_PROD,
  // 	name: 'USDC-BUSD to Charge-BUSD Accumulator',
  // 	depositToken: {
  // 		...addressBook.lps['pancake-usdc-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	accumulatedToken: {
  // 		...addressBook.lps['pancake-charge-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	platforms: ['Pancake'],
  // 	type: 'Accumulator',
  // 	otherInfo: ['Deposit USDC-BUSD', 'Accumulate Charge-BUSD'],
  // 	fees: {
  // 		withdrawal: 0,
  // 		deposit: 0,
  // 		performance: 4.25,
  // 		claim: 0.1,
  // 	},
  // 	description:
  // 		'USDC-BUSD is deposited into the farm. Yield is sold to accumulate Charge-BUSD and deposited in an auto-compounding Bank. The Bank autocompounds into more BTCB-ETH unless funds are claimed or withdrawn. This strategy automatically harvests your yield from USDT-BUSD and buys into BTCB-ETH at regular intervals ensuring you are taking advantage of lowering your average entry price.',
  // },
  // {
  // 	address: addressBook.vaults.accumulators.USDC_BUSD_to_BTCB_ETH,
  // 	isUsingAccumulations: true,
  // 	deployments: {
  // 		vault: 'GenericAccumulatorVault',
  // 	},
  // 	chain: 'BSC',
  // 	env: ENV_PROD,
  // 	name: 'USDC-BUSD to BTCB-ETH Accumulator',
  // 	depositToken: {
  // 		...addressBook.lps['pancake-usdc-busd'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	accumulatedToken: {
  // 		...addressBook.lps['pancake-btcb-eth'],
  // 		isLP: true,
  // 		canZap: false,
  // 	},
  // 	platforms: ['Pancake'],
  // 	type: 'Accumulator',
  // 	otherInfo: ['Deposit USDC-BUSD', 'Accumulate BTCB-ETH'],
  // 	fees: {
  // 		withdrawal: 0,
  // 		deposit: 0,
  // 		performance: 4.25,
  // 		claim: 0.1,
  // 	},
  // 	description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' , // description(addressBook.lps['pancake-usdc-busd'], addressBook.lps['pancake-btcb-eth']),
  // },
  {
    deprecated: true,
    address: addressBook.vaults.accumulators.USDT_BUSD_to_BTCB_ETH,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDT-BUSD to BTCB-ETH Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-usdt-busd'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-btcb-eth'],
      isLP: true,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDT-BUSD', 'Accumulate BTCB-ETH'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-usdt-busd'], addressBook.lps['pancake-btcb-eth']),
  },

  {
    deprecated: true,
    address: addressBook.vaults.accumulators.USDT_BUSD_to_WOM_BUSD,
    isUsingAccumulations: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDT-BUSD to WOM-BUSD Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-usdt-busd'],
      isLP: true,
      canZap: false
    },
    accumulatedToken: {
      ...addressBook.lps['pancake-wom-busd'],
      isLP: true,
      canZap: true
    },
    platforms: ['Pancake'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDT-BUSD', 'Accumulate WOM-BUSD'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // description(addressBook.lps['pancake-usdt-busd'], addressBook.lps['pancake-wom-busd']),
  },

  {
    deprecated: true,
    address: addressBook.vaults.accumulators.USDT_BUSD_to_BNB,
    isUsingAccumulations: true,
    isHoldAccumulator: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDT-BUSD to BNB Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-usdt-busd'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.tokens.WBNB,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDT-BUSD', 'Accumulate BNB'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // holdDescription(addressBook.lps['pancake-usdt-busd'], addressBook.tokens.WBNB)
  },

  {
    deprecated: true,
    address: addressBook.vaults.accumulators.USDT_BUSD_to_ETH,
    isUsingAccumulations: true,
    isHoldAccumulator: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDT-BUSD to ETH Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-usdt-busd'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.tokens.ETH,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDT-BUSD', 'Accumulate ETH'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // holdDescription(addressBook.lps['pancake-usdt-busd'], addressBook.tokens.ETH)
  },

  {
    deprecated: true,
    address: addressBook.vaults.accumulators.USDT_BUSD_to_BTC,
    isUsingAccumulations: true,
    isHoldAccumulator: true,
    deployments: {
      vault: 'GenericAccumulatorVault'
    },
    chain: 'BSC',
    env: ENV_PROD,
    name: 'USDT-BUSD to BTC Accumulator',
    depositToken: {
      ...addressBook.lps['pancake-usdt-busd'],
      isLP: true,
      canZap: true
    },
    accumulatedToken: {
      ...addressBook.tokens.BTC,
      canZap: false
    },
    platforms: ['Pancake'],
    type: 'Accumulator',
    otherInfo: ['Deposit USDT-BUSD', 'Accumulate BTC'],
    fees: {
      withdrawal: 0,
      deposit: 0,
      performance: 4.25,
      claim: 0.1
    },
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only' // holdDescription(addressBook.lps['pancake-usdt-busd'], addressBook.tokens.BTC)
  }
].filter((i) => featureAllowed(i.env));
