import {
	Flex,
	Container,
	useBreakpointValue, Text, Skeleton,
} from '@chakra-ui/react';
import * as React from 'react';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import FarmCard from './FarmCard';
import GridView from '../../common/GridView';
import { useDocumentTitle } from '../../../hooks/useDocumentTitle';
import { useAllFarms } from '../hooks/useAllFarms';
import FarmsPlatformStats from './FarmsPlatformStats';
import FarmsFilters from '../common/FarmFilters';
import { useGetGeneralPlatformStats } from '../../../hooks/useGetGeneralPlatformStats';
import PaginationModule from '../../common/Pagination/Pagination';

function Farms() {
	const [isGridView, setIsGridView] = useState<boolean>(false);
	const existingFarms = useAllFarms();

	const [enabledFarms, setEnabledFarms] = useState(existingFarms || []);
	const isMobile = useBreakpointValue({ base: true, xl: false });
	const getGeneralStats = useGetGeneralPlatformStats();

	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(0);
	const perPage = 15;

	const [clearFilters, setClearFilters] = useState<boolean>(false);

	const filteredFarms = (farms: any[]) => {
		const rows = farms ? farms.filter((f) => !f.deprecated || (f.deprecated && f.depositDetails?.amount)).slice(perPage * (currentPage - 1), perPage * currentPage) : null;
		setEnabledFarms(rows);
		setClearFilters(false);
	};

	useEffect(() => {
		const rows = existingFarms ? existingFarms.filter((f) => !f.deprecated || (f.deprecated && f.depositDetails?.amount))
			.slice(perPage * (currentPage - 1), perPage * currentPage) : null;
		const totalPagesSet = existingFarms ? existingFarms.length / perPage : 0;
		setTotalPages((Math.round(totalPagesSet) + 0.5) < totalPagesSet ? Math.round(totalPagesSet) + 1 : Math.round(totalPagesSet));
		setClearFilters(true);

		setEnabledFarms(rows);

		// TO DO: uncomment this before release
		// setEnabledFarms(existingFarms.filter((f) => !f.showOnlyIfBalanceExists || (f.showOnlyIfBalanceExists
		//     && (getTokenBalance(f.depositToken).integer || f.depositDetails.amount))));
	}, [existingFarms, currentPage]);

	useDocumentTitle('Bloomify - Farms');

	return (
    <Flex flexDir="column" h="100%" overflow="hidden">
        <Skeleton my={5} isLoaded={!existingFarms || !!existingFarms.length} height="100%">
            <Flex h="100%" w="100%" overflow="hidden" flexDir="column" overflowY="auto" pt={10}>
                {/* {existingFarms && <FarmsPlatformStats farms={existingFarms} tvl={getGeneralStats.farms.totalTvl} lastUpdated={getGeneralStats.updatedAt} />} */}
                <Container maxW="1800px" px={{ base: 1, sm: 5 }}>
                    <Flex align="center" justify="center">
                        {existingFarms && <FarmsFilters farms={existingFarms} filteredFarms={filteredFarms} clearFilters={clearFilters} />}
                    </Flex>
                    <GridView isGridView={isGridView} setIsGridView={setIsGridView} length={existingFarms ? existingFarms.length : 0} farms>
                        {existingFarms && existingFarms.length && enabledFarms.map((farm, key) =>
                            <FarmCard
                                isGridView={isGridView}
                                isMobile={isMobile}
                                key={key}
                                farm={farm} />)}
                        {(!existingFarms || (enabledFarms && !enabledFarms.length)) && <Text fontSize="3xl" py={5} textAlign="center">No farms found</Text>}
                    </GridView>
                    <Flex align="center" justify="center" w="100%"> <PaginationModule totalPages={totalPages} setPage={(page) => setCurrentPage(page)} /> </Flex>
                </Container>
            </Flex>
        </Skeleton>
    </Flex>
	);
}

export default Farms;
