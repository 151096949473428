import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useWeb3React } from '@web3-react/core';
import useWeb3 from './useWeb3';
import { getTokenBalances, getTokenBalance as externalGetTokenBalance } from '../service/token_balances.service';

export const useFetchBalances = () => {
	const web3 = useWeb3();
	const { account } = useWeb3React();
	const [balances, setBalances] = useState<any>();

	const getTokenBalance = (tokenDetails) => externalGetTokenBalance(tokenDetails, balances);

	const providedBalances = useMemo<any>(() => ({ balances, setBalances, getTokenBalance }), [balances, setBalances]);

	const getBalances = async () => {
		if (account) {
			const result = await getTokenBalances(web3, account);
			setBalances(result);
		}
	};

	useEffect(() => {
		getBalances();
		setInterval(() => getBalances(), 10000);
	}, [account]);

	return { balances, providedBalances, getTokenBalance };
};
