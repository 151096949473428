/* eslint-disable max-len */
import { defaultBlocks } from './blocks.config';
import { addressBook } from './addressbook.config';
import {
  TAKE_PROFIT_ID,
  AUTOCOMPOUND_ID,
  REINVEST_ID,
  DEFAULT_PERCENTAGE_TAKE_PROFIT,
  DEFAULT_PERCENTAGE_AUTOCOMPOUND,
  DEFAULT_PERCENTAGE_REINVEST
} from '../../../helpers/consts';
import { ENV_PROD, ENV_TESTING, featureAllowed } from '../../env';

export const defaultStrategies = [
  /* {
		tag: '120',
		deprecatedTags: ['306'],
		name: 'USDT-BUSD Accumulator',
		includesBank: true,
		otherInfo: ['Autocompounding Bank'],
		type: 'Blue Chip',
		platforms: ['Pancake'],
		description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'ETH Buff Strategy',
		blocks: [
			{
				...defaultBlocks.pcs_farm_usdt_busd,
				actionSettings: [
					{
						id: TAKE_PROFIT_ID,
						value: 0,
						title: 'Profit',
						description: '% taken as profit',
					},
					{
						id: AUTOCOMPOUND_ID,
						value: 5000,
						token: addressBook.lps['pancake-usdt-busd'],
						title: 'Compound',
						description: '% compounded in',
					},
					{
						id: REINVEST_ID,
						value: 5000,
						reinvestInTheBlockIndex: 0,
						token: addressBook.lps['pancake-usdt-busd'],
						title: 'Reinvest',
						description: '% reinvested in',
					},
				],
			},
			{
				...defaultBlocks.pcs_bank_busd_usdt,
				actionSettings: [
					{
						id: AUTOCOMPOUND_ID,
						value: 10000,
						title: 'Auto Compound',
						token: addressBook.lps['pancake-usdt-busd'],
						description: '% compounded in',
						disable: true,
					},
				],
			},
		],
		env: ENV_TESTING,
	},
	{
		tag: '125',
		deprecatedTags: [],
		name: 'Charge-BUSD Accumulator',
		type: 'Blue Chip',
		includesBank: true,
		platforms: ['Pancake'],
		description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'Charge-BUSD Accumulator',
		otherInfo: ['Autocompounding Bank'],
		blocks: [
			{
				...defaultBlocks.pcs_farm_usdt_busd,
				actionSettings: [
					{
						id: REINVEST_ID,
						value: 5000,
						reinvestInTheBlockIndex: 1,
						token: addressBook.lps['pancake-charge-busd'],
						title: 'Take Profit',
						description: '% profit to auto compounding',
					},
					{
						id: AUTOCOMPOUND_ID,
						value: 5000,
						title: 'Compounded',
						token: addressBook.lps['pancake-usdt-busd'],
						description: '% compounded in',
					},
				],
			},
			{
				...defaultBlocks.bloom_bank_charge_busd,
				actionSettings: [
					{
						id: AUTOCOMPOUND_ID,
						token: addressBook.lps['pancake-charge-busd'],
						value: 10000,
						title: 'Auto Compound',
						description: '% compounded in',
						disable: true,
					},
				],
			},
		],
		env: ENV_TESTING,
	},
	{
		tag: '124',
		includesBank: true,
		deprecatedTags: ['306'],
		name: 'ETH-USDC Profit Taker',
		type: 'Blue Chip',
		platforms: ['Pancake'],
		description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'ETH-USDC Profit Taker',
		otherInfo: [],
		blocks: [
			{
				...defaultBlocks.pcs_eth_usdc,
				actionSettings: [
					{
						id: AUTOCOMPOUND_ID,
						value: 5000,
						title: 'Take Profit',
						token: addressBook.lps['pancake-eth-usdc'],
						description: '% profit',
					},
					{
						id: REINVEST_ID,
						value: 5000,
						reinvestInTheBlockIndex: 1,
						token: addressBook.lps['pancake-usdt-busd'],
						title: 'Reinvest',
						description: '% reinvested in',
					},
				],
			},
			{
				...defaultBlocks.pcs_bank_busd_usdt,
				actionSettings: [
					{
						id: AUTOCOMPOUND_ID,
						value: 10000,
						title: 'Compound',
						token: addressBook.lps['pancake-usdt-busd'],
						description: '% compounded in',
					},
				],
			},
		],
		env: ENV_TESTING,
	}, */
  {
    tag: '130',
    deprecatedTags: ['301', '114'],
    name: 'Bits of bETH! (USDC)',
    type: 'Blue Chip',
    platforms: ['Pancake'],
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'The crème de la crème of all cryptos. Exposure to Bitcoin and ETH. Use the yield to grow your holding and reinvest a portion into stablecoins to lock in profits. Even your profits are put to work for extra yield!',
    blocks: [
      {
        ...defaultBlocks.pcs_farm_btcb_eth,
        actionSettings: [
          {
            id: TAKE_PROFIT_ID,
            value: DEFAULT_PERCENTAGE_TAKE_PROFIT,
            title: 'Profit',
            description: '% taken as profit'
          },
          {
            id: AUTOCOMPOUND_ID,
            value: DEFAULT_PERCENTAGE_AUTOCOMPOUND,
            token: addressBook.lps['pancake-btcb-eth'],
            title: 'Compound',
            description: '% compounded in'
          },

          {
            id: REINVEST_ID,
            value: DEFAULT_PERCENTAGE_REINVEST,
            reinvestInTheBlockIndex: 1,
            token: addressBook.lps['pancake-stable-usdc-busd'],
            title: 'Reinvest',
            description: '% reinvested in'
          }
        ]
      },
      {
        ...defaultBlocks.pcs_farm_usdc_busd_stable,
        actionSettings: [
          {
            id: TAKE_PROFIT_ID,
            value: 0,
            title: 'Profit',
            description: '% taken as profit'
          },
          {
            id: AUTOCOMPOUND_ID,
            token: addressBook.lps['pancake-stable-usdc-busd'],
            value: 5000,
            title: 'Compound',
            description: '% compounded in'
          },

          {
            id: REINVEST_ID,
            value: 5000,
            reinvestInTheBlockIndex: 0,
            token: addressBook.lps['pancake-btcb-eth'],
            title: 'Reinvest',
            description: '% reinvested in'
          }
        ]
      }
    ],
    env: ENV_PROD
  },

  {
    tag: '131',
    deprecatedTags: ['304', '117'],
    name: 'Bits of bETH! (USDT)',
    type: 'Blue Chip',
    platforms: ['Pancake'],
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'The crème de la crème of all cryptos. Exposure to Bitcoin and ETH. Use the yield to grow your holding and reinvest a portion into stablecoins to lock in profits. Even your profits are put to work for extra yield!',
    blocks: [
      {
        ...defaultBlocks.pcs_farm_btcb_eth,
        actionSettings: [
          {
            id: TAKE_PROFIT_ID,
            value: DEFAULT_PERCENTAGE_TAKE_PROFIT,
            title: 'Profit',
            description: '% taken as profit'
          },
          {
            id: AUTOCOMPOUND_ID,
            value: DEFAULT_PERCENTAGE_AUTOCOMPOUND,
            token: addressBook.lps['pancake-btcb-eth'],
            title: 'Compound',
            description: '% compounded in'
          },

          {
            id: REINVEST_ID,
            value: DEFAULT_PERCENTAGE_REINVEST,
            token: addressBook.lps['pancake-stable-usdt-busd'],
            reinvestInTheBlockIndex: 1,
            title: 'Reinvest',
            description: '% reinvested in'
          }
        ]
      },
      {
        ...defaultBlocks.pcs_farm_usdt_busd_stable,
        actionSettings: [
          {
            id: TAKE_PROFIT_ID,
            value: 0,
            title: 'Profit',
            description: '% taken as profit'
          },
          {
            id: AUTOCOMPOUND_ID,
            token: addressBook.lps['pancake-stable-usdt-busd'],
            value: 5000,
            title: 'Compound',
            description: '% compounded in'
          },

          {
            id: REINVEST_ID,
            value: 5000,
            reinvestInTheBlockIndex: 0,
            token: addressBook.lps['pancake-btcb-eth'],
            title: 'Reinvest',
            description: '% reinvested in'
          }
        ]
      }
    ],
    env: ENV_PROD
  },
  {
    tag: '132',
    deprecatedTags: ['302', '115'],
    name: 'BSC Bitcoiners (USDC)',
    type: 'Blue Chip',
    platforms: ['Pancake'],
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'The biggest of all blue chips Bitcoin is paired with BNB. Grow your holdings by compounding and earn passive income via profits locked into stablecoins.',
    blocks: [
      {
        ...defaultBlocks.pcs_farm_btcb_bnb,
        actionSettings: [
          {
            id: TAKE_PROFIT_ID,
            value: DEFAULT_PERCENTAGE_TAKE_PROFIT,
            title: 'Profit',
            description: '% taken as profit'
          },
          {
            id: AUTOCOMPOUND_ID,
            value: DEFAULT_PERCENTAGE_AUTOCOMPOUND,
            token: addressBook.lps['pancake-btcb-bnb'],
            title: 'Compound',
            description: '% compounded in'
          },
          {
            id: REINVEST_ID,
            value: DEFAULT_PERCENTAGE_REINVEST,
            token: addressBook.lps['pancake-stable-usdc-busd'],
            reinvestInTheBlockIndex: 1,
            title: 'Reinvest',
            description: '% reinvested in'
          }
        ]
      },
      {
        ...defaultBlocks.pcs_farm_usdc_busd_stable,
        actionSettings: [
          {
            id: TAKE_PROFIT_ID,
            value: 0,
            title: 'Profit',
            description: '% taken as profit'
          },
          {
            id: AUTOCOMPOUND_ID,
            value: 5000,
            token: addressBook.lps['pancake-stable-usdc-busd'],
            title: 'Compound',
            description: '% compounded in'
          },
          {
            id: REINVEST_ID,
            value: 5000,
            reinvestInTheBlockIndex: 0,
            token: addressBook.lps['pancake-btcb-bnb'],
            title: 'Reinvest',
            description: '% reinvested in'
          }
        ]
      }
    ],
    env: ENV_PROD
  },
  {
    tag: '133',
    name: 'BSC Bitcoiners (USDT)',
    deprecatedTags: ['305', '118'],
    type: 'Blue Chip',
    platforms: ['Pancake'],
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'The biggest of all blue chips Bitcoin is paired with BNB. Grow your holdings by compounding and earn passive income via profits locked into stablecoins.',
    blocks: [
      {
        ...defaultBlocks.pcs_farm_btcb_bnb,
        actionSettings: [
          {
            id: TAKE_PROFIT_ID,
            value: DEFAULT_PERCENTAGE_TAKE_PROFIT,
            title: 'Profit',
            description: '% taken as profit'
          },
          {
            id: AUTOCOMPOUND_ID,
            value: DEFAULT_PERCENTAGE_AUTOCOMPOUND,
            token: addressBook.lps['pancake-btcb-bnb'],
            title: 'Compound',
            description: '% compounded in'
          },

          {
            id: REINVEST_ID,
            value: DEFAULT_PERCENTAGE_REINVEST,
            token: addressBook.lps['pancake-stable-usdt-busd'],
            reinvestInTheBlockIndex: 1,
            title: 'Reinvest',
            description: '% reinvested in'
          }
        ]
      },
      {
        ...defaultBlocks.pcs_farm_usdt_busd_stable,
        actionSettings: [
          {
            id: TAKE_PROFIT_ID,
            value: 0,
            title: 'Profit',
            description: '% taken as profit'
          },
          {
            id: AUTOCOMPOUND_ID,
            token: addressBook.lps['pancake-stable-usdt-busd'],
            value: 5000,
            title: 'Compound',
            description: '% compounded in'
          },

          {
            id: REINVEST_ID,
            value: 5000,
            reinvestInTheBlockIndex: 0,
            token: addressBook.lps['pancake-btcb-bnb'],
            title: 'Reinvest',
            description: '% reinvested in'
          }
        ]
      }
    ],
    env: ENV_PROD
  },

  {
    tag: '134',
    deprecatedTags: ['303', '116'],
    name: 'ETH Buff (USDC)',
    type: 'Blue Chip',
    platforms: ['Pancake'],
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'A favourite for ETH enthusiasts and a supporter of the Binance Smart Chain. Gain exposure to ETH and BNB with options to lock profits into stablecoins.',
    blocks: [
      {
        ...defaultBlocks.pcs_farm_eth_bnb,
        actionSettings: [
          {
            id: TAKE_PROFIT_ID,
            value: DEFAULT_PERCENTAGE_TAKE_PROFIT,
            title: 'Profit',
            description: '% taken as profit'
          },
          {
            id: AUTOCOMPOUND_ID,
            value: DEFAULT_PERCENTAGE_AUTOCOMPOUND,
            token: addressBook.lps['pancake-eth-bnb'],
            title: 'Compound',
            description: '% compounded in'
          },

          {
            id: REINVEST_ID,
            value: DEFAULT_PERCENTAGE_REINVEST,
            token: addressBook.lps['pancake-stable-usdc-busd'],
            reinvestInTheBlockIndex: 1,
            title: 'Reinvest',
            description: '% reinvested in'
          }
        ]
      },
      {
        ...defaultBlocks.pcs_farm_usdc_busd_stable,
        actionSettings: [
          {
            id: TAKE_PROFIT_ID,
            value: 0,
            title: 'Profit',
            description: '% taken as profit'
          },
          {
            id: AUTOCOMPOUND_ID,
            token: addressBook.lps['pancake-stable-usdc-busd'],
            value: 5000,
            title: 'Compound',
            description: '% compounded in'
          },
          {
            id: REINVEST_ID,
            value: 5000,
            reinvestInTheBlockIndex: 0,
            token: addressBook.lps['pancake-eth-bnb'],
            title: 'Reinvest',
            description: '% reinvested in'
          }
        ]
      }
    ],
    env: ENV_PROD
  },

  {
    tag: '135',
    deprecatedTags: ['306', '119'],
    name: 'ETH Buff (USDT)',
    type: 'Blue Chip',
    platforms: ['Pancake'],
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'A favourite for ETH enthusiasts and a supporter of the Binance Smart Chain. Gain exposure to ETH and BNB with options to lock profits into stablecoins.',
    blocks: [
      {
        ...defaultBlocks.pcs_farm_eth_bnb,
        actionSettings: [
          {
            id: TAKE_PROFIT_ID,
            value: DEFAULT_PERCENTAGE_TAKE_PROFIT,
            title: 'Profit',
            description: '% taken as profit'
          },
          {
            id: AUTOCOMPOUND_ID,
            value: DEFAULT_PERCENTAGE_AUTOCOMPOUND,
            token: addressBook.lps['pancake-eth-bnb'],
            title: 'Compound',
            description: '% compounded in'
          },

          {
            id: REINVEST_ID,
            value: DEFAULT_PERCENTAGE_REINVEST,
            reinvestInTheBlockIndex: 1,
            token: addressBook.lps['pancake-stable-usdt-busd'],
            title: 'Reinvest',
            description: '% reinvested in'
          }
        ]
      },
      {
        ...defaultBlocks.pcs_farm_usdt_busd_stable,
        actionSettings: [
          {
            id: TAKE_PROFIT_ID,
            value: 0,
            title: 'Profit',
            description: '% taken as profit'
          },
          {
            id: AUTOCOMPOUND_ID,
            value: 5000,
            title: 'Compound',
            token: addressBook.lps['pancake-stable-usdt-busd'],
            description: '% compounded in'
          },

          {
            id: REINVEST_ID,
            value: 5000,
            reinvestInTheBlockIndex: 0,
            token: addressBook.lps['pancake-eth-bnb'],
            title: 'Reinvest',
            description: '% reinvested in'
          }
        ]
      }
    ],
    env: ENV_PROD
  }
].filter((i) => featureAllowed(i.env));

export const deprecatedTags = defaultStrategies.map((s) => s.deprecatedTags).flat();
export const currentExistingTags = defaultStrategies.map((s) => s.tag);
