import { useCallback, useEffect } from 'react';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { NoBscProviderError } from '@binance-chain/bsc-connector';
import {
	NoEthereumProviderError,
	UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
	UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
	WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import {
	ConnectorNames,
	connectorLocalStorageKey,
} from '@pancakeswap-libs/uikit';
import { useToast } from '@chakra-ui/react';
import { connectorsByName, getLibrary } from '../utils/web3React';
import { setupNetwork } from '../utils/wallet';

const useAuth = () => {
	const toast = useToast();
	const {
		activate, deactivate, library, account,
	} = useWeb3React();
	// const addPopup = useAddPopup();

	const login = useCallback((connectorID: ConnectorNames) => {
		const connector = connectorsByName[connectorID];
		if (connector) {
			activate(connector, async (error: Error) => {
				if (error instanceof UnsupportedChainIdError) {
					const hasSetup = await setupNetwork();
					if (hasSetup) {
						activate(connector);
					}
				} else {
					window.localStorage.removeItem(connectorLocalStorageKey);
					if (
						error instanceof NoEthereumProviderError
                        || error instanceof NoBscProviderError
					) {
						toast({
							title: 'Provider Error',
							description: 'No provider was found',
							status: 'error',
							duration: 5000,
						});
					} else if (
						error instanceof UserRejectedRequestErrorInjected
                        || error instanceof UserRejectedRequestErrorWalletConnect
					) {
						if (connector instanceof WalletConnectConnector) {
							console.log(connector);
							const walletConnector = connector as WalletConnectConnector;
							walletConnector.walletConnectProvider = null;
						}
						console.log('ERROR 1');
						toast({
							title: 'Authorization Error',
							description: 'Please authorize to access your account',
							status: 'error',
							duration: 5000,
						});
					} else {
						toast({
							title: error.name,
							description: error.message,
							status: 'error',
							duration: 5000,
						});
					}
				}
			});
		} else {
			toast({
				title: "Can't find the connector",
				description: 'The connector config is wrong',
				status: 'error',
				duration: 5000,
			});
		}
	}, []);

	return { login, logout: deactivate };
};

export default useAuth;
