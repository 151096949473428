/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import {
	BoxProps,
	Flex,
	useBreakpointValue,
	Select,
	FormControl,
	InputGroup,
	InputLeftElement,
	Input,
	Text, Badge, useColorModeValue as mode, keyframes,
} from '@chakra-ui/react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { SearchIcon } from '@chakra-ui/icons';

type Props = BoxProps & {
  strategies: any[];
  filteredStrategies: (strats: any[]) => void;
  myStrategies?: boolean;
  clearFilters?: boolean;
};

function StrategiesFilters({
	strategies = [],
	filteredStrategies,
	myStrategies = false,
	clearFilters,
}: Props) {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const badges = ['Autocompounding Bank', 'Accumulator', 'Profit Taker'];
	const uniqueElements = _(strategies).uniqBy('type').orderBy('type').value();

	const allExistingTokens = [
		{ value: 'all', label: 'All' },
		..._(strategies)
			.map((s) => s.blocks.map((b) => b.token))
			.flatten()
			.filter((x) => x)
			.orderBy()
			.uniq()
			.map((item) => ({ value: item.symbol, label: item.name }))
			.value(),
	];

	const typeFilter = [
		{ value: 'all', label: 'All' },
		..._.map(uniqueElements, (s) => ({
			value: s.type,
			label: `${s.type}`,
		})),
	];

	const lastRunFilter = [
		{ value: 'default', label: 'Default' },
		{ value: 'most-recent', label: 'Recently' },
		{ value: 'least-recent', label: 'Long time ago' },
	];

	// const hiddenStrategiesFilter = [
	//   { value: "working", label: "Working" },
	//   { value: "deprecated", label: "Deprecated" },
	//   { value: "all", label: "All" },
	// ];

	const [selectedtypeFilter, setSelectedtypeFilter] = useState<any>(
		'all',
	);

	const [selectedTokensInvolved, setSelectedTokensInvolved] = useState<any>(
		'all',
	);

	const [selectedLastRunFilter, setSelectedLastRunFilter] = useState<any>(
		'default',
	);

	// const [selectedHiddenFilter, setSelectedHiddenFilter] = useState<any>(
	//   "all"
	// );

	const [searchWord, setSearchWord] = useState<any>('');
	const [searchTag, setSearchTag] = useState<any>('');

	useEffect(() => {
		if (clearFilters) {
			setSearchWord('');
			setSelectedtypeFilter('all');
			setSelectedTokensInvolved('all');
			setSelectedLastRunFilter('default');
		}
	}, [clearFilters]);

	useEffect(() => {
		apply();
	}, [
		selectedtypeFilter,
		searchWord,
		selectedLastRunFilter,
		//  selectedHiddenFilter,
		selectedTokensInvolved,
		searchTag,
	]);

	const apply = () => {
		let filteredStrats = _.cloneDeep(strategies);

		if (
			selectedtypeFilter === 'all' &&
      selectedTokensInvolved === 'all' &&
      selectedLastRunFilter === 'default' &&
			//  selectedHiddenFilter === "all" &&
      !searchWord &&
			!searchTag
		) {
			filteredStrategies(filteredStrats);
			return;
		}

		filteredStrats = _.filter(filteredStrats, (strat) => (
			(selectedtypeFilter === 'all' ||
          strat.type
          	.toLowerCase()
          	.includes(selectedtypeFilter.toLowerCase())) &&
        (!searchWord ||
          strat.name.toLowerCase().includes(searchWord.toLowerCase())) &&
			// (!searchTag ||
			// 	strat.otherInfo && _.find(strat.otherInfo, (b) => b.toLowerCase() === searchTag.toLowerCase())) &&
        (myStrategies ||
          (!myStrategies &&
            (selectedTokensInvolved === 'all' ||
              _.find(strat.blocks, (b) => b.token.symbol === selectedTokensInvolved))))
		));

		if (myStrategies) {
			// if (selectedHiddenFilter !== "all") {
			//   filteredStrats = filteredStrats.filter((s) =>
			//     selectedHiddenFilter === "deprecated"
			//       ? deprecatedStrategies.includes(s.tag)
			//       : !deprecatedStrategies.includes(s.tag)
			//   );
			// }

			switch (selectedLastRunFilter) {
				case 'least-recent':
					filteredStrats = filteredStrats.sort(
						(a, b) => a.lastRunTime - b.lastRunTime,
					);
					break;
				case 'most-recent':
					filteredStrats = filteredStrats
						.sort((a, b) => a.lastRunTime - b.lastRunTime)
						.reverse();
					break;
				default:
					break;
			}
		}

		filteredStrategies(filteredStrats);
		return;
	};

	return (
		<Flex
    flexDir="column"
    py={1}
    alignItems="center"
    position="relative">
			<Flex
    py={1}
    position="relative"
    top={-3}
    left={-2}
    maxW={isMobile ? '260px' : 'unset'}
    direction={isMobile ? 'column' : 'row'}
			>
        <FormControl mr={3} pt={6}>
            <InputGroup>
                <InputLeftElement
                    pointerEvents="none"
                    ml={1}
                    children={<SearchIcon mt={2} color="gray.300" />}
          			/>
                <Input
                    value={searchWord}
                    size="lg"
                    fontSize="15px"
                    placeholder="Search by name"
                    onChange={(e: any) => setSearchWord(e.target.value)}
          />
            </InputGroup>
        </FormControl>
        <FormControl py={isMobile ? 3 : 0} mr={3}>
						<Text fontSize="sm" pb="2px" opacity="0.8">Type</Text>
            <Select
                size="lg"
                maxW="257px"
                fontSize="15px"
                onChange={(e) => setSelectedtypeFilter(e.target.value)}
        		>
                {typeFilter.map((option: any, key: number) => (
                    <option
                        key={key}
                        selected={option.value === selectedtypeFilter}
                        value={option.value}
              >
                        {option.label}
                    </option>
                ))}
            </Select>
        </FormControl>
        {!myStrategies ? (
            <FormControl py={isMobile ? 3 : 0}>
								<Text fontSize="sm" pb="2px" opacity="0.8">Tokens Involved</Text>
                <Select
                    size="lg"
                    maxW="257px"
                    fontSize="15px"
                    onChange={(e) => setSelectedTokensInvolved(e.target.value)}
          			>
                    {allExistingTokens.map((option: any, key: number) => (
                        <option
                            key={key}
                            selected={option.value === selectedTokensInvolved}
                            value={option.value}
                >
                            {option.label}
                        </option>
                    ))}
                </Select>
            </FormControl>
        ) : null}
        {myStrategies ? (
        	<>
            <FormControl py={isMobile ? 3 : 0} mr={3}>
										<Text fontSize="sm" pb="2px" opacity="0.8">Last Run</Text>
                    <Select
                        size="lg"
                        maxW="257px"
                        fontSize="15px"
                        onChange={(e) => setSelectedLastRunFilter(e.target.value)}
            				>
                    {lastRunFilter.map((option: any, key: number) => (
                            <option
                                key={key}
                                selected={option.value === selectedLastRunFilter}
                                value={option.value}
                  >
                            {option.label}
                            </option>
                    ))}
                    </Select>
            </FormControl>

            {/* <FormControl>
            <label>Strategies</label>
            <Select
              maxW="257px"
              onChange={(e) => {
                setSelectedHiddenFilter(e.target.value);
              }}
            >
              {hiddenStrategiesFilter.map((option: any, key: number) => {
                return (
                  <option
                    key={key}
                    selected={option.value === selectedHiddenFilter}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                );
              })}
            </Select>
          </FormControl> */}
        	</>
        ) : null}
    	</Flex>
			{/* <Flex flexDir={'column'} pt={6} pb={10}>
				<Text w={'full'} textAlign={'center'} fontSize={{ base: 'lg', sm: 'xl' }} fontWeight="bold" pb={2}>Popular Strategies</Text>
				<Flex justify={'center'} flexWrap='wrap'>
				{badges.map((i, key) =>
						<Badge
							boxShadow={mode('sm', 'sm-dark')}
							_hover={{'cursor': 'pointer'}}
							onClick={() => setSearchTag(i)} my="2px" colorScheme="red" mx={1} fontSize="12px" variant={mode(`${i===searchTag ? 'solid' : 'subtle'}`, `${i===searchTag ? 'subtle' : 'solid'}`)}>
							{i}
						</Badge>
				)}
				<Badge fontSize="12px" my="2px" _hover={{'cursor': 'pointer'}} onClick={() => setSearchTag('')}>Clear</Badge>
				</Flex>
			</Flex> */}
		</Flex>
	);
}

export default StrategiesFilters;
