import React from 'react';
import { Text, useColorModeValue as mode } from '@chakra-ui/react';
import { TAKE_PROFIT_ID, AUTOCOMPOUND_ID, REINVEST_ID } from './consts';

export const getTourSteps = (blocks: Array<any>, currentStep: any, nextStep: any) => {
	let tourConfig: Array<any> = [];
	const style = {
		backgroundColor: mode('#ffffff', '#05050F'),
	};

	// {
	//   selector: `[data-tut="reactour__step-main-${i}"]`,
	//     content: ({ goTo }) => (
	//   <>
	//     <Text>Please review the changes you made and hit next to adjust the next set of settings.</Text>
	//     <IconButton buttonHandler={nextStep} />
	//     {/*<CustomButton buttonHandler={() => { goTo(currentStep + 1); nextStep(); }} text={'Next'}/>*/}
	//   </>
	// ),
	//   style
	// }

	if (blocks && blocks.length) {
		const i = currentStep - 1;
		const reinvestAction = blocks[i].actionSettings.find((a) => a.id === REINVEST_ID);

		const nextAction = i !== blocks.length - 1
      	? {
      		selector: `[data-tut="reactour__step-main-${i}"]`,
      		content: ({ goTo }) => (
          <Text>Please review the changes you made and hit arrow right button to adjust the next set of settings.</Text>
      		),
      		style,
      	}
      	: {
      		selector: '[data-tut="reactour__step-create"]',
      		content: "The last creation steps consists of creating the strategy, which you'll then find in Portfolio page.",
      		style,
      	};

		// @ts-ignore
		const compoundStep = {
			selector: `[data-tut="reactour__step-2-${i}"]`,
			content: `Adjust percentage of profit to be compounded back in ${blocks[i].name}`,
			style,
		};

		const profitStep = {
			selector: `[data-tut="reactour__step-1-${i}"]`,
			content: 'Use the slider to adjust the percentage of profit taken.',
			style,
		};

		const reinvestStep = {
			selector: `[data-tut="reactour__step-3-${i}"]`,
			content: `Adjust the percentage of profit which is to be reinvested back into ${reinvestAction ? blocks[reinvestAction.reinvestInTheBlockIndex].name : 'it'}.`,
			style,
		};

		const numberOfActions = blocks[i].actionSettings.length;
		let tourInnerSteps = [profitStep, compoundStep, reinvestStep];

		if (numberOfActions === 1) {
			tourInnerSteps = [compoundStep];
		} else if (numberOfActions === 2) {
			tourInnerSteps = [profitStep, reinvestStep];
		}

		tourConfig = [...tourConfig, ...tourInnerSteps, nextAction];
		// }
		return tourConfig;
	}

	return {};
};

export const getMyLegosTourSteps = (isMobile = false) => {
	const style = {
		backgroundColor: mode('#ffffff', '#05050F'),
	};
	// @ts-ignore
	const runStep = {
		selector: '[data-tut="reactour__step-legos__run"]',
		content: isMobile
			? 'Before running a strategy, you need to make sure that you have deposited tokens in at least one of the blocks. Afterwards, the last step consists of running the strategy.'
			: 'After having deposited tokens, the last step consists of running the strategy.',
		style,
	};

	const depositStep = {
		selector: '[data-tut="reactour__step-legos__deposit"]',
		content: 'Before running a strategy, you need to make sure that you have deposited tokens in at least one of the blocks.',
		style,
	};

	const editStep = {
		selector: '[data-tut="reactour__step-legos__edit"]',
		content: 'And edit the percentages if needed.',
		style,
	};

	const checkStep = {
		selector: '[data-tut="reactour__step-legos__card"]',
		content: 'Before starting out, you might want to re-check the percentages you set during strategy creation.',
		style,
	};

	const tourConfig = isMobile ? [checkStep, editStep, runStep] : [checkStep, editStep, depositStep, runStep];
	return tourConfig;
};
