import Web3 from 'web3';
import { getGeneralBlockInfo, getAllBlockInfo } from './block.service';
import { getGeneralInfoForAClonedStrategy } from './strategy.service';
import { defaultChain } from '../../../bloomify/config';

export const getClonedStrategyAddresses = async (web3: Web3, walletAddress: string) => {
	const strategyRegistry = new web3.eth.Contract(
		defaultChain.deployments.StrategyRegistry.abi,
		defaultChain.deployments.StrategyRegistry.address,
		{ from: walletAddress },
	);

	return await strategyRegistry.methods.getStrategies(walletAddress).call();
};

export const getClonedStrategies = async (web3: Web3, strategies: Array<any>, strategyTags: Array<string>) => {
	const promiseArray = strategies.map(async (strategyAddr, i) => ({ ...await getGeneralInfoForAClonedStrategy(web3, strategyAddr, strategyTags[i]), tag: strategyTags[i] }));

	return await (await Promise.all(promiseArray)).filter((x) => x && x.strategyAddress); // if exists on current env and can be shown
};

export const getClonedStrategyBlocks = async (web3: Web3, strategyAddr: string, walletAddress: string) => {
	const strategyRunner = new web3.eth.Contract(
		defaultChain.deployments.StrategyRunner.abi,
		strategyAddr,
		{ from: walletAddress },
	);

	const promiseArray: Array<any> = [];
	const blockNumber = await strategyRunner.methods.numBlocks().call();
	for (let i = 0; i < blockNumber; i++) {
		promiseArray.push(strategyRunner.methods.blocks(i).call());
	}

	return await Promise.all(promiseArray);
};

export const getClonedStrategyGeneralBlocksInfo = async (web3: Web3, allExistingBlocks: Array<Array<string>>) => {
	const promiseArray = allExistingBlocks.map(async (blocksPerStrategy) => (blocksPerStrategy
		? await Promise.all(blocksPerStrategy.map((i) => getGeneralBlockInfo(web3, i))) : null));

	return (await Promise.all(promiseArray)).filter((x) => x);
};

export const getClonedStrategyAllBlocksInfo = async (web3: Web3, allExistingBlocks: Array<string>) => {
	const promiseArray = allExistingBlocks.map(async (blocksPerStrategy) => (blocksPerStrategy
		? getAllBlockInfo(web3, blocksPerStrategy).then((result) => result) : null));

	return await (await Promise.all(promiseArray)).filter((x) => x);
};
