/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import {
 Text, Flex, useColorModeValue as mode, Box, SimpleGrid,
} from '@chakra-ui/react';
import _ from 'lodash';
import Asset from './Asset';
import {defaultChain, getAssets} from '../../../bloomify/config';

function AssetDetailsGroup({ assetList }) {
  return (
    <>
      {' '}
      {assetList ? (
        <Box as="section" maxW="container.xl" mx="auto">
          <Box mx={{ base: '20px', lg: '32px' }} bg="bg-surface" rounded="3xl" p={3} mt={4} px={{ base: '20px', xl: '107px' }}>
            <Flex direction="column" align="center">
              <Text fontSize="3xl" fontWeight="bold" my="auto" textAlign="center" px={2}>
                Assets
              </Text>
              <SimpleGrid
                  alignItems="start"
                  columns={{ base: 1, md: assetList.length < 3 ? assetList.length : 3, lg: assetList.length < 5 ? assetList.length : 5 }}
                  my="8"
                  spacing={{ base: 12, md: 4, lg: 6 }}>
                {assetList.map((el, i) => (el !== undefined ? <Asset scanUrl={defaultChain.scanUrl} {...el} key={i} /> : null))}
              </SimpleGrid>
            </Flex>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export function StrategyAssetDetailsGroup({ blocks }) {
  const [assetList, setAssetList] = useState([]);

  useEffect(() => {
    if (blocks) {
      blocks.forEach((b) => {
        let res = [];
        if (b.token) {
          res = getAssets(b.token.symbol);
        }
        if (b.token) {
          res = getAssets(b.token.symbol);
        }
        if (res !== [] || res !== undefined || assetList.length < blocks.length) {
          setAssetList((prev) =>
            _([...prev, ...res])
              .filter((x) => x)
              .uniqBy('symbol')
              .value());
        }
      });
    }
  }, []);

  return <AssetDetailsGroup assetList={assetList} />;
}

export function VaultAssetDetailsGroup({ depositToken, accumulatedToken }) {
  const [assetList, setAssetList] = useState([]);

  useEffect(() => {
    if (depositToken.symbol) {
      const res = getAssets(depositToken.symbol);
      if (res !== [] || res !== undefined) {
        setAssetList((prev) =>
          _([...prev, ...res])
            .filter((x) => x)
            .uniqBy('symbol')
            .value());
      }
	}

	if (accumulatedToken && accumulatedToken.symbol) {
		const res = getAssets(accumulatedToken.symbol);
      if (res !== [] || res !== undefined) {
        setAssetList((prev) =>
          _([...prev, ...res])
            .filter((x) => x)
            .uniqBy('symbol')
            .value());
      }
	}
  }, []);

  return <AssetDetailsGroup assetList={assetList} />;
}

export function FarmAssetDetailsGroup({ depositToken }) {
  const [assetList, setAssetList] = useState([]);

  useEffect(() => {
    if (depositToken.symbol) {
      const res = getAssets(depositToken.symbol);

      if (res !== [] || res !== undefined) {
        setAssetList((prev) =>
          _([...prev, ...res])
            .filter((x) => x)
            .uniqBy('symbol')
            .value());
      }
    }
  }, []);

  return <AssetDetailsGroup assetList={assetList} />;
}
