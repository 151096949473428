/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-indent */
import {
	Box, chakra, HTMLChakraProps, useColorModeValue,
	 useColorModeValue as mode,
	Menu,
	MenuButton,
	Button,
	MenuList,
	MenuItem,
	Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { ChevronDownIcon } from '@chakra-ui/icons';

function SubLink(props: { to: string, label: string }) {
	const { to, label } = props;
	const { pathname } = useLocation();
	const isSubmenuActive = pathname.includes(to);

	return (
		<Box
    color={isSubmenuActive ? mode('gray.800', 'whiteAlpha.900') : mode('gray.500', 'whiteAlpha.700')}
    fontWeight="400"
    display="flex"
    alignItems="center"
    justifyContent="center"
    w="full"
    py="2"
    mb={1}
    h="full"
    borderBottom={`2px solid ${isSubmenuActive ? mode('#191932', 'rgba(255, 255, 255, 0.92)') : 'transparent'}`}
    transition="all 0.2s"
    px={{ base: '4', md: '6' }}
    _hover={{
    	borderBottom: `2px solid ${mode('#191932', 'rgba(255, 255, 255, 0.92)')}`,
    	color: mode('gray.800', 'whiteAlpha.900'),
    }}>
		<Text
    fontWeight="400"
    display="flex"
    alignItems="center"
    justifyContent="center"
    fontSize="14px"
    pt={1}
    color={isSubmenuActive ? mode('gray.800', 'whiteAlpha.900') : mode('gray.500', 'whiteAlpha.700')}
    px={{ base: '4', md: '6' }}
    h="100%"
    w="100%">
			{label}
		</Text>
	</Box>
	);
}

function DesktopNavLink(props: HTMLChakraProps<'a'> & any) {
	const { pathname } = useLocation();
	const isActive = props.href ? !!props.activeId && props.href.includes(props.activeId) : (pathname.replace('/', '') === props.to
  || (props.subTo ? props.subTo.includes(pathname) : false));

	const link = (
    <Box
        color={isActive ? mode('gray.800', 'whiteAlpha.900') : mode('gray.500', 'whiteAlpha.700')}
        fontWeight="400"
        fontSize="14px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        w={{ base: '7.7rem', md: '7.7rem', lg: '9rem' }}
        h="full"
        borderBottom={`2px solid ${isActive ? mode('#191932', 'rgba(255, 255, 255, 0.92)') : 'transparent'}`}
        transition="all 0.2s"
        px={{ base: '4', md: '5' }}
        _hover={{
        	borderBottom: `2px solid ${mode('#191932', 'rgba(255, 255, 255, 0.92)')}`,
        	color: mode('gray.800', 'whiteAlpha.900'),
        }}
        {...props}
      />
	);

	return (
		<>{props.href ?
    <NavHashLink to={props.href} smooth fontSize="14px">
            {link}
        </NavHashLink> :
			(!props.menu ? <Link to={props.to} style={{ display: 'flex', margin: 0 }}>
        {link}
    </Link>
				: <Menu>
        <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            minW="154px"
            h="full"
            style={{
            	display: 'flex', margin: 0, height: '100%', background: 'unset',
            }}>
          <Text fontWeight="400" fontSize="14px" color={isActive ? mode('gray.800', 'whiteAlpha.900') : mode('gray.500', 'whiteAlpha.700')}>{props.label}</Text>
        </MenuButton>
        <MenuList bg="bg-surface" py={0}>
          {props.menu.map((link, key) =>
          <MenuItem
              key={key}
              _focus={{ bg: 'inherit' }}>
            <Link
                to={link.to}
                style={{
            			display: 'flex', margin: 0, width: '100%',
                }}>
							<SubLink {...link} fontSize="14px" />
            </Link>
          </MenuItem>)}
        </MenuList>
      </Menu>)}
		</>
	);
}

function MobileNavLink(props: HTMLChakraProps<'a'> & any) {
	const { pathname } = useLocation();
	const isActive = props.href ? !!props.activeId && props.href.includes(props.activeId) : (pathname.replace('/', '') === props.to
  || (props.subTo ? props.subTo.includes(pathname) : false));
	const link = <Box
    color={isActive ? mode('gray.800', 'whiteAlpha.900') : mode('gray.500', 'whiteAlpha.700')}
    fontWeight="400"
    display="flex"
    alignItems="center"
    justifyContent="center"
    py="5"
    fontSize="14px"
    textAlign="center"
    borderBottom={`2px solid ${isActive ? mode('#191932', 'rgba(255, 255, 255, 0.92)') : 'transparent'}`}
    borderTop={`2px solid ${isActive ? mode('#191932', 'rgba(255, 255, 255, 0.92)') : 'transparent'}`}
    transition="all 0.2s"
    px={{ base: '4', md: '6' }}
    _hover={{
      	borderBottom: `2px solid ${mode('#191932', 'rgba(255, 255, 255, 0.92)')}`,
      	borderTop: `2px solid ${mode('#191932', 'rgba(255, 255, 255, 0.92)')}`,
      	color: mode('gray.800', 'whiteAlpha.900'),
    }}
    {...props} />;

	return (
		<>{props.href ?
    <NavHashLink to={props.href} smooth>
            {link}
        </NavHashLink> :
			(!props.menu ? <Link to={props.to}>
        {link}
    </Link>
				: <Menu>
        <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            style={{
        	  height: '100%', background: 'unset',
            }}>
          <Text
              fontWeight="400"
              textAlign="center"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color={isActive ? mode('gray.800', 'whiteAlpha.900') : mode('gray.500', 'whiteAlpha.700')}
              py="5"
              px={{ base: '4', md: '6' }}
              fontSize="lg">{props.label}</Text>
        </MenuButton>
        <MenuList bg="bg-surface" py={0}>
          {props.menu.map((link, key) =>
          <MenuItem key={key}>
            <Link
                to={link.to}
                style={{
                	width: '100%',
                	height: '100%',
                }}>
							<SubLink {...link} />
            </Link>
          </MenuItem>)}
        </MenuList>
				<Box w="100%" h={4} />
      </Menu>)}
		</>
	);
}

export const NavLink = {
	Mobile: MobileNavLink,
	Desktop: DesktopNavLink,
};
