/* eslint-disable no-shadow */
/* eslint-disable no-mixed-operators */
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { useBoolean } from '@chakra-ui/react';
import _ from 'lodash';
import useWeb3 from '../../../hooks/useWeb3';

import { useTokenPrices } from '../../../contexts/TokenPricesContext';
import { useDidUpdate } from '../../../hooks/useDidUpdate';
import {
	fetchFarms, withdrawAmountFromFarm, withdrawAllFromFarm, depositAmountInFarm, depositAllInFarm, claimEarningsFromFarm,
} from '../services/farm.service';

export const useSingleFarmDetails = (farmAddress, fetchedFarm?) => {
	const web3 = useWeb3();
	const { account } = useWeb3React();
	const { getTokenPrice, tokens } = useTokenPrices();

	// Containers and lists
	const [farmInfo, setFarmInfo] = useState<any>();

	// Action related
	const [isWithdrawing, setIsWithdrawing] = useBoolean(false);
	const [isDepositing, setIsDepositing] = useBoolean(false);
	const [isClaiming, setIsClaiming] = useBoolean(false);

	const claimEarnings = async () => {
		setIsClaiming.on();
		const response = await claimEarningsFromFarm(web3, farmAddress, account);

		if (response.status) {
			await getDetails(true);
		}

		setIsClaiming.off();
	};

	const withdrawAmount = async (amount, onError, onReceipt) => {
		setIsWithdrawing.on();
		const response = await withdrawAmountFromFarm(web3, amount, farmAddress, account, onError, onReceipt);

		if (response.status) {
			await getDetails(true);
		}

		setIsWithdrawing.off();
	};

	const depositAmount = async (amount, onError, onReceipt) => {
		setIsDepositing.on();
		const response = await depositAmountInFarm(web3, amount, farmAddress, account, onError, onReceipt);

		if (response.status) {
			await getDetails(true);
		}

		setIsDepositing.off();
	};

	const depositAll = async (amount, onError, onReceipt) => {
		setIsDepositing.on();
		const response = await depositAllInFarm(web3, amount, farmAddress, account, onError, onReceipt);

		if (response.status) {
			await getDetails(true);
		}

		setIsDepositing.off();
	};

	const withdrawAll = async (onError, onReceipt) => {
		setIsWithdrawing.on();
		const response = await withdrawAllFromFarm(web3, farmAddress, account, onError, onReceipt);

		if (response.status) {
			await getDetails(true);
		}

		setIsWithdrawing.off();
	};

	// Gets the details of the strategy instance and the individual blocks
	const getDetails = async (invalidateCache = false) => {
		if (tokens && tokens.name && getTokenPrice('STATIC')) {
			const farm = !invalidateCache ? (fetchedFarm || (await fetchFarms(web3, farmAddress, account))[0]) : (await fetchFarms(web3, farmAddress, account))[0];

			if (invalidateCache) {
				if (window.history.state.usr && window.history.state.usr.farm) {
					const usr = { ...window.history.state.usr };
					delete usr.farm;
					window.history.replaceState({ ...window.history.state, usr }, '');
				}
			}

			setFarmInfo(farm);
		}
	};

	useDidUpdate(() => {
		getDetails(true);
	}, [account]);

	useEffect(() => {
		getDetails();
	}, [account]);

	useEffect(() => {
		getDetails();
	}, [tokens]);

	return {
		farmInfo,
		isWithdrawing,
		isDepositing,
		isClaiming,
		withdrawAmount,
		withdrawAll,
		depositAmount,
		claimEarnings,
		depositAll,
		getDetails,
	};
};
