import React from 'react';
import {
	Badge, Box, Flex, Stack, StackDivider, Text, useColorModeValue as mode, Tooltip, Icon, Link,
} from '@chakra-ui/react';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';
import { BsBoxArrowUpRight } from '@react-icons/all-files/bs/BsBoxArrowUpRight';
import { Link as RouterLink } from 'react-router-dom';
import { StatCard } from '../../../common/StatComponent';
import TokenImage from '../../../common/TokenImage';
import { ResponsiveTooltip } from '../../../common/Tooltip';

type Props = {
  vault: any
  stats?: any
}
function VaultDetailsHeader({ vault, stats }: Props) {
	return (
    <Flex justify="space-between" py={2} px={0} rounded="xl" flexDir={{ base: 'column', xl: 'row' }}>
        <Box py={2} my="auto">
            <Flex>
                <TokenImage token={vault.accumulatedToken ? vault.accumulatedToken.symbol : vault.depositToken.symbol} mx={0} />
                <Text fontSize="3xl" fontWeight="bold" my="auto" px={2}>{vault.name}</Text>
            </Flex>
            <Flex mt="2px" mb="0.5rem" flexWrap="wrap">
                <Badge colorScheme="purple" my="2px" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>{vault.type}</Badge>
                {!!vault.customStrat && <Badge colorScheme="orange" my="2px" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                    <RouterLink to={`/strategies/${vault.customStrat.tag}`}>
                        <Text>
                            Based on {vault.customStrat.name} <Icon as={FaExternalLinkAlt} mx="auto" w={2.5} h={2.5} />
                        </Text>
                    </RouterLink>
                </Badge> }
                <ResponsiveTooltip label={vault.platforms.join(', ')} hasArrow placement="bottom" p={4} borderRadius="md">
                    <Badge colorScheme="yellow" my="2px" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                        <Flex align="center">  {vault.platforms.length > 1 ? `${vault.platforms.length} Platform(s)` : vault.platforms[0]}
                            {vault.platforms.length > 1 && <Icon as={FaInfoCircle} mx="auto" pl={1} w={4} h={4} /> }
                        </Flex>
                    </Badge>
                </ResponsiveTooltip>
                {vault.otherInfo ? vault.otherInfo.map((i) =>
                    <Badge my="2px" colorScheme="green" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                        {i}
                    </Badge>) : null}
                {!!vault.deprecated && <Badge my="2px" colorScheme="red" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                    ARCHIVED
                </Badge>}
            </Flex>
            <Text fontSize="14px" fontWeight="normal" px={2}>
                {vault.description}
                {/* <Link mt="2" display="flex" alignItems="center" href="https://docs.bloomify.co/" target="_blank" color="#8A88B7">
                    Learn more <Icon ml={1} as={BsBoxArrowUpRight} />
                </Link> */}
            </Text>
        </Box>
        <Box as="section" pl={{ base: 0, lg: 4 }} my="auto">
            <Box mx="auto">
                <Stack
                    p={2}
                    spacing="2"
                    justify="center"
                    flexWrap="wrap"
                    minW={{ base: '100%', lg: '42vw', xl: '600px' }}
                    direction={{ base: 'column', sm: 'row' }}
                    divider={<StackDivider display={{ base: 'none', sm: 'flex' }} />}>
                    {stats && stats.length ? stats.map((i, key) =>
                        <StatCard {...i} key={key} />) : null}
                </Stack>
            </Box>
        </Box>
    </Flex>
	);
}

export default VaultDetailsHeader;
