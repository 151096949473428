/* eslint-disable no-undef */
import {
	Button, ButtonProps, useColorModeValue as mode, Icon, Flex,
} from '@chakra-ui/react';
import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { useWalletProvider } from '../../../hooks/useWalletProvider';
import { ResponsiveTooltip } from '../Tooltip';

type Props = ButtonProps & {
    text: string
	onClick: () => void
	info?: string
}

function Web3Button({
	onClick, text, info, ...rest
}: Props) {
	const { account } = useWeb3React();
	const {
		onPresentConnectModal, logoutWallet, onPresentAccountModal, wallet,
	} = useWalletProvider();

	return (
    <Button {...rest} onClick={!account ? onPresentConnectModal : onClick} bg={mode('white', 'gray.700')} border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}>
        <ResponsiveTooltip label={info} hasArrow placement="bottom" p={4} borderRadius="md">
            <Flex align="center">
                {!account ? 'Connect Wallet' : text} {info && <Icon as={FaInfoCircle} mx="auto" pl={1} w={4} h={4} />}
            </Flex>
        </ResponsiveTooltip>
    </Button>
	);
}

export default Web3Button;
