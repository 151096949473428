/* eslint-disable react/no-children-prop */
import React from 'react';
import {
	Box,
	BoxProps,
	Circle,
	NumberInput,
	NumberInputField,
	Flex,
	Icon,
	NumberIncrementStepper,
	NumberDecrementStepper,
	InputRightElement,
	Slider,
	SliderFilledTrack, SliderThumb,
	SliderTrack,
	Spacer,
	Text,
} from '@chakra-ui/react';

import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { FaMinus } from '@react-icons/all-files/fa/FaMinus';
import TokenImage from '../TokenImage';

type Props = BoxProps &{
  show: boolean
  title: string
  subtitle: string
  value: any
  color: string
  legos?: boolean
  token?: any
  actionOutputToBlock?: string
  onChange: (i: any) => void
}

function SliderGroupSection({
	show, token, title, subtitle, onChange, value, color, actionOutputToBlock, ...props
} : Props) {
	const format = (val) => `${val}%`;
	const parse = (val) => Number(val.replace('%', ''));

	return (
    <Box py={1} data-tut={props['data-tut']}>
        <Flex pt={4} flexDir={{ base: 'column', xs: 'row' }}>
            <Flex>
                <Box px={3}>
                    <Text lineHeight="1" color={`${color}.500`} fontWeight="medium" fontSize="xl">{title}</Text>
                    <Text mx="auto" fontSize="sm">{subtitle}</Text>
                    { token && <Flex align="center">
                        <TokenImage token={token.symbol} sm />
                        <Text ml={2} fontSize="sm" lineHeight="1.2">{actionOutputToBlock}</Text>
                    </Flex> }
                </Box>
            </Flex>
            <Spacer />
            { !props.legos ?
                <NumberInput
                    min={0}
                    max={100}
                    h="40px"
                    w={{ base: '140px', md: '160px' }}
                    variant="unstyled"
                    maxW="165px"
                    value={format(value)}
                    onChange={(val) => onChange(parse(val))}
                    display="flex"
                    isDisabled={!show}>
                    <NumberDecrementStepper
                        w={{ base: '30px', md: '40px' }}
                        borderRadius="12px"
                        border="none"
                        bg="transparent"
                        children={<Icon
                            as={FaMinus}
                            mx="auto"
                            py={1}
                            borderRadius={20}
                            bg={`${color}.500`}
                            w={5}
                            h={5}
                            color="white" />}
          />
                    <NumberInputField
                        paddingInlineEnd={0}
                        rounded="xl"
                        color={`${color}.500`}
                        fontSize={{ base: '2xl', md: '3xl' }}
                        fontWeight="bold"
                        w={{ base: '72px', md: '90px' }}
                        textAlign="center" />
                    <NumberIncrementStepper
                        w={{ base: '30px', md: '40px' }}
                        borderRadius="12px"
                        border="none"
                        bg="transparent"
                        children={<Icon
                            as={FaPlus}
                            mx="auto"
                            py={1}
                            borderRadius={20}
                            bg={`${color}.500`}
                            w={5}
                            h={5}
                            color="white" />}
          />
                </NumberInput> : <Text
                    pl={{ base: 3, xs: 0 }}
                    color={`${color}.500`}
                    fontSize={{ base: '2xl', md: '3xl' }}
                    fontWeight="bold"
                    w={{ base: '72px', md: '90px' }}>{value}%</Text> }
        </Flex>
        { !props.legos ? <Flex bg="bg-canvas" rounded="xl" px={5} py={3} mt={3}>
            <Slider
                colorScheme={color}
                isDisabled={!show}
                value={value}
                onChange={onChange}
                focusThumbOnChange={false}>
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider>
        </Flex> : null }
    </Box>
	);
}

export default SliderGroupSection;
