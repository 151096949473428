import _ from 'lodash';

export const formatWalletAddr = (addr: string) => `${addr.slice(0, 4)}...${addr.slice(addr.length - 4)}`;

export const formatUS = (value: number) => new Intl.NumberFormat('en-US', { style: 'decimal', currency: 'USD' }).format(value);

export function timeDifference(current, previous) {
	const msPerMinute = 60 * 1000;
	const msPerHour = msPerMinute * 60;
	const msPerDay = msPerHour * 24;
	const msPerMonth = msPerDay * 30;
	const msPerYear = msPerDay * 365;

	const elapsed = current - previous;

	if (elapsed < msPerMinute) {
		return `${pluralize(Math.round(elapsed / 1000), "second")} ago`;
	}

	if (elapsed < msPerHour) {
		return `${pluralize(Math.round(elapsed / msPerMinute), "minute")} ago`;
	}

	if (elapsed < msPerDay) {
		return `${pluralize(Math.round(elapsed / msPerHour), "hour")} ago`;
	}

	if (elapsed < msPerMonth) {
		return `${pluralize(Math.round(elapsed / msPerDay), "day")} ago`;
	}

	if (elapsed < msPerYear) {
		return `${pluralize(Math.round(elapsed / msPerMonth), "month")} ago`;
	}

	return `${pluralize(Math.round(elapsed / msPerYear), "year")} ago`;
}

const pluralize = (count: number, noun: string, suffix = 's') =>
	`${count} ${noun}${count !== 1 ? suffix : ''}`;
