/* eslint-disable react/function-component-definition */
import React, {
	FC, ChangeEvent, useEffect, useState,
} from 'react';
import {
	Pagination,
	usePagination,
	PaginationNext,
	PaginationPage,
	PaginationPrevious,
	PaginationContainer,
	PaginationPageGroup,
} from '@ajna/pagination';

type Props = {
    totalPages?: any
    currentSetPage?: any
    setPage?: (pageNumber) => void
  }

function PaginationModule({ totalPages = 5, currentSetPage = 1, setPage }:Props) {
	const {
		currentPage,
		setCurrentPage,
		pagesCount,
		pages,
	} = usePagination({
		pagesCount: totalPages,
		initialState: { currentPage: currentSetPage },
	});

	return (
		totalPages > 1 && <Pagination
    pagesCount={pagesCount}
    currentPage={currentPage}
    onPageChange={(page) => { setCurrentPage(page); setPage(page); }}>
    <PaginationContainer>
        <PaginationPrevious>Previous</PaginationPrevious>
        <PaginationPageGroup>
            {pages.map((page: number) => (
                <PaginationPage
                    key={`pagination_page_${page}`}
                    page={page}
              />
            ))}
        </PaginationPageGroup>
        <PaginationNext>Next</PaginationNext>
    </PaginationContainer>
    </Pagination>
	);
}

export default PaginationModule;
