/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import {
	Flex,
	useBreakpointValue, Spacer, Text, Switch, FormLabel,
} from '@chakra-ui/react';
import * as React from 'react';

function GridView({
	children, isGridView, setIsGridView, length = 0, vaults = false, farms = false,
}) {
	const toggleClass = () => setIsGridView(!isGridView);
	const isMobile = useBreakpointValue({ base: true, xl: false });

	return (
		<>
        { !isMobile && length > 0 &&
        <Flex align="center" justify="center" pb={4}>
            <Switch colorScheme="purple" id="grid-view" onChange={toggleClass} size="lg" />
            <FormLabel m={0} pl={2} htmlFor="grid-view">Toggle grid view</FormLabel>
        </Flex>}
        <Flex w="100%" h="100%" overflowY="auto" py={1} flexWrap="wrap" align="center" flexDir={{ base: 'row', xl: isGridView ? 'row' : 'column' }} px={3}>
        {!isGridView && !isMobile && length ?
                <Flex
                    maxW="8xl"
                    pb={2}
                    w={{ xl: '96%' }}
                    mx="2%"
                    h="max-content"
                    bg="transparent"
                    cursor="pointer"
                    flexDir={{ xl: 'row' }}
                    justify="space-between">
                <Text fontSize="sm" pl="4" minW="275px" textAlign="left" fontWeight="light" textColor="gray.400">Details</Text>
        { !vaults && !farms && <Text fontSize="sm" textAlign="center" fontWeight="light" minW={{ lg: '520px' }} textColor="gray.400">Pools</Text> }
                <Spacer />
                <Text fontSize="sm" fontWeight="light" textColor="gray.400"> </Text> {/*  //Stats */}
                <Spacer />
            </Flex> : null}
        {children}
    </Flex>
		</>
	);
}

export default GridView;
