/* eslint-disable no-shadow */
import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import useWeb3 from '../../../hooks/useWeb3';
import { fetchFarms } from '../services/farm.service';

export const useAllFarms = () => {
	const web3 = useWeb3();
	const { account } = useWeb3React();

	const [farms, setFarms] = useState([]);

	useEffect(() => {
		get(account);
	}, [account]);

	const get = async (wallAccount) => {
		const farms = await fetchFarms(web3, null, wallAccount);

		return setFarms(farms.length ? farms : null);
	};

	return farms;
};
