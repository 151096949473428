import {
	Box,
	Text,
	Flex,
	useColorModeValue as mode,
	Badge,
	Spacer,
	HStack,
	Icon,
	Button,
	Tooltip,
	Skeleton,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { AiOutlineQuestionCircle } from '@react-icons/all-files/ai/AiOutlineQuestionCircle';
import { useWeb3React } from '@web3-react/core';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle';
import { AiOutlineBank } from '@react-icons/all-files/ai/AiOutlineBank';
import { AiOutlineArrowRight } from '@react-icons/all-files/ai/AiOutlineArrowRight';
import TokenImage from '../../../common/TokenImage';
import { formatValue } from '../../../../helpers/util';
import { GeneralCardAPRDetails } from '../../../common/APRDetails';
import { ValueTokenStat } from '../../../common/ValueTokenStat';
import { timeDifference } from '../../../../helpers/formating';
import { ResponsiveTooltip } from '../../../common/Tooltip';
// @ts-ignore
import bg from '../../../../assets/bank-bg.svg';

type Props = {
  vault?: any;
  isGridView?: boolean;
  isMobile?: boolean;
};

function VaultCard({
	vault,
	isGridView,
	isMobile,
	...boxProps
}: Props) {
	const navigate = useNavigate();

	const { account } = useWeb3React();
	const url = `/${vault.type.toLowerCase()}s/${vault.address}`;
	const lastHarvest = vault && vault.lastHarvest === 0
		? 'Never' : vault && timeDifference(new Date().getTime(), new Date(vault.lastHarvest * 1000).getTime());

	return (
    <Flex
        pb={6}
        px={{ base: 0, lg: 2, xl: isGridView ? 3 : 1 }}
        w={{ base: '100%', lg: '50%', xl: isGridView ? '33.33%' : '100%' }}
        justify="center"
        alignSelf="stretch"
    >
        <Flex
            w="full"
            transition="transform 0.15s ease-in-out"
            _hover={{ transform: 'scale3d(1.02, 1.02, 1)' }}
            maxW="8xl"
            h={{ base: 'max-content', lg: '100%', xl: isGridView ? '100%' : 'max-content' }}
            rounded="3xl"
            bg="bg-surface"
            // bgImg={vault.lastHarvest ? bg : ''}
            p={{ base: 2, sm: 4 }}
            onClick={() => navigate(`${url}`, { state: { vault } })}
            boxShadow={mode('sm', 'sm-dark')}
            cursor="pointer"
            flexDir={{ base: 'column', xl: isGridView ? 'column' : 'row' }}
            justify="space-between"
        >
            <Flex justify="start">
                <Flex>
                    <Flex flexDir="column" align="center" minW="36px" pl={1} my={3}>
                        <TokenImage token={vault.depositToken.symbol} w={vault.accumulatedToken ? '44px' : '60px'} h="auto" />
                        {vault.accumulatedToken &&
                        <>
                            <Icon as={AiOutlineArrowRight} transform="rotate(90deg)" color="#8A88B7" />
                            <TokenImage token={vault.accumulatedToken.symbol} w="44px" h="auto" />
                        </>}
                    </Flex>
                    <Box my="auto" px={1}>
                        <Flex>
                            <Flex ml={1} py={1} justifyContent="center">
                                <Text
                                    my="auto"
                                    fontSize={{ base: 'lg', sm: 'xl' }}
                                    pl={2}
                                    pr={1}
                                    pb={0}
                                    fontWeight="bold"
                            >
                                    {vault.isUsingAccumulations && <Icon as={AiOutlineBank} w="25px" h="25px" color="green.400" transform="translateY(4px)" mr={1} /> }
                                    {vault.name}
                                </Text>
                            </Flex>

                            <ResponsiveTooltip
                                label={vault.description}
                                hasArrow
                                placement="top"
                                p={4}
                                borderRadius="md"
                            >
                                <Button
                                    py={0}
                                    px={1}
                                    leftIcon={(
                                        <Icon
                                            as={AiOutlineQuestionCircle}
                                            w="16px"
                                            h="16px"
                                        />)}
                                    minW="20px"
                                    colorScheme="grey"
                                    variant="link"
                                />
                            </ResponsiveTooltip>
                        </Flex>
                        <Flex maxW={{ base: '78vw', md: '60vw' }} flexWrap="wrap">
                            <Badge
                                my="2px"
                                colorScheme="purple"
                                mx={1}
                                fontSize="12px"
                                variant={mode('solid', 'subtle')}
                            >
                                {vault.type}
                            </Badge>
                            {!!vault.customStrat && <Badge
                                my="2px"
                                colorScheme="orange"
                                mx={1}
                                fontSize="12px"
                                variant={mode('solid', 'subtle')}
                            >
                                Based on {vault.customStrat.name}
                            </Badge>}
                            <ResponsiveTooltip label={vault.platforms.join(', ')} hasArrow placement="bottom" p={4} borderRadius="md">
                                <Badge colorScheme="yellow" my="2px" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                                    <Flex align="center">  {vault.platforms.length > 1 ? `${vault.platforms.length} Platform(s)` : vault.platforms[0]}
                                        {vault.platforms.length > 1 && <Icon as={FaInfoCircle} mx="auto" pl={1} w={4} h={4} /> }
                                    </Flex>
                                </Badge>
                            </ResponsiveTooltip>
                            {vault.otherInfo ? vault.otherInfo.map((i) =>
                                <Badge my="2px" colorScheme="green" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                                    {i}
                                </Badge>) : null}
                            {!!vault.deprecated && <Badge my="2px" colorScheme="red" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                                ARCHIVED
                            </Badge>}
                        </Flex>
                    </Box>
                </Flex>
            </Flex>
            {!isGridView && <Spacer />}
            {/* <HStack
                justify="center"
                my={{ base: 4, md: 3 }}
                mx={isGridView ? null : { base: 4, md: 3 }}
                flexDir={{ base: 'column', sm: 'row' }}
                spacing={{ base: 0, sm: 6 }}
                bg={mode('#FAFAFA', 'blackAlpha.300')}
                px={6}
                py={4}
                rounded="lg"
            >
                <Box my="auto" minW="60px">
                    <Flex justify="center" align="center" direction="column">
                        <Text fontSize="sm" fontWeight="light" textColor="gray.400">
                            TVL
                        </Text>
                        <Text fontSize="lg" fontWeight="bold">
                            ${formatValue(vault.tvl || 0, 2)}
                        </Text>
                    </Flex>
                </Box>
                <Box my="auto" minW="60px">
                    <GeneralCardAPRDetails aprDetails={vault.aprDetails} itemType={vault ? vault.type : null} />
                </Box>
                {vault.lastHarvest && <Box my="auto" minW="105px">
                    <ResponsiveTooltip label={`Last time yield bought more ${vault.accumulatedToken.name}`} hasArrow placement="bottom" p={4} borderRadius="md">
                        <Flex justify="center" align="center" direction="column">
                            <Text fontSize="sm" fontWeight="light" textColor="gray.400">
                                <Flex justify="center" align="center"> <Icon as={FaInfoCircle} mx="auto" pl={1} w={4} h={4} mr={1} /> Last Harvest </Flex>
                            </Text>
                            <Text fontSize="md" fontWeight="bold">
                                {lastHarvest}
                            </Text>
                        </Flex>
                    </ResponsiveTooltip>
                </Box>}
            </HStack> */}
            {!!account && <HStack
                justify="center"
                my={{ base: 4, md: 3 }}
                mx={isGridView ? null : { base: 4, md: 3 }}
                flexDir={{ base: 'column', sm: 'row' }}
                spacing={{ base: 0, sm: 8 }}
                bg={mode('#FAFAFA', 'blackAlpha.300')}
                pl={6}
                pr={vault.accumulatedToken ? 4 : 6}
                py={vault.accumulatedToken ? 3 : 4}
                rounded="lg"
                >

                <ValueTokenStat
                    text="Deposited"
                    amount={vault.depositDetails.amount}
                    value={vault.depositDetails.value}
                    token={vault.depositToken.symbol} />

                {vault.accumulatedToken && <HStack
                    justify="center"
                    my={{ base: 4, md: 3 }}
                    mx={isGridView ? null : { base: 4, md: 2 }}
                    flexDir={{ base: 'column', sm: 'row' }}
                    spacing={{ base: 0, sm: 6 }}
                    bg={mode('#FAFAFA', 'blackAlpha.300')}
                    px={6}
                    py={3}
                    rounded="lg"
                >
                    <ValueTokenStat
                        text={vault.type.toLowerCase().includes('profit') ? 'Profit Taken' : 'Accumulated'}
                        amount={vault.accumulatedDetails.amount}
                        value={vault.accumulatedDetails.value}
                        token={vault.accumulatedToken.symbol}
                        info="The claimable/yielded tokens autocompound in a bank, continuing to generate more yield until
they are withdrawn or claimed." />
                </HStack> }
            </HStack> }
            {/* {deposit && profit && (
                	<>
                    {' '}
                    <Box my="auto" minW="60px">
                        <Flex justify="center" align="center" direction="column">
                            <Text fontSize="sm" fontWeight="light" textColor="gray.400">
                                Deposit
                            </Text>
                            <Text fontSize="lg" fontWeight="bold">
                                {deposit}
                            </Text>
                        </Flex>
                    </Box>
                    <Box my="auto" minW="60px">
                        <Flex justify="center" align="center" direction="column">
                            <Text fontSize="sm" fontWeight="light" textColor="gray.400">
                                Profit
                            </Text>
                            <Text fontSize="lg" fontWeight="bold">
                                {profit}
                            </Text>
                        </Flex>
                    </Box>
                    {' '}
                	</>
                )} */}
            {(isGridView || isMobile ? (
                <Flex flexDir="column" bg="purple.500" borderRadius={20}>
                    <Icon
                        as={FaPlus}
                        mx="auto"
                        py={2}
                        w={10}
                        h={10}
                        color="white"
                  />
                </Flex>
            ) : (
                <Flex flexDir="column" ml={2} justify="center">
                    <Icon
                        as={FaPlus}
                        mx="auto"
                        py={2}
                        borderRadius={20}
                        bg="purple.500"
                        w={10}
                        h={10}
                        color="white" />
                </Flex>
            ))}
        </Flex>
    </Flex>
	);
}

export default VaultCard;
