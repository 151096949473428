import React, { useState } from 'react';
import {
	Badge, Box, Button, Container, Flex, Icon, Image, Stack, StackDivider, Text, Tooltip, useColorModeValue as mode,
} from '@chakra-ui/react';
import { AiOutlineQuestionCircle } from '@react-icons/all-files/ai/AiOutlineQuestionCircle';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { StatCard } from '../StatComponent';
import PageTour from '../PageTour';
import {ResponsiveTooltip} from "../Tooltip";

type Props = {
  strategy: any
  stats?: any
  hasTour?: boolean
}
function StrategyDetailsHeader({ strategy, stats, hasTour = false }: Props) {
	const [isTourOpen, setIsTourOpen] = useState(false);
	const openTour = () => setIsTourOpen(true);

	return (
    <Flex justify="space-between" py={2} px={0} rounded="xl" flexDir={{ base: 'column', xl: 'row' }}>
        <PageTour isTourOpen={isTourOpen} setIsTourOpen={setIsTourOpen} isLegosTour />
        <Box py={2} my="auto">
            { !hasTour ? (
                <Text fontSize="3xl" fontWeight="bold" my="auto" px={2}>{strategy.name}</Text>
            ) : (
                <Flex>
                    <Text fontSize="3xl" fontWeight="bold" my="auto" px={2}>
                        {strategy.name}
                    </Text>
                    <ResponsiveTooltip
                        label="Need help? Try this quick tutorial"
                        hasArrow
                        placement="right"
                        p={4}
                        borderRadius="md"
            >
                        <Button
                            onClick={() => { setTimeout(() => window.scrollTo(0, 150), 10); openTour(); }}
                            py={0}
                            px={1}
                            leftIcon={
                                <Icon as={AiOutlineQuestionCircle} w="16px" h="16px" />
                }
                            minW="20px"
                            colorScheme="grey"
                            variant="link"
               />
                    </ResponsiveTooltip>
                </Flex>)}
            <Flex mt="2px" mb="0.5rem" flexWrap="wrap" maxW="40vw">
                <Badge colorScheme="purple" my="2px" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>{strategy.type}</Badge>
                <ResponsiveTooltip label={strategy.platforms.join(', ')} hasArrow placement="bottom" p={4} borderRadius="md">
                    <Badge colorScheme="yellow" my="2px" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                        <Flex align="center">  {strategy.platforms.length > 1 ? `${strategy.platforms.length} Platform(s)` : strategy.platforms[0]}
                            {strategy.platforms.length > 1 && <Icon as={FaInfoCircle} mx="auto" pl={1} w={4} h={4} /> }
                        </Flex>
                    </Badge>
                </ResponsiveTooltip>
                {(!!strategy.earn || !!strategy.deprecated) && <Badge my="2px" colorScheme="orange" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                    {strategy.deprecated ? 'ARCHIVED' : `Earn ${strategy.earn}`}
                </Badge>}
                {strategy.otherInfo ? strategy.otherInfo.map((i) =>
                    <Badge my="2px" colorScheme="green" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                        {i}
                    </Badge>) : null}
            </Flex>
            <Text fontSize="14px" fontWeight="normal" px={2}>{strategy.description}</Text>
        </Box>
        <Box as="section" pl={{ base: 0, lg: 4 }} my="auto">
            <Box mx="auto">
                {/* <Box bg="bg-surface" rounded="xl" shadow={mode("sm", "md-dark")}> */}
                <Stack
                    p={2}
                    spacing="2"
                    justify="center"
                    flexWrap="wrap"
                    minW={{ base: '100%', lg: '42vw', xl: '600px' }}
                    direction={{ base: 'column', sm: 'row' }}
                    divider={<StackDivider display={{ base: 'none', sm: 'flex' }} />}
          >
                    {stats && stats.length ? stats.map((i, key) =>
                        <StatCard {...i} key={key} />) : null}
                </Stack>
                {/* </Box> */}
            </Box>
        </Box>
    </Flex>
	);
}

export default StrategyDetailsHeader;
