/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	HStack,
	ModalBody,
	ModalCloseButton,
	Button,
	Flex,
	Text,
	Skeleton,
	Box,
	NumberInput,
	NumberInputField,
	FormControl,
	FormErrorMessage,
	VStack,
	useColorModeValue as mode,
	Select,
	Link,
	Icon,
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import _ from 'lodash';
import { BigNumber } from 'ethers';
import {parseUnits, formatEther, formatUnits, parseEther} from 'ethers/lib/utils';
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';
import { IoWarning } from '@react-icons/all-files/io5/IoWarning';
import useWeb3 from '../../../../hooks/useWeb3';
import TokenImage from '../../TokenImage';
import { erc20Allowance, erc20Approve } from '../../../../service/erc2.service';
import { defaultChain } from '../../../../bloomify/config';
import { formatValue } from '../../../../helpers/util';
import { useTokenPrices } from '../../../../contexts/TokenPricesContext';
import { useTokenBalance } from '../../../../contexts/TokensBalancesContext';
import {ethers} from "ethers/lib.esm";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDeposit: (amount, onError, onReceipt, token?) => void;
  onDepositAll: (amount, onError, onReceipt) => void;
  canDepositAll?: boolean,
  base: any;
  description?: string;
};

function DepositModal({
	isOpen,
	onClose,
	onDeposit,
	onDepositAll,
	canDepositAll = true,
	base,
	description = base.depositToken.canZap ? `Deposit ${base.depositToken.name} or Zap one of the other available tokens` : null,
}: Props) {
	const [maxEnabled, setMaxEnabled] = useState<any>(false);

	const { account } = useWeb3React();
	const { getTokenPrice, tokens } = useTokenPrices();
	const { getTokenBalance, balances } = useTokenBalance();
	const web3 = useWeb3();

	const [depositToken, setDepositToken] = useState<any>(base.depositToken);
	// Gets the allowance of a given token for a given wallet
	const getAllowance = async (tokenDetails) => {
		const allowance = await erc20Allowance(web3, tokenDetails.address, base.address, account);
		setTokenAllowance(parseEther(allowance));
	};

	const [tokenAllowance, setTokenAllowance] = useState<BigNumber>(ethers.constants.Zero);

	const depositTokens = [];

	try {
		if (base.depositToken.address.toLowerCase() === defaultChain.addressBook.lps['pancake-stable-usdt-usdc'].address.toLowerCase()) {
			depositTokens.push(...[defaultChain.addressBook.tokens.USDC, defaultChain.addressBook.tokens.USDT])
		} else if (base.depositToken.address.toLowerCase() === defaultChain.addressBook.lps['pancake-stable-usdt-busd'].address.toLowerCase()) {
			depositTokens.push(...[defaultChain.addressBook.tokens.BUSD, defaultChain.addressBook.tokens.USDT])
		} else if (base.depositToken.address.toLowerCase() === defaultChain.addressBook.lps['pancake-stable-usdc-busd'].address.toLowerCase()) {
			depositTokens.push(...[defaultChain.addressBook.tokens.BUSD, defaultChain.addressBook.tokens.USDC])
		} else {
			depositTokens.push(...[defaultChain.addressBook.tokens.BUSD])
		}
	}catch (e) {
	}

	if (!depositTokens.some((t) => t.address.toLowerCase() === base.depositToken.address.toLowerCase())) {
		depositTokens.push(base.depositToken);
	}

	useEffect(() => {
		if (account) getAllowance(base.depositToken);
	}, [account]);

	const tokenBalance = balances && getTokenBalance(depositToken) ? getTokenBalance(depositToken).integer : 0;

	const [depositAmount, setDeposit] = useState<BigNumber>(BigNumber.from(0));
	const [input, setInput] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [completeTokenBalancePrice, setCompleteTokenBalancePrice] = useState<number>(getTokenPrice(depositToken)
		? tokenBalance * getTokenPrice(depositToken) : 0);
	const [inputPrice, setInputPrice] = useState<number>(input * getTokenPrice(depositToken));

	// Checks if there is balances and a valid deposit > 0
	const isError = balances && depositAmount && depositAmount.gt(getTokenBalance(depositToken).hex);

	// uses util function to map an address to a token name / LP name
	const tokenAddr = depositToken.address;

	// Changes the deposit token
	const onChangeDepositToken = async (token: any) => {
		setDepositToken(JSON.parse(token));
		setMaxEnabled(false);
		setCompleteTokenBalancePrice(getTokenBalance(depositToken).integer * getTokenPrice(depositToken));
		await getAllowance(JSON.parse(token));
		setDeposit(parseUnits('0', token.decimals));
		setInput(0);
	};

	useEffect(() => {
		setInputPrice(input * getTokenPrice(depositToken));
	}, [input]);

	useEffect(() => {
		setCompleteTokenBalancePrice(tokenBalance * getTokenPrice(depositToken));
	}, [tokens, tokenBalance, depositToken]);

	// Approval to spend the given ERC20 token
	const onApprove = async () => {
		setIsLoading(true);
		const response = await erc20Approve(web3, tokenAddr, base.address, account, () => setIsLoading(true));

		if (response.status) {
			await getAllowance(depositToken);
			setIsLoading(false);
		}
	};

	// Deposits given token in the block
	const depositFunds = async () => {
		setIsLoading(true);
		if (maxEnabled && canDepositAll) {
			await onDepositAll(
				depositAmount,
				() => setIsLoading(false),
				() => onClose(),
			);

			setDeposit(parseUnits('0', depositToken.decimals));
		} else {
			await onDeposit(
				depositAmount,
				() => setIsLoading(false),
				() => onClose(),
				depositToken
			);

			setDeposit(parseUnits('0', depositToken.decimals));
		}
	};

	const roundDeposits = (percent: number) => {
		if (balances) {
			const tBalance = getTokenBalance(depositToken).hex;

			switch (percent) {
				case 25: {
					setMaxEnabled(false);
					return tBalance ? tBalance.div(4) : BigNumber.from(0);
				}
				case 50: {
					setMaxEnabled(false);
					return tBalance ? tBalance.div(2) : BigNumber.from(0);
				}
				case 75: {
					setMaxEnabled(false);
					return tBalance ? tBalance.div(4).mul(3) : BigNumber.from(0);
				}
				case 100: {
					setMaxEnabled(true);
					return tBalance || BigNumber.from(0);
				}
				default: {
					setMaxEnabled(false);
					return BigNumber.from(0);
				}
			}
		}
	};
	const roundDepositArr = [25, 50, 75, 100];

	return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
        <ModalOverlay />
        <ModalContent maxW="620px" px={{ base: 4, md: 5 }}>
            <ModalHeader mx="auto" px={{ base: 1, md: 5 }}>
                <Flex direction="column" justify="center" align="center">
                    <Text fontWeight="bold" fontSize="25px">
                        Deposit {base.depositToken.name}
                        {!base.depositToken.canZap && base.depositToken.buyLink &&
                        <Link href={base.depositToken.buyLink} target="_blank" ml={2}>
                            <Button variant="link">
                                ({' '}{base.depositToken.isLP ? 'Get LP' : 'Buy Token'} <Icon as={FaExternalLinkAlt} mx="auto" pl={2} w={5} h={5} mr={1} color="muted" /> )
                            </Button>
                        </Link>}
                    </Text>
                    <Text fontSize="sm" pl="4" minW="275px" textAlign="center" fontWeight="light" textColor="#8A88B7">
                        {description}
                    </Text>
                </Flex>
            </ModalHeader>
            <ModalCloseButton transition="transform 0.15s ease-in-out" _hover={{ transform: 'scale3d(1.02, 1.02, 1)' }} />
            <ModalBody p={{ base: 1, md: 5 }}>
                <VStack justifyContent="start" spacing={5}>
                    <Flex alignItems="center" justifyContent="space-between" w="full">
                        <Text color="#8A88B7" mb={2}>{base.depositToken.canZap ? 'Token used for deposit' : 'Deposit' }</Text>
                        <Box>
                            <Flex justify="center" align="center">
                                <TokenImage sm token={base.depositToken.symbol.toLowerCase()} />
                                { !base.depositToken.canZap
    	? <Text ml={2}>{depositToken.name} </Text>
                                	: <Select
                                        bg={mode('#FAFAFA', 'blackAlpha.300')}
                                        variant="filled"
                                        ml={2}
                                        maxW="200px"
                                        onChange={(e) => onChangeDepositToken(e.target.value === defaultChain.nativeToken ? 'native' : e.target.value)}>
                                    <option value="none" selected disabled hidden>
                                        Select an Option
                                    </option>
                                    {depositTokens.map((tokenDetails: any, key: number) => (
                                        <option
                                            key={key}
                                            selected={tokenDetails.address.toLowerCase() === depositToken.address.toLowerCase() && true}
                                            value={JSON.stringify(tokenDetails)}>
                                            {tokenDetails.name}
                                        </option>
                                    ))}
                                </Select>}
                            </Flex>
                        </Box>
                    </Flex>
                    <Skeleton isLoaded={balances} alignSelf="flex-end" style={{ marginTop: 0 }}>
                        <Text fontSize="sm" color="#8A88B7" mt="2px">
                            Balance ~{formatValue(tokenBalance, 3)} {depositToken.symbol === 'PULSE' ? '' : `($${formatValue(completeTokenBalancePrice, 2)})`}
                        </Text>
                    </Skeleton>
                </VStack>
                <Flex flexDir="column" justify="center" align="center" py={3}>
                    <FormControl isInvalid={isError} mr={2} rounded="lg" bg={mode('#FAFAFA', 'blackAlpha.300')} py={3} px={4}>
                        <NumberInput
                            variant="unstyled"
                            defaultValue={0}
                            value={input}
                            onChange={(num: any) => {
                            	setInput(num);
                            	setMaxEnabled(false);
                            	setDeposit(parseUnits(num || '0', depositToken.decimals));
                            }}
                            w="100%">
                            <NumberInputField w="100%" fontSize="25px" bg="transparent" />
                        </NumberInput>
                        {depositToken.symbol === 'PULSE' ? null : <Text fontSize={14} color="#8A88B7">~{`$${formatValue(inputPrice, 2)}`}</Text>}
                    </FormControl>
                    {isError ?
                        <Flex align="center" pt="2px" pb={1}>
                            <Box maxW={10}>
                                <Icon as={IoWarning} color="pink.500" />
                            </Box>
                            <Text ml={2} color="pink.500" fontSize="sm">
                                Insufficient or no tokens
                            </Text>
                        </Flex> : <Box h="30px" />}
                    {depositToken !== 'native' ? (
                        <Flex pt={2} flexDir="row" flexWrap="wrap" justifyContent="center">
                            {roundDepositArr.map((el, i) =>
                                <Button
                                    my={{ base: 1, md: 0 }}
                                    mx={2}
                                    variant="filled"
                                    bg={mode('#FAFAFA', 'blackAlpha.300')}
                                    w={{ base: '120px', md: '100px' }}
                                    onClick={() => {
    	setInput(Number(formatUnits(roundDeposits(el), depositToken.decimals)));
    	setDeposit(roundDeposits(el));
                                    }}>
                                    {el}%
                                </Button>)}
                        </Flex>
                    ) : null}
                </Flex>
                {/* <Text color={'#8A88B7'} fontSize={'sm'} pb={1}>Overview</Text>
								<Flex
									bg={mode('#FAFAFA', 'blackAlpha.300')}
									rounded="lg"
									flexDir={{ base: 'column', sm: 'row'}}
									px="2"
									justify="space-evenly"
									pt={{ base: '2', md: '4' }}
									w={'100%'}
									textAlign={{ base: 'left', md: 'center' }}
								>
									{fees.map((fee, i) => <StatCard {...fee} size="sm" key={i}/>)}
								</Flex> */}
                {tokenAllowance.lt(ethers.constants.MaxUint256)
				&& depositToken.address.toLowerCase() !== defaultChain.nativeTokenAddress.toLowerCase() ? (
    <Button
        my=""
        marginTop="1rem"
        isLoading={isLoading}
        w="100%"
        bg={mode('white', 'gray.800')}
        border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}
        leftIcon={<FaCheck />}
        onClick={onApprove}>
        Approve {depositToken.symbol.toUpperCase()}
    </Button>
                                	) : (
                                    <Button
                                        my="auto"
                                        marginTop="1rem"
                                        isDisabled={isError || depositAmount.eq(0)}
                                        isLoading={isLoading}
                                        w="100%"
                                        bg={mode('white', 'gray.800')}
                                        border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}
                                        onClick={depositFunds}>
                                        Confirm
                                    </Button>
                                	)}
            </ModalBody>
        </ModalContent>
    </Modal>
	);
}

export default DepositModal;
