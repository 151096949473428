import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const setChain = (chain: string) => {
	cookies.remove('currentChain', { path: '/' });
	cookies.set('currentChain', chain, { path: '/' });
	localStorage.removeItem('calculatedStats');
	localStorage.removeItem('fetchedFarms');
	localStorage.removeItem('fetchedVaults');
	localStorage.removeItem('remoteTokenPrices');
	localStorage.removeItem('tokenPrices');
	localStorage.removeItem('tokenBalances');
	setTimeout(() => { document.location.href = '/accumulators'; return; }, 300);
};

export const getSavedChain = () => cookies.get('currentChain') || 'BSC';
