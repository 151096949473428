/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import {
	Box, Button, Tooltip, Flex, Icon, Text, useColorModeValue as mode, useBreakpointValue,
} from '@chakra-ui/react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Tour from 'reactour';
import { getTourSteps, getMyLegosTourSteps } from '../../../helpers/tour';

type Props = {
  blocks?: Array<any>
  nextStep?: () => any
  setIsTourOpen: (e: any) => any
  isTourOpen: any
  currentStep?: any
  isLegosTour?: boolean
}

function PageTour({
	nextStep, blocks, setIsTourOpen, currentStep, isTourOpen, isLegosTour = false,
}: Props) {
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const disableBody = (target) => disableBodyScroll(target);
	const enableBody = (target) => enableBodyScroll(target);

	const closeTour = () => setIsTourOpen(false);

	const accentColor = mode('#05050F', '#2D3748');
	const tourConfig = isLegosTour ? getMyLegosTourSteps(isMobile) : getTourSteps(blocks, currentStep, nextStep);

	return (
		<>
        <Tour
            py={0}
            getCurrentStep={() => {}}
            onRequestClose={closeTour}
            steps={tourConfig}
            startAt={0}
            isOpen={isTourOpen}
            rounded={5}
            accentColor={accentColor}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
      />
		</>
	);
}

export default PageTour;
