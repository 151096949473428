import { addressBook } from './addressbook.config';

const CONFIG_ADAPTOR_ZAPPER_PANCAKE = 4000001;
const CONFIG_ADAPTOR_SWAPPER_PANCAKE = 4000002;
const CONFIG_ADAPTOR_CURVE2POOL_PANCAKE = 4000003;


const blocks = {
	charge_lp_farm_2: {
		withdrawZap: false,
		token: addressBook.lps['pancake-charge-busd'],
		name: 'Charge-BUSD Farm',
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		address: addressBook.blocks.Charge_BUSD,
		rewardTokens: [addressBook.tokens.CHARGE],
	},
	charge_lp_farm_3: {
		withdrawZap: false,
		token: addressBook.lps['pancake-charge-busd'],
		address: addressBook.blocks.Charge_BUSD,
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		name: 'Charge-BUSD Farm',
		rewardTokens: [addressBook.tokens.CHARGE],
	},
	pcs_farm_cake_bnb: {
		withdrawZap: false,
		token: addressBook.lps['pancake-cake-bnb'],
		name: 'Cake-BNB Farm',
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		address: addressBook.blocks.Cake_BNB,
		rewardTokens: [addressBook.tokens.CAKE],
	},
	pcs_farm_cake_busd: {
		withdrawZap: false,
		token: addressBook.lps['pancake-cake-busd'],
		address: addressBook.blocks.Cake_BUSD,
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		name: 'Cake-BUSD Farm',
		rewardTokens: [addressBook.tokens.CAKE],
	},
	pcs_farm_btcb_bnb: {
		isDoubleRewardToken: false,
		withdrawZap: false,
		token: addressBook.lps['pancake-btcb-bnb'],
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		address: addressBook.blocks.BTCB_BNB,
		name: 'BTCB-BNB Farm',
		rewardTokens: [addressBook.tokens.CAKE],
	},
	pcs_farm_btcb_eth: {
		withdrawZap: false,
		token: addressBook.lps['pancake-btcb-eth'],
		name: 'BTCB-ETH Farm',
		address: addressBook.blocks.BTCB_ETH,
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		rewardTokens: [addressBook.tokens.CAKE],
	},
	pcs_farm_eth_bnb: {
		withdrawZap: false,
		token: addressBook.lps['pancake-eth-bnb'],
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		address: addressBook.blocks.ETH_BNB,
		name: 'ETH-BNB Farm',
		rewardTokens: [addressBook.tokens.CAKE],
	},
	pcs_farm_usdt_busd: {
		withdrawZap: false,
		token: addressBook.lps['pancake-usdt-busd'],
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		address: addressBook.blocks.USDT_BUSD,
		name: 'USDT-BUSD Farm',
		rewardTokens: [addressBook.tokens.CAKE],
	},
	pcs_farm_usdc_busd: {
		withdrawZap: false,
		token: addressBook.lps['pancake-usdc-busd'],
		address: addressBook.blocks.USDC_BUSD,
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		name: 'USDC-BUSD Farm',
		rewardTokens: [addressBook.tokens.CAKE],
	},
	pcs_farm_usdt_busd_stable: {
		withdrawZap: false,
		token: addressBook.lps['pancake-stable-usdt-busd'],
		adapter: CONFIG_ADAPTOR_CURVE2POOL_PANCAKE,
		address: addressBook.blocks.USDT_BUSD_StableLP,
		name: 'USDT-BUSD Farm',
		rewardTokens: [addressBook.tokens.CAKE],
	},
	pcs_farm_usdc_busd_stable: {
		withdrawZap: false,
		token: addressBook.lps['pancake-stable-usdc-busd'],
		address: addressBook.blocks.USDC_BUSD_StableLP,
		adapter: CONFIG_ADAPTOR_CURVE2POOL_PANCAKE,
		name: 'USDC-BUSD Farm',
		rewardTokens: [addressBook.tokens.CAKE],
	},
	pcs_bank_busd_usdt: {
		withdrawZap: false,
		address: addressBook.blocks.banks.BUSD_USDT,
		token: addressBook.lps['pancake-usdt-busd'],
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		name: 'USDT-BUSD Bank',
		rewardTokens: [addressBook.tokens.CAKE],
		noProfit: true,
		isBank: true,
	},
	pcs_btcb_busd: {
		address: addressBook.blocks.BTCB_BUSD,
		withdrawZap: false,
		token: addressBook.lps['pancake-btcb-busd'],
		name: 'BTCB-BUSD Farm',
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		rewardTokens: [addressBook.tokens.CAKE],
	},
	pcs_btt_busd: {
		address: addressBook.blocks.BTT_BUSD,
		withdrawZap: false,
		token: addressBook.lps['pancake-btt-busd'],
		name: 'BTT-BUSD Farm',
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		rewardTokens: [addressBook.tokens.CAKE],
	},
	pcs_mbox_bnb: {
		address: addressBook.blocks.MBOX_BNB,
		withdrawZap: false,
		token: addressBook.lps['pancake-mbox-bnb'],
		name: 'MBOX-BNB Farm',
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		rewardTokens: [addressBook.tokens.CAKE],
	},
	pcs_trx_busd: {
		address: addressBook.blocks.TRX_BUSD,
		withdrawZap: false,
		token: addressBook.lps['pancake-trx-busd'],
		name: 'TRX-BUSD Farm',
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		rewardTokens: [addressBook.tokens.CAKE],
	},
	pcs_eth_usdc: {
		address: addressBook.blocks.ETH_USDC,
		withdrawZap: false,
		token: addressBook.lps['pancake-eth-usdc'],
		name: 'ETH-USDC Farm',
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		rewardTokens: [addressBook.tokens.CAKE],
	},
	bloom_bank_charge_busd: {
		address: addressBook.blocks.banks.CHARGE_BUSD,
		withdrawZap: false,
		token: addressBook.lps['pancake-charge-busd'],
		name: 'Charge-BUSD Bank',
		adapter: CONFIG_ADAPTOR_ZAPPER_PANCAKE,
		rewardTokens: [addressBook.tokens.CAKE],
		noProfit: true,
		isBank: true,
	},
};

export const defaultBlocks = blocks;
