import { useState, useEffect } from 'react';

export const useSetStrategyBlockPercentages = (blocks: any) => {
	const [defaultSettingsPerBlock, setDefaultSettingsPerBlock] = useState(
		blocks ? blocks.map((b) => b.actionSettings.map((c) => c.value / 100)) : [],
	); // for pre established strats
	const [blockSettings, setBlockSettings] = useState<Array<any>>(
		defaultSettingsPerBlock,
	);

	useEffect(() => {
		setDefaultSettingsPerBlock(blocks ? blocks.map((b) => b.actionSettings.map((c) => c.value / 100)) : []);
		setBlockSettings(blocks ? blocks.map((b) => b.actionSettings.map((c) => c.value / 100)) : []);
	}, [blocks]);

	// actionPosition - position of the action in strategy config
	// blockPosition - block position in the strat
	// value - what we set as a new value
	const modifyPercentages = (
		actionPosition: number,
		blockPosition: number,
		value: number,
	) => setBlockSettings((settings) => {
		settings[blockPosition][actionPosition] = value;
		return [...settings];
	});

	return { blockSettings, modifyPercentages };
};
