/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import {
	Button, Icon, Tooltip, useColorModeValue as mode,
} from '@chakra-ui/react';
import { IoChevronBack } from '@react-icons/all-files/io5/IoChevronBack';
import { IoChevronForward } from '@react-icons/all-files/io5/IoChevronForward';
import {ResponsiveTooltip} from "../Tooltip";

interface ButtonProps {
  text?: string
  className?: any
  width?: any
  buttonHandler?: () => void
  style?: any
  isDisabled?: any
  prev?: boolean
}

function IconButton(props: ButtonProps) {
	return <ResponsiveTooltip hasArrow label={props.isDisabled ? "Block doesn't add up to 100%" : ''} shouldWrapChildren mt={{ base: 0, lg: 3 }}>
    <Button
        onClick={props.buttonHandler}
        className={props.className}
        w={props.width || '40px'}
        h={props.width || '40px'}
        bg={mode('white', 'gray.700')}
        borderRadius="50%"
        isDisabled={props.isDisabled}
        ml={props.prev ? 1 : 0}
        mr={!props.prev ? 1 : 0}
        mt={{ base: props.prev ? 8 : 0, lg: 0 }}
        mb={{ base: !props.prev ? 4 : -6, lg: 0 }}
        border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}
        {...props.style}>
        <Icon transform={{ base: 'rotate(90deg)', lg: 'unset' }} as={props.prev ? IoChevronBack : IoChevronForward} />
    </Button>
</ResponsiveTooltip>;
}
export default IconButton;
