/* eslint-disable no-shadow */
import { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { getExternallyCalculatedStatistics } from '../service/remote.service';
import { defaultChain } from '../bloomify/config';

export const useGetGeneralPlatformStats = () => {
	const [chainTvl, setChainTvl] = useState<number>(null);
	const [protocolTvl, setProtocolTvl] = useState<number>(null);
	const [totalStratTvl, setTotalStratTvl] = useState<number>(null);
	const [tvlPerStrat, setStratTvl] = useState<any>(null);
	const [totalVaultTvl, setTotalVaultTvl] = useState<number>(null);
	const [totalAccumulatorTvl, setTotalAccumulatorTvl] = useState<number>(null);
	const [totalProfitTakersTvl, setTotalProfitTakersTvl] = useState<number>(null);
	const [tvlPerVault, setVaultTvl] = useState<any>(null);
	const [totalFarmTvl, setTotalFarmTvl] = useState<number>(null);
	const [tvlPerFarm, setFarmTvl] = useState<any>(null);
	const [createdStrats, setCreatedStrats] = useState<number>(null);
	const [blockAprs, setBlockAPRs] = useState<any>(null);
	const [vaultAprs, setVaultAPRs] = useState<any>(null);
	const [farmAprs, setFarmAPRs] = useState<any>(null);
	const [updatedAt, setUpdatedAt] = useState<string>(null);
	const [tokenPrices, setTokenPrices] = useState<any>(null);
	const [generalData, setGeneralData] = useState<any>(null);

	const blockAddresses = _(_.map(defaultChain.strategies, (s) => s.blocks.map((b) => b.address.toLowerCase()))).flatten().uniq().value();
	const stratTags = _.map(defaultChain.strategies, (s) => s.tag).concat(defaultChain.strategies.map((s) => s.deprecatedTags).flat());
	const allExistingVaultTypeStuffAdresses = _.map(defaultChain.vaults, (v) => v.address.toLowerCase());
	const vaultAddresses = _(defaultChain.vaults).filter((v) => v.type.toLowerCase().includes('vault')).map((v2) => v2.address.toLowerCase());
	const accumulatorsAddresses = _(defaultChain.vaults).filter((v) => v.type.toLowerCase().includes('accumulator')).map((v2) => v2.address.toLowerCase());
	const profitTakersAddresses = _(defaultChain.vaults).filter((v) => v.type.toLowerCase().includes('profit')).map((v2) => v2.address.toLowerCase());
	const farmAddresses = _.map(defaultChain.farms, (f) => f.address.toLowerCase());

	const fetchStats = useCallback(async () => {
		await getExternallyCalculatedStatistics();
		checkFetchedStuff();
	}, [generalData]);

	function checkFetchedStuff() {
		let data = JSON.parse(localStorage.getItem('calculatedStats'));
		data = data ? data.result : null;

		const stratTvls = {};
		const blockAprs = {};

		const vaultTvls = {};
		const accumulatorsTvls = {};
		const profitTakersTvls = {};
		const vaultAprs = {};

		const farmTvls = {};
		const farmAprs = {};

		if (data && data.tvls) {
			_.each(data.tvls, (tvl, address) => {
				if (stratTags.includes(address.toLowerCase())) {
					stratTvls[address] = tvl;
				} else if (vaultAddresses.includes(address.toLowerCase())) {
					vaultTvls[address] = tvl;
				} else if (accumulatorsAddresses.includes(address.toLowerCase())) {
					accumulatorsTvls[address] = tvl;
				} else if (profitTakersAddresses.includes(address.toLowerCase())) {
					profitTakersTvls[address] = tvl;
				} else if (farmAddresses.includes(address.toLowerCase())) {
					farmTvls[address] = tvl;
				}
			});

			_.each(data.aprs, (apr, address) => {
				if (blockAddresses.includes(address.toLowerCase())) {
					blockAprs[address] = apr;
				} else if (allExistingVaultTypeStuffAdresses.includes(address.toLowerCase())) {
					vaultAprs[address] = apr;
				} else if (farmAddresses.includes(address.toLowerCase())) {
					farmAprs[address] = apr;
				}
			});

			  setGeneralData(data);
			  setTotalStratTvl(_(stratTvls).map((x) => x).sum());
			  setTotalVaultTvl(_(vaultTvls).map((x) => x).sum());
			  setTotalAccumulatorTvl(_(accumulatorsTvls).map((x) => x).sum());
			  setTotalProfitTakersTvl(_(profitTakersTvls).map((x) => x).sum());
			  setTotalFarmTvl(_(farmTvls).map((x) => x).sum());
			  setBlockAPRs(blockAprs);
			  setVaultAPRs(vaultAprs);
			  setFarmAPRs(farmAprs);
			  setCreatedStrats(data.totalStratsCreated);
			  setUpdatedAt(moment(data.updatedAt).format('HH:mm A'));
			  setTokenPrices(data.prices);
			  setStratTvl(stratTvls);
			  setVaultTvl(vaultTvls);
			  setFarmTvl(farmTvls);

			  setChainTvl(data.chainTvl);
			  setProtocolTvl(data.protocolTvl);
		}
	  }

	// useEffect(() => {
	// 	setchainTvl(totalStratTvl + totalVaultTvl + totalAccumulatorTvl + totalProfitTakersTvl + totalFarmTvl);
	//   }, [totalStratTvl, totalVaultTvl, totalAccumulatorTvl, totalProfitTakersTvl, totalFarmTvl]);

	useEffect(() => {
		fetchStats().catch((err) =>
			console.error(`Failed to fetch stats epoch: ${err.stack}`));
		const refreshInterval = setInterval(
			fetchStats,
			1000 * 60 * 20, // 20 min
		);

		return () => clearInterval(refreshInterval);
	}, []);

	return {
		strategies: {
			totalTvl: totalStratTvl, tvlPerStrat, createdStrats, aprs: blockAprs,
		},
		vaults: {
			totalTvl: totalVaultTvl, tvlPerVault, aprs: vaultAprs,
		},
		accumulators: {
			totalTvl: totalAccumulatorTvl, tvlPerAccumulator: tvlPerVault, aprs: vaultAprs,
		},
		'profit-takers': {
			totalTvl: totalProfitTakersTvl, tvlPerProfitTaker: tvlPerVault, aprs: vaultAprs,
		},
		farms: {
			totalTvl: totalFarmTvl, tvlPerFarm, aprs: farmAprs,
		},
		updatedAt,
		tokenPrices,
		chainTvl,
		protocolTvl,
	};
};
