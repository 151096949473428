/* eslint-disable no-shadow */
import Web3 from 'web3';
import { BigNumber, ethers } from 'ethers';
import { defaultChain } from '../bloomify/config';
import {
	onConfirmation, onError, onReceipt, onSending, onSent, onTransactionHash,
} from '../utils/web3Handlers';
import {getGasPriceOptions} from "../utils/gasUtils";

const APPROVE_CHARGE_AMOUNT = BigNumber.from('1000000000000000000000000');
const APPROVE_AMOUNT = ethers.constants.MaxUint256;

export const erc20Allowance = async (web3: Web3, tokenAddr: string, contractAddr: string, walletAddress: string) => {
	if (tokenAddr.toLowerCase() === defaultChain.nativeTokenAddress.toLowerCase()) return true;

	const erc20 = new web3.eth.Contract(
		defaultChain.deployments.MockToken.abi,
		tokenAddr,
		{ from: walletAddress },
	);

	return await erc20.methods.allowance(walletAddress, contractAddr).call();
};

export const erc20Approve = async (
	web3: Web3,
	tokenAddr: string,
	contractAddr: string,
	walletAddress: string,
	onError: () => void,
) => {
	const erc20 = new web3.eth.Contract(
		defaultChain.deployments.MockToken.abi,
		tokenAddr,
		{ from: walletAddress },
	);
	const gasPrices = await getGasPriceOptions(web3);
	return await erc20.methods.approve(contractAddr, APPROVE_AMOUNT).send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', onError);
};
