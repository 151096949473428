/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import NotConnected from '../NotConnected';

type Props = {
    children: JSX.Element
}

function ChecksWrapper({ children }: Props) {
	const { account } = useWeb3React();

	if (!account) {
		return <NotConnected />;
	}

	return (
		{ ...children }
	);
}

export default ChecksWrapper;
