import Web3 from 'web3';
import _ from 'lodash';
import {
	onConfirmation, onError, onReceipt, onSending, onSent, onTransactionHash,
} from '../../../utils/web3Handlers';
import { defaultChain } from '../../../bloomify/config';
import {getGasPriceOptions} from "../../../utils/gasUtils";

export const createMoneyLegoStrategy = async (
	web3: Web3,
	walletAddress: string,
	params: any,
	onStrategyCreated: (address: string) => void,
	onErrorCallback?: any,
	onConfirmationCallback?: any,
) => {
	const strategyFactory = new web3.eth.Contract(
		defaultChain.deployments.StrategyFactory.abi,
		defaultChain.deployments.StrategyFactory.address,
		{ from: walletAddress },
	);
	const {
		tag, blocks, adaptors, settings,
	} = params;

	const gasPrices = await getGasPriceOptions(web3);
	await strategyFactory.methods.createStrategy(tag, blocks, adaptors, settings).send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', (receipt) => {
			onReceipt(receipt);
			if(receipt.events) {
				onStrategyCreated(receipt.events["StrategyCreated"].returnValues["clone"])
			} else {
				onStrategyCreated("");
			}
		})
		.on('confirmation', onConfirmationCallback || onConfirmation)
		.on('error', onErrorCallback || onError);
};

export const getCreatedStrategyAddress = async (web3: Web3, block: number) => {
	const strategyFactory = new web3.eth.Contract(
		defaultChain.deployments.StrategyFactory.abi,
		defaultChain.deployments.StrategyFactory.address,
	);

	return strategyFactory.getPastEvents('StrategyCreated', {
		fromBlock: block, toBlock: block,
	});
};

export const getStrategiesCreatedNumber = async (web3: Web3, fromBlock: string, toBlock: string) => {
	const strategyFactory = new web3.eth.Contract(
		defaultChain.deployments.StrategyFactory.abi,
		defaultChain.deployments.StrategyFactory.address,
	);

	const chunkLimit = 5000;
	const fromBlockNumber = +fromBlock;
	const toBlockNumber =
        toBlock === 'latest' ? +(await web3.eth.getBlockNumber()) : +toBlock;
	const totalBlocks = toBlockNumber - fromBlockNumber;
	const chunks = [];

	if (chunkLimit > 0 && totalBlocks > chunkLimit) {
		const count = Math.ceil(totalBlocks / chunkLimit);
		let startingBlock = fromBlockNumber;

		for (let index = 0; index < count; index++) {
			const fromRangeBlock = startingBlock;
			const toRangeBlock =
                index === count - 1 ? toBlockNumber : startingBlock + chunkLimit;
			startingBlock = toRangeBlock + 1;

			chunks.push({ fromBlock: fromRangeBlock, toBlock: toRangeBlock });
		}
	} else {
		chunks.push({ fromBlock: fromBlockNumber, toBlock: toBlockNumber });
	}

	const events = [];
	const errors = [];
	_.forEach(chunks, async (chunk) => {
		await strategyFactory.getPastEvents(
			'StrategyCreated',
			{
				fromBlock: chunk.fromBlock,
				toBlock: chunk.toBlock,
			},
			async (error, chunkEvents:any) => {
				if (chunkEvents?.length > 0) {
					events.push(...chunkEvents);
				}

				if (error) errors.push(error);
			},
		);
	});

	return { events, errors, lastBlock: toBlockNumber };

	// return strategyFactory.getPastEvents("StrategyCreated", {
	//     fromBlock: '18359475', toBlock: 'latest'
	// })
};
