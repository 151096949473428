/* eslint-disable no-extra-boolean-cast */
import {
	Box,
	HStack,
	Stack,
	Flex,
	Text,
	Skeleton,
	Tooltip,
	UnorderedList,
	ListItem,
	Link as InternalLink,
	Icon,
	Button,
} from '@chakra-ui/react';
import * as React from 'react';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';
import { ResponsiveTooltip } from '../Tooltip';

interface Props {
  label: string
  value: any
  subtitle?: string
  feeInfo?: any,
  size?: string
  aprInfo?: any,
  href?: string,
  info?: string,
  time?: boolean,
  itemType?: string,
}
export function StatCard(props: Props) {
	const {
		label, value, size, subtitle, feeInfo, aprInfo, itemType, href, info, time, ...boxProps
	} = props;

	return (
    <Flex flexDir="column" w={{ base: '100%', sm: 'max-content' }}>
        <Flex
            px="2"
            py={{ base: '1', md: '2' }}
            w="100%"
            align="center"
            justifyContent="space-between"
            flexDir={{ base: 'row', sm: 'column' }}
            borderRadius="lg"
            textAlign={{ base: 'left', md: 'center' }}
            {...boxProps}>
            {feeInfo ? (
                <ResponsiveTooltip
                    label={<Flex direction="column">
                        <UnorderedList><Text fontWeight="bold" mb={2}>Performance fee will be distributed as:</Text>
                            <ListItem><Flex><Text fontWeight="bold" mr={1}>CHARGE Holders:</Text> {feeInfo.holders}%</Flex></ListItem>
                            <ListItem><Flex><Text fontWeight="bold" mr={1}>Harvest Fee:</Text> {feeInfo.harvest}%</Flex></ListItem>
                            <hr style={{ marginTop: '3px', marginBottom: '3px', opacity: 0.2 }} />
                            <Flex><Text fontWeight="bold" mr={1}>TOTAL (Performance Fee):</Text>
                                {feeInfo.holders + feeInfo.treasury + feeInfo.devs + feeInfo.harvest}%
                            </Flex>
                        </UnorderedList>
                    </Flex>}
                    hasArrow
                    placement="bottom"
                    p={4}
                    borderRadius="md">
                    <Flex justify="center" align="center">
                        <Icon as={FaInfoCircle} mx="auto" pr={1} w={4} h={4} />
                        <Text
                            fontSize="sm"
                            color="muted"
                            minW={size === 'sm' ? '80px' : '132px'}
                            textAlign={{ base: 'left', sm: 'center' }}>
                            {label}
                        </Text>
                    </Flex>
                </ResponsiveTooltip>
            ) : aprInfo ? (
                <ResponsiveTooltip
                    label={<Flex direction="column">
                        <UnorderedList>
                            <ListItem><Flex><Text fontWeight="bold" mr={1}>Yield APR:</Text> {aprInfo.baseApr}%</Flex></ListItem>
                            {!!aprInfo.tradingApr && <ListItem><Flex><Text fontWeight="bold" mr={1}>Trading APR:</Text> {aprInfo.tradingApr}%</Flex></ListItem>}
                            <ListItem><Flex><Text fontWeight="bold" mr={1}>APY:</Text> {aprInfo.totalApy}%</Flex></ListItem>
                            {!!aprInfo.blocks && <>
                                <ListItem><Flex><Text fontWeight="bold" mr={1}>Deposit Block APY:</Text> {aprInfo.blocks[0].totalApy}%</Flex></ListItem>
                                <ListItem><Flex><Text fontWeight="bold" mr={1}>{itemType} Block APY:</Text> {aprInfo.blocks[1].totalApy}%</Flex></ListItem>
                            </>}
                        </UnorderedList>
                    </Flex>}
                    hasArrow
                    placement="bottom"
                    p={4}
                    borderRadius="md">
                    <Flex justify="center" align="center">
                        <Icon as={FaInfoCircle} mx="auto" pr={1} w={4} h={4} />
                        <Text
                            fontSize="sm"
                            color="muted"
                            minW="30px"
                            textAlign={{ base: 'left', sm: 'center' }}>
                            {label}
                        </Text>
                    </Flex>
                </ResponsiveTooltip>
            ) : info ? (
                <ResponsiveTooltip
                    label={<Text fontWeight="bold">{info}</Text>}
                    hasArrow
                    placement="bottom"
                    p={4}
                    borderRadius="md">
                    <Flex justify="center" align="center">
                        <Icon as={FaInfoCircle} mx="auto" pr={1} w={4} h={4} />
                        <Text
                            fontSize="sm"
                            color="muted"
                            minW="30px"
                            textAlign={{ base: 'left', sm: 'center' }}>
                            {label}
                        </Text>
                    </Flex>
                </ResponsiveTooltip>
            ) : <Text
                fontSize="sm"
                color="muted"
                fontWeight="light"
                minW={size === 'sm' ? '80px' : '132px'}
                textAlign={{ base: 'left', sm: 'center' }}>
                {label}
            </Text>}

            <Skeleton
                isLoaded={(aprInfo || feeInfo) || label.toLowerCase().includes('apy')
                || label.toLowerCase().includes('apr') || ((!aprInfo && !feeInfo) && (value !== undefined && value !== null))}
                w="100%"
                display="flex"
                justifyContent={{ base: 'flex-end', sm: 'center' }}
                height={{ base: '28px', lg: '50px' }}>
                {href ?
                    <InternalLink href={href} target="_blank" mt={-1}>
                        <Button
                            variant="link"
                            color="#8A88B7"
                            fontWeight="bold"
                            fontSize={{ base: 'lg', lg: size === 'sm' ? 'lg' : '3xl' }}
                            textAlign={{ base: 'right', sm: 'center' }}>
                            {value}
                            <Icon as={FaExternalLinkAlt} mx="auto" pl={2} w={5} h={5} mr={1} />
                        </Button>
                    </InternalLink> : <Text
                        fontWeight="bold"
                        fontSize={{ base: value ? 'lg' : 'sm', lg: size === 'sm' ? (value ? 'lg' : 'sm') : (time ? '2xl' : '3xl') }}
                        textAlign={{ base: 'right', sm: 'center' }}>
                        {value || '-'}
                    </Text> }
            </Skeleton>
        </Flex>
        {!!subtitle && <Skeleton
            isLoaded={(aprInfo || feeInfo) || label.toLowerCase().includes('apy')
            || label.toLowerCase().includes('apr') || ((!aprInfo && !feeInfo) && (value !== undefined && value !== null))}
            w="100%"
            mb={{ base: 1, md: -2 }}
            mt={{ base: -1, md: -3 }}
            alignSelf="flex-end"
            height={{ base: '28px', lg: '50px' }}>
            <Text
                fontSize="12px"
                color="#8A88B7"
                textAlign={{ base: 'right', sm: 'center' }}>
                {subtitle}
            </Text>
        </Skeleton>}
    </Flex>
	);
}
