import React from 'react';
import {
	HStack,
	Image,
	Text,
	Heading,
	Flex,
	Icon,
	Link,
	useColorModeValue as mode,
	Divider,
	Spacer,
	Box,
	Center,
	Img, Button,
} from '@chakra-ui/react';
import { FaExternalLinkAlt } from '@react-icons/all-files/fa/FaExternalLinkAlt';
import TokenImage from '../TokenImage';

interface AssetProps {
  symbol: any;
  name: any;
  description: any;
  website: any;
  address: any;
  scanUrl:any;
}

function Asset({
	symbol, name, description, website, address, scanUrl,
}: AssetProps) {
	return (
    <Box textAlign="center" px={2}>
        <Box
            bg="bg-surface"
            rounded="2xl"
            py={2}
            mt={4}
            px={{ base: 4, xl: 6 }}
			>
            <TokenImage token={symbol} h="48px" />
            <Text fontSize={{ base: '18px', md: '22px' }} mt="4" mb="2">
                {name}
            </Text>
            <Text fontSize="14px" mt="0" mb="15px" color="#8A88B7">
                {description}
            </Text>
            <Flex flexDir="column">
                {/* {website &&
                <Link href={website} target="_blank">
                    <Button variant="link">
                        Website <Icon as={FaExternalLinkAlt} mx="auto" pl={2} w={5} h={5} color="muted" />
                    </Button>
                </Link>} */}
                {/* {address &&
                <Link href={`${scanUrl}/address/${address}`} target="_blank">
                    <Button variant="link">
                        {name} contract <Icon as={FaExternalLinkAlt} mx="auto" pl={2} w={5} h={5} color="muted" />
                    </Button>
                </Link>
                } */}
            </Flex>
        </Box>
    </Box>
	);
}

export default Asset;
