import {
	Box, Flex, Skeleton, Text, Icon,
} from '@chakra-ui/react';
import React from 'react';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { FaRedo } from '@react-icons/all-files/fa/FaRedo';
import TokenImage from '../TokenImage';
import { formatValue } from '../../../helpers/util';
import { ResponsiveTooltip } from '../Tooltip';

export function ValueTokenStat(props: { text: any, token?: any, amount: any, value: number, info?: string }) {
	const {
		token, amount = 0, value = 0, text, info = '',
	} = props;

	return (
    <Box my="auto" minW="110px">
        <Flex justify="center" align="center" direction="column">
            <Flex justify="center" align="center" mb={0}>{token && <TokenImage sm token={token} />}
                <Text fontSize="sm" ml={1} fontWeight="light" textColor="muted">
                    {token ? token.toUpperCase() : ''}
                </Text>
            </Flex>
            <Text fontSize="sm" fontWeight="light" textColor="#8A88B7">
                <ResponsiveTooltip label={info} hasArrow placement="bottom" p={4} borderRadius="md">
                    <Flex align="center">
                        {text && text.toLowerCase().includes('accumulate') && <Icon as={FaRedo} mx="auto" pr={1} w={4} h={4} />}
                        {text} {info && <Icon as={FaInfoCircle} mx="auto" pl={1} w={4} h={4} />}
                    </Flex>
                </ResponsiveTooltip>
            </Text>
            <Text fontSize="lg" fontWeight="bold">
                {`${formatValue(amount)} ${!token.toLowerCase().includes('pulse') ? `($${formatValue(value) || 0})` : ''}`}
            </Text>
        </Flex>
    </Box>
	);
}
