import React, { useEffect, useState } from 'react';
import {
	Flex,
	Box,
	Container,
	Stack,
	Tooltip,
	useBreakpointValue,
} from '@chakra-ui/react';

import _ from 'lodash';
import GenericStrategyBlock from './GenericStrategyBlock';
import IconButton from '../../common/Button/IconButton';

import Web3Button from '../../common/Web3Button';
import { useSetStrategyBlockPercentages } from '../hooks/useSetStrategyBlockPercentages';
import {ResponsiveTooltip} from "../../common/Tooltip";

type Props = {
  step: number;
  isLoading: any;
  blocks: Array<any>;
  nextStep: () => any;
  prevStep: () => any;
  openTour: () => any;
  createStrategy: (blockSettings: any) => any;
  setStep: (e) => void;
};

function GenericStrategyView({
	nextStep,
	openTour,
	prevStep,
	step,
	setStep,
	isLoading,
	createStrategy,
	blocks,
}: Props) {
	const { blockSettings, modifyPercentages } = useSetStrategyBlockPercentages(
		blocks,
	);

	const isMobile = useBreakpointValue({ base: true, lg: false });
	const checkError = () => blockSettings.map((i) => _.sum(i) === 100 && true);
	const doesAddUp = checkError().every((el) => el);

	const [blockError, setBlockError] = useState([false, false, false]);

	useEffect(() => {
		setBlockError(() => checkError());
	}, [blockSettings]);

	return (
		!!blocks &&
    blocks.length && (
    <Box flex={1} my="auto" w={{ base: '100%', lg: '100%' }}>
        <Container
            px={{ base: 0, sm: 2, md: 4 }}
            overflow="hidden"
            display="flex"
            justifyContent="center"
            mt={{ base: -1, lg: -2 }}
        >
            <Stack
                direction={{ base: 'column', lg: 'row' }}
                spacing={{ base: 4, lg: 5 }}
                w={{ base: 'full', lg: 'unset' }}
                justifyContent="center"
                transition="transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84)"
                transform={
              !isMobile && blocks
              	? blocks.length === 2
              		? step === 1
              			? 'translate(24%, 0px)'
              			: step === 2
              				? 'translate(-25.5%, 0px)'
              				: 'unset'
              		: blocks.length === 3
              			? step === 1
              				? 'translate(33%, 0px)'
              				: step === 2
              					? 'translate(0, 0px)'
              					: 'translate(-34%, 0px)'
              			: 'translate(0, 0px)'
              	: 'unset'
            }
          >
                {blocks &&
              blocks.map((b: any, bPosition: number) => {
              	const show = step === bPosition + 1;
              	const isPrev = step - 1 === bPosition + 1;
              	const isNext = step + 1 === bPosition + 1;
              	const next = document.querySelector(
              		`[data-tut="reactour__step-main-${step}"]`,
              	);
              	const prev = document.querySelector(
              		`[data-tut="reactour__step-main-${step - 2}"]`,
              	);
              	return (
                  <Flex
                      key={bPosition}
                      flexDir={isMobile ? 'column' : 'row'}
                      align="center"
                      w="full"
                      data-tut={`reactour__step-main-${bPosition}`}
                      pt={{ base: 2, lg: 6 }}
                      pb={{ base: 4, lg: '120px' }}
                  >
                      {isNext && !isMobile && (
                      <IconButton
                          buttonHandler={nextStep}
                          isDisabled={!blockError[bPosition - 1]}
                      />
                      )}
                      {isNext && isMobile && (
                      <IconButton
                          buttonHandler={() => {
                        	nextStep();
                        	next.scrollIntoView({
                        		behavior: 'smooth',
                        		block: 'start',
                        	});
                          }}
                          isDisabled={!blockError[bPosition - 1]}
                      />
                      )}
                      <GenericStrategyBlock
                          openTour={openTour}
                          boxShadow={show ? '0px 12px 38px rgb(35 0 248 / 14%)' : 'none'}
                          transition="all 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84)"
                          opacity={show ? 1 : 0.25}
                          transform={`scale(${show ? 1 : 0.92})`}
                          pointerEvents={show || isMobile ? 'auto' : 'none'}
                          show={isMobile ? true : show}
                          currentBlock={b}
                          blocks={blocks}
                          error={!blockError[bPosition]}
                          blockPercentages={blockSettings[bPosition]}
                          modifyPercentages={(percentageValue, actionIndex) =>
                      	modifyPercentages(
                      		actionIndex,
                      		bPosition,
                      		percentageValue,
                      	)} // to change the 3 2 1 to the id of the action from the block configs
                          id={`${bPosition}`}
                    />
                      {isPrev && !isMobile && (
                      <IconButton
                          prev
                          buttonHandler={prevStep}
                          isDisabled={!blockError[bPosition + 1]}
                      />
                      )}
                      {isPrev && isMobile && (
                      <IconButton
                          prev
                          buttonHandler={() => {
                          	prevStep();
                          	prev.scrollIntoView({
                          		behavior: 'smooth',
                          		block: 'start',
                          	});
                          }}
                          isDisabled={!blockError[bPosition + 1]}
                      />
                      )}
                  </Flex>
              	);
              })}
            </Stack>
        </Container>
        <Container maxW="5xl" mt={{ base: -5, lg: '-120px' }}>
            <Flex py={8} justifyContent="center" data-tut="reactour__step-create">
                <ResponsiveTooltip
                    hasArrow
                    label={!doesAddUp ? "Block doesn't add up to 100%" : ''}
                    shouldWrapChildren
                    mt="3"
            >
                    <Web3Button
                        w="100%"
                        mx={2}
                        isDisabled={!doesAddUp}
                        isLoading={isLoading}
                        text="Create Strategy"
                        onClick={() => createStrategy(blockSettings)}
              />
                </ResponsiveTooltip>
            </Flex>
        </Container>
    </Box>
		)
	);
}

export default GenericStrategyView;
