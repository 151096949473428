/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	HStack,
	ModalBody,
	ModalCloseButton,
	Button,
	Flex,
	Text,
	Skeleton,
	Box,
	NumberInput,
	NumberInputField,
	FormControl,
	FormErrorMessage,
	useColorModeValue as mode,
	Select, VStack, Icon,
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import _ from 'lodash';
import { BigNumber } from 'ethers';
import {parseUnits, formatEther, parseEther} from 'ethers/lib/utils';
import { IoWarning } from '@react-icons/all-files/io5/IoWarning';
import { depositInBlock } from '../../services/block.service';
import useWeb3 from '../../../../hooks/useWeb3';
import TokenImage from '../../../common/TokenImage';
import { erc20Allowance, erc20Approve } from '../../../../service/erc2.service';
import { defaultChain } from '../../../../bloomify/config';
import { formatValue } from '../../../../helpers/util';
import { useTokenPrices } from '../../../../contexts/TokenPricesContext';
import { useTokenBalance } from '../../../../contexts/TokensBalancesContext';
import {ethers} from "ethers/lib.esm";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  blockIndex: number;
  token: any;
  strategyAddress: string;
  description?: string;
};

function DepositModal({
	isOpen,
	blockIndex,
	onClose,
	token,
	strategyAddress,
	description = `Deposit ${token.symbol.toUpperCase()} or Zap one of the other available tokens`,
}: Props) {
	const { account } = useWeb3React();
	const { getTokenPrice, tokens } = useTokenPrices();
	const { getTokenBalance, balances } = useTokenBalance();
	const web3 = useWeb3();

	const [depositToken, setDepositToken] = useState<any>(token);
	// Gets the allowance of a given token for a given wallet
	const getAllowance = async (tokenDetails) => {
		const allowance = await erc20Allowance(web3, tokenDetails.address, strategyAddress, account);
		setTokenAllowance(parseEther(allowance));
	};

	const [tokenAllowance, setTokenAllowance] = useState<BigNumber>(ethers.constants.Zero);

	const depositTokens = [defaultChain.addressBook.tokens.BNB,
		// defaultChain.addressBook.tokens.STATIC,
		// defaultChain.addressBook.tokens.CHARGE,
		defaultChain.addressBook.tokens.BUSD];

	if (!depositTokens.some((t) => t.address.toLowerCase() === token.address.toLowerCase())) {
		depositTokens.push(token);
	}

	useEffect(() => {
		if (account) getAllowance(token);
	}, [account]);

	const tokenBalance = balances && getTokenBalance(depositToken) ? getTokenBalance(depositToken).integer : 0;

	const [deposit, setDeposit] = useState<BigNumber>(BigNumber.from(0));
	const [input, setInput] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	// eslint-disable-next-line max-len
	const [completeTokenBalancePrice, setCompleteTokenBalancePrice] = useState<number>(tokenBalance * getTokenPrice(depositToken));
	const [inputPrice, setInputPrice] = useState<number>(input * getTokenPrice(depositToken));

	// Checks if there is balances and a valid deposit > 0
	const isError = balances && deposit && deposit.gt(getTokenBalance(depositToken).hex);

	// uses util function to map an address to a token name / LP name
	const tokenAddr = depositToken.address;

	// Changes the deposit token
	const onChangeDepositToken = async (token: any) => {
		setDepositToken(token);
		setCompleteTokenBalancePrice(getTokenBalance(depositToken).integer * getTokenPrice(depositToken));
		await getAllowance(token);
		setDeposit(parseUnits('0'));
		setInput(0);
	};

	useEffect(() => {
		setCompleteTokenBalancePrice(tokenBalance * getTokenPrice(depositToken));
	}, [tokens, tokenBalance, depositToken]);

	useEffect(() => {
	//	const tokenName = depositToken === 'native' ? 'bnb' : depositToken;

		setInputPrice(input * getTokenPrice(depositToken));
	}, [input, tokens]);

	// Approval to spend the given ERC20 token
	const onApprove = async () => {
		setIsLoading(true);
		const response = await erc20Approve(web3, tokenAddr, strategyAddress, account, () => setIsLoading(true));

		if (response.status) {
			await getAllowance(depositToken);
			setIsLoading(false);
		}
	};

	// Deposits given token in the block
	const depositFunds = async () => {
		setIsLoading(true);
		await depositInBlock(
			web3,
			strategyAddress,
			tokenAddr,
			blockIndex,
			deposit.toString(),
			account,
			() => setIsLoading(false),
			() => onClose(),
		);
	};

	const roundDeposits = (percent: number) => {
		if (balances) {
			const tBalance = getTokenBalance(depositToken).hex;

			switch (percent) {
				case 25:
					return tBalance ? tBalance.div(4) : BigNumber.from(0);
				case 50:
					return tBalance ? tBalance.div(2) : BigNumber.from(0);
				case 75:
					return tBalance ? tBalance.div(4).mul(3) : BigNumber.from(0);
				case 100:
					return tBalance || BigNumber.from(0);
				default:
					BigNumber.from(0);
			}
		}
	};
	const roundDepositArr = [25, 50, 75, 100];

	return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
        <ModalOverlay />
        <ModalContent maxW="620px" px={{ base: 4, md: 5 }}>
            <ModalHeader mx="auto" px={{ base: 1, md: 5 }}>
                <Flex direction="column" justify="center" align="center">
                    <Text fontWeight="bold" fontSize="25px">Deposit {token === 'native' ? 'BNB' : token.symbol.toUpperCase()}</Text>
                    <Text fontSize="sm" pl="4" minW="275px" textAlign="center" fontWeight="light" textColor="#8A88B7">
                        {description}
                    </Text>
                </Flex>
            </ModalHeader>
            <ModalCloseButton transition="transform 0.15s ease-in-out" _hover={{ transform: 'scale3d(1.02, 1.02, 1)' }} />
            <ModalBody p={{ base: 1, md: 5 }}>
                <VStack justifyContent="start" spacing={5}>
                    <Flex alignItems="center" justifyContent="space-between" w="full">
                        <Text color="#8A88B7" mb={2}>Deposit</Text>
                        <Box>
                            <Flex justify="center" align="center">
                                <TokenImage sm token={depositToken.symbol.toLowerCase()} />
                                <Select
                                    ml={2}
                                    bg={mode('#FAFAFA', 'blackAlpha.300')}
                                    variant="filled"
                                    maxW="200px"
                                    onChange={(e) => onChangeDepositToken(JSON.parse(e.target.value))}>
                                    <option value="none" selected disabled hidden>
                                        Select an Option
                                    </option>
                                    {depositTokens.map((tokenDetails: any, key: number) => (
                                        <option
                                            key={key}
                                            selected={tokenDetails.address.toLowerCase() === depositToken.address.toLowerCase() && true}
                                            value={JSON.stringify(tokenDetails)}>
                                            {tokenDetails.symbol.toUpperCase()}
                                        </option>
                                    ))}
                                </Select>
                            </Flex>
                        </Box>
                    </Flex>
                    <Skeleton isLoaded={balances} alignSelf="flex-end" style={{ marginTop: '2px' }}>
                        <Text fontSize="sm" color="#8A88B7" pr="2px">
                            Balance ~{formatValue(tokenBalance, 3)} ({`$${formatValue(completeTokenBalancePrice, 2)}`})
                        </Text>
                    </Skeleton>
                </VStack>
                <Flex flexDir="column" justify="center" align="center" py={3}>
                    <FormControl isInvalid={isError} rounded="lg" bg={mode('#FAFAFA', 'blackAlpha.300')} py={3} px={4}>
                        <NumberInput
                            variant="unstyled"
                            defaultValue={0}
                            value={input}
                            onChange={(num: any) => {
                	setInput(num);
                	setDeposit(parseUnits(num || '0', depositToken.decimals));
                            }}
                            w="100%">
                            <NumberInputField w="100%" fontSize="25px" bg="transparent" />
                        </NumberInput>
                        <Text fontSize={14} color="#8A88B7">~{`$${formatValue(inputPrice, 2)}`}</Text>
                    </FormControl>
                    {isError ?
                        <Flex align="center" pt="2px" pb={1}>
                            <Box maxW={10}>
                                <Icon as={IoWarning} color="pink.500" />
                            </Box>
                            <Text ml={2} color="pink.500" fontSize="sm">
                                Insufficient or no tokens
                            </Text>
                        </Flex> : <Box h="30px" />}
                    {depositToken !== 'native' ? (
                        <Flex pt={2} flexDir="row" flexWrap="wrap" justifyContent="center">
                            {roundDepositArr.map((el, i) =>
                                <Button
                                    my={{ base: 1, md: 0 }}
                                    mx={2}
                                    variant="filled"
                                    bg={mode('#FAFAFA', 'blackAlpha.300')}
                                    w={{ base: '120px', md: '100px' }}
                                    onClick={() => {
                  	setInput(Number(formatEther(roundDeposits(el))));
                  	setDeposit(roundDeposits(el));
                                    }}>
                                    {el}%
                                </Button>)}
                        </Flex>
                    ) : null}
                </Flex>
                {tokenAllowance.lt(ethers.constants.MaxUint256)
				&& depositToken.address.toLowerCase() !== defaultChain.nativeTokenAddress.toLowerCase() ? (
    <Button
        my=""
        marginTop="1rem"
        isLoading={isLoading}
        w="100%"
        bg={mode('white', 'gray.800')}
        border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}
        leftIcon={<FaCheck />}
        onClick={onApprove}>
        Approve {depositToken.symbol.toUpperCase()}
    </Button>
                	) : (
                    <Button
                        my="auto"
                        marginTop="1rem"
                        isDisabled={isError || deposit.eq(0)}
                        isLoading={isLoading}
                        w="100%"
                        bg={mode('white', 'gray.800')}
                        border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}
                        onClick={depositFunds}>
                        Confirm
                    </Button>
                	)}
            </ModalBody>
        </ModalContent>
    </Modal>
	);
}

export default DepositModal;
