import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { theme } from '@chakra-ui/pro-theme';

const styles = {
	global: (props: any) => ({
		body: {
			color: mode('#313138', 'whiteAlpha.900')(props),
			bg: mode('#EEF1FA', '#05050F')(props),
		},
		'.chakra-stack__divider': {
			marginInlineEnd: '20px !important',
		},
	}),
};

export const chargeTheme = extendTheme(
	{
		fonts: {
			heading: 'Mulish, sans-serif',
			body: 'Mulish, sans-serif',
		},
		colors: {
			...theme.colors,
			brand: {
				...theme.colors.purple,
				200: '#F64D8C',
			},
			purple: { 500: '#4F51C0', 200: '#4F51C0' },
			cyan: { 500: '#654AFF', 200: '#654AFF' },
			pink: { 500: '#F64D8C', 200: '#F64D8C' },
			green: { 500: '#1ECF9A', 200: '#1ECF9A' },
			gray: { 800: '#191932', 900: '#05050F' },
		},
		shadows: {
			'sm-dark': '0px 8px 24px rgb(35 0 248 / 16%)',
			sm: '0px 8px 24px rgb(35 0 248 / 12%)',
		},
		breakpoints: {
			xs: '25em',
		},
		styles,
		components: {
			Modal: {
				baseStyle: () => ({
					dialog: {
						borderRadius: '1rem',
						bg: 'bg-surface',
						padding: 5
					},
				}),
			},
			Button: {
				baseStyle: {
					borderRadius: '50px',
				},
				sizes: {
					md: {
						h: '45px',
						fontSize: 'sm',
					},
				},
				variants: {
					'gradient': {
						color: 'white',
						bg: 'radial-gradient(96% 96% at 70.4% 31.2%, #838FFF 0%, rgba(227, 230, 255, 0) 100%), #030092',
						border: 'none',
						boxShadow: 'box-shadow: 0px 40px 80px rgb(35 0 248 / 24%), inset 0px 2px 10px rgb(255 255 255 / 58%), inset 0px -20px 50px rgb(124 161 255 / 37%), inset 10px 16px 20px rgb(207 163 252 / 95%)'
					},
				},
			},
			Heading: {
				variants: {
					'mainTitle': {
						fontWeight: 700,
						letterSpacing: 'letterSpacings-wide',
						textTransform: 'uppercase',
					}
				}
			}
		},
	},
	theme,
);
