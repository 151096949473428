import { addressBook } from './addressbook.config';
import { ENV_PROD, featureAllowed } from '../../env';

export const deployedFarms = [
  {
    address: addressBook.pools.Charge,
    deployment: 'GovernanceRewardPool',
    isGovernance: true,
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'Deposit your $Charge in the farm, and earn $Static. Your earned $Static will stay in the Earnings until you Claim or Withdraw. Withdrawing your tokens will also Claim earnings.',
    chain: 'BSC',
    env: ENV_PROD,
    otherInfo: ['Performance Fee Rewards'],
    name: 'Charge Reward Pool',
    platform: 'Bloomify',
    depositToken: {
      ...addressBook.tokens.CHARGE,
      isLP: false,
      canZap: false
    },
    earn: addressBook.tokens.STATIC,
    geolocation: true
  },
  {
    address: addressBook.pools.Pulse,
    deployment: 'GovernanceRewardPool',
    isGovernance: true,
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'Deposit your $Pulse in the farm, and earn $Static. Your earned $Static will stay in the Earnings until you Claim or Withdraw. Withdrawing your tokens will also Claim earnings.',
    chain: 'BSC',
    env: ENV_PROD,
    otherInfo: ['Performance Fee Rewards'],
    name: 'Pulse Pool',
    platform: 'Bloomify',
    depositToken: {
      ...addressBook.tokens.PULSE,
      isLP: false,
      canZap: false
    },
    earn: addressBook.tokens.STATIC,
    hideAPR: true,
    hideTVL: true,
    showOnlyIfBalanceExists: true
  },
  {
    address: addressBook.farms.CHARGE_BUSD,
    deployment: 'GenericFarmAllocation',
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'Deposit your Charge-BUSD LP to gain more Charge. Only a limited amount of Charge is left before emissions halt, forever!',
    chain: 'BSC',
    env: ENV_PROD,
    name: 'Charge-BUSD Farm',
    otherInfo: ['Deposit CHARGE-BUSD', 'Earn CHARGE'],
    platform: 'Bloomify',
    depositToken: {
      ...addressBook.lps['pancake-charge-busd'],
      isLP: true,
      canZap: false
    },
    earn: addressBook.tokens.CHARGE,
    geolocation: true
  },
  {
    address: addressBook.farms.STATIC_BUSD,
    deployment: 'GenericFarmAllocation',
    description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'Deposit your Static-BUSD LP to gain more Charge. Only a limited amount of Charge is left before emissions halt, forever!',
    chain: 'BSC',
    env: ENV_PROD,
    name: 'Static-BUSD Farm',
    otherInfo: ['Deposit STATIC-BUSD', 'Earn CHARGE'],
    platform: 'Bloomify',
    depositToken: {
      ...addressBook.lps['pancake-static-busd'],
      isLP: true,
      canZap: false
    },
    earn: addressBook.tokens.CHARGE,
    geolocation: true
  }
].filter((i) => featureAllowed(i.env));
