import React, { useEffect } from 'react';
import { Box, Flex, useColorMode } from '@chakra-ui/react';
import { ThemeProvider } from 'styled-components';
import {
	light, dark, PancakeTheme, ModalProvider,
} from '@pancakeswap-libs/uikit';
import { Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import TopNavBar from './modules/common/TopNavBar/TopNavBar';
import { useTokens } from './hooks/useTokens';
import { useFetchBalances } from './hooks/useFetchBalances';
import { TokenPricesContext } from './contexts/TokenPricesContext';
import { TokensBalancesContext } from './contexts/TokensBalancesContext';
import Strategies from './modules/generic-strategies/views/index';
import UserClonedStrategies from './modules/cloned-strategies/views/index';
import ClonedStrategyView from './modules/cloned-strategies/views/ClonedStrategyView';
import StrategyDetails from './modules/generic-strategies/views/StrategyDetails/index';
import Landing from './modules/landing/index';
import Terms from './modules/terms/index';
import './index.css';
import './assets/main.css';
import Footer from './modules/common/Footer/index';
import useAutoConnect from './hooks/useAutoConnect';
import Vaults from './modules/vaults/views';
import VaultDetails from './modules/vaults/views/VaultDetails';
import { useGetGeneralPlatformStats } from './hooks/useGetGeneralPlatformStats';
import Farms from './modules/farms/views';
import FarmDetails from './modules/farms/views/FarmDetails';

import {
	defaultChain,
} from './bloomify/config';
import ChainSwitcher from './modules/common/ChainSwitcher';
import { ENV_PROD, ENV_TESTING, featureAllowed } from './bloomify/env';

function App() {
	const { colorMode } = useColorMode();
	const { providedTokens } = useTokens();
	const { providedBalances } = useFetchBalances();
	const location = useLocation();
	useGetGeneralPlatformStats();

	useAutoConnect();

	const menuDark = {
		colors: {
			tertiary: '#05050F',
			card: '#191932',
			borderColor: '#05050F',
		},
		modal: { background: '#191932' },
	};
	const menuLight = { colors: { tertiary: '#EEF1FA' } };

	const vaultTypes = {
	  profitTaker: {
	    title: 'Profit Takers',
			description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'Automate taking profit. Deposit LP1. Yield from LP1 is taken out to a stable LP in a bank that autocompounds into more stables. Fully automated, shared gas costs, generate performance fee.',
		},
		accumulator: {
			title: 'Accumulators',
			description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'Use your "free money" (profit) to accumulate other tokens. Deposit LP1. Yield from LP1 accumulates LP2. LP2 autocompounds in a bank to buy more LP2. Fully automated, shared gas costs, generate performance fee.',
		},
		base: {
			title: 'Vaults',
			description: 'Please note that as of December 04, 2023 Bloomify is withdrawal only', // 'Bloomify Vaults are fully automated methods of reinvesting your profits back into your original LP. Fully automated, shared gas costs, generate performance fee.',
		},
	};

	useEffect(() => {
		const trackingId = 'UA-237111943-1';
		ReactGA.initialize(trackingId);
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
    <ThemeProvider theme={colorMode === 'dark' ? { ...dark, ...menuDark } as PancakeTheme : { ...light, ...menuLight } as PancakeTheme}>
        <ModalProvider>
            <TokenPricesContext.Provider value={providedTokens}>
                <TokensBalancesContext.Provider value={providedBalances}>
                    <Flex flexDir="column" overflowY="auto" overflowX="hidden" minH="100vh">
                        <TopNavBar />
                        <Box w="100vw" pt="70px" minH="calc(100vh - 200px)">
                            <Routes>
                                <Route path="/" element={<Landing />} />
                                {/* {defaultChain.shortName === 'bsc' && <Route path="/strategies" element={<Strategies />} /> } */}
                                {defaultChain.shortName === 'bsc' && <Route path="/strategies/:tag" element={<StrategyDetails />} /> }
                                {defaultChain.shortName === 'bsc' && <Route path="/portfolio" element={<UserClonedStrategies />} />}
                                {defaultChain.shortName === 'bsc' && <Route path="/portfolio/:tag/:hash" element={<ClonedStrategyView />} />}
                                <Route path="/terms-and-conditions" element={<Terms />} />
                                <Route path="/vaults" element={<Vaults info={vaultTypes.base} type="vault" key="3" />} />
                                <Route path="/accumulators" element={<Vaults info={vaultTypes.accumulator} type="accumulator" key="1" />} />
                                <Route path="/profit-takers" element={<Vaults info={vaultTypes.profitTaker} type="profit" key="2" />} />
                                <Route path="/vaults/:address" element={<VaultDetails key="5" />} />
                                <Route path="/accumulators/:address" element={<VaultDetails key="6" />} />
                                <Route path="/profit-takers/:address" element={<VaultDetails key="7" />} />
                                <Route path="/farms" element={<Farms />} />
                                <Route path="/farms/:address" element={<FarmDetails />} />
                            </Routes>
                        </Box>
                        { location.pathname !== '/' && featureAllowed(ENV_PROD) && <ChainSwitcher /> }
                        {location.pathname !== '/' && <Footer />}
                    </Flex>
                </TokensBalancesContext.Provider>
            </TokenPricesContext.Provider>
        </ModalProvider>
    </ThemeProvider>
	);
}

export default App;
