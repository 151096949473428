/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import useGeoLocation from 'react-ipgeolocation';

const useRevokeAccessGeolocation = () => {
	const location = useGeoLocation();
	const restrictedCountries = ['US', 'USA', 'CN', 'CHN', 'SY', 'SYR', 'KP', 'PRK', 'IR', 'IRN', 'RU', 'RUS', 'BY', 'BLR', 'CG', 'COG', 'IQ', 'IRQ', 'SD', 'SDN', 'SS', 'SSD', 'ZW', 'ZWE', 'CU', 'CUB', 'SC', 'SYC', 'MY', 'MYS', 'ON', 'ONT', 'UA-43', 'UA'];

	const [revokeAccess, setAccess] = useState(location.isLoading || restrictedCountries.includes(location.country));
	const [isLoading, setIsLoading] = useState(location.isLoading);

	useEffect(() => {
		setAccess(
			location.isLoading || restrictedCountries.includes(location.country),
		);
		setIsLoading(location.isLoading);
	}, [location.isLoading, location]);

	return { revokeAccess, isLoading };
};

export default useRevokeAccessGeolocation;
