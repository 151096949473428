/* eslint-disable react/jsx-indent */
import React from 'react';
import {
	Box, Text, Skeleton, Icon, Flex, Tooltip,
} from '@chakra-ui/react';
import { RiTimerFlashFill } from '@react-icons/all-files/ri/RiTimerFlashFill';
import {ResponsiveTooltip} from "../../common/Tooltip";

type Props = {
    label: string
    value: string|number
    timer?: string|number
}

function Stat({ label, value, timer }: Props) {
	const overflowTextStyle = () => ({
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		display: 'inline-block',
		maxW: { base: '300px', sm: '150px' },
	});

	return (
    <Box my="auto">
        <Text fontSize="sm" fontWeight="light" textColor="gray.400">{label}</Text>
        <Skeleton isLoaded={value !== undefined}>
            <Flex justify="flex-start" align="center">
                <ResponsiveTooltip label={value} fontSize="md">
                  <Text {...overflowTextStyle()} whiteSpace="nowrap" lineHeight="1.25" fontSize="lg" fontWeight="bold">{value}</Text>
                </ResponsiveTooltip>
                {timer ?
                    	<>
                        <ResponsiveTooltip label={`Claimable in ${timer}`} fontSize="md">
                            <Box maxH={5} ml={3} mr="2px">
                                <Icon h={5} as={RiTimerFlashFill} color="purple.500" />
                            </Box>
                        </ResponsiveTooltip>
                        <Text paddingTop="2px" fontSize="sm" fontWeight="bold">{timer}</Text>
                	    </>
                	: null}
            </Flex>
        </Skeleton>
    </Box>
	);
}

export default Stat;
