/* eslint-disable no-shadow */
import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import useWeb3 from '../../../hooks/useWeb3';
import { fetchVaults } from '../services/vault.service';

export const useAllVaults = (type) => {
	const web3 = useWeb3();
	const { account } = useWeb3React();

	const [vaults, setVaults] = useState<Array<any>>([]);

	useEffect(() => {
		get(account);
	}, [account]);

	const get = async (wallAccount) => {
		const vaults = await fetchVaults(web3, null, wallAccount, type);

		return setVaults(vaults.length ? vaults : null);
	};

	return vaults;
};
