import React, {useRef, useState, useEffect} from 'react';
import {TooltipProps, Box, Tooltip, useBreakpointValue} from '@chakra-ui/react';

type Props = TooltipProps & {
  children?: any
  desc?: string
}

export const ResponsiveTooltip = (props: Props) => {
  const { children, desc } = props;
  const isMobile = useBreakpointValue({ base: true, md: false });

  const ref = useRef(null);
  const [mobileTooltipBehaviour, useMobileTooltipBehaviour] = useState(false)

  const handleMobileClick = () => useMobileTooltipBehaviour(prev => !prev)


  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        useMobileTooltipBehaviour(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
    };
  }, [ref]);

  return (<>
    { isMobile ?
      <Tooltip
        label={desc}
        isOpen={isMobile && mobileTooltipBehaviour}
        {...props}
      >
        <Box display='flex'
             onTouchStart={handleMobileClick}
             ref={ref}>
        { children }
        </Box>
      </Tooltip> :
        <Tooltip
          label={desc}
          {...props}
        >
          { children }
        </Tooltip>
    }
  </>);
}

