/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
	Box,
	BoxProps,
	Button,
	Flex,
	Icon,
	Text,
	Tooltip,
	useBreakpointValue,
	Stack,
	useColorModeValue as mode,
	Link,
	keyframes,
	Badge,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { IoWarning } from '@react-icons/all-files/io5/IoWarning';
import { AiOutlineBank } from '@react-icons/all-files/ai/AiOutlineBank';
import { AiOutlineQuestionCircle } from '@react-icons/all-files/ai/AiOutlineQuestionCircle';
import _ from 'lodash';
import SliderGroupSection from '../../common/SliderGroupSection';
import TokenImage from '../../common/TokenImage';
import Stat from './Stat';
import { APRDetailsForDataBlock } from '../../common/APRDetails';
import { ACTION_COLOR_MAP } from '../../../helpers/consts';
import { ResponsiveTooltip } from '../../common/Tooltip';

type Props = BoxProps & {
  blocks: any;
  currentBlock: any;
  blockPercentages: Array<any>;
  modifyPercentages: (value: any, index: any) => void;
  show?: boolean;
  error?: boolean;
  id?: string;
  openTour?: () => void;
};

const animationKeyframes = keyframes`
      0% { box-shadow: inset 0 0 500px 0px rgb(237 100 166 / 12%), 0 0 20px 2px rgb(237 100 166 / 24%); }
      50% { box-shadow: inset 0 0 500px 0px rgb(237 100 166 / 2%), 0 0 20px 2px rgb(237 100 166 / 2%); }
      100% { box-shadow: inset 0 0 500px 0px rgb(237 100 166 / 12%), 0 0 20px 2px rgb(237 100 166 / 24%); }
    `;

const animation = `${animationKeyframes} 3s ease-in-out infinite`;

function GenericStrategyBlock({
	show = true, blocks, currentBlock, openTour, error, blockPercentages, modifyPercentages, id, ...rest
}: Props) {
	const isMobile = useBreakpointValue({ base: true, lg: false });

	return (
    <Flex flexDir="column" w="100%" h="full">
        {error ? (
            <Box>
                <Flex justify="center" align="center">
                    <Box maxW={10}>
                        <Icon as={IoWarning} color="pink.500" />
                    </Box>
                    <Text color="pink.500" fontSize="15px">
                        Block doesn't add up to 100% (<span style={{ fontWeight: 700 }}>{_.sum(blockPercentages)}%</span>)
                    </Text>
                </Flex>
            </Box>
        ) : (
            <Box h="24px" />
        )}
        <Box
            bg="bg-surface"
            w={!isMobile ? '420px' : '100%'}
            h={isMobile ? 'max-content' : 'full'}
            py={5}
            px={5}
            display="flex"
            flexDir="column"
            rounded="3xl"
            position="relative"
            {...rest}
            border={error ? '2px solid #ED64A6' : '2px solid var(--chakra-colors-bg-surface)'}>
            <Box>
                <Flex py={1} position="relative" top={-3} left={-2} justifyContent="flex-start">
                    <ResponsiveTooltip label="Need help? Try this quick tutorial." fontSize="md">
                        <Button py={1} zIndex={95} leftIcon={<Icon as={AiOutlineQuestionCircle} w="22px" h="22px" />} colorScheme="grey" variant="link" onClick={openTour} />
                    </ResponsiveTooltip>
                </Flex>
                <Flex justify="center" mt={{ base: -2, sm: -8 }} align="center">
                    <Box maxW={10}>
                        <TokenImage token={currentBlock.token.symbol.toLowerCase()} sm />
                    </Box>
                    <Text ml={2}>{currentBlock.name}</Text>
                </Flex>
                <Flex justify="center" align="center" mt={1}>
                    {currentBlock.token.platformId && <Badge colorScheme="yellow" my="2px" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                        <Flex align="center">
                            {currentBlock.token.platformId[0].toUpperCase() + currentBlock.token.platformId.slice(1, currentBlock.token.platformId.length)}
                        </Flex>
                    </Badge>}
                </Flex>
            </Box>

            <Stack direction={{ base: 'column', md: 'row' }} spacing={5} justify="center" align="center" pt={5}>
                {/* <Flex flexDir="column" my="auto" align="center" w="50%" bg={mode('#FAFAFA', 'blackAlpha.300')} rounded="lg" p={4}>
                    {currentBlock.aprDetails
                    && currentBlock.aprDetails.totalApy
                    	? <APRDetailsForDataBlock aprDetails={currentBlock.aprDetails} /> : <Stat label="No APY available yet" value="" />}
                </Flex> */}
            </Stack>

            {blockPercentages &&
          blockPercentages.map((bPercentage, index) => (
              <SliderGroupSection
                  data-tut={`reactour__step-${index + 1}-${id}`}
                  onChange={(value) => modifyPercentages(value, index)}
                  color={ACTION_COLOR_MAP[currentBlock.actionSettings[index].id]}
                  title={currentBlock.actionSettings[index].title}
                  subtitle={currentBlock.actionSettings[index].description}
                  value={bPercentage}
                  show={show ? !currentBlock.actionSettings[index].disable : show}
                  token={currentBlock.actionSettings[index].token}
                  actionOutputToBlock={_.isNumber(currentBlock.actionSettings[index].reinvestInTheBlockIndex)
                  	? blocks[currentBlock.actionSettings[index].reinvestInTheBlockIndex].name : currentBlock.name}
            />
          ))}
            {currentBlock.isBank
          	?
              <Flex
                  h="full"
                  w="full"
                  justify="center"
                  flexWrap="wrap"
                  mb={{ base: 2, xl: 0 }}
                  mt={5}>
                  <Flex
                      as={motion.div}
                      transition="transform 0.15s ease-in-out"
                      animation={animation}
                      w="full"
                      m={1}
                      flexDir="column"
                      alignSelf="stretch"
                      justify="center"
                      bg={mode('#FAFAFA', 'blackAlpha.300')}
                      rounded="lg"
                      px={{ base: 3, md: 5 }}
                      py={4}>

                      <Flex ml={1} py={1} justifyContent="center">
                          <Icon as={AiOutlineBank} w="26px" h="26px" mr={1} />
                      </Flex>
                      <Text fontSize="20px" mx="auto" lineHeight="20px" textAlign="center">
                          Bank
                      </Text>
                      <Text fontSize="sm" mt="2" mb="3" color="#8A88B7" textAlign="center">
                          {`The ${currentBlock.token.name} Bank takes all rewards from your LP and compounds it automatically back into more ${currentBlock.token.name}. Once deposited there is nothing more for you to do outside of watching your LP token quantity increase.`}
                      </Text>
                      {/* <Flex flexDir="column">
                          <Link href="#" target="_blank">
                              <Button variant="link">
                                  Read more <Icon as={FaExternalLinkAlt} mx="auto" pl={2} w={5} h={5} color="muted" />
                              </Button>
                          </Link>
                      </Flex> */}
                  </Flex>
              </Flex> : null}
        </Box>
    </Flex>
	);
}

export default GenericStrategyBlock;
