import { createStandaloneToast, Link } from '@chakra-ui/react';
import React from 'react';
import { formatWalletAddr } from '../helpers/formating';
import { chargeTheme } from '../assets/theme';
import { defaultChain } from '../bloomify/config';

const toast = createStandaloneToast({
	theme: chargeTheme,
});

//  Fired when the transaction hash is available.
export const onTransactionHash = (hash: string) => {
	toast({
		title: 'Transaction Sent',
		description: 'Your transaction was sent',
		status: 'info',
		duration: 5000,
		isClosable: true,
	});
	// console.log("On Transaction Hash")
	// console.log(hash)
};
// returns confirmation: Number, receipt: Object,
// latestBlockHash: String: Fired for every confirmation up to the 24th confirmation.
export const onConfirmation = (confirmationNumber: number, receipt: any) => {
	// console.log("On Confirmation")
	// console.log(confirmationNumber)
	// console.log(receipt)
};

// Fired when the transaction receipt is available. Receipts from contracts will have no logs property,
// but instead an events property with event names as keys and events as properties.
// See getPastEvents return values for details about the returned event object.
export const onReceipt = (receipt: any) => {
	console.log('On Receipt');
	if (receipt.status) {
		toast({
			title: 'Transaction Successful',
			description: React.createElement(
				'a',
				{ target: '_blank', href: `${defaultChain.scanUrl}/tx/${receipt.transactionHash}` },
				`Transaction Hash: ${formatWalletAddr(receipt.transactionHash)}`,
			),
			status: 'success',
			duration: 5000,
			isClosable: true,
		});
	}
};

// Fired immediately before transmitting the transaction request.
export const onSending = (payload: any) => {
	console.log('On Sending');
	// console.log(payload)
};

// Fired immediately after the request body has been written to the client, but before the transaction hash is received.
export const onSent = (payload: any) => {
	console.log('On Sent');
	// console.log(payload)
};

// Fired when there is an error
export const onError = (error: any, receipt: any) => {
	console.log('On Error');
	console.log(error);
	// console.log(receipt)
};
