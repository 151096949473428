import { useEffect } from 'react';
import {
	connectorLocalStorageKey,
	ConnectorNames,
} from '@pancakeswap-libs/uikit';
import useAuth from './useAuth';

const useAutoConnect = () => {
	const { login } = useAuth();

	useEffect(() => {
		const connectorId = window.localStorage.getItem(
			connectorLocalStorageKey,
		) as ConnectorNames;
		if (connectorId) {
			login(connectorId);
		}
	}, [login]);
};

export default useAutoConnect;
