/* eslint-disable no-undef */
import React from 'react';
import {
	Box, Button, Flex, Icon, Text, useColorModeValue as mode, useDisclosure, keyframes, useBreakpointValue, Badge,
} from '@chakra-ui/react';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { useParams } from 'react-router-dom';
import { IoWarning } from '@react-icons/all-files/io5/IoWarning';
import _ from 'lodash';
import { AiOutlineBank } from '@react-icons/all-files/ai/AiOutlineBank';
import { motion } from 'framer-motion';
import TokenImage from '../../common/TokenImage';

import DepositModal from './DepositModal/index';
import { useTokenPrices } from '../../../contexts/TokenPricesContext';
import Stat from '../../generic-strategies/common/Stat';
import SliderGroupSection from '../../common/SliderGroupSection';

import { useBoardrooms } from '../hooks/useBoardrooms';
import { formatValue } from '../../../helpers/util';
import WithdrawModal from './WithdrawModal';
import { APRDetailsForDataBlock } from '../../common/APRDetails';
import { ACTION_COLOR_MAP } from '../../../helpers/consts';

type Props = {
  isEditSettings: boolean;
  blockIndex: number;
  blocks: any;
  currentBlock: any;
  blockPercentages: any;
  error?: boolean;
  setPercentages: (settings: any) => void;
  onDepositSuccess: () => void;
  onWithdrawSuccess?: () => void;
  deprecated?: boolean;
};

function ClonedStrategyBlock({
	error,
	blockIndex,
	blocks,
	currentBlock,
	isEditSettings,
	blockPercentages,
	deprecated = false,
	setPercentages,
	onDepositSuccess,
	onWithdrawSuccess,
	...rest
}: Props) {
	const animationKeyframes = keyframes`
      0% { box-shadow: inset 0 0 500px 0px rgb(237 100 166 / 12%), 0 0 20px 2px rgb(237 100 166 / 24%); }
      50% { box-shadow: inset 0 0 500px 0px rgb(237 100 166 / 2%), 0 0 20px 2px rgb(237 100 166 / 2%); }
      100% { box-shadow: inset 0 0 500px 0px rgb(237 100 166 / 12%), 0 0 20px 2px rgb(237 100 166 / 24%); }
    `;

	const animation = `${animationKeyframes} 3s ease-in-out infinite`;

	const { hash: strategyAddr } = useParams();
	const { getTokenPrice } = useTokenPrices();

	blockPercentages = blockPercentages.slice(0, currentBlock.actionSettings.length);

	const { isOpen: isOpenDepositModal, onOpen: onOpenDepositModal, onClose: onCloseDepositModal } = useDisclosure();
	const { isOpen: isOpenWithdrawModal, onOpen: onOpenWithdrawModal, onClose: onCloseWithdrawModal } = useDisclosure();

	const tokensAmount = currentBlock.blockBalance / 1e18;
	const tokensValue = tokensAmount * getTokenPrice(currentBlock.token);

	const pendingRewardsDollar = currentBlock.blockPendingRewards ? (currentBlock.blockPendingRewards.length > 1
		? currentBlock.blockPendingRewards.reduce((a, b) => (a.hex / 1e18) * getTokenPrice('CHARGE') + (b.hex / 1e18) * getTokenPrice('STATIC'))
		: (currentBlock.blockPendingRewards[0].hex / 1e18) * getTokenPrice(currentBlock.outToken.toLowerCase())) : 0;

	const onDeposit = () => {
		onCloseDepositModal();
		onDepositSuccess();
	};

	const onWithdraw = () => {
		onCloseWithdrawModal();
		onWithdrawSuccess();
	};

	const { withdrawTimer, claimTimer } = useBoardrooms(currentBlock.epoch, currentBlock.nextEpochPoint, currentBlock.lastEpochClaimed / 1, currentBlock.lastEpochDeposited / 1);

	const statWrapperStyles = () => ({
		alignSelf: 'stretch',
		w: { base: 'calc(100% - 16px)', sm: 'calc(50% - 16px)' },
		bg: mode('#FAFAFA', 'blackAlpha.300'),
		rounded: 'lg',
		p: 4,
		m: 2,
	});

	const isMobile = useBreakpointValue({ base: true, lg: false });

	return (
    <Flex flexDir="column" w="100%">
        {error && isEditSettings ? (
            <Box>
                <Flex justify="center" align="center">
                    <Box maxW={10}>
                        <Icon as={IoWarning} color="pink.500" />
                    </Box>
                    <Text ml={2} color="pink.500" fontSize="sm">
                        Block doesn't add up to 100% (
                        <span style={{ fontWeight: 700 }}>
                            {_.sum(blockPercentages)}
                            %
                        </span>
                        )
                    </Text>
                </Flex>
            </Box>
        ) : (
            <Box h="24px" />
        )}
        <Box
            bg="bg-surface"
            w={!isMobile ? '420px' : '100%'}
            h={isMobile ? 'max-content' : 'full'}
            py={5}
            px={5}
            display="flex"
            flexDir="column"
            rounded="3xl"
            position="relative"
            {...rest}>
            {isOpenDepositModal && <DepositModal
                isOpen={isOpenDepositModal}
                onClose={onDeposit}
                blockIndex={blockIndex}
                token={currentBlock.token}
                strategyAddress={strategyAddr} />}
            {isOpenWithdrawModal && <WithdrawModal
                withdrawZap={currentBlock.withdrawZap}
                depositedAmount={currentBlock.blockBalance}
                depositedValue={tokensValue}
                isOpen={isOpenWithdrawModal}
                onClose={onWithdraw}
                blockIndex={blockIndex}
                token={currentBlock.token}
                strategyAddress={strategyAddr} />}
            <Box>
                <Flex justify="center" align="center">
                    <Box maxW={10}>
                        <TokenImage token={currentBlock.token.symbol.toLowerCase()} sm />
                    </Box>
                    <Text ml={2}>{currentBlock.name}</Text>
                </Flex>
                <Flex justify="center" align="center" mt={1}>
                    {currentBlock.token.platformId && <Badge colorScheme="yellow" my="2px" mx={1} fontSize="12px" variant={mode('solid', 'subtle')}>
                        <Flex align="center">
                            {currentBlock.token.platformId[0].toUpperCase() + currentBlock.token.platformId.slice(1, currentBlock.token.platformId.length)}
                        </Flex>
                    </Badge>}
                </Flex>
            </Box>
            <Flex justify="center" align="center" pt={5} flexWrap="wrap">
                {/* <Flex {...statWrapperStyles()}>
                    {currentBlock.aprDetails && currentBlock.aprDetails.totalApy ?
                        <APRDetailsForDataBlock aprDetails={currentBlock.aprDetails} />
                   	: <Stat label="No APY available yet" value="" /> }
                </Flex> */}
                <Flex {...statWrapperStyles()}>
                    <Stat label="Deposit" value={`${formatValue(tokensAmount)} ($${formatValue(tokensValue)})`} timer={withdrawTimer} />
                </Flex>
                <Flex {...statWrapperStyles()}>
                    <Stat
                        label={currentBlock.noProfit ? 'Block autocompounds the rewards' : 'Pending Rewards'}
                        value={currentBlock.noProfit ? '' : `$${formatValue(pendingRewardsDollar)}`}
                        timer={claimTimer} />

                </Flex>
            </Flex>

            {blockPercentages.map((bPercentage, index) => (
                <SliderGroupSection
                    onChange={(value) => {
            	        const settings = blockPercentages;
                    	settings[index] = value;

            	        return setPercentages(settings);
                    }}
                    key={index}
                    color={ACTION_COLOR_MAP[currentBlock.actionSettings[index].id]}
                    title={currentBlock.actionSettings[index].title}
                    subtitle={currentBlock.actionSettings[index].description}
                    value={bPercentage}
                    show={isEditSettings ? !currentBlock.actionSettings[index].disable : isEditSettings}
                    legos={!isEditSettings}
                    token={currentBlock.actionSettings[index].token}
                    actionOutputToBlock={_.isNumber(currentBlock.actionSettings[index].reinvestInTheBlockIndex)
                    	? blocks[currentBlock.actionSettings[index].reinvestInTheBlockIndex].name : currentBlock.name}
                />
            ))}

            {currentBlock.isBank
          	?
              <Flex
                  h="full"
                  w="full"
                  justify="center"
                  flexWrap="wrap"
                  mb={{ base: 2, xl: 0 }}
                  mt={5}>
                  <Flex
                      as={motion.div}
                      transition="transform 0.15s ease-in-out"
                      animation={animation}
                      w="full"
                      m={1}
                      flexDir="column"
                      alignSelf="stretch"
                      justify="center"
                      bg={mode('#FAFAFA', 'blackAlpha.300')}
                      rounded="lg"
                      px={{ base: 3, md: 5 }}
                      py={4}>

                      <Flex ml={1} py={1} justifyContent="center">
                          <Icon as={AiOutlineBank} w="26px" h="26px" mr={1} />
                      </Flex>
                      <Text fontSize="20px" mx="auto" lineHeight="20px" textAlign="center">
                          Bank
                      </Text>
                      <Text fontSize="sm" mt="2" mb="3" color="#8A88B7" textAlign="center">
                          {`The ${currentBlock.token.name} Bank takes all rewards from your LP and compounds it automatically back into more ${currentBlock.token.name}. Once deposited there is nothing more for you to do outside of watching your LP token quantity increase.`}
                      </Text>
                      {/* <Flex flexDir="column">
                          <Link href="#" target="_blank">
                              <Button variant="link">
                                  Read more <Icon as={FaExternalLinkAlt} mx="auto" pl={2} w={5} h={5} color="muted" />
                              </Button>
                          </Link>
                      </Flex> */}
                  </Flex>
              </Flex> : null}

            {/* {!isEditSettings && (
            	<Flex w="100%" justify="center" align="center">
                <Button
                    id="deposit"
                    data-tut="reactour__step-legos__deposit"
                    my="auto"
                    mt={4}
                    leftIcon={<FaPlus />}
                    isLoading={false}
                    disabled={deprecated}
                    w="48%"
                    mr={1}
                    bg={mode('white', 'gray.800')}
                    border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}
                    onClick={onOpenDepositModal}
            >
                    Deposit
                </Button>
                <Button
                    id="deposit"
                    data-tut="reactour__step-legos__deposit"
                    my="auto"
                    mt={4}
                    leftIcon={<FaWallet />}
                    isLoading={false}
                    disabled={deprecated}
                    w="48%"
                    ml={1}
                    bg={mode('white', 'gray.800')}
                    border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}
                    onClick={onOpenWithdrawModal}
                    >
                    Withdraw
                </Button>
            	</Flex>
            )} */}
            {/* {!isEditSettings && (
            <Button
                id="deposit"
                data-tut="reactour__step-legos__deposit"
                my="auto"
                mt={4}
                leftIcon={<FaPlus />}
                isLoading={false}
                disabled={deprecated}
                w="100%"
                mr={1}
                bg={mode('white', 'gray.800')}
                border={mode('2px solid rgb(0, 0, 0)', '2px solid white')}
                onClick={onOpenDepositModal}
            >
                Deposit
                </Button>
            )} */}
        </Box>
    </Flex>
	);
}

export default ClonedStrategyBlock;
