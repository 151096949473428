/* eslint-disable dot-notation */
/* eslint-disable quotes */
import { ContractCallContext } from 'ethereum-multicall';
import Web3 from 'web3';
import _ from 'lodash';
import { defaultChain } from '../../../bloomify/config';
import { doMulticall } from '../../../service/multicall.service';
import {
	onConfirmation, onError, onReceipt, onSending, onSent, onTransactionHash,
} from '../../../utils/web3Handlers';
import { getExternallyCalculatedStatistics } from '../../../service/remote.service';
import {getGasPriceOptions} from "../../../utils/gasUtils";

const _generateBlockContext = (blockAddress: string): ContractCallContext[] => {
	let minimumInfo = _generateBlockMinimumInfoNeededContext(blockAddress);

	minimumInfo = minimumInfo.concat([
		{
			reference: 'blockDepositToken',
			contractAddress: blockAddress,
			abi: defaultChain.deployments.IBlock.abi,
			calls: [
				{
					reference: 'blockDepositToken',
					methodName: 'getDepositToken',
					methodParameters: [],
				},
			],
		},
		{
			reference: 'blockOutTokens',
			contractAddress: blockAddress,
			abi: defaultChain.deployments.IBlock.abi,
			calls: [
				{
					reference: 'blockOutTokens',
					methodName: 'getOutTokens',
					methodParameters: [],
				},
			],
		},
	]);

	return minimumInfo;
};

const _generateBlockMinimumInfoNeededContext = (blockAddress: string): ContractCallContext[] => {
	const defaultContext = [
		{
			reference: 'blockBalance',
			contractAddress: blockAddress,
			abi: defaultChain.deployments.IBlock.abi,
			calls: [
				{
					reference: 'blockBalance',
					methodName: 'balance',
					methodParameters: [],
				},
			],
		},
		{
			reference: 'supportsInterface',
			contractAddress: blockAddress,
			abi: defaultChain.deployments.IBlock.abi,
			calls: [
				{
					reference: 'supportsInterface',
					methodName: 'supportsInterface',
					methodParameters: ['0x3650780e'],
				},
			],
		},
	];

	return defaultContext;
};

const pendingRewardsBlockContext = (blockAddress: string): ContractCallContext[] =>
	[{
		reference: 'blockPendingRewards',
		contractAddress: blockAddress,
		abi: defaultChain.deployments.IRewardable.abi,
		calls: [
			{
				reference: 'blockPendingRewards',
				methodName: 'pendingRewards',
				methodParameters: [],
			},
		],
	},
	];

export const getGeneralBlockInfo = async (web3: Web3, blockAddress: string) => {
	const context = _generateBlockMinimumInfoNeededContext(blockAddress);
	const multicall = await doMulticall(web3, context);
	let pendingRewards = null;

	if (multicall.supportsInterface[0]) {
		pendingRewards = (await doMulticall(web3, pendingRewardsBlockContext(blockAddress))).blockPendingRewards[1];
	}

	return {
		blockBalance: multicall.blockBalance[0].hex,
		blockPendingRewards: pendingRewards,
		blockApr: 0,
		lastEpochDeposited: null,
		lastEpochClaimed: null,
		harvestable: null,
		withdrawable: null,
		epoch: null,
		nextEpochPoint: null,
	};
};

export const getAllBlockInfo = async (web3: Web3, blockAddress: string) => {
	const context = _generateBlockContext(blockAddress);
	const multicall = await doMulticall(web3, context);

	let pendingRewards = null;

	if (multicall.supportsInterface[0]) {
		pendingRewards = (await doMulticall(web3, pendingRewardsBlockContext(blockAddress))).blockPendingRewards[1];
	}

	return {
		depositToken: multicall.blockDepositToken[0],
		outToken: multicall.blockOutTokens[0],
		blockBalance: multicall.blockBalance[0].hex,
		blockPendingRewards: pendingRewards,
		blockApr: 0,
		lastEpochDeposited: null,
		lastEpochClaimed: null,
		harvestable: null,
		withdrawable: null,
		epoch: null,
		nextEpochPoint: null,
	};
};

export const getBlockAPR = async (blockAddress) => {
	let generalStats = JSON.parse(localStorage.getItem('calculatedStats'));

	if (!generalStats) {
		await getExternallyCalculatedStatistics();
		generalStats = JSON.parse(localStorage.getItem('calculatedStats'));
	}

	generalStats = generalStats ? generalStats.result : null;

	let calculatedBlockApr = generalStats ? generalStats.aprs[blockAddress] : null;

	if (!calculatedBlockApr && generalStats) calculatedBlockApr = _.find(generalStats.aprs, (_val, addr) => blockAddress.toLowerCase() === addr.toLowerCase());

	return calculatedBlockApr;
};

export const depositInBlock = async (
	web3: Web3,
	strategyAddress: string,
	tokenAddr: string,
	blockIndex: number,
	amount: string | any,
	walletAddress: string,
	onErrorCallback?: any,
	onReceiptCallback?: any,
) => {
	const strategyRegistry = new web3.eth.Contract(defaultChain.deployments.StrategyRunner.abi, strategyAddress, { from: walletAddress });

	if (tokenAddr === defaultChain.addressBook.tokens.WBNB.address) {
		return await strategyRegistry.methods
			.depositEther(blockIndex, 0)
			.send({ value: amount })
			.on('sending', onSending)
			.on('sent', onSent)
			.on('transactionHash', onTransactionHash)
			.on('receipt', onReceiptCallback || onReceipt)
			.on('confirmation', onConfirmation)
			.on('error', onErrorCallback || onError);
	}
	const gasPrices = await getGasPriceOptions(web3);
	return await strategyRegistry.methods
		.deposit(tokenAddr, amount, blockIndex, 0)
		.send({...gasPrices})
		.on('sending', onSending)
		.on('sent', onSent)
		.on('transactionHash', onTransactionHash)
		.on('receipt', onReceiptCallback || onReceipt)
		.on('confirmation', onConfirmation)
		.on('error', onErrorCallback || onError);
};
