import { Center, Button, Heading, Flex } from '@chakra-ui/react';
import React from 'react';
import {useWalletProvider} from "../../../hooks/useWalletProvider";
import {useColorModeValue as mode} from "@chakra-ui/react";

const NotConnected = () => {
    const { onPresentConnectModal, logoutWallet, onPresentAccountModal, wallet,} = useWalletProvider()

    return (
        <Center h="calc(100vh - 75px)">
            <Flex flexDir="column">
                <Heading mx="auto" size="sm">Connect your your wallet</Heading>
                <Button mx="auto" mt={5} onClick={onPresentConnectModal} w="150px" bg={mode('white', 'gray.700')} border={mode("2px solid rgb(0, 0, 0)", "2px solid white")}>Connect Wallet</Button>
            </Flex>
        </Center>
    );
};

export default NotConnected;
