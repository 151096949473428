/* eslint-disable react/no-children-prop */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import {
	BoxProps, Flex, useBreakpointValue, Text, Select, FormControl, InputGroup, InputLeftElement, Input,
} from '@chakra-ui/react';
import _ from 'lodash';
import { SearchIcon } from '@chakra-ui/icons';
import { useWeb3React } from '@web3-react/core';

type Props = BoxProps & {
  farms: any[];
  filteredFarms: (strats: any[]) => void;
  clearFilters?: boolean;
};

function FarmFilters({ farms = [], filteredFarms, clearFilters }: Props) {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const useFarms = farms.filter((f) => !f.deprecated || (f.deprecated && f.depositDetails?.amount));

	const { account } = useWeb3React();

	const allExistingTokens = [
		{ value: 'all', label: 'All' },
		..._(useFarms)
			.map((s) => s.depositToken.symbol)
			.filter((x) => x)
			.orderBy()
			.uniq()
			.map((item) => ({ value: item, label: item.toUpperCase() }))
			.value(),
	];

	const typeFilter = [
		{ value: 'all', label: 'All' },
		{ value: 'mine', label: 'My Farms' },
	];

	const [selectedTypeFilter, setSelectedTypeFilter] = useState<any>('all');

	const [selectedTokensInvolved, setSelectedTokensInvolved] = useState<any>('all');

	const [searchWord, setSearchWord] = useState<any>('');

	useEffect(() => {
		apply();
	}, [
		selectedTypeFilter,
		searchWord,
		selectedTokensInvolved,
	]);

	useEffect(() => {
		if (clearFilters) {
			setSearchWord('');
			setSelectedTypeFilter('all');
			setSelectedTokensInvolved('all');
		}
	}, [clearFilters]);

	const apply = () => {
		let filteredFarmsList = _.cloneDeep(useFarms);

		if (
			(!account || selectedTypeFilter === 'all') &&
      selectedTokensInvolved === 'all' &&
      !searchWord
		) {
			filteredFarms(filteredFarmsList);
			return;
		}

		filteredFarmsList = _.filter(
			filteredFarmsList,
			(strat) =>
				(selectedTypeFilter === 'all' || (account && strat.depositDetails.amount > 0)) &&
        (!searchWord || strat.name.toLowerCase().includes(searchWord.toLowerCase())) &&
        (selectedTokensInvolved === 'all' || strat.depositToken.symbol === selectedTokensInvolved),
		);

		filteredFarms(filteredFarmsList);
		return;
	};

	return (
    <Flex py={1} position="relative" top={-3} left={-2} direction={isMobile ? 'column' : 'row'}>
      <FormControl mr={3} pt={6}>
        <InputGroup>
          <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
          <Input
              value={searchWord}
              fontSize="15px"
              size="lg"
              placeholder="Search by name"
              onChange={(e: any) => {
            	setSearchWord(e.target.value);
              }}
          />
        </InputGroup>
      </FormControl>
      {!!account && <FormControl py={isMobile ? 3 : 0} mr={3}>
        <Text fontSize="sm" pb="2px" opacity="0.8">Farms</Text>
        <Select
            maxW="257px"
            fontSize="15px"
            size="lg"
            onChange={(e) => {
          	setSelectedTypeFilter(e.target.value);
            }}>
          {typeFilter.map((option: any, key: number) => (
            <option key={key} selected={option.value === selectedTypeFilter} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </FormControl>}
      <FormControl py={isMobile ? 3 : 0}>
        <Text fontSize="sm" pb="2px" opacity="0.8">Base Token</Text>
        <Select
            maxW="257px"
            fontSize="15px"
            size="lg"
            onChange={(e) => {
          	setSelectedTokensInvolved(e.target.value);
            }}>
          {allExistingTokens.map((option: any, key: number) => (
            <option key={key} selected={option.value === selectedTokensInvolved} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </FormControl>
    </Flex>
	);
}

export default FarmFilters;
