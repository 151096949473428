/* eslint-disable no-shadow */
/* eslint-disable no-mixed-operators */
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { useBoolean } from '@chakra-ui/react';
import _ from 'lodash';
import useWeb3 from '../../../hooks/useWeb3';

import { useTokenPrices } from '../../../contexts/TokenPricesContext';
import { useDidUpdate } from '../../../hooks/useDidUpdate';
import {
	fetchVaults, withdrawDepositedAmountFromVault, withdrawAllFromVault, depositAmountInVault, depositAllInVault, claimAllAccumulatedTokensFromVault,
} from '../services/vault.service';

export const useSingleVaultDetails = (vaultAddress, fetchedVault?) => {
	const web3 = useWeb3();
	const { account } = useWeb3React();
	const { getTokenPrice, tokens } = useTokenPrices();

	// Containers and lists
	const [vaultInfo, setVaultInfo] = useState<any>();

	// Action related
	const [isWithdrawing, setIsWithdrawing] = useBoolean(false);
	const [isClaimingAccumulated, setIsClaimingAccumulated] = useBoolean(false);
	const [isDepositing, setIsDepositing] = useBoolean(false);

	const withdrawAmount = async (amount, onError, onReceipt) => {
		setIsWithdrawing.on();
		const response = await withdrawDepositedAmountFromVault(web3, amount, vaultAddress, account, onError, onReceipt);

		if (response.status) {
			await getDetails(true);
		}

		setIsWithdrawing.off();
	};

	const depositAmount = async (amount, onError, onReceipt, token) => {
		setIsDepositing.on();
		const response = await depositAmountInVault(web3, amount, vaultAddress, account, onError, onReceipt, token);

		if (response.status) {
			await getDetails(true);
		}

		setIsDepositing.off();
	};

	const depositAll = async (onError, onReceipt) => {
		setIsDepositing.on();
		const response = await depositAllInVault(web3, vaultAddress, account, onError, onReceipt);

		if (response.status) {
			await getDetails(true);
		}

		setIsDepositing.off();
	};

	const withdrawAll = async (onError, onReceipt) => {
		setIsWithdrawing.on();
		const response = await withdrawAllFromVault(web3, vaultAddress, account, onError, onReceipt);

		if (response.status) {
			await getDetails(true);
		}

		setIsWithdrawing.off();
	};

	const claimAccumulated = async () => {
		setIsClaimingAccumulated.on();
		const response = await claimAllAccumulatedTokensFromVault(web3, vaultAddress, account);

		if (response.status) {
			await getDetails(true);
		}

		setIsClaimingAccumulated.off();
	};

	// Gets the details of the strategy instance and the individual blocks
	const getDetails = async (invalidateCache = false) => {
		if (tokens && tokens.name && getTokenPrice('STATIC')) {
			const vault = !invalidateCache ? (fetchedVault || (await fetchVaults(web3, vaultAddress, account))[0]) : (await fetchVaults(web3, vaultAddress, account))[0];

			if (invalidateCache) {
				if (window.history.state.usr && window.history.state.usr.vault) {
					const usr = { ...window.history.state.usr };
					delete usr.vault;
					window.history.replaceState({ ...window.history.state, usr }, '');
				}
			}

			setVaultInfo(vault);
		}
	};

	useDidUpdate(() => {
		getDetails(true);
	}, [account]);

	useEffect(() => {
		getDetails();
	}, [account]);

	useEffect(() => {
		getDetails();
	}, [tokens]);

	return {
		vaultInfo,
		isWithdrawing,
		isClaimingAccumulated,
		isDepositing,
		withdrawAmount,
		withdrawAll,
		depositAmount,
		depositAll,
		getDetails,
		claimAccumulated,
	};
};
